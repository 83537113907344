<div class="row bg-white h-100">
  <div class="col-2 pt-3 border-right">
    <div class="card">
      <div class="card-body p-0">
        <ul class="list-group list-group-flush">
          <!-- <li class="list-group-item list-group-item-action text-left"
              id="menu-dashboard"
              [ngClass]="{'active': showTab('menu-dashboard')}"
              (click)="changeTab($event)">
            <span class="material-icons md-16">dashboard</span>
            <span i18n="rep|Main buttons - dashboard@@rep.main.button.dashboard">Dashboard</span>
          </li>
          <li class="list-group-item list-group-item-action text-left"
              id="menu-favorites"
              [ngClass]="{'active': showTab('menu-favorites')}"
              (click)="changeTab($event)">
            <span class="material-icons md-16">grade</span>
            <span i18n="rep|Main buttons - favorites@@rep.main.button.favorites">Favorites</span>
          </li> -->
          <li class="list-group-item list-group-item-action text-left d-flex align-items-center"
              id="menu-reports"
              [ngClass]="{'active': showTab('menu-reports') || like(activeTab, 'report') || showTab('menu-compare')}"
              (click)="changeTab($event)">
            <i class="material-icons mr-2">description</i>
            <span i18n="rep|Main buttons - reports@@rep.main.button.reports">Reports</span>
          </li>
          <!-- <li class="list-group-item list-group-item-action text-left"
              id="menu-logs"
              [ngClass]="{'active': showTab('menu-logs')}"
              (click)="changeTab($event)">
            <span class="material-icons md-16">edit</span>
            <span i18n="rep|Main buttons - logs@@rep.main.button.logs">Logs</span>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
  <!--<div class="col-10 px-0 pt-3" *ngIf="showTab('menu-dashboard')">
    <div class="px-3 my-3">
      <h1>
        <ng-container *ngIf="userMode == 'editDashboard'">Edit dashboard:</ng-container>
        {{dashboard.name}}
      </h1>
      <div class="reportTopButtons">
        <ng-container *ngIf="userMode != 'editDashboard'; else: editDashboardButtons">
          <div class="btn-group">
            <button class="btn  btn-sm btn-light-gray" type="button">
              {{dashboard.name}}
            </button>
            <button type="button"
                    class="btn btn-sm btn-light-gray dropdown-toggle dropdown-toggle-split"
                    data-toggle="dropdown">
            </button>
            <div class="dropdown-menu dropdown-menu-right">
              <button class="dropdown-item" type="button" *ngFor="let dashboard of dashboards" (click)="selectDashboard(dashboard.id)">
                {{dashboard.name}}
              </button>
            </div>
          </div>
          <div class="btn-group">
            <button class="btn btn-sm btn-light-gray" type="button" (click)="userMode = 'editDashboard'">
              <span class="material-icons md-16">dashboard</span>
              <span i18n="rep|Main buttons - edit dashboard@@rep.main.button.editDashboard">Edit dashboard</span>
            </button>
            <button type="button"
                    class="btn btn-sm btn-light-gray dropdown-toggle dropdown-toggle-split"
                    data-toggle="dropdown">
            </button>
            <div class="dropdown-menu dropdown-menu-right">
              <button class="dropdown-item" type="button" (click)="newDashboard()">
                <span class="material-icons md-16">library_add</span>
                <span i18n="rep|Main buttons - new dashboard@@rep.main.button.newDashboard">New dashboard</span>
              </button>
              <button class="dropdown-item" type="button"
                      (click)="showMessageWindow('Are you sure you want to delete the dashboard?', 'confirm', deleteDashboard, dashboard.id)">
                <span class="material-icons md-16">delete</span>
                <span i18n="rep|Main buttons - delete dashboard@@rep.main.button.deleteDashboard">Delete dashboard</span>
              </button>
            </div>
          </div>
          <button class="btn btn-light-gray btn-sm">
            <span class="material-icons md-16">refresh</span>
            <span i18n="rep|Main buttons - refresh all@@rep.main.button.refteshAll">Refresh all</span>
          </button>
        </ng-container>
        <ng-template #editDashboardButtons>
          <button class="btn btn-light-gray btn-sm" (click)="userMode = ''">
            <i class="material-icons md-16">keyboard_backspace</i>
            <span i18n="rep|Main buttons - back@@rep.main.button.back">Back</span>
          </button>
          <button class="btn btn-light-gray btn-sm" (click)="userAction = 'renameDashboard'">
            <i class="material-icons md-16">create</i>
            <span i18n="rep|Main buttons - change dashboard name@@rep.main.button.changeDashboardName">Change dashboard name</span>
          </button>
          <button type="button" class="btn btn-light-gray btn-sm" data-toggle="modal" data-target=".add-chart"(click)="createChart()">
            <i class="material-icons md-16">add</i>
            <span i18n="rep|Main buttons - add chart@@rep.main.button.addChart">Add chart</span>
          </button>
          <button class="btn btn-light-gray btn-sm" (click)="userAction = 'addChart'">
            <i class="material-icons md-16">add</i>
            <span>Add chart</span>
          </button>
        </ng-template>
      </div>
    </div>

    <div class="border-top mt-2 dashboard-charts">
      <div class="dashboard-chart border-right border-bottom p-3"
          *ngFor="let chart of dashboard.reports"
          [ngClass]="{'chart-size-1': chart.size==1,
                      'chart-size-2': chart.size==2,
                      'chart-size-4': chart.size==4}">
        <div class="d-flex align-items-center justify-content-between mb-2">
          <nav>
            <ol class="breadcrumb bg-white m-0 p-0 font-small">
              <li class="breadcrumb-item">{{arrTitleSearch(reportGroup, chart.reportId)}}</li>
              <li class="breadcrumb-item active">{{arrTitleSearch(reportItemAll, chart.reportId)}}</li>
            </ol>
          </nav>
          <div class="btn-group" *ngIf="userMode == 'editDashboard'; else: buttonRefresh">
            <button type="button"
                    class="btn btn-xs btn-light-gray dropdown-toggle dropdown-toggle-split"
                    data-toggle="dropdown">
            </button>
            <div class="dropdown-menu dropdown-menu-right">
              <button class="dropdown-item" type="button" data-toggle="modal" data-target=".add-chart" (click)="editChart(chart.id)">
                <span class="material-icons md-16">library_add</span>
                <span i18n="rep|Main buttons - property@@rep.main.button.property">Property</span>
              </button>
              <button class="dropdown-item" type="button"
                      (click)="showMessageWindow(systemMessagesTexts['deleteChart'], 'confirm', deleteChart, chart.id)">
                <span class="material-icons md-16">delete</span>
                <span i18n="rep|Main buttons - delete@@rep.main.button.delete">Delete</span>
              </button>
            </div>
          </div>
          <ng-template #buttonRefresh>
            <button class="btn btn-xs btn-ico btn-light-gray refresh">
              <i class="material-icons">refresh</i>
            </button>
          </ng-template>
        </div>
        <google-chart [options]="{height: '100%', chartArea:{width:'100%',height:'100%'}}"
                      [type]="chart.show"
                      [data]="reportDiagramData[chart.reportId]"
                      [columns]="reportDiagramColumns[chart.reportId]">
        </google-chart>
      </div>
    </div>

    <div *ngIf="userMode == 'addDashboard'" class="ms-modal-message">
      <div class="modal-content w-50">
        <div class="modal-header bg-info">
          <h5 class="modal-title">New dashboard</h5>
          <button type="button" class="close" (click)="userMode = ''">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <label for="nameNewDashboard" class="m-0">Name for new dashboard:</label>
          <input type="text" class="form-control" id="nameNewDashboard" name="nameNewDashboard" #nameNewDashboard>
        </div>
        <div class="modal-footer bg-light-gray border-0">
          <button type="button"
                  class="btn btn-sm btn-blue"
                  (click)="addDashboard(nameNewDashboard.value)"
                  i18n="rep|Main buttons - ok@@rep.main.button.ok">
            OK
          </button>
          <button type="button"
                  class="btn btn-sm btn-secondary"
                  (click)="userMode = ''"
                  i18n="rep|Main buttons - cancel@@rep.main.button.cancel">
            Cancel
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="userAction == 'renameDashboard'" class="ms-modal-message">
      <div class="modal-content w-50">
        <div class="modal-header bg-info">
          <h5 class="modal-title">Select report</h5>
          <button type="button" class="close" (click)="userAction = ''">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <label for="nameDashboard" class="m-0">New name for dashboard:</label>
          <input type="text" class="form-control" id="nameDashboard" name="nameDashboard" #nameDashboard>
        </div>
        <div class="modal-footer bg-light-gray border-0">
          <button type="button"
                  class="btn btn-sm btn-blue"
                  (click)="renameDashboard(nameDashboard.value)"
                  i18n="rep|Main buttons - ok@@rep.main.button.ok">
            OK
          </button>
          <button type="button"
                  class="btn btn-sm btn-secondary"
                  (click)="userAction = ''"
                  i18n="rep|Main buttons - cancel@@rep.main.button.cancel">
            Cancel
          </button>
        </div>
      </div>
    </div>

    <form #chartForm="ngForm">
      <div class="modal fade bd-example-modal-l add-chart" data-backdrop="static" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-l">
          <div class="modal-content">
            <div class="modal-header">
              <h5 *ngIf="activeState=='action-update'" class="modal-title" i18n="rep|Modal title - property chart@@rep.modal.title.propertyChart">
                Property chart
              </h5>
              <h5 *ngIf="activeState=='action-append'" class="modal-title" i18n="rep|Modal title - add chart@@rep.modal.title.addChart">
                Add chart
              </h5>
              <button #modalAddChart type="button" class="close" data-dismiss="modal" aria-label="Close" #closebutton>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row mb-3">
                <div class="col-4" i18n="rep|Modal header - report@@rep.modal.header.report">Report</div>
                <div class="col-8">
                  <div class="input-group">
                    <input type="text"
                          id="reportChart"
                          name="reportChart"
                          class="form-control form-control-md text-uppercase"
                          placeholder="{{arrTitleSearch(reportItemAll, dashboardChart.reportId)}}"
                          disabled>
                    <div class="input-group-append">
                      <button type="button"
                              class="btn btn-xs btn-blue"
                              (click)="userAction = 'selectReport'"
                              i18n="rep|Modal button - choose@@rep.modal.button.choose">
                        Choose
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-4" i18n="rep|Modal header - configuration@@rep.modal.header.configuration">Configuration</div>
                <div class="col-8">
                  <select class="form-control form-control-sm mb-1"
                          [ngModel]="dashboardChart.configurationId"
                          (input)="dashboardChart.configurationId = +$event.target.value"
                          name="chartConfiguration"
                          id="chartConfiguration">
                    <option value="1">U6</option>
                    <option value="2">Test</option>
                  </select>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-4" i18n="rep|Modal header - show@@rep.modal.header.show">Show</div>
                <div class="col-8">
                  <select class="form-control form-control-sm mb-1"
                          [(ngModel)]="dashboardChart.show"
                          name="chartShow"
                          id="chartShow">
                    <option value="null">-</option>
                    <option *ngFor="let show of reportShow" value="{{show.value}}" [selected]="show.value==dashboardChart.show">{{show.title}}</option>
                  </select>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-4" i18n="rep|Modal header - update time@@rep.modal.header.updateTime">Update time</div>
                <div class="col-8">
                  <select class="form-control form-control-sm mb-1"
                          [ngModel]="dashboardChart.updateTime"
                          (input)="dashboardChart.updateTime = +$event.target.value"
                          name="chartUpdate"
                          id="chartUpdate">
                    <option value="1">1 minutes</option>
                    <option value="5">5 minutes</option>
                    <option value="10">10 minutes</option>
                    <option value="30">30 minutes</option>
                    <option value="60">1 hour</option>
                  </select>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-4" i18n="rep|Modal header - size@@rep.modal.header.size">Size</div>
                <div class="col-8">
                  <select class="form-control form-control-sm mb-1"
                          [ngModel]="dashboardChart.size"
                          (input)="dashboardChart.size = +$event.target.value"
                          name="chartSize"
                          id="chartSize">
                    <option value="4" i18n="rep|Modal option size - big@@rep.modal.option.size.big">Big(2x2)</option>
                    <option value="2" i18n="rep|Modal option size - long@@rep.modal.option.size.long">Long(1x2)</option>
                    <option value="1" i18n="rep|Modal option size - normal@@rep.modal.option.size.normal">Normal</option>
                  </select>
                  <div class="chart-size-box pt-3">
                    <div class="chart-size" [ngClass]="{'active': dashboardChart.size==4}" (click)="dashboardChart.size=4">
                      <div class="chart-size-title" i18n="rep|Modal option size - big@@rep.modal.option.size.big">Big(2x2)</div>
                      <div class="chart-size-img">
                        <div class="square"></div>
                        <div class="square"></div>
                        <div class="square"></div>
                        <div class="square"></div>
                      </div>
                    </div>
                    <div class="chart-size" [ngClass]="{'active': dashboardChart.size==2}" (click)="dashboardChart.size=2">
                      <div class="chart-size-title" i18n="rep|Modal option size - long@@rep.modal.option.size.long">Long(1x2)</div>
                        <div class="chart-size-img">
                          <div class="square"></div>
                          <div class="square"></div>
                        </div>
                      </div>
                      <div class="chart-size" [ngClass]="{'active': dashboardChart.size==1}" (click)="dashboardChart.size=1">
                        <div class="chart-size-title" i18n="rep|Modal option size - normal@@rep.modal.option.size.normal">Normal</div>
                        <div class="chart-size-img">
                          <div class="square"></div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer bg-light-gray border-0">
              <button type="button"
                      *ngIf="activeState=='action-append'"
                      class="btn btn-sm btn-blue"
                      (click)="addChart()"
                      i18n="rep|Modal buttons - add@@rep.modal.button.add">
                Add
              </button>
              <button type="button"
                      *ngIf="activeState=='action-update'"
                      class="btn btn-sm btn-blue"
                      (click)="saveChart(dashboardChart.id)"
                      i18n="rep|Modal buttons - save@@rep.modal.button.save">
                Save
              </button>
              <button type="button"
                      class="btn btn-sm btn-secondary"
                      data-dismiss="modal"
                      i18n="rep|Modal buttons - close@@rep.modal.button.close">
                Close
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="userAction == 'selectReport'" class="ms-modal-message">
          <div class="modal-content w-50">
            <div class="modal-header bg-info">
              <h5 class="modal-title" i18n="rep|Modal title - rename dashboard@@rep.modal.title.renameDashboard">Rename dashboard</h5>
              <button type="button" class="close" (click)="userAction = ''">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                        id="basic-addon1"
                        i18n="rep|Modal search button - find@@rep.modal.search.button.find">
                    Find
                  </span>
                </div>
                <input type="text" name="searchReport" class="form-control" placeholder="Enter report name" [(ngModel)]="searchReport">
              </div>
              <ng-container *ngFor="let itemReportGroup of reportGroup">
                <ul class="favoriteReportGroup">
                  <li *ngIf="issetFavoritesGroup(itemReportGroup.id)">
                    <div class="p-3">{{itemReportGroup.getTitle(globalSettings.language)}}</div>
                    <ul>
                      <ng-container *ngFor="let itemReportSubGroup of itemReportGroup.reportSubGroup">
                        <li *ngIf="issetFavoritesSubGroup(itemReportGroup.id, itemReportSubGroup.id)">
                          <div class="pl-3 bg-light-gray"><b>{{itemReportSubGroup.getTitle(globalSettings.language)}}</b></div>
                          <ul>
                            <ng-container *ngFor="let itemReportItem of itemReportSubGroup.reportItem">
                              <li *ngIf="itemReportItem.favorite == 1"
                                  class="pl-3 table-item-hover"
                                  [ngClass]="{'bg-gray': itemReportItem.id==dashboardChart.reportId}">
                                <input type="radio" name="radio" [(ngModel)]="selectedReport" [checked]="itemReportItem.id==dashboardChart.reportId" [value]="itemReportItem.id">
                                {{itemReportItem.getTitle(globalSettings.language)}}
                              </li>
                            </ng-container>
                          </ul>
                        </li>
                      </ng-container>
                    </ul>
                  </li>
                </ul>
              </ng-container>
            </div>
            <div class="modal-footer bg-light-gray border-0">
              <button type="button" class="btn btn-sm btn-blue" (click)="selectReport()" i18n="rep|Modal buttons - ok@@rep.modal.button.ok">
                OK
              </button>
              <button type="button" class="btn btn-sm btn-secondary" (click)="userAction = ''" i18n="rep|Modal buttons - cancel@@rep.modal.button.cancel">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>


  </div>
  <ng-container *ngIf="showTab('menu-compare')">
    <div class="col-8 pl-0 pt-3 border-right">
      <nav>
        <ol class="breadcrumb bg-white mb-0 pb-1">
          <li class="breadcrumb-item">Operational</li>
          <li class="breadcrumb-item active">Load sheet</li>
        </ol>
      </nav>
      <div class="px-3 mb-3">
        <h3>Comparison reports</h3>
        <button class="btn btn-light-gray btn-sm" id="report-{{reportActiveId}}" (click)="loadReport($event)">
          <i class="material-icons">keyboard_backspace</i>
          Back
        </button>
      </div>
      <div class="row border-top m-0">
        <ng-container *ngIf="compareMode == 'comparison'">
          <div class="col-6  border-right p-3">
            <google-chart class="w-100 med"
                          [options]="{height: '100%', width: '100%', chartArea:{width:'100%',height:'100%'}}"
                          [type]="reportDiagramType[reportDiagramActive]"
                          [data]="reportDiagramData[reportDiagramActive]"
                          [columns]="reportDiagramColumns[reportDiagramActive]">
            </google-chart>
            <div class="reporting-options">
              <div class="font-large mb-2">Reporting options</div>
              <p class="m-0"><b>Main: </b> <span class="text-blue">Period: </span> 01.01.2020 00:00 - 15.01.2020 00:00</p>
              <p class="m-0"><b>Other: </b> <span class="text-blue">Group by: </span> People</p>
              <p class="m-0"><b>Data sections: </b> Passangers</p>
            </div>
          </div>
          <div class="col-6 p-3">
            <google-chart class="w-100 med"
                          [options]="{height: '100%', width: '100%', chartArea:{width:'100%',height:'100%'}}"
                          [type]="reportDiagramType[reportDiagramActive]"
                          [data]="reportDiagramData[reportDiagramActive]"
                          [columns]="reportDiagramColumns[reportDiagramActive]">
            </google-chart>
            <div class="reporting-options p-3">
              <div class="font-large mb-2">Reporting options</div>
              <p class="m-0"><b>Main: </b> <span class="text-blue">Period: </span> 01.01.2020 00:00 - 15.01.2020 00:00</p>
              <p class="m-0"><b>Other: </b> <span class="text-blue">Group by: </span> People</p>
              <p class="m-0"><b>Data sections: </b> Passangers</p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="compareMode == 'overlay'">
          <google-chart class="w-100"
                        [options]="{seriesType: 'bars', series: 5}"
                        [type]="reportComboType"
                        [data]="reportDiagramData[reportDiagramActive]"
                        [columns]="reportDiagramColumns[reportDiagramActive]">
          </google-chart>
          <div class="reporting-options">
            <div class="font-large mb-2">Reporting options</div>
            <p class="m-0"><b>Main: </b> <span class="text-blue">Period: </span> 01.01.2020 00:00 - 15.01.2020 00:00</p>
            <p class="m-0"><b>Other: </b> <span class="text-blue">Group by: </span> People</p>
            <p class="m-0"><b>Data sections: </b> Passangers</p>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="col-2">
      <div class="row p-3 border-bottom">
        <b>Comparison</b>
      </div>
      <div class="row p-3 pr-3">
        <p><b>Mode</b></p>
        <div class="btn-group w-100" role="group">
          <button type="button" class="btn btn-light-gray btn-sm"
                  (click)="changeCompareMode('comparison')"
                  [ngClass]="{'btn-blue': compareMode == 'comparison'}">
            Comparison
          </button>
          <button type="button" class="btn btn-light-gray btn-sm"
                  (click)="changeCompareMode('overlay')"
                  [ngClass]="{'btn-blue': compareMode == 'overlay'}">
            Overlay
          </button>
        </div>
      </div>

      <div class="row border-top border-bottom py-3">
        <div class="col-12">
          <div class="mb-2"><b>Report 1</b></div>
          <div class="mb-3">
            <label for="period" class="m-0">Period</label>
            <select class="form-control form-control-sm mb-1" name="reportParamsPeriod1" id="reportParamsPeriod1">
              <option value="work-shift">Work shift</option>
              <option value="day">Day</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
              <option value="qarter">Qarter</option>
              <option value="manual-setting" selected>Manual setting
              </option>
            </select>
            <div class="extra-small ta-right">
              Range: 01.01.2020, 00:00 - 15.01.2020, 00:00
            </div>
          </div>
          <ng-container>
            <label for="startTimePeriod1" class="mb-0">
              Begin
              <span class="required-asterisk">*</span>
            </label>
            <div class="mb-2 row">
              <div class="col pr-0">
                <input type="time" class="form-control"
                      [ngModel]="reportParams.start | date: 'HH:mm'"
                      (input)="reportParams.start = parseTime(startDatePeriod1, $event.target.value)"
                      name="startTimePeriod1"
                      #startTimePeriod1="ngModel"
                      required>
                <div *ngIf="!startTimePeriod1?.valid && (startTimePeriod1?.dirty || startTimePeriod1?.touched)"
                  class="invalid-feedback d-block">
                  <div *ngIf="startTimePeriod1.errors.required">
                    Time is required
                  </div>
                </div>
              </div>
              <div class="col pl-1">
                <input type="date" class="form-control"
                      [ngModel]="reportParams.start | date: 'yyyy-MM-dd'"
                      (input)="reportParams.start = parseDate($event.target.value, startTimePeriod1)"
                      name="startDatePeriod1"
                      #startDatePeriod1="ngModel"
                      required>
                <div *ngIf="!startDatePeriod1?.valid && (startDatePeriod1?.dirty || startDatePeriod1?.touched)"
                  class="invalid-feedback d-block">
                  <div *ngIf="startDatePeriod1.errors.required">
                    Date is required
                  </div>
                </div>
              </div>
            </div>
            <label for="finishTimePeriod1" class="mb-0">
              End
              <span class="required-asterisk">*</span>
            </label>
            <div class="mb-2 row">
              <div class="col pr-0">
                <input type="time" class="form-control"
                      [ngModel]="reportParams.finish | date: 'HH:mm'"
                      (input)="reportParams.finish = parseTime(finishDatePeriod1, $event.target.value)"
                      name="finishTimePeriod1"
                      #finishTimePeriod1="ngModel"
                      required>
                <div *ngIf="!finishTimePeriod1?.valid && (finishTimePeriod1?.dirty || finishTimePeriod1?.touched)"
                  class="invalid-feedback d-block">
                  <div *ngIf="finishTimePeriod1.errors.required">
                    Time is required
                  </div>
                </div>
              </div>
              <div class="col pl-1">
                <input type="date" class="form-control"
                      [ngModel]="reportParams.finish | date: 'yyyy-MM-dd'"
                      (input)="reportParams.finish = parseDate($event.target.value, finishTimePeriod1)"
                      name="finishDatePeriod1"
                      #finishDatePeriod1="ngModel"
                      required>
                <div *ngIf="!finishDatePeriod1?.valid && (finishDatePeriod1?.dirty || finishDatePeriod1?.touched)" class="invalid-feedback d-block">
                  <div *ngIf="finishDatePeriod1.errors.required">
                    Date is required
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="row py-3 mb-3">
        <div class="col-12">
          <div class="mb-2"><b>Report 2</b></div>
          <div class="mb-3">
            <label for="period" class="m-0">Period</label>
            <select class="form-control form-control-sm mb-1" name="reportParamsPeriod2" id="reportParamsPeriod2">
              <option value="work-shift">Work shift</option>
              <option value="day">Day</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
              <option value="qarter">Qarter</option>
              <option value="manual-setting" selected>Manual setting
              </option>
            </select>
            <div class="extra-small ta-right">
              Range: 01.01.2020, 00:00 - 15.01.2020, 00:00
            </div>
          </div>
          <ng-container>
            <label for="reportParamsStartTime" class="mb-0">
              Begin
              <span class="required-asterisk">*</span>
            </label>
            <div class="mb-2 row">
              <div class="col pr-0">
                <input type="time" class="form-control"
                      [ngModel]="reportParams.start | date: 'HH:mm'"
                      (input)="reportParams.start = parseTime(startDatePeriod2, $event.target.value)"
                      name="startTimePeriod2"
                      #startTimePeriod2="ngModel"
                      required>
                <div *ngIf="!startTimePeriod2?.valid && (startTimePeriod2?.dirty || startTimePeriod2?.touched)"
                  class="invalid-feedback d-block">
                  <div *ngIf="startTimePeriod2.errors.required">
                    Time is required
                  </div>
                </div>
              </div>
              <div class="col pl-1">
                <input type="date" class="form-control"
                      [ngModel]="reportParams.start | date: 'yyyy-MM-dd'"
                      (input)="reportParams.start = parseDate($event.target.value, startTimePeriod2)"
                      name="startDatePeriod2"
                      #startDatePeriod2="ngModel"
                      required>
                <div *ngIf="!startDatePeriod2?.valid && (startDatePeriod2?.dirty || startDatePeriod2?.touched)"
                  class="invalid-feedback d-block">
                  <div *ngIf="startDatePeriod2.errors.required">
                    Date is required
                  </div>
                </div>
              </div>
            </div>
            <label for="filter-date-from" class="mb-0">
              End
              <span class="required-asterisk">*</span>
            </label>
            <div class="mb-2 row">
              <div class="col pr-0">
                <input type="time" class="form-control"
                      [ngModel]="reportParams.finish | date: 'HH:mm'"
                      (input)="reportParams.finish = parseTime(finishDatePeriod2, $event.target.value)"
                      name="finishTimePeriod2"
                      #finishTimePeriod2="ngModel"
                      required>
                <div *ngIf="!finishTimePeriod2?.valid && (finishTimePeriod2?.dirty || finishTimePeriod2?.touched)"
                  class="invalid-feedback d-block">
                  <div *ngIf="finishTimePeriod2.errors.required">
                    Time is required
                  </div>
                </div>
              </div>
              <div class="col pl-1">
                <input type="date" class="form-control"
                      [ngModel]="reportParams.finish | date: 'yyyy-MM-dd'"
                      (input)="reportParams.finish = parseDate($event.target.value, finishTimePeriod2)"
                      name="finishDatePeriod2"
                      #finishDatePeriod2="ngModel"
                      required>
                <div *ngIf="!finishDatePeriod2?.valid && (finishDatePeriod2?.dirty || finishDatePeriod2?.touched)" class="invalid-feedback d-block">
                  <div *ngIf="finishDatePeriod2.errors.required">
                    Date is required
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <button type="button" class="btn btn-sm btn-blue w-100">Compare</button>
        </div>
      </div>

    </div>
  </ng-container>-->
  <!-- Start tab reports options -->
  <ng-container *ngIf="showTab('menu-favorites') || showTab('menu-reports')">
    <div class="col-8 pt-3" *ngIf="showTab('menu-reports')">
      <h1 i18n="rep|Reports title - reports library@@rep.reports.title.reportsLibrary">Reports library</h1>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1" i18n="rep|Reports search - find@@rep.reports.search.find">Find</span>
        </div>
        <input type="text"
               class="form-control"
               placeholder="Enter report name"
               i18n-placeholder="rep|Reports search - enter report name@@rep.main.search.field.enterReportName"
               [(ngModel)]="searchReport">
      </div>
      <hr>
      <ul class="nav nav-tabs mb-3">
        <li class="nav-item" *ngFor="let itemReportGroup of reportGroup">
          <a class="nav-link"
              (click)="changeTabReport(itemReportGroup.id)"
              [ngClass]="{'active': showTabReport(itemReportGroup.id)}">
            {{itemReportGroup.getTitle(globalSettings.language)}}
          </a>
        </li>
      </ul>
      <ng-container *ngFor="let itemReportGroup of reportGroup">
        <div *ngIf="reportActiveTab == itemReportGroup.id">
          <ul class="reportGroup">
            <li *ngFor="let itemReportSubGroup of itemReportGroup.reportSubGroup">
              <div class="pl-1">{{itemReportSubGroup.getTitle(globalSettings.language)}}</div>
              <ul>
                <li *ngFor="let itemReportItem of itemReportSubGroup.reportItem"
                    class="table-item-hover"
                    [ngClass]="{'favorite':itemReportItem.favorite==1, 'bg-gray':itemReportItem.id==reportActiveId }"
                    (click)="changeReport(itemReportItem.id)">
                  <i class="material-icons md-16 gold">grade</i>
                  {{itemReportItem.getTitle(globalSettings.language)}}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
    <div class="col-8 pt-3" *ngIf="showTab('menu-favorites')">
      <h1 i18n="rep|Reports title - favorites reports library@@rep.reports.title.favoritesReportsLibrary">Favorites reports library</h1>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1" i18n="rep|Reports search - find@@rep.reports.search.find">Find</span>
        </div>
        <input type="text"
               class="form-control"
               placeholder="Enter report name"
               i18n-placeholder="rep|Reports search - enter report name@@rep.main.search.field.enterReportName"
               [(ngModel)]="searchReport">
      </div>
      <hr>
      <ng-container *ngFor="let itemReportGroup of reportGroup">
        <ul class="favoriteReportGroup">
          <li *ngIf="issetFavoritesGroup(itemReportGroup.id)">
            <div class="p-3">{{itemReportGroup.getTitle(globalSettings.language)}}</div>
            <ul>
              <ng-container *ngFor="let itemReportSubGroup of itemReportGroup.reportSubGroup">
                <li *ngIf="issetFavoritesSubGroup(itemReportGroup.id, itemReportSubGroup.id)">
                  <div class="pl-3 bg-light-gray"><b>{{itemReportSubGroup.getTitle(globalSettings.language)}}</b></div>
                  <ul>
                    <ng-container *ngFor="let itemReportItem of itemReportSubGroup.reportItem">
                      <li *ngIf="itemReportItem.favorite == 1"
                          class="pl-3 table-item-hover"
                          [ngClass]="{'bg-gray': itemReportItem.id==reportActiveId}"
                          (click)="changeReport(itemReportItem.id)">
                        <i class="material-icons md-16 gold pointer" ng-click="let thisShow = 0">grade</i>
                        {{itemReportItem.getTitle(globalSettings.language)}}
                      </li>
                    </ng-container>
                  </ul>
                </li>
              </ng-container>
            </ul>
          </li>
        </ul>
      </ng-container>
    </div>

    <div class="col-2 border-left pt-3 pb-5">
      <ng-container *ngIf="reportActive!=null">
        <div class="rounded bg-light-gray px-2 py-1"
             [ngClass]="(reportActive.getDescription(globalSettings.language)) ? 'text-dark' : 'text-dark-light'">
          <b i18n="rep|Reports title - description@@rep.reports.title.description">Description</b>
        </div>
        <p class="text-dark-gray pl-2 pt-1">{{reportActive.getDescription(globalSettings.language)}}</p>

        <div class="rounded bg-light-gray px-2 py-1"
             [ngClass]="(reportActive.getFormula(globalSettings.language)) ? 'text-dark' : 'text-dark-light'">
          <b i18n="rep|Reports title - calculation formula@@rep.reports.title.calculationFormula">Calculation formula</b>
        </div>
        <p class="text-dark-gray pl-2 pt-1">{{reportActive.getFormula(globalSettings.language)}}</p>

        <div class="rounded bg-light-gray px-2 py-1"
             [ngClass]="(reportActive.visualizationOptions && reportActive.visualizationOptions.length > 0) ? 'text-dark' : 'text-dark-light'">
          <b i18n="rep|Reports title - possible display options@@rep.reports.title.possibleDisplayOptions">Possible display options</b>
        </div>
        <ul class="text-dark-gray">
          <li *ngFor="let varVisual of reportActive.visualizationOptions">
            {{reportVariantVisual[varVisual]}}
          </li>
        </ul>

        <div class="rounded bg-light-gray px-2 py-1"
             [ngClass]="(reportActive.visual && reportActive.visual.length > 0) ? 'text-dark' : 'text-dark-light'">
          <b i18n="rep|Reports title - visualization method@@rep.reports.title.visualizationMethod">Visualization method</b>
        </div>
        <ul class="text-dark-gray">
          <li *ngFor="let visual of reportActive.visual">
            {{reportVisual[visual]}}
          </li>
        </ul>

        <button type="button"
                *ngIf="showTab('menu-reports')"
                id="report-{{reportActiveId}}"
                (click)="my_loadReport(reportActiveId)"
                class="btn btn-sm btn-blue w-100"
                i18n="rep|Reports button - open report@@rep.reports.button.openReport">
          Open report
        </button>
        <div class="btn-group delete-favorite dropup w-100" *ngIf="showTab('menu-favorites')">
          <button class="btn btn-sm btn-blue w-100"
                  type="button"
                  id="report-{{reportActiveId}}"
                  (click)="my_loadReport(reportActiveId)"
                  i18n="rep|Reports button - open report@@rep.reports.button.openReport">
            Open report
          </button>
          <button type="button" class="btn btn-sm btn-blue dropdown-toggle dropdown-toggle-split" data-toggle="dropdown">
          </button>
          <div class="dropdown-menu dropdown-menu-right w-100 p-0">
            <button class="dropdown-item d-flex align-items-center" type="button">
              <i class="material-icons md-16">delete</i>
              <span i18n="rep|Reports button - delete from favorites@@rep.reports.button.deleteFromFavorites">Delete from favorites</span>
            </button>
          </div>
        </div>
      </ng-container>
      <!--<ng-container *ngFor="let itemReportItemAll of reportItemAll">
        <ng-container *ngIf="reportActiveId == itemReportItemAll.id">
          <b>Описание</b>
          <p>{{itemReportItemAll.getDescription(globalSettings.language)}}</p>
          <b>Расчетная формула</b>
          <p>{{itemReportItemAll.getFormula(globalSettings.language)}}</p>
          <b>Возможные варианты отображения</b>
          <ul>
            <li *ngFor="let varVisual of itemReportItemAll.visualizationOptions">
              {{reportVariantVisual[varVisual]}}
            </li>
          </ul>
          <b>Способ визуализации</b>
          <ul>
            <li *ngFor="let visual of itemReportItemAll.visual">
              {{reportVisual[visual]}}
            </li>
          </ul>
          <button type="button" *ngIf="showTab('menu-reports')"
                  id="report-{{reportActiveId}}"
                  (click)="loadReport($event)"
                  class="btn btn-sm btn-blue w-100">
            Open report
          </button>
          <div class="btn-group delete-favorite dropup w-100" *ngIf="showTab('menu-favorites')">
            <button class="btn btn-sm btn-blue w-100"
                    type="button"
                    id="report-{{reportActiveId}}"
                    (click)="loadReport($event)">
              Open report
            </button>
            <button type="button" class="btn btn-sm btn-blue dropdown-toggle dropdown-toggle-split" data-toggle="dropdown">
            </button>
            <div class="dropdown-menu dropdown-menu-right w-100 p-0">
              <button class="dropdown-item d-flex align-items-center" type="button">
                <i class="material-icons md-16">delete</i>
                <span>Delete from favorites</span>
              </button>
            </div>
          </div>
        </ng-container>
      </ng-container>-->
    </div>
  </ng-container>

  <!--Start reports html new -->
  <ng-container *ngIf="activeTab == 'report'">
    <div class="col-8 pt-3 px-0">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-white m-0">
          <li class="breadcrumb-item pointer" (click)="activeTab = 'menu-reports'">{{arrTitleSearch(reportGroup, reportActiveTab)}}</li>
          <li class="breadcrumb-item active">{{arrTitleSearch(reportItemAll, reportActiveId)}}</li>
        </ol>
      </nav>

      <div class="reportTopButtons mb-3 px-3">

        <button class="btn btn-light-gray btn-sm" (click)="modalEnterEmail = !modalEnterEmail" id="menu-compare"
                [disabled]="reportActive.params.includes(14) && !reportParams.channel || reportActive.params.includes(0) && !reportParams.id">
          <i class="material-icons md-16">email</i>
          <span i18n="rep|Report button - share by mail@@rep.report.button.shareByMail">Share by mail</span>
        </button>

        <button class="btn btn-light-gray btn-sm"
                (click)="saveReport(reportActive.url, 'xlsx')"
                id="menu-compare"
                [disabled]="reportActive.params.includes(14) && !reportParams.channel || reportActive.params.includes(0) && !reportParams.id">
          <i class="material-icons md-16">save_alt</i>
          <span i18n="rep|Report button - download report@@rep.report.button.downloadReport">Download report</span>
        </button>

        <button class="btn btn-light-gray btn-sm" (click)="print()"
                [disabled]="reportActive.params.includes(14) && !reportParams.channel || reportActive.params.includes(0) && !reportParams.id">
          <i class="material-icons md-16">print</i>
          <span i18n="rep|Report button - print@@rep.report.button.print">Print</span>
        </button>

        <!--<button class="btn btn-light-gray btn-sm" (click)="changeTab($event)" id="menu-compare">
          <span class="material-icons md-16">compare</span>
          <span i18n="rep|Report button - compare@@rep.report.button.compare">Compare</span>
        </button>-->
      </div>

      <!-- report-test -->
      <div class="p-3">

        <div class="report">
          <!-- ngIf="reportActiveId == 1 || reportActiveId == 2" -->
          <ul class="nav nav-tabs mb-3">
            <li class="nav-item">
              <a class="nav-link"
                  *ngIf="reportView == 'chart' && reportActiveData && reportActiveData.chart && reportActiveData.chart.length > 0"
                  (click)="changeReportView('chart')"
                  [ngClass]="{'active': reportView == 'chart'}"
                  i18n="rep|Report tab - chart@@rep.report.tab.chart">
                Chart
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link"
                  (click)="changeReportView('table')"
                  [ngClass]="{'active': reportView == 'table'}"
                  i18n="rep|Report tab - data@@rep.report.tab.data">
                Data
              </a>
            </li>
          </ul>
          <ng-container *ngIf="reportView == 'chart' && reportActiveData && reportActiveData.chart.length > 0">
            <div class="">
              <p i18n="rep|Report title - data section@@rep.report.title.dataSection">Data section</p>
              <ng-container *ngFor="let item of reportsData[reportActiveId].chart">
                <span (click)="changeDiagramActive(item.id)" class="mr-1 pointer">
                  <input [checked]="reportDiagramActive==item.id" type="radio" name="radioDiagramActive"> {{item.title}}
                </span>
              </ng-container>
            </div>
            <!-- К сожалению, [options] не принимает переменных. А также не принимает вариативности. Поэтому для каждого типа свой почти идентичный блок -->
            <google-chart *ngIf="reportDiagramType[reportDiagramActive] == 'ColumnChart'"
                          class="pt-3 med mb-4"
                          [options]="{legend: { position: 'top', maxLines: 3 },
                                      bar: { groupWidth: '75%' },
                                      isStacked: true,
                                      width: '100%',
                                      height: '100%'}"
                          [type]="reportDiagramType[reportDiagramActive]"
                          [data]="reportDiagramData[reportDiagramActive]"
                          [columns]="reportDiagramColumns[reportDiagramActive]">
            </google-chart>
            <google-chart *ngIf="reportDiagramType[reportDiagramActive] == 'PieChart'"
                          class="pt-3 med mb-4"
                          [options]="{legend: {position: 'right', width: '500', itemWidth: '200'},
                                      chartArea: { top: 0, left: 0, width:'100%', height: '100%'},
                                      width: '100%',
                                      height: '100%'}"
                          [type]="reportDiagramType[reportDiagramActive]"
                          [data]="reportDiagramData[reportDiagramActive]"
                          [columns]="reportDiagramColumns[reportDiagramActive]">
            </google-chart>
            <div class="reporting-options">
              <div class="font-large mb-2" i18n="rep|Report title - reporting options@@rep.report.title.reportingOptions">Reporting options</div>
              <p class="m-0">
                <b i18n="rep|Report options - main@@rep.report.options.main">Main:</b>&nbsp;<span class="text-blue"
                      i18n="rep|Report options - period@@rep.report.options.period">Period:</span>&nbsp;01.01.2020 00:00 - 15.01.2020
                00:00
              </p>
              <p class="m-0">
                <b i18n="rep|Report options - other@@rep.report.options.other">Other:</b>&nbsp;
                <span class="text-blue" i18n="rep|Report options - group by@@rep.report.options.groupBy">Group by:</span>&nbsp;<span i18n="rep|Report options - group by@@rep.report.options.groupByPeople">People</span></p>
              <p class="m-0">
                <b class="text-blue"
                   i18n="rep|Report options - data sections@@rep.report.options.dataSections">Data sections:</b>&nbsp;<span
                      i18n="rep|Report options - data sections@@rep.report.options.dataSectionsPassangers">Passangers</span>
              </p>
            </div>
          </ng-container>
          <!-- Конструктор таблиц -->
          <ng-container *ngIf="showReportData && reportActiveData.data.length > 0">
            <div *ngIf="reportView == 'table'" class="row">
              <div class="col-12 pr-0 scroll-x">
                <table class="table table-borderless table-hover">
                  <thead>
                    <tr class="text-muted detail-head table__mail-header--subhead">
                      <th class="text-center" *ngFor="let item of reportActiveData.titles">{{item}}</th>
                    </tr>
                  </thead>
                  <tbody class="font-small">
                    <tr *ngFor="let row of reportActiveData.data
                                          | paginate: {
                                            itemsPerPage: itemsPerPage,
                                            currentPage: currentPage,
                                            totalItems: reportActiveData.data.length
                                          }">
                      <ng-container *ngFor="let name of reportActiveData.dataNames">
                        <ng-container *ngIf="checkType(row[name])=='object'; else: noObject">
                          <td *ngFor="let item of row[name]" class="text-center">{{item}}</td>
                        </ng-container>          
                        <ng-template #noObject>
                          <td class="text-center">{{row[name]}}</td>
                        </ng-template>
                      </ng-container>
                    </tr>
                  </tbody>
                </table>
                <div class="loading" *ngIf="loading">
                  <div class="cssload-spin-box"></div>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- End of Конструктор таблиц -->
          <pagination-controls *ngIf="reportView == 'table' && reportActiveData.data.length > 0"
                              (pageChange)="currentPage = $event"
                              maxSize="7"
                              directionLinks="true"
                              autoHide="true"
                              previousLabel="Previous"
                              nextLabel="Next"
                              i18n-previousLabel="rep|Report - pagination previos@@rep.report.pagination.previousLabel"
                              i18n-nextLabel="rep|Report -  pagination next@@rep.report.pagination.nextLabel">>
          </pagination-controls>
        </div>
      </div>
    </div>
    <div class="col-2 border-left pt-3 pb-5">
      <ng-container>
        <!-- ngIf="reportActiveId == 1 || reportActiveId == 2" -->
        <ul class="nav nav-tabs mb-3">
          <li class="nav-item">
            <a class="nav-link"
                (click)="changeReportRightTab('parameters')"
                [ngClass]="{'active': reportRightTab == 'parameters'}"
                i18n="rep|Report tab - parametrs@@rep.report.tab.parametrs">
              Parameters
            </a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link d-flex align-items-center"
                (click)="changeReportRightTab('favorites')"
                [ngClass]="{'active': reportRightTab == 'favorites'}">
              <i class="material-icons md-16 pl-0" [ngClass]="{'gold': reportRightTab == 'favorites'}">grade</i>
              <span i18n="rep|Report tab - favorites@@rep.report.tab.favorites">Favorites</span>
            </a>
          </li> -->
        </ul>
        <form #reportForm="ngForm" class="form-report" *ngIf="reportRightTab == 'parameters'">
          <!-- <div class="mb-3">
            <div class="mb-2">
              <b i18n="rep|Report parameters title - configuration@@rep.report.parameters.title.configuration">Configuration</b>
            </div>
            <select class="form-control form-control-sm mb-2" name="configuration" id="configuration">
              <option value="default"
                      i18n="rep|Report parameters configuration option - default@@rep.report.parameters.configuration.option.default">
                Default
              </option>
              <option value="not-default"
                      i18n="rep|Report parameters configuration option - not default@@rep.report.parameters.configuration.option.notDefault">
                Not default
              </option>
            </select>
            <div class="setting-buttons text-right">
              <button type="button"
                      class="btn btn-xs mr-1 btn-ico btn-light-gray"
                      placement="top-right"
                      ngbTooltip="Delete configuration"
                      i18n-ngbTooltip="rep|Report buttons - delete configuration@@rep.report.button.deleteConfiguration">
                <i class="material-icons">delete</i>
              </button>
              <button type="button"
                      class="btn btn-xs mr-1 btn-ico btn-light-gray"
                      placement="top-right"
                      ngbTooltip="Update configuration"
                      i18n-ngbTooltip="rep|Report buttons - update configuration@@rep.report.button.updateConfiguration">
                <i class="material-icons">save</i>
              </button>
              <button type="button"
                      class="btn btn-xs btn-ico btn-light-gray"
                      placement="top-right"
                      ngbTooltip="Save configuration as..."
                      i18n-ngbTooltip="rep|Report buttons - save configuration@@rep.report.button.saveConfiguration">
                <i class="material-icons">settings</i>
              </button>
            </div>
          </div> -->
          <div class="mb-2" *ngIf="reportActive.params.includes(0)">
            <label for="report-id" class="mb-0" i18n="rep|Report parameters label - id@@rep.report.parameters.label.id">
              ID
            </label>
            <input type="number" id="report-id" class="form-control" [(ngModel)]="reportParams.id" name="reportId">
          </div>
          <div class="row border-top border-bottom py-3 mb-3" *ngIf="like(reportActive.params, 1)">
            <div class="col-12">
              <b class="mb-3" i18n="rep|Report parameters title - main@@rep.report.parameters.title.main">Main</b>
              <div class="mb-3">
                <label for="period" class="mb-2" i18n="rep|Report parameters label - period@@rep.report.parameters.label.period">Period</label>
                <div class="mb-2" *ngIf="reportParams.period != 'manual-setting'">
                  <div class="btn-group w-100" role="group" aria-label="">
                    <button type="button"
                            class="btn btn-sm btn-h-25 btn-light-gray"
                            (click)="selectPeriod('current')"
                            [ngClass]="{'btn-blue': selectedPeriod == 'current'}"
                            i18n="rep|Report parameters button - current@@rep.report.parameters.button.current">
                      Current
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-h-25 btn-light-gray"
                            (click)="selectPeriod('previous')"
                            [ngClass]="{'btn-blue': selectedPeriod == 'previous'}"
                            i18n="rep|Report parameters button - previous@@rep.report.parameters.button.previous">
                      Previous
                    </button>
                  </div>
                </div>
                <select class="form-control form-control-sm mb-1"
                        [(ngModel)]="reportParams.period"
                        name="reportParamsPeriod"
                        id="reportParamsPeriod"
                        (change)="changeParamsPeriod($event.target.value)">
                  <!-- Понятие рабочей смены на данный момент не определено -->
                  <!-- <option value="work-shift"
                          [selected]="reportParams.period == 'work-shift'"
                          i18n="rep|Report parameters period option - work shift@@rep.report.parameters.period.option.workShift">
                    Work shift
                  </option> -->
                  <option value="day"
                          [selected]="reportParams.period == 'day'"
                          i18n="rep|Report parameters period option - day@@rep.report.parameters.period.option.day">
                    Day
                  </option>
                  <option value="week"
                          [selected]="reportParams.period == 'week'"
                          i18n="rep|Report parameters period option - week@@rep.report.parameters.period.option.week">
                    Week
                  </option>
                  <option value="month"
                          [selected]="reportParams.period == 'month'"
                          i18n="rep|Report parameters period option - month@@rep.report.parameters.period.option.month">
                    Month
                  </option>
                  <option value="qarter"
                          [selected]="reportParams.period == 'qarter'"
                          i18n="rep|Report parameters period option - qarter@@rep.report.parameters.period.option.qarter">
                    Qarter
                  </option>
                  <option value="manual-setting"
                          [selected]="reportParams.period == 'manual-setting'"
                          i18n="rep|Report parameters period option - manual setting@@rep.report.parameters.period.option.manualSetting">
                    Manual setting
                  </option>
                </select>
                <div class="extra-small ta-right">
                  <span
                        i18n="rep|Report parameters period - range@@rep.report.parameters.period.range">Range:</span>&nbsp;{{reportParams.start
                  | date: 'dd-MM-yyyy HH:mm' : 'UTC+0'}}&nbsp;-&nbsp;{{reportParams.finish | date: 'dd-MM-yyyy HH:mm' : 'UTC+0'}}
                </div>
              </div>
              <ng-container *ngIf="reportParams.period == 'manual-setting'">
                <label for="reportParamsStartTime" class="mb-0">
                  <span i18n="rep|Report parameters period - begin@@rep.report.parameters.period.begin">Begin</span>
                  <span class="required-asterisk">*</span>
                </label>
                <div class="mb-2 row">
                  <div class="col-5 pr-0">
                    <input type="time" class="form-control"
                          [ngModel]="reportParams.start | date: 'HH:mm' : 'UTC+0'"
                          (input)="reportParams.start = parseUTCDate(start_date.value, $event.target.value)"
                          name="reportParamsStartTime"
                          #start_time="ngModel"
                          [required]="reportParams.period == 'manual-setting'">
                    <div *ngIf="!start_time?.valid && (start_time?.dirty || start_time?.touched)"
                      class="invalid-feedback d-block">
                      <div *ngIf="start_time.errors.required" i18n="rep|Report parameters error - time required@@rep.report.parameters.error.timeRequired">
                        Time is required
                      </div>
                    </div>
                  </div>
                  <div class="col-7 pl-1">
                    <input type="date" class="form-control"
                          [ngModel]="reportParams.start | date: 'yyyy-MM-dd' : 'UTC+0'"
                          (input)="reportParams.start = parseUTCDate($event.target.value, start_time.value)"
                          name="reportParamsStartDate"
                          #start_date="ngModel"
                          [required]="reportParams.period == 'manual-setting'">
                    <div *ngIf="!start_date?.valid && (start_date?.dirty || start_date?.touched)"
                      class="invalid-feedback d-block">
                      <div *ngIf="start_date.errors.required" i18n="rep|Report parameters error - date required@@rep.report.parameters.error.dateRequired">
                        Date is required
                      </div>
                    </div>
                  </div>
                </div>
                <label for="filter-date-from" class="mb-0">
                  <span i18n="rep|Report parameters period - end@@rep.report.parameters.period.end">End</span>
                  <span class="required-asterisk">*</span>
                </label>
                <div class="mb-2 row">
                  <div class="col-5 pr-0">
                    <input type="time" class="form-control"
                          [ngModel]="reportParams.finish | date: 'HH:mm' : 'UTC+0'"
                          (input)="reportParams.finish = parseUTCDate(finish_date.value, $event.target.value)"
                          name="reportParamsFinishTime"
                          #finish_time="ngModel"
                          [required]="reportParams.period == 'manual-setting'">
                    <div *ngIf="!finish_time?.valid && (finish_time?.dirty || finish_time?.touched)"
                      class="invalid-feedback d-block">
                      <div *ngIf="finish_time.errors.required" i18n="rep|Report parameters error - time required@@rep.report.parameters.error.timeRequired">
                        Time is required
                      </div>
                    </div>
                  </div>
                  <div class="col-7 pl-1">
                    <input type="date" class="form-control"
                          [ngModel]="reportParams.finish | date: 'yyyy-MM-dd' : 'UTC+0'"
                          (input)="reportParams.finish = parseUTCDate($event.target.value, finish_time.value)"
                          name="reportParamsFinishDate"
                          #finish_date="ngModel"
                          [required]="reportParams.period == 'manual-setting'">
                    <div *ngIf="!finish_date?.valid && (finish_date?.dirty || finish_date?.touched)" class="invalid-feedback d-block">
                      <div *ngIf="finish_date.errors.required" i18n="rep|Report parameters error - date required@@rep.report.parameters.error.dateRequired">
                        Date is required
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="row" *ngIf="reportActive.params.includes(5)">
            <div class="col-12">
              <label for="reportParamsDate" class="mb-0">
                <span i18n="rep|Report parameters label - date@@rep.report.parameters.label.date">Date</span>
                <span class="required-asterisk">*</span>
              </label>
              <div class="mb-2 row">
                <div class="col-5 pr-0">
                  <input type="time" class="form-control"
                        [ngModel]="reportParams.date | date: 'HH:mm' : 'UTC+0'"
                        (input)="reportParams.date = parseUTCDate(date_date.value, $event.target.value)"
                        name="reportParamsDateTime"
                        #date_time="ngModel">
                  <div *ngIf="!date_time?.valid && (date_time?.dirty || date_time?.touched)"
                    class="invalid-feedback d-block">
                    <div *ngIf="date_time.errors.required" i18n="rep|Report parameters error - time required@@rep.report.parameters.error.timeRequired">
                      Time is required
                    </div>
                  </div>
                </div>
                <div class="col-7 pl-1">
                  <input type="date" class="form-control"
                        [ngModel]="reportParams.date | date: 'yyyy-MM-dd' : 'UTC+0'"
                        (input)="reportParams.date = parseUTCDate($event.target.value, date_time.value)"
                        name="reportParamsDate"
                        #date_date="ngModel">
                  <div *ngIf="!date_date?.valid && (date_date?.dirty || date_date?.touched)"
                    class="invalid-feedback d-block">
                    <div *ngIf="date_date.errors.required" i18n="rep|Report parameters error - date required@@rep.report.parameters.error.dateRequired">
                      Date is required
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-2" *ngIf="reportActive.params.includes(6)">
            <label for="report-airline" class="mb-0" i18n="rep|Report parameters label - airline@@rep.report.parameters.label.airline">
              Airline
            </label>
            <ng-select class="form-control custom-sm border p-0"
                      [items]="referanceAirlines"
                      bindLabel="iata"
                      bindValue="id"
                      [(ngModel)]="reportParams.airline"
                      #reportAirline="ngModel"
                      name="reportAirline"
                      [virtualScroll]="true"
                      [loading]="referanceAirlinesLoading"
                      (scroll)="onScrollReferanceAirlines($event)"
                      (scrollToEnd)="onScrollReferanceAirlinesToEnd()">
              <ng-template ng-option-tmp let-item="item">
                IATA: <span class="font-weight-bold">{{item.iata}}</span>, ICAO: {{item.icao}} <br />
                <small>{{item.names}}</small>
              </ng-template>
            </ng-select>
          </div>
          <div class="mb-2" *ngIf="reportActive.params.includes(7)">
            <label for="report-airline" class="mb-0" i18n="rep|Report parameters label - movement@@rep.report.parameters.label.movement">
              Movement
            </label>
            <ng-select class="form-control custom-sm border p-0"
                      [items]="referanceMovements"
                      bindLabel="name"
                      bindValue="id"
                      [(ngModel)]="reportParams.movement"
                      name="reportMovement"
                      #reportMovement="ngModel"
                      [virtualScroll]="true"
                      [loading]="referanceMovementsLoading"
                      (scroll)="onScrollReferanceMovements($event)"
                      (scrollToEnd)="onScrollReferanceMovementsToEnd()">
              <ng-template ng-option-tmp let-item="item">
                {{item.code}} / {{item.name}}
              </ng-template>
            </ng-select>
          </div>
          <div class="mb-2" *ngIf="reportActive.params.includes(8)">
            <label for="report-geo" class="mb-0" i18n="rep|Report parameters label - geo@@rep.report.parameters.label.geo">
              Geo
            </label>
            <ng-select class="form-control custom-sm border p-0"
                      [items]="referanceGeoTypes"
                      [bindLabel]="globalSettings.language=='en' ? 'nameEnglish' : 'nameLocal'"
                      bindValue="id"
                      name="reportGeo"
                      id="reportGeo"
                      [(ngModel)]="reportParams.geo">
              <ng-template ng-option-tmp let-item="item">
                <span>{{globalSettings.language=='en' ? item.nameEnglish : item.nameLocal}}</span>
              </ng-template>
            </ng-select>
          </div>
          <div class="mb-2" *ngIf="reportActive.params.includes(9)">
            <label for="report-aircraft" class="mb-0" i18n="rep|Report parameters label - aircraft@@rep.report.parameters.label.aircraft">
              Aircraft
            </label>
            <ng-select class="form-control custom-sm border p-0"
                      [items]="referanceAircraftTypes"
                      bindLabel="names"
                      bindValue="id"
                      name="reportAircraft"
                      id="reportAircraft"
                      [(ngModel)]="reportParams.aircraft"
                      [virtualScroll]="true"
                      [loading]="referanceAircraftTypesLoading"
                      (scroll)="onScrollReferanceAircraftTypes($event)"
                      (scrollToEnd)="onScrollReferanceAircraftTypesToEnd()">
            </ng-select>
          </div>
          <div class="mb-2" *ngIf="reportActive.params.includes(10)">
            <label for="report-airport" class="mb-0" i18n="rep|Report parameters label - airport@@rep.report.parameters.label.airport">
              Airport
            </label>
            <ng-select class="form-control custom-sm border p-0"
                      [items]="referanceAirports"
                      bindLabel="iata"
                      bindValue="id"
                      [(ngModel)]="reportParams.airport"
                      #reportAirport="ngModel"
                      name="reportAirport"
                      [virtualScroll]="true"
                      [loading]="referanceAirportsLoading"
                      (scroll)="onScrollReferanceAirports($event)"
                      (scrollToEnd)="onScrollReferanceAirportsToEnd()">
              <ng-template ng-option-tmp let-item="item">
                <span class="font-weight-bold">{{item.iata}}</span>, {{item.names}}
              </ng-template>
            </ng-select>
          </div>
          <div class="mb-2" *ngIf="reportActive.params.includes(11)">
            <label for="report-season" class="mb-0" i18n="rep|Report parameters label - season@@rep.report.parameters.label.season">
              Season
            </label>
            <ng-select class="form-control custom-sm border p-0"
                      [items]="referanceSeasons"
                      [bindLabel]="globalSettings.language=='en' ? 'nameEnglish' : 'nameLocal'"
                      bindValue="id"
                      name="reportSeason"
                      id="reportSeason"
                      [(ngModel)]="reportParams.season">
              <ng-template ng-option-tmp let-item="item">
                <span>{{globalSettings.language=='en' ? item.nameEnglish : item.nameLocal}}</span>
              </ng-template>
            </ng-select>
          </div>
          <div class="mb-2" *ngIf="reportActive.params.includes(12)">
            <label for="report-regularity" class="mb-0" i18n="rep|Report parameters label - regularity@@rep.report.parameters.label.regularity">
              Regularity
            </label>
            <ng-select class="form-control custom-sm border p-0"
                      [items]="referanceRegulatures"
                      [bindLabel]="globalSettings.language=='en' ? 'nameEnglish' : 'nameLocal'"
                      bindValue="id"
                      name="reportRegularity"
                      id="reportRegularity"
                      [(ngModel)]="reportParams.regularity">
              <ng-template ng-option-tmp let-item="item">
                <span>{{globalSettings.language=='en' ? item.nameEnglish : item.nameLocal}}</span>
              </ng-template>
            </ng-select>
          </div>
          <div class="mb-3 mt-3" *ngIf="reportActive.params.includes(13)">
            <p class="mb-2">
              <b i18n="rep|Report parameters label - other@@rep.report.parameters.label.other">Other</b>
            </p>
            <label for="report-regularity" class="mb-0" i18n="rep|Report parameters label - groupBy@@rep.report.parameters.label.groupBy">
              Group by
            </label>
            <select class="form-control form-control-sm mb-1" [(ngModel)]="reportParams.groupBy" name="groupBy" id="groupBy">
              <option value="people"
                      [selected]="reportParams.groupBy == 'people'"
                      i18n="rep|Report parameters groupBy option - people@@rep.report.parameters.groupBy.option.people">
                People
              </option>
              <option value="day"
                      [selected]="reportParams.groupBy == 'day'"
                      i18n="rep|Report parameters groupBy option - day@@rep.report.parameters.groupBy.option.day">
                Day
              </option>
            </select>
          </div>
          <div class="mb-3 mt-3" *ngIf="reportActive.params.includes(14)">
            <p class="mb-2">
              <b i18n="rep|Report parameters label - other@@rep.report.parameters.label.other">Channel</b>
            </p>
            <label for="report-regularity" class="mb-0" i18n="rep|Report parameters label - channel@@rep.report.parameters.label.channel">
              Channel
            </label>
            <select class="form-control form-control-sm mb-1" 
                    [ngModel]="reportParams.channel"
                    name="paramsChannel"
                    id="paramsChannel"
                    (change)="reportParams.channel = +$event.target.value"
                    required>
              <option [selected]="!reportParams.channel"
                      i18n="rep|Report parameters channel option - select@@rep.report.parameters.channel.option.select">
                Select...
              </option>
              <option *ngFor="let channel of referanceChannels"
                      [value]="channel.id"
                      [selected]="reportParams.channel == channel.id">
                {{channel.name}}
              </option>
            </select>
          </div>
          <button class="btn btn-blue btn-sm mt-4 w-100"
                  (click)="my_loadReport()"
                  i18n="rep|Report parameters button - create report@@rep.report.parameters.button.createReport">
            Create report
          </button>
        </form>
        <div class="" *ngIf="reportRightTab == 'favorites'">
          <div class="pb-1" i18n="rep|Report parameters label - parameters@@rep.report.parameters.label.parameters">Parameters</div>
          <div class="pb-3 border-bottom">
            <select class="form-control form-control-sm" name="select1">
              <option i18n="rep|Report parameters option - default@@rep.report.parameters.option.default">Default</option>
              <option i18n="rep|Report parameters option - not default@@rep.report.parameters.option.notDefault">Not default</option>
            </select>
          </div>
          <div class="pb-1 pt-3">
            <b><span i18n="rep|Report parameters label - email@@rep.report.parameters.label.email">Email</span></b>
          </div>
          <div class="pb-1" i18n="rep|Report parameters label - send to mail@@rep.report.parameters.label.sendToMail">
            Send to mail
          </div>
          <div class="pb-3">
            <select class="form-control form-control-sm">
              <option i18n="rep|Report parameters period send option - everyday@@rep.report.parameters.period.send.option.everyday">Everyday</option>
              <option i18n="rep|Report parameters period send option - yesterday@@rep.report.parameters.period.send.option.yesterday">Yesterday</option>
            </select>
          </div>
          <div class="pb-1" i18n="rep|Report parameters label - mail sending time@@rep.report.parameters.label.mailSendingTime">
            Mail sending time
          </div>
          <div class="pb-3">
            <input type="time"
                  class="form-control form-control-sm"
                  [ngModel]="reportParams.finish | date: 'HH:mm'"
                  (input)="reportParams.finish = parseTime(finish_date, $event.target.value)"
                  #finish_date="ngModel"
                  required>
          </div>
          <div class="pb-1" i18n="rep|Report parameters label - adresses@@rep.report.parameters.label.adresses">
            Adresses
          </div>
          <div class="pb-3">
            <input class="form-control form-control-sm" value="plan@airport.com">
          </div>
          <div class="btn-group delete-favorite dropup w-100">
            <button class="btn btn-sm btn-blue w-100" i18n="rep|Report parameters button - save favorites@@rep.report.parameters.button.saveFavorites">
              Save favorites
            </button>
            <button type="button" class="btn btn-sm btn-blue dropdown-toggle dropdown-toggle-split" data-toggle="dropdown">
            </button>
            <div class="dropdown-menu dropdown-menu-right w-100">
              <button class="dropdown-item" type="button">
                <i class="material-icons md-16">delete</i>
                <span i18n="rep|Report parameters button - delete from favorites@@rep.report.parameters.button.deleteFromFavorites">
                  Delete from favorites
                </span>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- right 1 -->
    </div>
  </ng-container>
  <!-- End reports html -->

  <!--Start reports html -->
  <!--<ng-container *ngIf="like(activeTab, 'report-')">
    <div class="col-8 pt-3 px-0">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-white m-0">
          <li class="breadcrumb-item">{{arrTitleSearch(reportGroup, reportActiveTab)}}</li>
          <li class="breadcrumb-item active">{{arrTitleSearch(reportItemAll, reportActiveTab)}}</li>
        </ol>
      </nav>
      <div class="reportTopButtons mb-3 px-3">
        <div class="btn-group">
          <button class="btn btn-sm btn-light-gray" type="button">
            <i class="material-icons md-16">email</i>
            <span>Share by mail</span>
          </button>
          <button type="button" class="btn btn-sm btn-light-gray dropdown-toggle dropdown-toggle-split" data-toggle="dropdown">
          </button>
          <div class="dropdown-menu dropdown-menu-right w-100">
            <button class="dropdown-item" type="button">
              <i class="material-icons md-16">public</i>
              <span>Share by link</span>
            </button>
          </div>
        </div>

        <div class="btn-group">
          <button class="btn  btn-sm btn-light-gray" type="button">
            <i class="material-icons md-16">save_alt</i>
            <span>Download report</span>
          </button>
          <button type="button" class="btn btn-sm btn-light-gray dropdown-toggle dropdown-toggle-split" data-toggle="dropdown">
          </button>
          <div class="dropdown-menu dropdown-menu-right w-100">
            <button class="dropdown-item" type="button">
              <i class="material-icons md-16">description</i>
              <span>Download data</span>
            </button>
            <button class="dropdown-item" type="button">
              <i class="material-icons md-16">pie_chart</i>
              <span>Download chart</span>
            </button>
          </div>
        </div>


        <button class="btn btn-light-gray btn-sm" (click)="changeTab($event)" id="menu-compare">
          <span class="material-icons md-16">compare</span>
          Compare
        </button>

      </div>

      <div class="reportTopOptions bg-light-gray border-top border-bottom p-3">
        <p>Data section</p>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" value="" id="dataPassengers">
          <label class="form-check-label" for="dataPassengers">
            Passengers
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" value="" id="dataCargo">
          <label class="form-check-label" for="dataCargo">
            Cargo
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" value="" id="dataPost">
          <label class="form-check-label" for="dataPost">
            Post
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" value="" id="dataBaggage">
          <label class="form-check-label" for="dataBaggage">
            Baggage
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" value="" id="dataSorties">
          <label class="form-check-label" for="dataSorties">
            Sorties
          </label>
        </div>
      </div> 
      <div class="p-3">

        <div class="report"> 
          <ul class="nav nav-tabs mb-3">
            <li class="nav-item">
              <a class="nav-link" (click)="changeReportView('chart')" [ngClass]="{'active': reportView == 'chart'}">
                Chart
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="changeReportView('table')" [ngClass]="{'active': reportView == 'table'}">
                Data
              </a>
            </li>
          </ul>
          <ng-container *ngIf="reportView == 'chart'">
            <div class="">
              <p>Data section</p>
              <ng-container *ngFor="let item of reportsData[reportActiveId].chart">
                <span (click)="changeDiagramActive(item.id)" class="mr-1 pointer">
                  <input [checked]="reportDiagramActive==item.id" type="radio" name="radioDiagramActive"> {{item.title}}
                </span>
              </ng-container>
            </div>
            <google-chart *ngIf="reportDiagramType[reportDiagramActive] == 'ColumnChart'"
                          class="pt-3 med mb-4"
                          [options]="{legend: { position: 'top', maxLines: 3 },
                                      bar: { groupWidth: '75%' },
                                      isStacked: true,
                                      width: '100%',
                                      height: '100%'}"
                          [type]="reportDiagramType[reportDiagramActive]"
                          [data]="reportDiagramData[reportDiagramActive]"
                          [columns]="reportDiagramColumns[reportDiagramActive]">
            </google-chart>
            <google-chart *ngIf="reportDiagramType[reportDiagramActive] == 'PieChart'"
                          class="pt-3 med mb-4"
                          [options]="{legend: {position: 'right', width: '500', itemWidth: '200'},
                                      chartArea: { top: 0, left: 0, width:'100%', height: '100%'},
                                      width: '100%',
                                      height: '100%'}"
                          [type]="reportDiagramType[reportDiagramActive]"
                          [data]="reportDiagramData[reportDiagramActive]"
                          [columns]="reportDiagramColumns[reportDiagramActive]">
            </google-chart>
            <div class="reporting-options">
              <div class="font-large mb-2">Reporting options</div>
              <p class="m-0"><b>Main: </b> <span class="text-blue">Period: </span> 01.01.2020 00:00 - 15.01.2020 00:00</p>
              <p class="m-0"><b>Other: </b> <span class="text-blue">Group by: </span> People</p>
              <p class="m-0"><b>Data sections: </b> Passangers</p>
            </div>
          </ng-container>
          <div *ngIf="reportView == 'table'" class="row">
            <div class="col-12 pr-0 scroll-x">
              <table class="table table-borderless table-hover">
                <thead>
                  <tr class="text-muted detail-head table__mail-header--subhead">
                    <th *ngFor="let item of reportsData[reportActiveId].titles">{{item}}</th>
                  </tr>
                </thead>
                <tbody class="font-small">
                  <tr *ngFor="let row of reportsData[reportActiveId].data | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                    <td *ngFor="let col of row" class="text-center">{{col}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <pagination-controls *ngIf="reportView == 'table'"
                              (pageChange)="currentPage = $event"
                              maxSize="7"
                              directionLinks="true"
                              autoHide="true"
                              previousLabel="Previous"
                              nextLabel="Next">
          </pagination-controls>
        </div>
      </div>
    </div>
    <div class="col-2 border-left pt-3 pb-5">
      <ng-container>
        <ul class="nav nav-tabs mb-3">
          <li class="nav-item">
            <a class="nav-link" (click)="changeReportRightTab('parameters')" [ngClass]="{'active': reportRightTab == 'parameters'}">
              Parameters
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link d-flex align-items-center"
                (click)="changeReportRightTab('favorites')"
                [ngClass]="{'active': reportRightTab == 'favorites'}">
              <i class="material-icons md-16 pl-0" [ngClass]="{'gold': reportRightTab == 'favorites'}">grade</i>
              Favorites
            </a>
          </li>
        </ul>
        <form #reportForm="ngForm" class="form-report" *ngIf="reportRightTab == 'parameters'">
          <div class="mb-3">
            <div class="mb-2">
              <b>Configuration</b>
            </div>
            <select class="form-control form-control-sm mb-2" name="configuration" id="configuration">
              <option value="default">Default</option>
              <option value="not-default">Not default</option>
            </select>
            <div class="setting-buttons text-right">
              <button type="button" class="btn btn-xs mr-1 btn-ico btn-light-gray" placement="top-right"  ngbTooltip="Delete configuration">
                <i class="material-icons">delete</i>
              </button>
              <button type="button" class="btn btn-xs mr-1 btn-ico btn-light-gray" placement="top-right"  ngbTooltip="Update configuration">
                <i class="material-icons">save</i>
              </button>
              <button type="button" class="btn btn-xs btn-ico btn-light-gray" placement="top-right"  ngbTooltip="Save configuration us...">
                <i class="material-icons">settings</i>
              </button>
            </div>
          </div>
          <div class="row border-top border-bottom py-3 mb-3">
            <div class="col-12">
              <b class="mb-3">Main</b>
              <div class="mb-3">
                <label for="period" class="mb-2">Period</label>
                <div class="mb-2">
                  <div class="btn-group w-100" role="group" aria-label="">
                    <button type="button"
                            class="btn btn-sm btn-h-25 btn-light-gray"
                            (click)="selectedPeriod = 'current'"
                            [ngClass]="{'btn-blue': selectedPeriod == 'current'}">
                      Current
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-h-25 btn-light-gray"
                            (click)="selectedPeriod = 'previous'"
                            [ngClass]="{'btn-blue': selectedPeriod == 'previous'}">
                      Previous
                    </button>
                  </div>
                </div>
                <select class="form-control form-control-sm mb-1" [(ngModel)]="reportParams.period" name="reportParamsPeriod" id="reportParamsPeriod">
                  <option value="work-shift" [selected]="reportParams.period == 'work-shift'">Work shift</option>
                  <option value="day" [selected]="reportParams.period == 'day'">Day</option>
                  <option value="week" [selected]="reportParams.period == 'week'">Week</option>
                  <option value="month" [selected]="reportParams.period == 'month'">Month</option>
                  <option value="qarter" [selected]="reportParams.period == 'qarter'">Qarter</option>
                  <option value="manual-setting" [selected]="reportParams.period == 'manual-setting'">Manual setting
                  </option>
                </select>
                <div class="extra-small ta-right">
                  Range: 01.01.2020, 00:00 - 15.01.2020, 00:00
                </div>
              </div>
              <ng-container *ngIf="reportParams.period == 'manual-setting'">
                <label for="reportParamsStartTime" class="mb-0">
                  Begin
                  <span class="required-asterisk">*</span>
                </label>
                <div class="mb-2 row">
                  <div class="col pr-0">
                    <input type="time" class="form-control"
                          [ngModel]="reportParams.start | date: 'HH:mm'"
                          (input)="reportParams.start = parseTime(start_date, $event.target.value)"
                          name="reportParamsStartTime"
                          #start_time="ngModel"
                          [required]="reportParams.period == 'manual-setting'">
                    <div *ngIf="!start_time?.valid && (start_time?.dirty || start_time?.touched)"
                      class="invalid-feedback d-block">
                      <div *ngIf="start_time.errors.required">
                        Time is required
                      </div>
                    </div>
                  </div>
                  <div class="col pl-1">
                    <input type="date" class="form-control"
                          [ngModel]="reportParams.start | date: 'yyyy-MM-dd'"
                          (input)="reportParams.start = parseDate($event.target.value, start_time)"
                          name="reportParamsStartDate"
                          #start_date="ngModel"
                          [required]="reportParams.period == 'manual-setting'">
                    <div *ngIf="!start_date?.valid && (start_date?.dirty || start_date?.touched)"
                      class="invalid-feedback d-block">
                      <div *ngIf="start_date.errors.required">
                        Date is required
                      </div>
                    </div>
                  </div>
                </div>
                <label for="filter-date-from" class="mb-0">
                  End
                  <span class="required-asterisk">*</span>
                </label>
                <div class="mb-2 row">
                  <div class="col pr-0">
                    <input type="time" class="form-control"
                          [ngModel]="reportParams.finish | date: 'HH:mm'"
                          (input)="reportParams.finish = parseTime(finish_date, $event.target.value)"
                          name="reportParamsFinishTime"
                          #finish_time="ngModel"
                          [required]="reportParams.period == 'manual-setting'">
                    <div *ngIf="!finish_time?.valid && (finish_time?.dirty || finish_time?.touched)"
                      class="invalid-feedback d-block">
                      <div *ngIf="finish_time.errors.required">
                        Time is required
                      </div>
                    </div>
                  </div>
                  <div class="col pl-1">
                    <input type="date" class="form-control"
                          [ngModel]="reportParams.finish | date: 'yyyy-MM-dd'"
                          (input)="reportParams.finish = parseDate($event.target.value, finish_time)"
                          name="reportParamsFinishDate"
                          #finish_date="ngModel"
                          [required]="reportParams.period == 'manual-setting'">
                    <div *ngIf="!finish_date?.valid && (finish_date?.dirty || finish_date?.touched)" class="invalid-feedback d-block">
                      <div *ngIf="finish_date.errors.required">
                        Date is required
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="mb-2">
            <label for="report-airline" class="mb-0">
              Airline
            </label>
            <ng-select class="form-control custom-sm border p-0"
                      [items]="referanceAirlines"
                      bindLabel="iata"
                      bindValue="id"
                      [(ngModel)]="reportParams.airline"
                      #reportAirline="ngModel"
                      name="reportAirline"
                      [virtualScroll]="true"
                      [loading]="referanceAirlinesLoading"
                      (scroll)="onScrollReferanceAirlines($event)"
                      (scrollToEnd)="onScrollReferanceAirlinesToEnd()">
              <ng-template ng-option-tmp let-item="item">
                IATA: <span class="font-weight-bold">{{item.iata}}</span>, ICAO: {{item.icao}} <br />
                <small>{{item.names}}</small>
              </ng-template>
            </ng-select>
          </div>
          <div class="mb-2">
            <label for="report-airline" class="mb-0">
              Movement
            </label>
            <ng-select class="form-control custom-sm border p-0"
                      [items]="referanceMovements"
                      bindLabel="name"
                      bindValue="id"
                      [(ngModel)]="reportParams.movement"
                      name="reportMovement"
                      #reportMovement="ngModel"
                      [virtualScroll]="true"
                      [loading]="referanceMovementsLoading"
                      (scroll)="onScrollReferanceMovements($event)"
                      (scrollToEnd)="onScrollReferanceMovementsToEnd()">
              <ng-template ng-option-tmp let-item="item">
                {{item.code}} / {{item.name}}
              </ng-template>
            </ng-select>
          </div>
          <div class="mb-2">
            <label for="report-geo" class="mb-0">
              Geo
            </label>
            <ng-select class="form-control custom-sm border p-0"
                      [items]="referanceGeoTypes"
                      [bindLabel]="globalSettings.language=='en' ? 'nameEnglish' : 'nameLocal'"
                      bindValue="id"
                      name="reportGeo"
                      id="reportGeo"
                      [(ngModel)]="reportParams.geo">
              <ng-template ng-option-tmp let-item="item">
                <span>{{globalSettings.language=='en' ? item.nameEnglish : item.nameLocal}}</span>
              </ng-template>
            </ng-select>
          </div>
          <div class="mb-2">
            <label for="report-aircraft" class="mb-0">
              Aircraft
            </label>
            <ng-select class="form-control custom-sm border p-0"
                      [items]="referanceAircraftTypes"
                      bindLabel="names"
                      bindValue="id"
                      name="reportAircraft"
                      id="reportAircraft"
                      [(ngModel)]="reportParams.aircraft"
                      [virtualScroll]="true"
                      [loading]="referanceAircraftTypesLoading"
                      (scroll)="onScrollReferanceAircraftTypes($event)"
                      (scrollToEnd)="onScrollReferanceAircraftTypesToEnd()">
            </ng-select>
          </div>
          <div class="mb-2">
            <label for="report-airport" class="mb-0">
              Airport
            </label>
            <ng-select class="form-control custom-sm border p-0"
                      [items]="referanceAirports"
                      bindLabel="iata"
                      bindValue="id"
                      [(ngModel)]="reportParams.airport"
                      #reportAirport="ngModel"
                      name="reportAirport"
                      [virtualScroll]="true"
                      [loading]="referanceAirportsLoading"
                      (scroll)="onScrollReferanceAirports($event)"
                      (scrollToEnd)="onScrollReferanceAirportsToEnd()">
              <ng-template ng-option-tmp let-item="item">
                <span class="font-weight-bold">{{item.iata}}</span>, {{item.names}}
              </ng-template>
            </ng-select>
          </div>
          <div class="mb-2">
            <label for="report-season" class="mb-0">
              Season
            </label>
            <ng-select class="form-control custom-sm border p-0"
                      [items]="referanceSeasons"
                      [bindLabel]="globalSettings.language=='en' ? 'nameEnglish' : 'nameLocal'"
                      bindValue="id"
                      name="reportSeason"
                      id="reportSeason"
                      [(ngModel)]="reportParams.season">
              <ng-template ng-option-tmp let-item="item">
                <span>{{globalSettings.language=='en' ? item.nameEnglish : item.nameLocal}}</span>
              </ng-template>
            </ng-select>
          </div>
          <div class="mb-2">
            <label for="report-regularity" class="mb-0">
              Regularity
            </label>
            <ng-select class="form-control custom-sm border p-0"
                      [items]="referanceRegulatures"
                      [bindLabel]="globalSettings.language=='en' ? 'nameEnglish' : 'nameLocal'"
                      bindValue="id"
                      name="reportRegularity"
                      id="reportRegularity"
                      [(ngModel)]="reportParams.regularity">
              <ng-template ng-option-tmp let-item="item">
                <span>{{globalSettings.language=='en' ? item.nameEnglish : item.nameLocal}}</span>
              </ng-template>
            </ng-select>
          </div>
          <div class="mb-3 mt-3">
            <p class="mb-2"><b>Other</b></p>
            <label for="report-regularity" class="mb-0">Group by</label>
            <select class="form-control form-control-sm mb-1" [(ngModel)]="reportParams.groupBy" name="groupBy" id="groupBy">
              <option value="people" [selected]="reportParams.groupBy == 'people'">People</option>
              <option value="day" [selected]="reportParams.groupBy == 'day'">Day</option>
            </select>
          </div>
          <button class="btn btn-blue btn-sm mt-4 w-100" (click)="loadReportDailyPlan()">
            Create report
          </button>
        </form>
        <div class="" *ngIf="reportRightTab == 'favorites'">
          <div class="pb-1">Parameters</div>
          <div class="pb-3 border-bottom">
            <select class="form-control form-control-sm" name="select1">
              <option>Default</option>
              <option>No default</option>
            </select>
          </div>
          <div class="pb-1 pt-3">
            <b>Email</b>
          </div>
          <div class="pb-1">
            Send to mail
          </div>
          <div class="pb-3">
            <select class="form-control form-control-sm">
              <option>Everyday</option>
              <option>Yesterday</option>
              <option>All My</option>
              <option>Troubles</option>
              <option>Seemed so</option>
              <option>Far away</option>
            </select>
          </div>
          <div class="pb-1">
            Mail sending time
          </div>
          <div class="pb-3">
            <input type="time"
                  class="form-control form-control-sm"
                  [ngModel]="reportParams.finish | date: 'HH:mm'"
                  (input)="reportParams.finish = parseTime(finish_date, $event.target.value)"
                  #finish_date="ngModel"
                  required>
          </div>
          <div class="pb-1">
            Adresses
          </div>
          <div class="pb-3">
            <input class="form-control form-control-sm" value="plan@airport.com">
          </div>
          <div class="btn-group delete-favorite dropup w-100">
            <button class="btn btn-sm btn-blue w-100">
              Save favorites
            </button>
            <button type="button" class="btn btn-sm btn-blue dropdown-toggle dropdown-toggle-split" data-toggle="dropdown">
            </button>
            <div class="dropdown-menu dropdown-menu-right w-100">
              <button class="dropdown-item" type="button">
                <i class="material-icons md-16">delete</i>
                <span>Delete from favorites</span>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>-->
  <!-- End reports html -->
  <!--<div class="col-8 pt-3 px-0" *ngIf="showTab('tab-daily-plan') || showTab('tab-synchron')">
    <div class="p-3 border-left border-right border-bottom">
      <div class="form-report border bg-gray p-3">
        <div class="mb-2 row">
          <div class="col-6">
            <label for="reportParamsStartTime" class="mr-2 mb-0">
              Date from
            </label>
            <div class="row">
              <div class="col-6">
                <input type="time" class="form-control mr-1"
                      [ngModel]="reportParams.start | date: 'HH:mm'"
                      (input)="reportParams.start = parseTime(start_date, $event.target.value)"
                      name="reportParamsStartTime"
                      #start_time>
              </div>
              <div class="col-6">
                <input type="date" class="form-control"
                      [ngModel]="reportParams.start | date: 'yyyy-MM-dd'"
                      (input)="reportParams.start = parseDate($event.target.value, start_time)"
                      name="reportParamsStartDate"
                      #start_date>
                <div class="card-header bg-gray-blue-dark text-center text-white font-weight-bold">
                  Modules
                </div>
                <ul class="list-group list-group-flush">
                  <li id="module-coordination"
                      class="list-group-item active"
                      [ngClass]="{'active': activeModule=='module-coordination'}"
                      (click)="changeModule($event)">
                    Slot coordination
                  </li>
                  <li id="module-informational"
                      class="list-group-item"
                      [ngClass]="{'active': activeModule=='module-informational'}"
                      (click)="changeModule($event)">
                    Informational
                  </li>
                </ul>
              </div>
            </div>
            <div class="card-header bg-gray-blue-dark text-center text-white font-weight-bold">
              Modules
            </div>
            <ul class="list-group list-group-flush">
              <li id="module-coordination"
                  class="list-group-item active"
                  [ngClass]="{'active': activeModule=='module-coordination'}"
                  (click)="changeModule($event)">
                Slot coordination
              </li>
              <li id="module-informational"
                  class="list-group-item"
                  [ngClass]="{'active': activeModule=='module-informational'}"
                  (click)="changeModule($event)">
                Informational
              </li>
            </ul>
          </div>
        </div>
        <div class="col-10 pl-0">
          <div *ngIf="activeModule=='module-coordination'">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a id="tab-daily-plan" [ngClass]="{'active': showTab('tab-daily-plan')}" class="nav-link" (click)="changeTab($event)">
                  Daily plan
                </a>
              </li>
              <li class="nav-item">
                <a id="tab-synchron" [ngClass]="{'active': showTab('tab-synchron')}" class="nav-link" (click)="changeTab($event)">
                  Synchron
                </a>
              </li>
            </ul>
            <div *ngIf="showTab('tab-daily-plan')" class="p-3 border-left border-right border-bottom">
              <form #reportForm="ngForm" class="form-report border bg-gray p-3">
                <div class="mb-2 row">
                  <div class="col-6">
                    <label for="reportParamsStartTime" class="mr-2 mb-0">
                      Date from
                      <span class="required-asterisk">*</span>
                    </label>
                    <div class="row">
                      <div class="col-6">
                        <input type="time"
                              class="form-control mr-1"
                              [ngModel]="reportParams.start | date: 'HH:mm'"
                              (input)="reportParams.start = parseTime(start_date, $event.target.value)"
                              name="reportParamsStartTime"
                              #start_time="ngModel"
                              required>
                        <div *ngIf="!start_time?.valid && (start_time?.dirty || start_time?.touched)"
                          class="invalid-feedback d-block">
                          <div *ngIf="start_time.errors.required">
                            Time is required
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <input type="date"
                              class="form-control"
                              [ngModel]="reportParams.start | date: 'yyyy-MM-dd'"
                              (input)="reportParams.start = parseDate($event.target.value, start_time)"
                              name="reportParamsStartDate"
                              #start_date="ngModel"
                              required>
                        <div *ngIf="!start_date?.valid && (start_date?.dirty || start_date?.touched)" class="invalid-feedback d-block">
                          <div *ngIf="start_date.errors.required">
                            Date is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label for="filter-date-from" class="mr-2 mb-0">
                      Date to
                      <span class="required-asterisk">*</span>
                    </label>
                    <div class="row">
                      <div class="col-6">
                        <input type="time"
                              class="form-control mr-1"
                              [ngModel]="reportParams.finish | date: 'HH:mm'"
                              (input)="reportParams.finish = parseTime(finish_time, $event.target.value)"
                              name="reportParamsFinishTime"
                              #finish_time="ngModel"
                              required>
                        <div *ngIf="!finish_time?.valid && (finish_time?.dirty || finish_time?.touched)"
                          class="invalid-feedback d-block">
                          <div *ngIf="finish_time.errors.required">
                            Time is required
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <input type="date"
                              class="form-control"
                              [ngModel]="reportParams.finish | date: 'yyyy-MM-dd'"
                              (input)="reportParams.finish = parseDate($event.target.value, finish_time)"
                              name="reportParamsFinishDate"
                              #finish_date="ngModel"
                              required>
                        <div *ngIf="!finish_date?.valid && (finish_date?.dirty || finish_date?.touched)"
                          class="invalid-feedback d-block">
                          <div *ngIf="finish_date.errors.required">
                            Date is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-2 row">
                  <div class="col-3">
                    <label for="report-airline" class="mb-0">
                      Airline
                    </label>
                    <select id="report-airline"
                            class="form-control form-control-sm py-0"
                            name="report-airline"
                            (input)="reportParams.airline = $event.target.value">
                      <option value="null" [selected]="reportParams.airline==null">-</option>
                      <option *ngFor="let airline of referanceAirlines" [value]="airline.id"
                        [selected]="airline.id == reportParams.airline">{{airline.names}}</option>
                    </select>
                  </div>
                  <div class="col-3">
                    <label for="report-airline" class="mb-0">
                      Movement
                    </label>
                    <select id="report-movement"
                            class="form-control form-control-sm py-0"
                            name="report-movement"
                            (input)="reportParams.movement = $event.target.value">
                      <option value="null" [selected]="reportParams.movement==null">-</option>
                      <option *ngFor="let movement of referanceMovements"
                              [value]="movement.id"
                              [selected]="movement.id == reportParams.movement">
                        {{movement.code}} {{movement.names}}
                      </option>
                    </select>
                  </div>
                  <div class="col-3">
                    <label for="report-geo" class="mb-0">
                      Geo
                    </label>
                    <select id="report-geo"
                            class="form-control form-control-sm py-0"
                            name="report-geo"
                            (input)="reportParams.geo = $event.target.value">
                      <option value="null" [selected]="reportParams.geo==null">-</option>
                      <option *ngFor="let geo of referanceGeoTypes" [value]="geo.id" [selected]="geo.id == reportParams.geo">
                        {{geo.names}}
                      </option>
                    </select>
                  </div>
                  <div class="col-3">
                    <label for="report-aircraft" class="mb-0">
                      Aircraft
                    </label>
                    <select id="report-aircraft"
                            class="form-control form-control-sm py-0"
                            name="report-aircraft"
                            (input)="reportParams.aircraft = $event.target.value">
                      <option value="null" [selected]="reportParams.aircraft==null">-</option>
                      <option *ngFor="let aircraft of referanceAircraftTypes" [value]="aircraft.id" [selected]="aircraft.id == reportParams.aircraft">
                        {{aircraft.names}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="mb-2 row">
                  <div class="col-3">
                    <label for="report-airport" class="mb-0">
                      Airport
                    </label>
                    <select id="report-airport"
                            class="form-control form-control-sm py-0"
                            name="report-airport"
                            (input)="reportParams.airport = $event.target.value">
                      <option value="null" [selected]="reportParams.airport==null">-</option>
                      <option *ngFor="let airport of referanceAirports" [value]="airport.id" [selected]="airport.id == reportParams.airport">
                        {{airport.names}}
                      </option>
                    </select>
                  </div>
                  <div class="col-3">
                    <label for="report-season" class="mb-0">
                      Season
                    </label>
                    <select id="report-season"
                            class="form-control form-control-sm py-0"
                            name="report-season"
                            (input)="reportParams.season = $event.target.value">
                      <option value="null" [selected]="reportParams.season==null">-</option>
                      <option *ngFor="let season of referanceSeasons" [value]="season.id" [selected]="season.id == reportParams.season">
                        {{season.names}}
                      </option>
                    </select>
                  </div>
                  <div class="col-3">
                    <label for="report-regularity" class="mb-0">
                      Regularity
                    </label>
                    <select id="report-regularity"
                            class="form-control form-control-sm py-0"
                            name="report-regularity"
                            (input)="reportParams.regularity = $event.target.value">
                      <option value="null" [selected]="reportParams.regularity==null">-</option>
                      <option *ngFor="let regularity of referanceRegulatures" [value]="regularity.id" [selected]="regularity.id == reportParams.regularity">
                        {{regularity.names}}
                      </option>
                    </select>
                  </div>
                  <div class="col-3">
                    <button class="btn btn-blue btn-sm float-right mt-4" (click)="loadReportDailyPlan()">
                      Create report
                    </button>
                  </div>
                </div>
              </form>
              <div class="report" *ngIf="reportDailyPlan">
                <div class="my-3">
                  <button class="btn btn-sm btn-light-gray" (click)="saveReport('daily_plan', 'xls')">
                    <i class="material-icons">get_app</i> Download report
                  </button>
                </div>
                <google-chart [type]="diagramType"
                              [data]="diagramData"
                              [columns]="diagramColumns">
                </google-chart>
                <div class="row">
                  <div class="col-6 pr-0">
                    <table class="table table-borderless table-hover">
                      <thead>
                        <tr class="text-muted detail-head table__mail-header--subhead">
                          <th class="text-center font-medium" colspan="7" style="border-radius: 4px 0 0 0;">
                            Arrivial
                          </th>
                        </tr>
                        <tr class="text-muted detail-head table__mail-header--subhead font-small">
                          <th class="text-center" style="border-radius: 0 0 0 4px;">Datetime</th>
                          <th class="text-center">Flight</th>
                          <th class="text-center">Route</th>
                          <th class="text-center" style="border-radius: 0;">Aircraft type</th>
                        </tr>
                      </thead>
                      <tbody class="font-small">
                        <tr
                          *ngFor="let flight of reportDailyPlan.arr | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                          <td class="text-center">
                            <span class="font-medium font-weight-bold">{{flight.dt | date: 'HH:mm'}}</span><br>
                            <span class="font-small">{{flight.dt | date: 'dd.MM'}}</span>
                          </td>
                          <td class="text-center body-border__right--gray-normal">
                            {{flight.airline}} {{flight.flight}}
                          </td>
                          <td class="text-center">
                            {{flight.route.join(' - ')}}
                          </td>
                          <td class="text-center body-border__right--gray-normal" style="border-radius: 0;">
                            {{flight.aircraftType}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-6 pl-0">
                    <table class="table table-borderless table-hover">
                      <thead>
                        <tr class="text-muted detail-head table__mail-header--subhead">
                          <th class="text-center font-medium" colspan="7" style="border-radius: 0 4px 0 0;">
                            Departure
                          </th>
                        </tr>
                        <tr class="text-muted detail-head table__mail-header--subhead font-small">
                          <th class="text-center" style="border-radius: 0;">Datetime</th>
                          <th class="text-center">Flight</th>
                          <th class="text-center">Route</th>
                          <th class="text-center">Aircraft type</th>
                        </tr>
                      </thead>
                      <tbody class="font-small">
                        <tr
                          *ngFor="let flight of reportDailyPlan.dep | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                          <td class="text-center" style="border-radius: 0;">
                            <span class="font-medium font-weight-bold">{{flight.dt | date: 'HH:mm'}}</span><br>
                            <span class="font-small">{{flight.dt | date: 'dd.MM'}}</span>
                          </td>
                          <td class="text-center body-border__right--gray-normal">
                            {{flight.airline}} {{flight.flight}}
                          </td>
                          <td class="text-center">
                            {{flight.route.join(' - ')}}
                          </td>
                          <td class="text-center body-border__right--gray-normal">
                            {{flight.aircraftType}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <pagination-controls (pageChange)="currentPage = $event"
                                      maxSize="7"
                                      directionLinks="true"
                                      autoHide="true"
                                      previousLabel="Previous"
                                      nextLabel="Next">
                </pagination-controls>
              </div>
            </div>
            <div *ngIf="showTab('tab-synchron')" class="p-3 border-left border-right border-bottom">
              <form #reportForm="ngForm" class="form-report border bg-gray p-3">
                <div class="mb-2 row">
                  <div class="col-6">
                    <label for="reportParamsStartTime" class="mr-2 mb-0">
                      Date from
                    </label>
                    <div class="row">
                      <div class="col-6">
                        <input type="time"
                              class="form-control mr-1"
                              [ngModel]="reportParams.start | date: 'HH:mm'"
                              (input)="reportParams.start = parseTime(start_date, $event.target.value)"
                              name="reportParamsStartTime"
                              #start_time="ngModel"
                              required>
                        <div *ngIf="!start_time?.valid && (start_time?.dirty || start_time?.touched)" class="invalid-feedback d-block">
                          <div *ngIf="start_time.errors.required">
                            Time is required
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <input type="date"
                              class="form-control"
                              [ngModel]="reportParams.start | date: 'yyyy-MM-dd'"
                              (input)="reportParams.start = parseDate($event.target.value, start_time)"
                              name="reportParamsStartDate"
                              #start_date="ngModel"
                              required>
                        <div *ngIf="!start_date?.valid && (start_date?.dirty || start_date?.touched)"
                          class="invalid-feedback d-block">
                          <div *ngIf="start_date.errors.required">
                            Date is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label for="filter-date-from" class="mr-2 mb-0">
                      Date to
                    </label>
                    <div class="row">
                      <div class="col-6">
                        <input type="time"
                              class="form-control mr-1"
                              [ngModel]="reportParams.finish | date: 'HH:mm'"
                              (input)="reportParams.finish = parseTime(finish_time, $event.target.value)"
                              name="reportParamsFinishTime"
                              #finish_time="ngModel"
                              required>
                        <div *ngIf="!finish_time?.valid && (finish_time?.dirty || finish_time?.touched)"
                          class="invalid-feedback d-block">
                          <div *ngIf="finish_time.errors.required">
                            Time is required
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <input type="date"
                              class="form-control"
                              [ngModel]="reportParams.finish | date: 'yyyy-MM-dd'"
                              (input)="reportParams.finish = parseDate($event.target.value, finish_time)"
                              name="reportParamsFinishDate"
                              #finish_date="ngModel"
                              required>
                        <div *ngIf="!finish_date?.valid && (finish_date?.dirty || finish_date?.touched)" class="invalid-feedback d-block">
                          <div *ngIf="finish_date.errors.required">
                            Date is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-2 row">
                  <div class="col-3">
                    <label for="report-airline" class="mb-0">
                      Airline
                    </label>
                    <select id="report-airline"
                            class="form-control form-control-sm py-0"
                            name="report-airline"
                            (input)="reportParams.airline = $event.target.value">
                      <option value="null" [selected]="reportParams.airline==null">-</option>
                      <option *ngFor="let airline of referanceAirlines" [value]="airline.id" [selected]="airline.id == reportParams.airline">
                        {{airline.names}}
                      </option>
                    </select>
                  </div>
                  <div class="col-3">
                    <label for="report-airline" class="mb-0">
                      Movement
                    </label>
                    <select id="report-movement"
                            class="form-control form-control-sm py-0"
                            name="report-movement"
                            (input)="reportParams.movement = $event.target.value">
                      <option value="null" [selected]="reportParams.movement==null">-</option>
                      <option *ngFor="let movement of referanceMovements" [value]="movement.id" [selected]="movement.id == reportParams.movement">
                        {{movement.code}} {{movement.names}}
                      </option>
                    </select>
                  </div>
                  <div class="col-3">
                    <label for="report-geo" class="mb-0">
                      Geo
                    </label>
                    <select id="report-geo"
                            class="form-control form-control-sm py-0"
                            name="report-geo"
                            (input)="reportParams.geo = $event.target.value">
                      <option value="null" [selected]="reportParams.geo==null">-</option>
                      <option *ngFor="let geo of referanceGeoTypes" [value]="geo.id" [selected]="geo.id == reportParams.geo">
                        {{geo.names}}
                      </option>
                    </select>
                  </div>
                  <div class="col-3">
                    <label for="report-aircraft" class="mb-0">
                      Aircraft
                    </label>
                    <select id="report-aircraft"
                            class="form-control form-control-sm py-0"
                            name="report-aircraft"
                            (input)="reportParams.aircraft = $event.target.value">
                      <option value="null" [selected]="reportParams.aircraft==null">-</option>
                      <option *ngFor="let aircraft of referanceAircraftTypes" [value]="aircraft.id" [selected]="aircraft.id == reportParams.aircraft">
                        {{aircraft.names}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="mb-2 row">
                  <div class="col-3">
                    <label for="report-airport" class="mb-0">
                      Airport
                    </label>
                    <select id="report-airport"
                            class="form-control form-control-sm py-0"
                            name="report-airport"
                            (input)="reportParams.airport = $event.target.value">
                      <option value="null" [selected]="reportParams.airport==null">-</option>
                      <option *ngFor="let airport of referanceAirports" [value]="airport.id" [selected]="airport.id == reportParams.airport">
                        {{airport.names}}
                      </option>
                    </select>
                  </div>
                  <div class="col-3">
                    <label for="report-season" class="mb-0">
                      Season
                    </label>
                    <select id="report-season"
                            class="form-control form-control-sm py-0"
                            name="report-season"
                            (input)="reportParams.season = $event.target.value">
                      <option value="null" [selected]="reportParams.season==null">-</option>
                      <option *ngFor="let season of referanceSeasons" [value]="season.id" [selected]="season.id == reportParams.season">
                        {{season.names}}
                      </option>
                    </select>
                  </div>
                  <div class="col-3">
                    <label for="report-regularity" class="mb-0">
                      Regularity
                    </label>
                    <select id="report-regularity"
                            class="form-control form-control-sm py-0"
                            name="report-regularity"
                            (input)="reportParams.regularity = $event.target.value">
                      <option value="null" [selected]="reportParams.regularity==null">-</option>
                      <option *ngFor="let regularity of referanceRegulatures" [value]="regularity.id" [selected]="regularity.id == reportParams.regularity">
                        {{regularity.names}}
                      </option>
                    </select>
                  </div>
                  <div class="col-3 mt-4">
                    <input type="checkbox" name="report-full" id="report-full" [(ngModel)]="reportParams.full"> Full
                    <button class="btn btn-blue btn-sm float-right" (click)="loadReportSynchron()">
                      Create report
                    </button>
                  </div>
                </div>
              </form>
              <div class="report" *ngIf="reportSynchron">
                <div class="my-3">
                  <button class="btn btn-sm btn-light-gray" (click)="saveReport('synchron', 'xls')">
                    <i class="material-icons">get_app</i> Download report
                  </button>
                </div>
                <table class="table table-borderless table-hover">
                  <thead>
                    <tr class="text-muted detail-head table__mail-header--subhead">
                      <th class="text-center text-small" colspan="6" style="border-bottom-left-radius: 0;">
                        Arrivial
                      </th>
                      <th class="text-center text-small" rowspan="2">Time<br>Date</th>
                      <th class="text-center text-small" colspan="6" style="border-bottom-right-radius: 0;">
                        Departure
                      </th>
                    </tr>
                    <tr class="text-muted detail-head table__mail-header--subhead font-small">
                      <th class="text-center" style="border-top-left-radius: 0;">Flight</th>
                      <th class="text-center">Route</th>
                      <th class="text-center">Aircraft type</th>
                      <th class="text-center">From</th>
                      <th class="text-center">Till</th>
                      <th class="text-center">Days</th>
                      <th class="text-center">Flight</th>
                      <th class="text-center">Route</th>
                      <th class="text-center">Aircraft type</th>
                      <th class="text-center">From</th>
                      <th class="text-center">Till</th>
                      <th class="text-center" style="border-top-right-radius: 0;">Days</th>
                    </tr>
                  </thead>
                  <tbody class="font-small">
                    <tr *ngFor="let flight of reportSynchron | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                      <ng-container *ngIf="flight.arrdep!='dep'; else arrivial">
                        <td class="text-center">
                          {{flight.airline}} {{flight.flight}}
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          {{flight.route.join(' - ')}}
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          {{flight.aircraftType}}
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <span class="font-small">{{flight.start | date: 'dd.MM.yyyy'}}</span>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <span class="font-small">{{flight.finish | date: 'dd.MM.yyyy'}}</span>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          {{flight.frequency}}
                        </td>
                      </ng-container>
                      <ng-template #arrivial>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </ng-template>
                      <td class="text-center body-border__right--gray-normal">
                        <span class="font-medium font-weight-bold">{{flight.dt | date: 'HH:mm'}}</span><br>
                        <span class="font-small">{{flight.dt | date: 'dd.MM'}}</span>
                      </td>
                      <ng-container *ngIf="flight.arrdep=='dep'; else departure">
                        <td class="text-center">
                          {{flight.airline}} {{flight.flight}}
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          {{flight.route.join(' - ')}}
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          {{flight.aircraftType}}
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <span class="font-small">{{flight.start | date: 'dd.MM.yyyy'}}</span>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          <span class="font-small">{{flight.finish | date: 'dd.MM.yyyy'}}</span>
                        </td>
                        <td class="text-center body-border__right--gray-normal">
                          {{flight.frequency}}
                        </td>
                      </ng-container>
                      <ng-template #departure>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </ng-template>
                    </tr>
                  </tbody>
                </table>
                <pagination-controls (pageChange)="currentPage = $event"
                                      maxSize="7"
                                      directionLinks="true"
                                      autoHide="true"
                                      previousLabel="Previous"
                                      nextLabel="Next">
                </pagination-controls>
              </div>
            </div>
          </div>
          <div *ngIf="activeModule=='module-informational'">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a id="tab-media-logs" [ngClass]="{'active': showTab('tab-media-logs')}" class="nav-link" (click)="changeTab($event)">
                  Daily plan
                </a>
              </li>
            </ul>
            <div *ngIf="showTab('tab-media-logs')" class="p-3 border-left border-right border-bottom">
              <form #reportForm="ngForm" class="form-report border bg-gray p-3">
                <div class="mb-2 row">
                  <div class="col-6">
                    <label for="reportParamsStartTime" class="mr-2 mb-0">
                      Date from
                    </label>
                    <div class="row">
                      <div class="col-6">
                        <input type="time"
                              class="form-control mr-1"
                              [ngModel]="reportParams.start | date: 'HH:mm'"
                              (input)="reportParams.start = parseTime(start_date, $event.target.value)"
                              name="reportParamsStartTime"
                              #start_time="ngModel"
                              required>
                        <div *ngIf="!start_time?.valid && (start_time?.dirty || start_time?.touched)"
                          class="invalid-feedback d-block">
                          <div *ngIf="start_time.errors.required">
                            Time is required
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <input type="date"
                              class="form-control"
                              [ngModel]="reportParams.start | date: 'yyyy-MM-dd'"
                              (input)="reportParams.start = parseDate($event.target.value, start_time)"
                              name="reportParamsStartDate"
                              #start_date="ngModel"
                              required>
                        <div *ngIf="!start_date?.valid && (start_date?.dirty || start_date?.touched)"
                          class="invalid-feedback d-block">
                          <div *ngIf="start_date.errors.required">
                            Date is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label for="filter-date-from" class="mr-2 mb-0">
                      Date to
                    </label>
                    <div class="row">
                      <div class="col-6">
                        <input type="time"
                              class="form-control mr-1"
                              [ngModel]="reportParams.finish | date: 'HH:mm'"
                              (input)="reportParams.finish = parseTime(finish_date, $event.target.value)"
                              name="reportParamsFinishTime"
                              #finish_time="ngModel"
                              required>
                        <div *ngIf="!finish_time?.valid && (finish_time?.dirty || finish_time?.touched)"
                          class="invalid-feedback d-block">
                          <div *ngIf="finish_time.errors.required">
                            Time is required
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <input type="date"
                              class="form-control"
                              [ngModel]="reportParams.finish | date: 'yyyy-MM-dd'"
                              (input)="reportParams.finish = parseDate($event.target.value, finish_time)"
                              name="reportParamsFinishDate"
                              #finish_date="ngModel"
                              required>
                        <div *ngIf="!finish_date?.valid && (finish_date?.dirty || finish_date?.touched)"
                          class="invalid-feedback d-block">
                          <div *ngIf="finish_date.errors.required">
                            Date is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-2 row">
                  <div class="col-12">
                    <button class="btn btn-blue btn-sm float-right mt-4" (click)="loadReportMediaLogs()">
                      Create report
                    </button>
                  </div>
                </div>
              </form>
              <div class="report" *ngIf="reportMediaLogs">
                <div class="my-3">
                  <button class="btn btn-sm btn-light-gray" (click)="saveReport('media_logs', 'xls')">
                    <i class="material-icons">get_app</i> Download report
                  </button>
                </div>
                <div class="row">
                  <div class="col-12">
                    <table class="table table-borderless table-hover">
                      <thead>
                        <tr class="text-muted detail-head table__mail-header--subhead font-small">
                          <th class="text-center">Client</th>
                          <th class="text-center">Media</th>
                          <th class="text-center">Interrupted</th>
                          <th class="text-center">Start</th>
                        </tr>
                      </thead>
                      <tbody class="font-small">
                        <tr *ngFor="let report of reportMediaLogs | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                          <td class="text-center">
                            {{report.client}}
                          </td>
                          <td class="text-center body-border__right--gray-normal">
                            {{report.media}}
                          </td>
                          <td class="text-center">
                            {{report.interrupted ? 'Yes' : 'No'}}
                          </td>
                          <td class="text-center body-border__right--gray-normal">
                            {{report.start | date: 'dd.MM.yyy HH:mm'}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <pagination-controls (pageChange)="currentPage = $event"
                                      maxSize="7"
                                      directionLinks="true"
                                      autoHide="true"
                                      previousLabel="Previous"
                                      nextLabel="Next">
                </pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>-->
</div>

  <div *ngIf="messageWindow.show" class="ms-modal-message">
    <div class="modal-content w-50">
      <div class="modal-header"
          [ngClass]="{'bg-info': messageWindow.type == 'info' || messageWindow.type == 'confirm',
                      'bg-warning': messageWindow.type == 'warning',
                      'bg-danger': messageWindow.type == 'error'}">
        <h5 *ngIf="messageWindow.type == 'info'"
            class="modal-title"
            i18n="rep|MessageWindow title - information@@rep.messageWindow.title.information">
          Information
        </h5>
        <h5 *ngIf="messageWindow.type == 'confirm'"
            class="modal-title"
            i18n="rep|MessageWindow title - confirm action@@rep.messageWindow.title.confirmAction">
          Confirm action
        </h5>
        <h5 *ngIf="messageWindow.type == 'warning'"
            class="modal-title"
            i18n="rep|MessageWindow title - warning@@rep.messageWindow.title.warning">
          Warning
        </h5>
        <h5 *ngIf="messageWindow.type == 'error'"
            class="modal-title"
            i18n="rep|MessageWindow title - error@@rep.messageWindow.title.error">
          Error
        </h5>
        <button type="button" class="close" (click)="hideMessageWindow()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{messageWindow.text}}
      </div>
      <div class="modal-footer bg-light-gray border-0">
        <button type="button"
                class="btn btn-sm btn-blue"
                (click)="messageWindow.func.call(this, messageWindow.id)"
                *ngIf="messageWindow.type == 'confirm'"
                i18n="rep|MessageWindow button - ok@@rep.messageWindow.button.ok">
          OK
        </button>
        <button type="button"
                class="btn btn-sm btn-secondary"
                (click)="hideMessageWindow()"
                i18n="rep|MessageWindow button - cancel@@rep.messageWindow.button.cancel">
          Cancel
        </button>
      </div>
    </div>
  </div>   
  
  <div *ngIf="modalEnterEmail" class="ms-modal-message">
    <div class="loading" *ngIf="loading">
      <div class="cssload-spin-box"></div>
    </div>
    <div class="wrapper-answer wrapper-answer-check" *ngIf="messageSended">
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" stroke="green"  stroke-width="3" stroke-dasharray="100">
        <path id="check" d="M 12,22 L 22,31 L 36,13" stroke-dashoffset="0">
          <animate attributeName="stroke-dashoffset" from="100" to="0" repeatCount="indefinite" dur="3s"></animate>
        </path>
      </svg> {{systemMessagesTexts['messageSent']}}
    </div>
    <div class="modal-content w-50">
      <div class="modal-header bg-info">
        <h5 class="modal-title" i18n="rep|MessageWindow title - enter email@@rep.messageWindow.title.enterEmail">Enter emails</h5>
        <button type="button" class="close" (click)="modalEnterEmail = !modalEnterEmail">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p i18n="rep|MessageWindow text - enter email@@rep.messageWindow.text.enterEmail">Enter email addresses separated by commas</p>
        <input type="text" name="searchReport" class="form-control" [(ngModel)]="reportParams.recipients">
      </div>
      <div class="modal-footer bg-light-gray border-0">
        <button type="button"
                class="btn btn-sm btn-blue"
                (click)="sendByEmail()"
                i18n="rep|MessageWindow button - send@@rep.messageWindow.button.send">
          Send
        </button>
        <button type="button"
                class="btn btn-sm btn-secondary"
                (click)="modalEnterEmail = !modalEnterEmail"
                i18n="rep|MessageWindow button - cancel@@rep.messageWindow.button.cancel">
          Cancel
        </button>
      </div>
    </div>
  </div>   

