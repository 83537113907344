<div class="row pt-2 pb-2 bg-white">
  <div class="col-2">
    <div class="list-group">
      <button type="button"
              class="list-group-item list-group-item-action active btn-blue"
              i18n="ref|Left navigation panel - section main referance@@ref.nav.mainReferance">Main reference
      </button>
      <button type="button"
              class="list-group-item list-group-item-action"
              i18n="ref|Left navigation panel - section schedule@@ref.nav.schedule"
              disabled>Schedule
      </button>
      <button type="button"
              class="list-group-item list-group-item-action"
              i18n="ref|Left navigation panel - section slot coordination@@ref.nav.slotCoordination"
              disabled>Slot coordination
      </button>
      <button type="button"
              class="list-group-item list-group-item-action"
              i18n="ref|Left navigation panel - section workflow@@ref.nav.workflow"
              disabled>Workflow
      </button>
      <button type="button"
              class="list-group-item list-group-item-action"
              i18n="ref|Left navigation panel - section communication module@@ref.nav.comModule"
              disabled>Communication module
      </button>
      <button type="button"
              class="list-group-item list-group-item-action"
              i18n="ref|Left navigation panel - section mobile apron@@ref.nav.mobileApron"
              disabled>Mobile apron
      </button>
      <button type="button"
              class="list-group-item list-group-item-action"
              i18n="ref|Left navigation panel - section logs&reports@@ref.nav.logsReports"
              disabled>Logs&Reports
      </button>
    </div>
  </div>
  <div class="col-10 pl-0">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a id="tab-tails" [ngClass]="{'active': showTab('tab-tails')}" class="nav-link"
           (click)="changeTab($event)"
           i18n="ref|Main referance - tab tails@@ref.main.tab.tails">
           Tails
        </a>
      </li>
      <li class="nav-item">
        <a id="tab-aircraft-types" [ngClass]="{'active': showTab('tab-aircraft-types')}" class="nav-link"
           (click)="changeTab($event)"
           i18n="ref|Main referance - tab aircrafts@@ref.main.tab.aircrafts">
           A/C Types
        </a>
      </li>
      <li class="nav-item">
        <a id="tab-aircraft-kinds" [ngClass]="{'active': showTab('tab-aircraft-kinds')}" class="nav-link"
           (click)="changeTab($event)"
           i18n="ref|Main referance - tab aircraft-kinds@@ref.main.tab.aircraftKinds">
          A/C Kinds
        </a>
      </li>
      <li class="nav-item">
        <a id="tab-aircraft-classes" [ngClass]="{'active': showTab('tab-aircraft-classes')}" class="nav-link"
           (click)="changeTab($event)"
           i18n="ref|Main referance - tab aircraft-classes@@ref.main.tab.aircraftClasses">
          A/C Classes
        </a>
      </li>
      <li class="nav-item">
        <a id="tab-airlines" [ngClass]="{'active': showTab('tab-airlines')}" class="nav-link"
           (click)="changeTab($event)"
           i18n="ref|Main referance - tab airlines@@ref.main.tab.airlines">
          Airlines
        </a>
      </li>
      <li class="nav-item">
        <a id="tab-airports" [ngClass]="{'active': showTab('tab-airports')}" class="nav-link active"
           (click)="changeTab($event)"
           i18n="ref|Main referance - tab airports@@ref.main.tab.airports">
          Airports
        </a>
      </li>
      <li class="nav-item">
        <a id="tab-cities" [ngClass]="{'active': showTab('tab-cities')}" class="nav-link"
           (click)="changeTab($event)"
           i18n="ref|Main referance - tab cities@@ref.main.tab.cities">
           Cities
        </a>
      </li>
      <li class="nav-item">
        <a id="tab-countries" [ngClass]="{'active': showTab('tab-countries')}" class="nav-link"
           (click)="changeTab($event)"
           i18n="ref|Main referance - tab countries@@ref.main.tab.countries">
           Countries
        </a>
      </li>
      <li class="nav-item">
        <a id="tab-regions" [ngClass]="{'active': showTab('tab-regions')}" class="nav-link"
           (click)="changeTab($event)"
           i18n="ref|Main referance - tab regions@@ref.main.tab.regions">
           Regions
        </a>
      </li>
      <li class="nav-item">
        <a id="tab-delays" [ngClass]="{'active': showTab('tab-delays')}" class="nav-link"
           (click)="changeTab($event)"
           i18n="ref|Main referance - tab delays@@ref.main.tab.delays">
          Delays
        </a>
      </li>
      <li class="nav-item">
        <a id="tab-delay-sources" [ngClass]="{'active': showTab('tab-delay-sources')}" class="nav-link"
           (click)="changeTab($event)"
           i18n="ref|Main referance - tab delays@@ref.main.tab.delaySources">
          Delay sources
        </a>
      </li>
      <li class="nav-item">
        <a id="tab-movements" [ngClass]="{'active': showTab('tab-movements')}" class="nav-link"
           (click)="changeTab($event)"
           i18n="ref|Main referance - tab movements@@ref.main.tab.movements">
          Movements
        </a>
      </li>
      <li class="nav-item">
        <a id="tab-regularities" [ngClass]="{'active': showTab('tab-regularities')}" class="nav-link"
           (click)="changeTab($event)"
           i18n="ref|Main referance - tab regularities@@ref.main.tab.regularities">
           Regularities
        </a>
      </li>
      <li class="nav-item">
        <a id="tab-seasons" [ngClass]="{'active': showTab('tab-seasons')}" class="nav-link"
           (click)="changeTab($event)"
           i18n="ref|Main referance - tab seasons@@ref.main.tab.seasons">
          Seasons
        </a>
      </li>
      <li class="nav-item">
        <a id="tab-stands" [ngClass]="{'active': showTab('tab-stands')}" class="nav-link"
           (click)="changeTab($event)"
           i18n="ref|Main referance - tab stends@@ref.main.tab.stands">
          Stands
        </a>
      </li>
      <li class="nav-item">
        <a id="tab-stand-types" [ngClass]="{'active': showTab('tab-stand-types')}" class="nav-link"
           (click)="changeTab($event)"
           i18n="ref|Main referance - tab stands type@@ref.main.tab.standsType">
          Stand Types
        </a>
      </li>
      <li class="nav-item">
        <a id="tab-terminals" [ngClass]="{'active': showTab('tab-terminals')}" class="nav-link"
           (click)="changeTab($event)"
           i18n="ref|Main referance - tab terminals@@ref.main.tab.terminals">
          Terminals
        </a>
      </li>
    </ul>

    <!-- Tab airports -->
    <div *ngIf="showTab('tab-airports')" class="bg-white p-3">
      <div class="row my-2">
        <div class="col-6">
          <div class="btn-group">
            <button type="button"
                    class="btn btn-sm btn-light-gray mr-1"
                    data-toggle="modal"
                    data-target=".bd-example-modal-xl"
                    (click)="createAirport()">
              <i class="material-icons">add</i>
              <span i18n="ref|Tab airports - button new@@ref.main.tab.airports.buttonNew">New</span>
            </button>
          </div>
          <div class="btn-group">
            <div class="dropdown">
              <button class="btn btn-sm btn-light-gray mr-1 dropdown-toggle"
                      type="button"
                      id="dropdownMenuExportAirports"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                <i class="material-icons">arrow_downward</i>
                <span i18n="ref|Tab airports - button export@@ref.main.tab.airports.buttonExport">Export</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuExportAirports">
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('airports', 'csv')">CSV
                </button>
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('airports', 'xml')">XML
                </button>
              </div>
            </div>
          </div>
          <div class="btn-group">
            <button type="button"
                    class="btn btn-sm btn-light-gray"
                    data-toggle="modal"
                    data-target=".modal-import"
                    (click)="getFieldsFromDB('airports')">
              <i class="material-icons">arrow_upward</i>
              <span i18n="ref|Tab airports - button import@@ref.main.tab.airports.buttonImport">Import</span>
            </button>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group referance-filter">
            <div class="input-group-prepend">
              <span class="input-group-text font-small text-center"
                    i18n="ref|Tab airports - field search@@ref.main.tab.airports.fieldSearch">Search
              </span>
            </div>
            <div class="position-relative">
              <input type="text" aria-label="IATA" class="form-control rounded-0 border-right-0" placeholder="IATA" [(ngModel)]="filterIata">
              <span class="clear-filter" (click)="filterIata=''">&times;</span>
            </div>
            <div class="position-relative">
              <input type="text" aria-label="ICAO" class="form-control rounded-0 border-right-0" placeholder="ICAO" [(ngModel)]="filterIcao">
              <span class="clear-filter" (click)="filterIcao=''">&times;</span>
            </div>
            <div class="position-relative">
              <input type="text" aria-label="Name"
                   class="form-control rounded-0"
                   placeholder="NAME"
                   [(ngModel)]="filterName"
                   i18n-placeholder="ref|Tab airports - field search name@@ref.main.tab.airports.fieldSearchName">
              <span class="clear-filter" (click)="filterName=''">&times;</span>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-hover w-100">
        <thead class="">
        <tr #trAirports>
          <th class="text-center text-uppercase column-iata-size"
              field="iata"
              data-method
              (click)="sortBy($event.target, trAirports)">IATA
          </th>
          <th class="text-center text-uppercase column-icao-size"
              field="icao"
              data-method
              (click)="sortBy($event.target, trAirports)">ICAO
          </th>
          <th class="text-uppercase"
              field="names"
              data-method
              (click)="sortBy($event.target, trAirports)"
              i18n="ref|Tab airports - table data column name@@ref.main.tab.airports.tableColumnName">Name
          </th>
          <th class="text-uppercase column-lastupdate-size"
              field="lastupdate"
              data-method
              (click)="sortBy($event.target, trAirports)"
              i18n="ref|Tab airports - table data column lastupdate@@ref.main.tab.airports.tableColumnLastupdate">
            Last update
          </th>
        </tr>
        </thead>
        <tbody>
          <tr *ngIf="loading">
            <td colspan="4" class="p-5">
              <div class="loading">
                <div class="cssload-spin-box"></div>
              </div>
            </td>
          </tr>
        <tr *ngFor="let airport of airports | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
            class=""
            data-toggle="modal" data-target=".bd-example-modal-xl"
            (click)="loadAirport(airport.id)">
          <td class="text-center">{{airport.iata}}</td>
          <td class="text-center">{{airport.icao}}</td>
          <td>{{airport.names}}</td>
          <td>{{airport.lastupdate | date: 'dd.MM.yyyy HH:mm'}}</td>
        </tr>
        </tbody>
      </table>

      <pagination-controls
        (pageChange)="currentPage = $event"
        maxSize="7"
        directionLinks="true"
        autoHide="true"
        previousLabel="Previous"
        nextLabel="Next"
        i18n-previousLabel="ref|Tab airports - pagination previous label@@ref.main.tab.airports.pagPrevious"
        i18n-nextLabel="ref|Tab airports - pagination next label@@ref.main.tab.airports.pagNext">
      </pagination-controls>
    </div>

    <!-- Tab airlines -->
    <div *ngIf="showTab('tab-airlines')" class="bg-white p-3">
      <div class="row my-2">
        <div class="col-6">
          <button type="button"
                  class="btn btn-sm btn-light-gray mr-1"
                  data-toggle="modal"
                  data-target=".bd-example-modal-xl"
                  (click)="createAirline()">
            <i class="material-icons">add</i>
            <span i18n="ref|Tab airlines - button new@@ref.main.tab.airlines.buttonNew">New</span>
          </button>
          <div class="btn-group">
            <div class="dropdown">
              <button class="btn btn-sm btn-light-gray mr-1 dropdown-toggle"
                      type="button"
                      id="dropdownMenuExportAirlines"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                <i class="material-icons">arrow_downward</i>
                <span i18n="ref|Tab airlines - button export@@ref.main.tab.airlines.buttonExport">Export</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuExportAirports">
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('airlines', 'csv')">CSV
                </button>
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('airlines', 'xml')">XML
                </button>
              </div>
            </div>
          </div>
          <button type="button"
                  class="btn btn-sm btn-light-gray"
                  data-toggle="modal"
                  data-target=".modal-import"
                  (click)="getFieldsFromDB('airlines')">
            <i class="material-icons">arrow_upward</i>
            <span i18n="ref|Tab airlines - button import@@ref.main.tab.airlines.buttonImport">Import</span>
          </button>
        </div>
        <div class="col-6">
          <div class="input-group referance-filter">
            <div class="input-group-prepend">
              <span class="input-group-text font-small text-center"
                    i18n="ref|Tab airlines - field search@@ref.main.tab.airlines.fieldSearch">Search </span>
            </div>
            <div class="position-relative">
              <input type="text" aria-label="IATA" class="form-control rounded-0 border-right-0" placeholder="IATA" [(ngModel)]="filterIata">
              <span class="clear-filter" (click)="filterIata=''">&times;</span>
            </div>
            <div class="position-relative">
              <input type="text" aria-label="ICAO" class="form-control rounded-0 border-right-0" placeholder="ICAO" [(ngModel)]="filterIcao">
              <span class="clear-filter" (click)="filterIcao=''">&times;</span>
            </div>
            <div class="position-relative">
              <input type="text"
                    aria-label="Name"
                    class="form-control rounded-0"
                    placeholder="NAME"
                    [(ngModel)]="filterName"
                    i18n-placeholder="ref|Tab airlines - field search name@@ref.main.tab.airlines.fieldSearchName">
              <span class="clear-filter" (click)="filterName=''">&times;</span>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-hover w-100">
        <thead class="">
        <tr #trAirlines>
          <th class="text-center text-uppercase column-iata-size"
              field="iata"
              data-method
              (click)="sortBy($event.target, trAirlines)">IATA
          </th>
          <th class="text-center text-uppercase column-icao-size"
              field="icao"
              data-method
              (click)="sortBy($event.target, trAirlines)">ICAO
          </th>
          <th class="text-uppercase"
              field="names"
              data-method
              (click)="sortBy($event.target, trAirlines)"
              i18n="ref|Tab airlines - table data column name@@ref.main.tab.airlines.tableColumnName">Name
          </th>
          <th class="text-uppercase column-lastupdate-size"
              field="lastupdate"
              data-method
              (click)="sortBy($event.target, trAirlines)"
              i18n="ref|Tab airlines - table data column lastupdate@@ref.main.tab.airlines.tableColumnLastupdate">
            Last update
          </th>
        </tr>
        </thead>
        <tbody>
          <tr *ngIf="loading">
            <td colspan="4" class="p-5">
              <div class="loading">
                <div class="cssload-spin-box"></div>
              </div>
            </td>
          </tr>
          <tr *ngFor="let airline of airlines | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
              data-toggle="modal" data-target=".bd-example-modal-xl"
              (click)="loadAirline(airline.id)">
            <td class="text-center">{{airline.iata}}</td>
            <td class="text-center">{{airline.icao}}</td>
            <td>{{airline.names}}</td>
            <td>{{airline.lastupdate | date: 'dd.MM.yyyy HH:mm'}}</td>
          </tr>
        </tbody>
      </table>
      <pagination-controls
        (pageChange)="currentPage = $event"
        maxSize="7"
        directionLinks="true"
        autoHide="true"
        previousLabel="Previous"
        nextLabel="Next"
        i18n-previousLabel="ref|Tab airlines - pagination previous label@@ref.main.tab.airlines.pagPrevious"
        i18n-nextLabel="ref|Tab airlines - pagination next label@@ref.main.tab.airlines.pagNext">
      </pagination-controls>
    </div>

    <!-- Tab aircrafts -->
    <div *ngIf="showTab('tab-aircraft-types')" class="bg-white p-3">
      <div class="row my-2">
        <div class="col-6">
          <button type="button"
                  class="btn btn-sm btn-light-gray mr-1"
                  data-toggle="modal"
                  data-target=".bd-example-modal-xl"
                  (click)="createAircraftType()">
            <i class="material-icons">add</i>
            <span i18n="ref|Tab aircrafts - button new@@ref.main.tab.aircrafts.buttonNew">New</span>
          </button>
          <div class="btn-group">
            <div class="dropdown">
              <button class="btn btn-sm btn-light-gray mr-1 dropdown-toggle"
                      type="button"
                      id="dropdownMenuExportAircraft"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                <i class="material-icons">arrow_downward</i>
                <span i18n="ref|Tab aircrafts - button export@@ref.main.tab.aircrafts.buttonExport">Export</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuExportAirports">
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('aircraft_types', 'csv')">CSV
                </button>
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('aircraft_types', 'xml')">XML
                </button>
              </div>
            </div>
          </div>
          <button type="button"
                  class="btn btn-sm btn-light-gray"
                  data-toggle="modal"
                  data-target=".modal-import"
                  (click)="getFieldsFromDB('aircraft_types')">
            <i class="material-icons">arrow_upward</i>
            <span i18n="ref|Tab aircrafts - button import@@ref.main.tab.aircrafts.buttonImport">Import</span>
          </button>
        </div>
        <div class=" col-6">
          <div class="input-group referance-filter">
            <div class="input-group-prepend">
              <span class="input-group-text font-small text-center"
                    i18n="ref|Tab aircrafts - field search@@ref.main.tab.aircrafts.fieldSearch">Search</span>
            </div>
            <div class="position-relative">
              <input type="text" aria-label="IATA" class="form-control rounded-0 border-right-0" placeholder="IATA" [(ngModel)]="filterIata">
              <span class="clear-filter" (click)="filterIata=''">&times;</span>
            </div>
            <div class="position-relative">
              <input type="text" aria-label="ICAO" class="form-control rounded-0 border-right-0" placeholder="ICAO" [(ngModel)]="filterIcao">
              <span class="clear-filter" (click)="filterIcao=''">&times;</span>
            </div>
            <div class="position-relative">
              <input type="text"
                    aria-label="Name"
                    class="form-control rounded-0"
                    placeholder="NAME"
                    [(ngModel)]="filterName"
                    i18n-placeholder="ref|Tab aircrafts - field search name@@ref.main.tab.aircrafts.fieldSearchName">
              <span class="clear-filter" (click)="filterName=''">&times;</span>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-hover w-100">
        <thead class="t">
        <tr #trAircrafts>
          <th class="text-center text-uppercase column-iata-size"
              field="iata"
              data-method
              (click)="sortBy($event.target, trAircrafts)">IATA
          </th>
          <th class="text-center text-uppercase column-icao-size"
              field="icao"
              data-method
              (click)="sortBy($event.target, trAircrafts)">ICAO
          </th>
          <th class="text-uppercase"
              field="names"
              data-method
              (click)="sortBy($event.target, trAircrafts)"
              i18n="ref|Tab aircrafts - table data column lastupdate@@ref.main.tab.aircrafts.tableColumnName">
            Name
          </th>
          <th class="text-uppercase column-lastupdate-size"
              field="lastupdate"
              data-method
              (click)="sortBy($event.target, trAircrafts)"
              i18n="ref|Tab aircrafts - table data column lastupdate@@ref.main.tab.aircrafts.tableColumnLastupdate">
            Last update
          </th>
        </tr>
        </thead>
        <tbody>
          <tr *ngIf="loading">
            <td colspan="4" class="p-5">
              <div class="loading">
                <div class="cssload-spin-box"></div>
              </div>
            </td>
          </tr>
          <tr *ngFor="let aircraftType of aircraftTypes | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
              data-toggle="modal" data-target=".bd-example-modal-xl"
              (click)="loadAircraftType(aircraftType.id)">
            <td class="text-center">{{aircraftType.iata}}</td>
            <td class="text-center">{{aircraftType.icao}}</td>
            <td>{{aircraftType.names}}</td>
            <td>{{aircraftType.lastupdate | date: 'dd.MM.yyyy HH:mm'}}</td>
          </tr>
        </tbody>
      </table>
      <pagination-controls
        (pageChange)="currentPage = $event"
        maxSize="7"
        directionLinks="true"
        autoHide="true"
        previousLabel="Previous"
        nextLabel="Next"
        i18n-previousLabel="ref|Tab aircraft - pagination previous label@@ref.main.tab.aircraft.pagPrevious"
        i18n-nextLabel="ref|Tab aircraft - pagination next label@@ref.main.tab.aircraft.pagNext">
      </pagination-controls>
    </div>

    <!-- Tab Movements -->
    <div *ngIf="showTab('tab-movements')" class="bg-white p-3">
      <div class="row my-2">
        <div class="col-6">
          <button type="button"
                  class="btn btn-sm btn-light-gray mr-1"
                  data-toggle="modal"
                  data-target=".bd-example-modal-xl"
                  (click)="createMovement()">
            <i class="material-icons">add</i>
            <span i18n="ref|Tab movements - button new@@ref.main.tab.movements.buttonNew">New</span>
          </button>
          <div class="btn-group">
            <div class="dropdown">
              <button class="btn btn-sm btn-light-gray mr-1 dropdown-toggle"
                      type="button"
                      id="dropdownMenuExportMovements"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                <i class="material-icons">arrow_downward</i>
                <span i18n="ref|Tab movements - button export@@ref.main.tab.movements.buttonExport">Export</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuExportAirports">
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('movement_types', 'csv')">CSV
                </button>
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('movement_types', 'xml')">XML
                </button>
              </div>
            </div>
          </div>
          <button type="button"
                  class="btn btn-sm btn-light-gray"
                  data-toggle="modal"
                  data-target=".modal-import"
                  (click)="getFieldsFromDB('movement_types')">
            <i class="material-icons">arrow_upward</i>
            <span i18n="ref|Tab movements - button import@@ref.main.tab.movements.buttonImport">Import</span>
          </button>
        </div>
        <div class="col-6">
          <div class="input-group referance-filter">
            <div class="input-group-prepend">
              <span class="input-group-text font-small text-center">Search </span>
            </div>
            <div class="position-relative">
              <input type="text" aria-label="IATA" class="form-control rounded-0 border-right-0" placeholder="IATA" [(ngModel)]="filterIata">
              <span class="clear-filter" (click)="filterIata=''">&times;</span>
            </div>
            <div class="position-relative">
              <input type="text"
                    aria-label="Name"
                    class="form-control rounded-0"
                    placeholder="NAME"
                    [(ngModel)]="filterName"
                    i18n-placeholder="ref|Tab movements - field search name@@ref.main.tab.movements.fieldSearchName">
              <span class="clear-filter" (click)="filterName=''">&times;</span>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-hover w-100">
        <thead class="">
        <tr #trMovements>
          <th class="text-center text-uppercase column-iata-size"
              field="code"
              data-method
              (click)="sortBy($event.target, trMovements)">IATA
          </th>
          <th class="text-uppercase"
              field="names"
              data-method
              (click)="sortBy($event.target, trMovements)"
              i18n="ref|Tab movements - table data column name@@ref.main.tab.movements.tableColumnName">
            Name
          </th>
          <th class="text-uppercase"
              field="regularityName"
              data-method
              (click)="sortBy($event.target, trMovements)"
              i18n="ref|Tab movements - table data column name@@ref.main.tab.movements.tableColumnRegularity">
            Regularity
          </th>
          <th class="text-uppercase column-lastupdate-size"
              field="lastupdate"
              data-method
              (click)="sortBy($event.target, trMovements)"
              i18n="ref|Tab movements - table data column last update@@ref.main.tab.movements.tableColumnLastupdate">
            Last update
          </th>
        </tr>
        </thead>
        <tbody>
          <tr *ngIf="loading">
            <td colspan="4" class="p-5">
              <div class="loading">
                <div class="cssload-spin-box"></div>
              </div>
            </td>
          </tr>
          <tr *ngFor="let movement of movements | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
              data-toggle="modal" data-target=".bd-example-modal-xl"
              (click)="loadMovement(movement.id)">
            <td class="text-center">{{movement.code}}</td>
            <td>{{movement.names}}</td>
            <td>{{movement.regularityName}}</td>
            <td>{{movement.lastupdate | date: 'dd.MM.yyyy HH:mm'}}</td>
          </tr>
        </tbody>
      </table>
      <pagination-controls
        (pageChange)="currentPage = $event"
        maxSize="7"
        directionLinks="true"
        autoHide="true"
        previousLabel="Previous"
        nextLabel="Next"
        i18n-previousLabel="ref|Tab movements - pagination previous label@@ref.main.tab.movements.pagPrevious"
        i18n-nextLabel="ref|Tab movements - pagination next label@@ref.main.tab.movements.pagNext">
      </pagination-controls>
    </div>

    <!-- Tab Delays -->
    <div *ngIf="showTab('tab-delays')" class="bg-white p-3">
      <div class="row my-2">
        <div class="col-6">
          <button type="button"
                  class="btn btn-sm btn-light-gray mr-1"
                  data-toggle="modal"
                  data-target=".bd-example-modal-xl"
                  (click)="createDelay()">
            <i class="material-icons">add</i>
            <span i18n="ref|Tab delays - button new@@ref.main.tab.delays.buttonNew">New</span>
          </button>
          <div class="btn-group">
            <div class="dropdown">
              <button class="btn btn-sm btn-light-gray mr-1 dropdown-toggle"
                      type="button"
                      id="dropdownMenuExportDelays"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                <i class="material-icons">arrow_downward</i>
                <span i18n="ref|Tab delays - button export@@ref.main.tab.delays.buttonExport">Export</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuExportAirports">
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('delays', 'csv')">CSV
                </button>
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('delays', 'xml')">XML
                </button>
              </div>
            </div>
          </div>
          <button type="button"
                  class="btn btn-sm btn-light-gray"
                  data-toggle="modal"
                  data-target=".modal-import"
                  (click)="getFieldsFromDB('delays')">
            <i class="material-icons">arrow_upward</i>
            <span i18n="ref|Tab delays - button import@@ref.main.tab.delays.buttonImport">Import</span>
          </button>
        </div>
        <div class="col-6">
          <div class="input-group referance-filter">
            <div class="input-group-prepend">
              <span class="input-group-text font-small text-center">Search </span>
            </div>
            <div class="position-relative">
              <input type="text"
                    aria-label="Name"
                    class="form-control rounded-0"
                    placeholder="NAME"
                    [(ngModel)]="filterName"
                    i18n-placeholder="ref|Tab delays - field search name@@ref.main.tab.delays.fieldSearchName">
              <span class="clear-filter" (click)="filterName=''">&times;</span>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-hover w-100">
        <thead class="">
        <tr #trDelays>
          <th class="text-center text-uppercase column-iata-size"
              field="iataChar"
              data-method
              (click)="sortBy($event.target, trDelays)">CHAR
          </th>
          <th class="text-uppercase"
              field="names"
              data-method
              (click)="sortBy($event.target, trDelays)"
              i18n="ref|Tab delays - table data column name@@ref.main.tab.delays.tableColumnName">Name
          </th>
          <th class="text-uppercase column-lastupdate-size"
              field="lastupdate"
              data-method
              (click)="sortBy($event.target, trDelays)"
              i18n="ref|Tab delays - table data column lastupdate@@ref.main.tab.delays.tableColumnLastupdate">
            Last update
          </th>
        </tr>
        </thead>
        <tbody>
          <tr *ngIf="loading">
            <td colspan="3" class="p-5">
              <div class="loading">
                <div class="cssload-spin-box"></div>
              </div>
            </td>
          </tr>
          <tr *ngFor="let delay of delays | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
              data-toggle="modal" data-target=".bd-example-modal-xl"
              (click)="loadDelay(delay.id)">
            <td class="text-center">{{delay.iataChar}}</td>
            <td>{{delay.names}}</td>
            <td>{{delay.lastupdate | date: 'dd.MM.yyyy HH:mm'}}</td>
          </tr>
        </tbody>
      </table>
      <pagination-controls
        (pageChange)="currentPage = $event"
        maxSize="7"
        directionLinks="true"
        autoHide="true"
        previousLabel="Previous"
        nextLabel="Next"
        i18n-previousLabel="ref|Tab airports - pagination previous label@@ref.main.tab.delays.pagPrevious"
        i18n-nextLabel="ref|Tab airports - pagination next label@@ref.main.tab.delays.pagNext">
      </pagination-controls>
    </div>

    <!-- Tab Delay Sources -->
    <div *ngIf="showTab('tab-delay-sources')" class="bg-white p-3">
      <div class="row my-2">
        <div class="col-6">
          <button type="button"
                  class="btn btn-sm btn-light-gray mr-1"
                  data-toggle="modal"
                  data-target=".bd-example-modal-xl"
                  (click)="createDelaySource()">
            <i class="material-icons">add</i>
            <span i18n="ref|Tab delay-sources - button new@@ref.main.tab.delaySources.buttonNew">New</span>
          </button>
          <div class="btn-group">
            <div class="dropdown">
              <button class="btn btn-sm btn-light-gray mr-1 dropdown-toggle"
                      type="button"
                      id="dropdownMenuExportDelaysSource"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                <i class="material-icons">arrow_downward</i>
                <span i18n="ref|Tab delay-sources - button export@@ref.main.tab.delaySources.buttonExport">Export</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuExportAirports">
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('delay_sources', 'csv')">CSV
                </button>
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('delay_sources', 'xml')">XML
                </button>
              </div>
            </div>
          </div>
          <button type="button"
                  class="btn btn-sm btn-light-gray"
                  data-toggle="modal"
                  data-target=".modal-import"
                  (click)="getFieldsFromDB('delay_sources')">
            <i class="material-icons">arrow_upward</i>
            <span i18n="ref|Tab delay-sources - button import@@ref.main.tab.delay-sources.buttonImport">Import</span>
          </button>
        </div>
        <div class="col-6">
          <div class="input-group referance-filter">
            <div class="input-group-prepend">
              <span class="input-group-text font-small text-center">Search </span>
            </div>
            <div class="position-relative">
              <input type="text"
                    aria-label="Name"
                    class="form-control rounded-0"
                    placeholder="NAME"
                    [(ngModel)]="filterName"
                    i18n-placeholder="ref|Tab delay-sources - field search name@@ref.main.tab.delay-sources.fieldSearchName">
              <span class="clear-filter" (click)="filterName=''">&times;</span>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-hover w-100">
        <thead class="">
        <tr #trDelaySources>
          <th class="text-uppercase"
              field="names"
              data-method
              (click)="sortBy($event.target, trDelaySources)"
              i18n="ref|Tab delay-sources - table data column name@@ref.main.tab.delaySources.tableColumnName">Name
          </th>
          <th class="text-uppercase column-lastupdate-size"
              field="lastupdate"
              data-method
              (click)="sortBy($event.target, trDelaySources)"
              i18n="ref|Tab delay-sources - table data column lastupdate@@ref.main.tab.delaySources.tableColumnLastupdate">
            Last update
          </th>
        </tr>
        </thead>
        <tbody>
          <tr *ngIf="loading">
            <td colspan="2" class="p-5">
              <div class="loading">
                <div class="cssload-spin-box"></div>
              </div>
            </td>
          </tr>
          <tr *ngFor="let delay of delaySources | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
              data-toggle="modal" data-target=".bd-example-modal-xl"
              (click)="loadDelaySource(delay.id)">
            <td>{{delay.names}}</td>
            <td>{{delay.lastupdate | date: 'dd.MM.yyyy HH:mm'}}</td>
          </tr>
        </tbody>
      </table>
      <pagination-controls
        (pageChange)="currentPage = $event"
        maxSize="7"
        directionLinks="true"
        autoHide="true"
        previousLabel="Previous"
        nextLabel="Next"
        i18n-previousLabel="ref|Tab delay-sources - pagination previous label@@ref.main.tab.delaySources.pagPrevious"
        i18n-nextLabel="ref|Tab delay-sources - pagination next label@@ref.main.tab.delaySources.pagNext">
      </pagination-controls>
    </div>

    <!-- Tab Seasons -->
    <div *ngIf="showTab('tab-seasons')" class="bg-white p-3">
      <div class="row my-2">
        <div class="col-6">
          <button type="button"
                  class="btn btn-sm btn-light-gray mr-1"
                  data-toggle="modal"
                  data-target=".bd-example-modal-xl"
                  (click)="createSeason()">
            <i class="material-icons">add</i>
            <span i18n="ref|Tab seasons - button new@@ref.main.tab.seasons.buttonNew">New</span>
          </button>
          <div class="btn-group">
            <div class="dropdown">
              <button class="btn btn-sm btn-light-gray mr-1 dropdown-toggle"
                      type="button"
                      id="dropdownMenuExportSeasons"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                <i class="material-icons">arrow_downward</i>
                <span i18n="ref|Tab seasons - button export@@ref.main.tab.seasons.buttonExport">Export</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuExportAirports">
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('seasons', 'csv')">CSV
                </button>
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('seasons', 'xml')">XML
                </button>
              </div>
            </div>
          </div>
          <button type="button"
                  class="btn btn-sm btn-light-gray"
                  data-toggle="modal"
                  data-target=".modal-import"
                  (click)="getFieldsFromDB('seasons')">
            <i class="material-icons">arrow_upward</i>
            <span i18n="ref|Tab seasons - button import@@ref.main.tab.seasons.buttonImport">Import</span>
          </button>
        </div>
        <div class="col-6">
          <div class="input-group referance-filter">
            <div class="input-group-prepend">
              <span class="input-group-text font-small text-center"
                    i18n="ref|Tab seasons - field search@@ref.main.tab.seasons.fieldSearch">Search</span>
            </div>
            <div class="position-relative">
              <input type="text"
                    aria-label="TYPE"
                    class="form-control rounded-0"
                    placeholder="TYPE"
                    [(ngModel)]="filterType"
                    i18n-placeholder="ref|Tab seasons - field search name@@ref.main.tab.seasons.fieldSearchType">
              <span class="clear-filter" (click)="filterType=''">&times;</span>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-hover w-100">
        <thead class="">
        <tr #trSeasons>
          <th class="text-center text-uppercase"
              field="seasonName"
              data-method
              (click)="sortBy($event.target, trSeasons)"
              i18n="ref|Tab seasons - table data column type@@ref.main.tab.seasons.tableColumnType">TYPE
          </th>
          <th class="text-uppercase"
              field="dtSeasonStart"
              data-method
              (click)="sortBy($event.target, trSeasons)"
              i18n="ref|Tab seasons - table data column start@@ref.main.tab.seasons.tableColumnStart">START
          </th>
          <th class="text-uppercase"
              field="dtSeasonFinish"
              data-method
              (click)="sortBy($event.target, trSeasons)"
              i18n="ref|Tab seasons - table data column finish@@ref.main.tab.seasons.tableColumnFinish">FINISH
          </th>
          <th class="text-uppercase column-lastupdate-size"
              field="lastupdate"
              data-method
              (click)="sortBy($event.target, trSeasons)"
              i18n="ref|Tab seasons - table data column lastupdate@@ref.main.tab.seasons.tableColumnLastupdate">
            Last update
          </th>
        </tr>
        </thead>
        <tbody>
          <tr *ngIf="loading">
            <td colspan="4" class="p-5">
              <div class="loading">
                <div class="cssload-spin-box"></div>
              </div>
            </td>
          </tr>
          <tr *ngFor="let season of seasons | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
              data-toggle="modal" data-target=".bd-example-modal-xl"
              (click)="loadSeason(season.id)">
            <td class="text-center">{{season.seasonName}}</td>
            <td>{{season.dtSeasonStart | date: 'dd.MM.yyyy HH:mm' : 'UTC+0'}}</td>
            <td>{{season.dtSeasonFinish | date: 'dd.MM.yyyy HH:mm' : 'UTC+0'}}</td>
            <td class="column-lastupdate-size">{{season.lastupdate | date: 'dd.MM.yyyy HH:mm'}}</td>
          </tr>
        </tbody>
      </table>
      <pagination-controls
        (pageChange)="currentPage = $event"
        maxSize="7"
        directionLinks="true"
        autoHide="true"
        previousLabel="Previous"
        nextLabel="Next"
        i18n-previousLabel="ref|Tab seasons - pagination previous label@@ref.main.tab.seasons.pagPrevious"
        i18n-nextLabel="ref|Tab seasons - pagination next label@@ref.main.tab.seasons.pagNext">
      </pagination-controls>
    </div>

    <!-- Tab regions -->
    <div *ngIf="showTab('tab-regions')" class="bg-white p-3">
      <div class="row my-2">
        <div class="col-6">
          <div class="btn-group">
            <button type="button"
                    class="btn btn-sm btn-light-gray mr-1"
                    data-toggle="modal"
                    data-target=".bd-example-modal-xl"
                    (click)="createRegion()">
              <i class="material-icons">add</i>
              <span i18n="ref|Tab regions - button new@@ref.main.tab.regions.buttonNew">New</span>
            </button>
          </div>
          <div class="btn-group">
            <div class="dropdown">
              <button class="btn btn-sm btn-light-gray mr-1 dropdown-toggle"
                      type="button"
                      id="dropdownMenuExportRegions"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                <i class="material-icons">arrow_downward</i>
                <span i18n="ref|Tab regions - button export@@ref.main.tab.regions.buttonExport">Export</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuExportRegions">
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('regions', 'csv')">CSV
                </button>
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('regions', 'xml')">XML
                </button>
              </div>
            </div>
          </div>
          <div class="btn-group">
            <button type="button"
                    class="btn btn-sm btn-light-gray"
                    data-toggle="modal"
                    data-target=".modal-import"
                    (click)="getFieldsFromDB('regions')">
              <i class="material-icons">arrow_upward</i>
              <span i18n="ref|Tab regions - button import@@ref.main.tab.regions.buttonImport">Import</span>
            </button>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group referance-filter">
            <div class="input-group-prepend">
              <span class="input-group-text font-small text-center"
                    i18n="ref|Tab regions - field search@@ref.main.tab.regions.fieldSearch">Search
              </span>
            </div>
            <div class="position-relative">
              <input type="text" aria-label="IATA" class="form-control rounded-0 border-right-0" placeholder="IATA" [(ngModel)]="filterIata">
              <span class="clear-filter" (click)="filterIata=''">&times;</span>
            </div>
            <div class="position-relative">
              <input type="text" aria-label="ICAO" class="form-control rounded-0 border-right-0" placeholder="ICAO" [(ngModel)]="filterIcao">
              <span class="clear-filter" (click)="filterIcao=''">&times;</span>
            </div>
            <div class="position-relative">
              <input type="text"
                    aria-label="Name"
                    class="form-control rounded-0"
                    placeholder="NAME"
                    [(ngModel)]="filterName"
                    i18n-placeholder="ref|Tab regions - field search name@@ref.main.tab.regions.fieldSearchName">
              <span class="clear-filter" (click)="filterName=''">&times;</span>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-hover w-100">
        <thead class="">
        <tr #trRegions>
          <th class="text-center text-uppercase column-iata-size"
              field="iata"
              data-method
              (click)="sortBy($event.target, trRegions)">IATA
          </th>
          <th class="text-center text-uppercase column-icao-size"
              field="icao"
              data-method
              (click)="sortBy($event.target, trRegions)">ICAO
          </th>
          <th class="text-uppercase"
              field="names"
              data-method
              (click)="sortBy($event.target, trRegions)"
              i18n="ref|Tab regions - table data column name@@ref.main.tab.regions.tableColumnName">Name
          </th>
          <th class="text-uppercase column-lastupdate-size"
              field="lastupdate"
              data-method
              (click)="sortBy($event.target, trRegions)"
              i18n="ref|Tab regions - table data column lastupdate@@ref.main.tab.regions.tableColumnLastupdate">
            Last update
          </th>
        </tr>
        </thead>
        <tbody>
          <tr *ngIf="loading">
            <td colspan="4" class="p-5">
              <div class="loading">
                <div class="cssload-spin-box"></div>
              </div>
            </td>
          </tr>
          <tr *ngFor="let region of regions | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
              class=""
              data-toggle="modal" data-target=".bd-example-modal-xl"
              (click)="loadRegion(region.id)">
            <td class="text-center">{{region.iata}}</td>
            <td class="text-center">{{region.icao}}</td>
            <td>{{region.names}}</td>
            <td>{{region.lastupdate | date: 'dd.MM.yyyy HH:mm'}}</td>
          </tr>
        </tbody>
      </table>

      <pagination-controls
        (pageChange)="currentPage = $event"
        maxSize="7"
        directionLinks="true"
        autoHide="true"
        previousLabel="Previous"
        nextLabel="Next"
        i18n-previousLabel="ref|Tab regions - pagination previous label@@ref.main.tab.regions.pagPrevious"
        i18n-nextLabel="ref|Tab regions - pagination next label@@ref.main.tab.regions.pagNext">
      </pagination-controls>
    </div>

    <!-- Tab countries -->
    <div *ngIf="showTab('tab-countries')" class="bg-white p-3">
      <div class="row my-2">
        <div class="col-6">
          <div class="btn-group">
            <button type="button"
                    class="btn btn-sm btn-light-gray mr-1"
                    data-toggle="modal"
                    data-target=".bd-example-modal-xl"
                    (click)="createCountry()">
              <i class="material-icons">add</i>
              <span i18n="ref|Tab countries - button new@@ref.main.tab.countries.buttonNew">New</span>
            </button>
          </div>
          <div class="btn-group">
            <div class="dropdown">
              <button class="btn btn-sm btn-light-gray mr-1 dropdown-toggle"
                      type="button"
                      id="dropdownMenuExportCountries"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                <i class="material-icons">arrow_downward</i>
                <span i18n="ref|Tab countries - button export@@ref.main.tab.countries.buttonExport">Export</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuExportCountries">
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('countries', 'csv')">CSV
                </button>
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('countries', 'xml')">XML
                </button>
              </div>
            </div>
          </div>
          <div class="btn-group">
            <button type="button"
                    class="btn btn-sm btn-light-gray"
                    data-toggle="modal"
                    data-target=".modal-import"
                    (click)="getFieldsFromDB('countries')">
              <i class="material-icons">arrow_upward</i>
              <span i18n="ref|Tab countries - button import@@ref.main.tab.countries.buttonImport">Import</span>
            </button>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group referance-filter">
            <div class="input-group-prepend">
              <span class="input-group-text font-small text-center"
                    i18n="ref|Tab countries - field search@@ref.main.tab.countries.fieldSearch">Search
              </span>
            </div>
            <div class="position-relative">
              <input type="text" aria-label="IATA" class="form-control rounded-0 border-right-0" placeholder="IATA" [(ngModel)]="filterIata">
              <span class="clear-filter" (click)="filterIata=''">&times;</span>
            </div>
            <div class="position-relative">
              <input type="text" aria-label="ICAO" class="form-control rounded-0 border-right-0" placeholder="ICAO" [(ngModel)]="filterIcao">
              <span class="clear-filter" (click)="filterIcao=''">&times;</span>
            </div>
            <div class="position-relative">
              <input type="text"
                    aria-label="Name"
                    class="form-control rounded-0"
                    placeholder="NAME"
                    [(ngModel)]="filterName"
                    i18n-placeholder="ref|Tab countries - field search name@@ref.main.tab.countries.fieldSearchName">
              <span class="clear-filter" (click)="filterName=''">&times;</span>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-hover w-100">
        <thead class="">
        <tr #trCountries>
          <th class="text-center text-uppercase column-iata-size"
              field="iata"
              data-method
              (click)="sortBy($event.target, trCountries)">IATA
          </th>
          <th class="text-center text-uppercase column-icao-size"
              field="icao"
              data-method
              (click)="sortBy($event.target, trCountries)">ICAO
          </th>
          <th class="text-uppercase"
              field="names"
              data-method
              (click)="sortBy($event.target, trCountries)"
              i18n="ref|Tab countries - table data column name@@ref.main.tab.countries.tableColumnName">Name
          </th>
          <th class="text-uppercase"
              field="regions"
              data-method
              (click)="sortBy($event.target, trCountries)"
              i18n="ref|Tab countries - table data column lastupdate@@ref.main.tab.countries.tableColumnRegion">
            Region
          </th>
          <th class="text-uppercase column-lastupdate-size"
              field="lastupdate"
              data-method
              (click)="sortBy($event.target, trCountries)"
              i18n="ref|Tab countries - table data column lastupdate@@ref.main.tab.countries.tableColumnLastupdate">
            Last update
          </th>
        </tr>
        </thead>
        <tbody>
          <tr *ngIf="loading">
            <td colspan="5" class="p-5">
              <div class="loading">
                <div class="cssload-spin-box"></div>
              </div>
            </td>
          </tr>
          <tr *ngFor="let country of countries | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
              class=""
              data-toggle="modal" data-target=".bd-example-modal-xl"
              (click)="loadCountry(country.id)">
            <td class="text-center">{{country.iata}}</td>
            <td class="text-center">{{country.icao}}</td>
            <td>{{country.names}}</td>
            <td>{{country.regions}}</td>
            <td>{{country.lastupdate | date: 'dd.MM.yyyy HH:mm'}}</td>
          </tr>
        </tbody>
      </table>

      <pagination-controls
        (pageChange)="currentPage = $event"
        maxSize="7"
        directionLinks="true"
        autoHide="true"
        previousLabel="Previous"
        nextLabel="Next"
        i18n-previousLabel="ref|Tab countries - pagination previous label@@ref.main.tab.countries.pagPrevious"
        i18n-nextLabel="ref|Tab countries - pagination next label@@ref.main.tab.countries.pagNext">
      </pagination-controls>
    </div>

    <!-- Tab cities -->
    <div *ngIf="showTab('tab-cities')" class="bg-white p-3">
      <div class="row my-2">
        <div class="col-6">
          <div class="btn-group">
            <button type="button"
                    class="btn btn-sm btn-light-gray mr-1"
                    data-toggle="modal"
                    data-target=".bd-example-modal-xl"
                    (click)="createCity()">
              <i class="material-icons">add</i>
              <span i18n="ref|Tab cities - button new@@ref.main.tab.cities.buttonNew">New</span>
            </button>
          </div>
          <div class="btn-group">
            <div class="dropdown">
              <button class="btn btn-sm btn-light-gray mr-1 dropdown-toggle"
                      type="button"
                      id="dropdownMenuExportCities"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                <i class="material-icons">arrow_downward</i>
                <span i18n="ref|Tab cities - button export@@ref.main.tab.cities.buttonExport">Export</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuExportCities">
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('cities', 'csv')">CSV
                </button>
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('cities', 'xml')">XML
                </button>
              </div>
            </div>
          </div>
          <div class="btn-group">
            <button type="button"
                    class="btn btn-sm btn-light-gray"
                    data-toggle="modal"
                    data-target=".modal-import"
                    (click)="getFieldsFromDB('cities')">
              <i class="material-icons">arrow_upward</i>
              <span i18n="ref|Tab cities - button import@@ref.main.tab.cities.buttonImport">Import</span>
            </button>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group referance-filter">
            <div class="input-group-prepend">
              <span class="input-group-text font-small text-center"
                    i18n="ref|Tab cities - field search@@ref.main.tab.cities.fieldSearch">Search
              </span>
            </div>
            <div class="position-relative">
              <input type="text" aria-label="IATA" class="form-control rounded-0 border-right-0" placeholder="IATA" [(ngModel)]="filterIata">
              <span class="clear-filter" (click)="filterIata=''">&times;</span>
            </div>
            <div class="position-relative">
              <input type="text" aria-label="ICAO" class="form-control rounded-0 border-right-0" placeholder="ICAO" [(ngModel)]="filterIcao">
              <span class="clear-filter" (click)="filterIcao=''">&times;</span>
            </div>
            <div class="position-relative">
              <input type="text"
                    aria-label="Name"
                    class="form-control rounded-0"
                    placeholder="NAME"
                    [(ngModel)]="filterName"
                    i18n-placeholder="ref|Tab cities - field search name@@ref.main.tab.cities.fieldSearchName">
              <span class="clear-filter" (click)="filterName=''">&times;</span>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-hover w-100">
        <thead class="">
        <tr #trCities>
          <th class="text-center text-uppercase column-iata-size"
              field="iata"
              data-method
              (click)="sortBy($event.target, trCities)">IATA
          </th>
          <th class="text-center text-uppercase column-icao-size"
              field="icao"
              data-method
              (click)="sortBy($event.target, trCities)">ICAO
          </th>
          <th class="text-uppercase"
              field="names"
              data-method
              (click)="sortBy($event.target, trCities)"
              i18n="ref|Tab cities - table data column name@@ref.main.tab.cities.tableColumnName">Name
          </th>
          <th class="text-uppercase"
              field="countrys"
              data-method
              (click)="sortBy($event.target, trCities)"
              i18n="ref|Tab cities - table data column lastupdate@@ref.main.tab.cities.tableColumnCountry">
            Country
          </th>
          <th class="text-uppercase column-lastupdate-size"
              field="lastupdate"
              data-method
              (click)="sortBy($event.target, trCities)"
              i18n="ref|Tab cities - table data column lastupdate@@ref.main.tab.cities.tableColumnLastupdate">
            Last update
          </th>
        </tr>
        </thead>
        <tbody>
          <tr *ngIf="loading">
            <td colspan="5" class="p-5">
              <div class="loading">
                <div class="cssload-spin-box"></div>
              </div>
            </td>
          </tr>
          <tr *ngFor="let city of cities | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
              class=""
              data-toggle="modal" data-target=".bd-example-modal-xl"
              (click)="loadCity(city.id)">
            <td class="text-center">{{city.iata}}</td>
            <td class="text-center">{{city.icao}}</td>
            <td>{{city.names}}</td>
            <td>{{city.countrys}}</td>
            <td>{{city.lastupdate | date: 'dd.MM.yyyy HH:mm'}}</td>
          </tr>
        </tbody>
      </table>

      <pagination-controls
        (pageChange)="currentPage = $event"
        maxSize="7"
        directionLinks="true"
        autoHide="true"
        previousLabel="Previous"
        nextLabel="Next"
        i18n-previousLabel="ref|Tab cities - pagination previous label@@ref.main.tab.cities.pagPrevious"
        i18n-nextLabel="ref|Tab cities - pagination next label@@ref.main.tab.cities.pagNext">
      </pagination-controls>
    </div>

    <!-- Tab aircraft-kinds -->
    <div *ngIf="showTab('tab-aircraft-kinds')" class="bg-white p-3">
      <div class="row my-2">
        <div class="col-6">
          <div class="btn-group">
            <button type="button"
                    class="btn btn-sm btn-light-gray mr-1"
                    data-toggle="modal"
                    data-target=".bd-example-modal-xl"
                    (click)="createAircraftKind()">
              <i class="material-icons">add</i>
              <span i18n="ref|Tab aircraft-kinds - button new@@ref.main.tab.aircraftKinds.buttonNew">New</span>
            </button>
          </div>
          <div class="btn-group">
            <div class="dropdown">
              <button class="btn btn-sm btn-light-gray mr-1 dropdown-toggle"
                      type="button"
                      id="dropdownMenuExportKinds"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                <i class="material-icons">arrow_downward</i>
                <span
                  i18n="ref|Tab aircraft-kinds - button export@@ref.main.tab.aircraftKinds.buttonExport">Export</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuExportKinds">
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('aircraft_kinds', 'csv')">CSV
                </button>
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('aircraft_kinds', 'xml')">XML
                </button>
              </div>
            </div>
          </div>
          <div class="btn-group">
            <button type="button"
                    class="btn btn-sm btn-light-gray"
                    data-toggle="modal"
                    data-target=".modal-import"
                    (click)="getFieldsFromDB('aircraft_kinds')">
              <i class="material-icons">arrow_upward</i>
              <span i18n="ref|Tab aircraft-kinds - button import@@ref.main.tab.aircraftKinds.buttonImport">Import</span>
            </button>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group referance-filter">
            <div class="input-group-prepend">
              <span class="input-group-text font-small text-center"
                    i18n="ref|Tab aircraft-kinds - field search@@ref.main.tab.aircraftKinds.fieldSearch">Search
              </span>
            </div>
            <div class="position-relative">
              <input type="text"
                    aria-label="Name"
                    class="form-control rounded-0"
                    placeholder="NAME"
                    [(ngModel)]="filterName"
                    i18n-placeholder="ref|Tab aircraft-kinds - field search name@@ref.main.tab.aircraft-kinds.fieldSearchName">
              <span class="clear-filter" (click)="filterName=''">&times;</span>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-hover w-100">
        <thead class="">
        <tr #trKinds>
          <th class="text-uppercase"
              field="names"
              data-method
              (click)="sortBy($event.target, trKinds)"
              i18n="ref|Tab aircraft-kinds - table data column name@@ref.main.tab.aircraftKinds.tableColumnName">Name
          </th>
          <th class="text-uppercase column-lastupdate-size"
              field="lastupdate"
              data-method
              (click)="sortBy($event.target, trKinds)"
              i18n="ref|Tab aircraft-kinds - table data column lastupdate@@ref.main.tab.aircraftKinds.tableColumnLastupdate">
            Last update
          </th>
        </tr>
        </thead>
        <tbody>
          <tr *ngIf="loading">
            <td colspan="2" class="p-5">
              <div class="loading">
                <div class="cssload-spin-box"></div>
              </div>
            </td>
          </tr>
          <tr *ngFor="let kind of aircraftKinds | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
              class=""
              data-toggle="modal" data-target=".bd-example-modal-xl"
              (click)="loadAircraftKind(kind.id)">
            <td>{{kind.names}}</td>
            <td>{{kind.lastupdate | date: 'dd.MM.yyyy HH:mm'}}</td>
          </tr>
        </tbody>
      </table>

      <pagination-controls
        (pageChange)="currentPage = $event"
        maxSize="7"
        directionLinks="true"
        autoHide="true"
        previousLabel="Previous"
        nextLabel="Next"
        i18n-previousLabel="ref|Tab aircraft-kinds - pagination previous label@@ref.main.tab.aircraft-kinds.pagPrevious"
        i18n-nextLabel="ref|Tab aircraft-kinds - pagination next label@@ref.main.tab.aircraft-kinds.pagNext">
      </pagination-controls>
    </div>

    <!-- Tab aircraft-classes -->
    <div *ngIf="showTab('tab-aircraft-classes')" class="bg-white p-3">
      <div class="row my-2">
        <div class="col-6">
          <div class="btn-group">
            <button type="button"
                    class="btn btn-sm btn-light-gray mr-1"
                    data-toggle="modal"
                    data-target=".bd-example-modal-xl"
                    (click)="createAircraftClass()">
              <i class="material-icons">add</i>
              <span i18n="ref|Tab aircraft-classes - button new@@ref.main.tab.aircraftClasses.buttonNew">New</span>
            </button>
          </div>
          <div class="btn-group">
            <div class="dropdown">
              <button class="btn btn-sm btn-light-gray mr-1 dropdown-toggle"
                      type="button"
                      id="dropdownMenuExportClass"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                <i class="material-icons">arrow_downward</i>
                <span
                  i18n="ref|Tab aircraft-classes - button export@@ref.main.tab.aircraftClasses.buttonExport">Export</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuExportClass">
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('aircraft_classes', 'csv')">CSV
                </button>
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('aircraft_classes', 'xml')">XML
                </button>
              </div>
            </div>
          </div>
          <div class="btn-group">
            <button type="button"
                    class="btn btn-sm btn-light-gray"
                    data-toggle="modal"
                    data-target=".modal-import"
                    (click)="getFieldsFromDB('aircraft_classes')">
              <i class="material-icons">arrow_upward</i>
              <span
                i18n="ref|Tab aircraft-classes - button import@@ref.main.tab.aircraftClasses.buttonImport">Import</span>
            </button>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group referance-filter">
            <div class="input-group-prepend">
              <span class="input-group-text font-small text-center"
                    i18n="ref|Tab aircraft-classes - field search@@ref.main.tab.aircraftClasses.fieldSearch">Search
              </span>
            </div>
            <div class="position-relative">
              <input type="text"
                    aria-label="Name"
                    class="form-control rounded-0"
                    placeholder="NAME"
                    [(ngModel)]="filterName"
                    i18n-placeholder="ref|Tab aircraft_classes - field search name@@ref.main.tab.aircraft_classes.fieldSearchName">
              <span class="clear-filter" (click)="filterName=''">&times;</span>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-hover w-100">
        <thead class="">
        <tr #trClass>
          <th class="text-uppercase"
              field="names"
              data-method
              (click)="sortBy($event.target, trClass)"
              i18n="ref|Tab aircraft-classes - table data column name@@ref.main.tab.aircraftClasses.tableColumnName">
            Name
          </th>
          <th class="text-uppercase column-lastupdate-size"
              field="lastupdate"
              data-method
              (click)="sortBy($event.target, trClass)"
              i18n="ref|Tab aircraft-classes - table data column lastupdate@@ref.main.tab.aircraftClasses.tableColumnLastupdate">
            Last update
          </th>
        </tr>
        </thead>
        <tbody>
          <tr *ngIf="loading">
            <td colspan="2" class="p-5">
              <div class="loading">
                <div class="cssload-spin-box"></div>
              </div>
            </td>
          </tr>
          <tr *ngFor="let class of aircraftClasses | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
              class=""
              data-toggle="modal" data-target=".bd-example-modal-xl"
              (click)="loadAircraftClass(class.id)">
            <td>{{class.names}}</td>
            <td>{{class.lastupdate | date: 'dd.MM.yyyy HH:mm'}}</td>
          </tr>
        </tbody>
      </table>

      <pagination-controls
        (pageChange)="currentPage = $event"
        maxSize="7"
        directionLinks="true"
        autoHide="true"
        previousLabel="Previous"
        nextLabel="Next"
        i18n-previousLabel="ref|Tab aircraft-classes - pagination previous label@@ref.main.tab.aircraftClasses.pagPrevious"
        i18n-nextLabel="ref|Tab aircraft-classes - pagination next label@@ref.main.tab.aircraftClasses.pagNext">
      </pagination-controls>
    </div>

    <!-- Tab tails -->
    <div *ngIf="showTab('tab-tails')" class="bg-white p-3">
      <div class="row my-2">
        <div class="col-6">
          <div class="btn-group">
            <button type="button"
                    class="btn btn-sm btn-light-gray mr-1"
                    data-toggle="modal"
                    data-target=".bd-example-modal-xl"
                    (click)="createTail()">
              <i class="material-icons">add</i>
              <span i18n="ref|Tab tails - button new@@ref.main.tab.tails.buttonNew">New</span>
            </button>
          </div>
          <div class="btn-group">
            <div class="dropdown">
              <button class="btn btn-sm btn-light-gray mr-1 dropdown-toggle"
                      type="button"
                      id="dropdownMenuTails"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                <i class="material-icons">arrow_downward</i>
                <span i18n="ref|Tab tails - button export@@ref.main.tab.tails.buttonExport">Export</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuExportType">
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('tails', 'csv')">CSV
                </button>
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('tails', 'xml')">XML
                </button>
              </div>
            </div>
          </div>
          <div class="btn-group">
            <button type="button"
                    class="btn btn-sm btn-light-gray"
                    data-toggle="modal"
                    data-target=".modal-import"
                    (click)="getFieldsFromDB('tails')">
              <i class="material-icons">arrow_upward</i>
              <span i18n="ref|Tab tails - button import@@ref.main.tab.tails.buttonImport">Import</span>
            </button>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group referance-filter">
            <div class="input-group-prepend">
              <span class="input-group-text font-small text-center"
                    i18n="ref|Tab tails - field search@@ref.main.tab.tails.fieldSearch">Search
              </span>
            </div>
            <div class="position-relative">
              <input type="text"
                    aria-label="TAIL"
                    class="form-control rounded-0"
                    placeholder="TAIL"
                    [(ngModel)]="filterName"
                    i18n-placeholder="ref|Tab tails - field search name@@ref.main.tab.tails.fieldSearchName">
              <span class="clear-filter" (click)="filterName=''">&times;</span>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-hover w-100">
        <thead class="">
        <tr #trTail>
          <th class="text-uppercase"
              field="tail"
              data-method
              (click)="sortBy($event.target, trTail)"
              i18n="ref|Tab tails - table data column name@@ref.main.tab.tails.tableColumnTail">Tail
          </th>
          <th class="text-uppercase column-lastupdate-size"
              field="lastupdate"
              data-method
              (click)="sortBy($event.target, trTail)"
              i18n="ref|Tab tails - table data column lastupdate@@ref.main.tab.tails.tableColumnLastupdate">
            Last update
          </th>
        </tr>
        </thead>
        <tbody>
          <tr *ngIf="loading">
            <td colspan="2" class="p-5">
              <div class="loading">
                <div class="cssload-spin-box"></div>
              </div>
            </td>
          </tr>
          <tr *ngFor="let tail of tails | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
              class=""
              data-toggle="modal" data-target=".bd-example-modal-xl"
              (click)="loadTail(tail.id)">
            <td>{{tail.tail}}</td>
            <td>{{tail.lastupdate | date: 'dd.MM.yyyy HH:mm'}}</td>
          </tr>
        </tbody>
      </table>

      <pagination-controls
        (pageChange)="currentPage = $event"
        maxSize="7"
        directionLinks="true"
        autoHide="true"
        previousLabel="Previous"
        nextLabel="Next"
        i18n-previousLabel="ref|Tab tails - pagination previous label@@ref.main.tab.tails.pagPrevious"
        i18n-nextLabel="ref|Tab tails - pagination next label@@ref.main.tab.tails.pagNext">
      </pagination-controls>
    </div>

    <!-- Tab regularities -->
    <div *ngIf="showTab('tab-regularities')" class="bg-white p-3">
      <div class="row my-2">
        <div class="col-6">
          <div class="btn-group">
            <button type="button"
                    class="btn btn-sm btn-light-gray mr-1"
                    data-toggle="modal"
                    data-target=".bd-example-modal-xl"
                    (click)="createRegularitie()">
              <i class="material-icons">add</i>
              <span i18n="ref|Tab regularities - button new@@ref.main.tab.regularities.buttonNew">New</span>
            </button>
          </div>
          <div class="btn-group">
            <div class="dropdown">
              <button class="btn btn-sm btn-light-gray mr-1 dropdown-toggle"
                      type="button"
                      id="dropdownMenuRegularities"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                <i class="material-icons">arrow_downward</i>
                <span i18n="ref|Tab regularities - button export@@ref.main.tab.regularities.buttonExport">Export</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuExportType">
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('regularities', 'csv')">CSV
                </button>
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('regularities', 'xml')">XML
                </button>
              </div>
            </div>
          </div>
          <div class="btn-group">
            <button type="button"
                    class="btn btn-sm btn-light-gray"
                    data-toggle="modal"
                    data-target=".modal-import"
                    (click)="getFieldsFromDB('regularities')">
              <i class="material-icons">arrow_upward</i>
              <span i18n="ref|Tab regularities - button import@@ref.main.tab.regularities.buttonImport">Import</span>
            </button>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group referance-filter">
            <div class="input-group-prepend">
              <span class="input-group-text font-small text-center"
                    i18n="ref|Tab regularities - field search@@ref.main.tab.regularities.fieldSearch">Search
              </span>
            </div>
            <div class="position-relative">
              <input type="text"
                    aria-label="Name"
                    class="form-control rounded-0"
                    placeholder="NAME"
                    [(ngModel)]="filterName"
                    i18n-placeholder="ref|Tab regularities - field search name@@ref.main.tab.regularities.fieldSearchName">
              <span class="clear-filter" (click)="filterName=''">&times;</span>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-hover w-100">
        <thead class="">
        <tr #trRegularitie>
          <th class="text-uppercase"
              field="names"
              data-method
              (click)="sortBy($event.target, trRegularitie)"
              i18n="ref|Tab regularities - table data column name@@ref.main.tab.regularities.tableColumnNames">Name
          </th>
          <th class="text-uppercase column-lastupdate-size"
              field="lastupdate"
              data-method
              (click)="sortBy($event.target, trRegularitie)"
              i18n="ref|Tab regularities - table data column lastupdate@@ref.main.tab.regularities.tableColumnLastupdate">
            Last update
          </th>
        </tr>
        </thead>
        <tbody>
          <tr *ngIf="loading">
            <td colspan="2" class="p-5">
              <div class="loading">
                <div class="cssload-spin-box"></div>
              </div>
            </td>
          </tr>
          <tr *ngFor="let regularitie of regularities | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
              class=""
              data-toggle="modal" data-target=".bd-example-modal-xl"
              (click)="loadRegularitie(regularitie.id)">
            <td>{{regularitie.names}}</td>
            <td>{{regularitie.lastupdate | date: 'dd.MM.yyyy HH:mm'}}</td>
          </tr>
        </tbody>
      </table>

      <pagination-controls
        (pageChange)="currentPage = $event"
        maxSize="7"
        directionLinks="true"
        autoHide="true"
        previousLabel="Previous"
        nextLabel="Next"
        i18n-previousLabel="ref|Tab aregularities - pagination previous label@@ref.main.tab.aregularities.pagPrevious"
        i18n-nextLabel="ref|Tab regularities - pagination next label@@ref.main.tab.regularities.pagNext">
      </pagination-controls>
    </div>

    <!-- Tab Stands -->
    <div *ngIf="showTab('tab-stands')" class="bg-white p-3">
      <div class="row my-2">
        <div class="col-6">
          <div class="btn-group">
            <button type="button"
                    class="btn btn-sm btn-light-gray mr-1"
                    data-toggle="modal"
                    data-target=".bd-example-modal-xl"
                    (click)="createStand()">
              <i class="material-icons">add</i>
              <span i18n="ref|Tab stands - button new@@ref.main.tab.stands.buttonNew">New</span>
            </button>
          </div>
          <div class="btn-group">
            <div class="dropdown">
              <button class="btn btn-sm btn-light-gray mr-1 dropdown-toggle"
                      type="button"
                      id="dropdownMenuRegularities"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                <i class="material-icons">arrow_downward</i>
                <span i18n="ref|Tab stands - button export@@ref.main.tab.stands.buttonExport">Export</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuExportType">
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('stands', 'csv')">CSV
                </button>
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('stands', 'xml')">XML
                </button>
              </div>
            </div>
          </div>
          <div class="btn-group">
            <button type="button"
                    class="btn btn-sm btn-light-gray"
                    data-toggle="modal"
                    data-target=".modal-import"
                    (click)="getFieldsFromDB('stands')">
              <i class="material-icons">arrow_upward</i>
              <span i18n="ref|Tab stands - button import@@ref.main.tab.stands.buttonImport">Import</span>
            </button>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group referance-filter">
            <div class="input-group-prepend">
              <span class="input-group-text font-small text-center"
                    i18n="ref|Tab stands - field search@@ref.main.tab.stands.fieldSearch">Search
              </span>
            </div>
            <div class="position-relative">
              <input type="text"
                    aria-label="Name"
                    class="form-control rounded-0"
                    placeholder="NAME"
                    [(ngModel)]="filterName"
                    i18n-placeholder="ref|Tab stands - field search name@@ref.main.tab.stands.fieldSearchName">
              <span class="clear-filter" (click)="filterName=''">&times;</span>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-hover w-100">
        <thead class="">
        <tr #trStand>
          <th class="text-uppercase"
              field="name"
              data-method
              (click)="sortBy($event.target, trStand)"
              i18n="ref|Tab stands - table data column name@@ref.main.tab.stands.tableColumnNames">Name
          </th>
          <th class="text-uppercase"
              field="standTypeName"
              data-method
              (click)="sortBy($event.target, trStand)"
              i18n="ref|Tab stands - table data column type@@ref.main.tab.stands.tableColumnType">Type
          </th>
          <th class="text-uppercase column-lastupdate-size"
              field="lastupdate"
              data-method
              (click)="sortBy($event.target, trStand)"
              i18n="ref|Tab stands - table data column lastupdate@@ref.main.tab.stands.tableColumnLastupdate">
            Last update
          </th>
        </tr>
        </thead>
        <tbody>
          <tr *ngIf="loading">
            <td colspan="3" class="p-5">
              <div class="loading">
                <div class="cssload-spin-box"></div>
              </div>
            </td>
          </tr>
          <tr *ngFor="let stand of stands | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
              class=""
              data-toggle="modal" data-target=".bd-example-modal-xl"
              (click)="loadStand(stand.id)">
            <td>{{stand.name}}</td>
            <td>{{stand.standTypeName}}</td>
            <td>{{stand.lastupdate | date: 'dd.MM.yyyy HH:mm'}}</td>
          </tr>
        </tbody>
      </table>

      <pagination-controls
        (pageChange)="currentPage = $event"
        maxSize="7"
        directionLinks="true"
        autoHide="true"
        previousLabel="Previous"
        nextLabel="Next"
        i18n-previousLabel="ref|Tab stands - pagination previous label@@ref.main.tab.stands.pagPrevious"
        i18n-nextLabel="ref|Tab stands - pagination next label@@ref.main.tab.stands.pagNext">
      </pagination-controls>
    </div>

    <!-- Tab Stand Types -->
    <div *ngIf="showTab('tab-stand-types')" class="bg-white p-3">
      <div class="row my-2">
        <div class="col-6">
          <div class="btn-group">
            <button type="button"
                    class="btn btn-sm btn-light-gray mr-1"
                    data-toggle="modal"
                    data-target=".bd-example-modal-xl"
                    (click)="createStandType()">
              <i class="material-icons">add</i>
              <span i18n="ref|Tab stand types - button new@@ref.main.tab.standTypes.buttonNew">New</span>
            </button>
          </div>
          <div class="btn-group">
            <div class="dropdown">
              <button class="btn btn-sm btn-light-gray mr-1 dropdown-toggle"
                      type="button"
                      id="dropdownMenuRegularities"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                <i class="material-icons">arrow_downward</i>
                <span i18n="ref|Tab stand types - button export@@ref.main.tab.standTypes.buttonExport">Export</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuExportType">
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('standTypes', 'csv')">CSV
                </button>
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('standTypes', 'xml')">XML
                </button>
              </div>
            </div>
          </div>
          <div class="btn-group">
            <button type="button"
                    class="btn btn-sm btn-light-gray"
                    data-toggle="modal"
                    data-target=".modal-import"
                    (click)="getFieldsFromDB('stand_types')">
              <i class="material-icons">arrow_upward</i>
              <span i18n="ref|Tab stand types - button import@@ref.main.tab.standTypes.buttonImport">Import</span>
            </button>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group referance-filter">
            <div class="input-group-prepend">
              <span class="input-group-text font-small text-center"
                    i18n="ref|Tab stand types - field search@@ref.main.tab.standTypes.fieldSearch">Search
              </span>
            </div>
            <div class="position-relative">
              <input type="text"
                    aria-label="Name"
                    class="form-control rounded-0"
                    placeholder="NAME"
                    [(ngModel)]="filterName"
                    i18n-placeholder="ref|Tab stand types - field search name@@ref.main.tab.standTypes.fieldSearchName">
              <span class="clear-filter" (click)="filterName=''">&times;</span>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-hover w-100">
        <thead class="">
        <tr #trStandType>
          <th class="text-uppercase"
              field="names"
              data-method
              (click)="sortBy($event.target, trStandType)"
              i18n="ref|Tab stand types - table data column name@@ref.main.tab.standTypes.tableColumnNames">Name
          </th>
          <th class="text-uppercase column-lastupdate-size"
              field="lastupdate"
              data-method
              (click)="sortBy($event.target, trStandType)"
              i18n="ref|Tab stand types - table data column lastupdate@@ref.main.tab.standTypes.tableColumnLastupdate">
            Last update
          </th>
        </tr>
        </thead>
        <tbody>
          <tr *ngIf="loading">
            <td colspan="2" class="p-5">
              <div class="loading">
                <div class="cssload-spin-box"></div>
              </div>
            </td>
          </tr>
          <tr *ngFor="let item of standTypes | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
              class=""
              data-toggle="modal" data-target=".bd-example-modal-xl"
              (click)="loadStandType(item.id)">
            <td>{{item.names}}</td>
            <td>{{item.lastupdate | date: 'dd.MM.yyyy HH:mm'}}</td>
          </tr>
        </tbody>
      </table>

      <pagination-controls
        (pageChange)="currentPage = $event"
        maxSize="7"
        directionLinks="true"
        autoHide="true"
        previousLabel="Previous"
        nextLabel="Next"
        i18n-previousLabel="ref|Tab stand types - pagination previous label@@ref.main.tab.standTypes.pagPrevious"
        i18n-nextLabel="ref|Tab stand types - pagination next label@@ref.main.tab.standTypes.pagNext">
      </pagination-controls>
    </div>

    <!-- Tab Terminals -->
    <div *ngIf="showTab('tab-terminals')" class="bg-white p-3">
      <div class="row my-2">
        <div class="col-6">
          <div class="btn-group">
            <button type="button"
                    class="btn btn-sm btn-light-gray mr-1"
                    data-toggle="modal"
                    data-target=".bd-example-modal-xl"
                    (click)="createTerminal()">
              <i class="material-icons">add</i>
              <span i18n="ref|Tab terminals - button new@@ref.main.tab.terminals.buttonNew">New</span>
            </button>
          </div>
          <div class="btn-group">
            <div class="dropdown">
              <button class="btn btn-sm btn-light-gray mr-1 dropdown-toggle"
                      type="button"
                      id="dropdownMenuRegularities"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                <i class="material-icons">arrow_downward</i>
                <span i18n="ref|Tab terminals - button export@@ref.main.tab.terminals.buttonExport">Export</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuExportType">
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('terminals', 'csv')">CSV
                </button>
                <button class="dropdown-item"
                        type="button"
                        (click)="downloadFile('terminals', 'xml')">XML
                </button>
              </div>
            </div>
          </div>
          <div class="btn-group">
            <button type="button"
                    class="btn btn-sm btn-light-gray"
                    data-toggle="modal"
                    data-target=".modal-import"
                    (click)="getFieldsFromDB('terminals')">
              <i class="material-icons">arrow_upward</i>
              <span i18n="ref|Tab terminals - button import@@ref.main.tab.terminals.buttonImport">Import</span>
            </button>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group referance-filter">
            <div class="input-group-prepend">
              <span class="input-group-text font-small text-center"
                    i18n="ref|Tab terminals - field search@@ref.main.tab.terminals.fieldSearch">Search
              </span>
            </div>
            <div class="position-relative">
              <input type="text"
                    aria-label="Name"
                    class="form-control rounded-0"
                    placeholder="NAME"
                    [(ngModel)]="filterName"
                    i18n-placeholder="ref|Tab terminals - field search name@@ref.main.tab.terminals.fieldSearchName">
              <span class="clear-filter" (click)="filterName=''">&times;</span>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-hover w-100">
        <thead class="">
        <tr #trTerminal>
          <th class="text-uppercase"
              field="name"
              data-method
              (click)="sortBy($event.target, trTerminal)"
              i18n="ref|Tab terminals - table data column name@@ref.main.tab.terminals.tableColumnNames">Name
          </th>
          <th class="text-uppercase column-lastupdate-size"
              field="lastupdate"
              data-method
              (click)="sortBy($event.target, trTerminal)"
              i18n="ref|Tab terminals - table data column lastupdate@@ref.main.tab.terminals.tableColumnLastupdate">
            Last update
          </th>
        </tr>
        </thead>
        <tbody>
          <tr *ngIf="loading">
            <td colspan="2" class="p-5">
              <div class="loading">
                <div class="cssload-spin-box"></div>
              </div>
            </td>
          </tr>
          <tr *ngFor="let terminal of terminals | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
              class=""
              data-toggle="modal" data-target=".bd-example-modal-xl"
              (click)="loadTerminal(terminal.id)">
            <td>{{terminal.name}}</td>
            <td>{{terminal.lastupdate | date: 'dd.MM.yyyy HH:mm'}}</td>
          </tr>
        </tbody>
      </table>

      <pagination-controls
        (pageChange)="currentPage = $event"
        maxSize="7"
        directionLinks="true"
        autoHide="true"
        previousLabel="Previous"
        nextLabel="Next"
        i18n-previousLabel="ref|Tab terminals - pagination previous label@@ref.main.tab.terminals.pagPrevious"
        i18n-nextLabel="ref|Tab terminals - pagination next label@@ref.main.tab.terminals.pagNext">
      </pagination-controls>
    </div>

    <!-- Modal Airports -->
    <div *ngIf="showTab('tab-airports')">
      <form #airportForm="ngForm">
        <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
             aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content bg-light-gray">
              <div class="loading" *ngIf="loading">
                <div class="cssload-spin-box"></div>
              </div>
              <div class="modal-header">
                <h5 *ngIf="showButton('state-append')"
                    class="modal-title">
                  <span i18n="ref|Modal airports - header for append mode@@ref.main.modal.airport.headerAppend">Airports append</span>&nbsp;{{airport.iata}}
                </h5>
                <h5 *ngIf="showButton('state-update')"
                    class="modal-title">
                  <span i18n="ref|Modal airports - header for edit mode@@ref.main.modal.airports.headerEdit">Edit airport</span>&nbsp;{{airport.iata}}
                </h5>
                <button #airportFormFade type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal airports - field iata code@@ref.main.modal.airport.field.iataCode">IATA code</span>
                    </label>
                    <input type="text"
                            class="form-control"
                            name="airportIata"
                            #airportIata="ngModel"
                            [(ngModel)]="airport.iata"
                            minlength="3"
                            maxlength="3"
                            pattern="^[a-zA-Z]{0,}$"
                            [required]="!airport.iata && !airport.icao && !airport.codeLocal">
                    <div *ngIf="!airportIata?.valid &&
                          (airportIata?.dirty || airportIata?.touched
                          || airportIcao?.dirty || airportIcao?.touched
                          || airportCode1?.dirty || airportCode1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="airportIata.errors.required"
                           i18n="ref|Modal airports - error iata code is required@@ref.main.modal.airport.err.iataIcaoLocalCodeReq">
                        IATA or ICAO or Local code is required
                      </div>
                      <div *ngIf="airportIata.errors.minlength">
                        <span i18n="ref|Modal airports - error iata code is min length@@ref.main.modal.airport.err.iataCodeMinLength">IATA code minimum length is</span>&nbsp;{{airportIata.errors.minlength?.requiredLength}}
                      </div>
                      <div *ngIf="airportIata.errors.pattern"
                           i18n="ref|Modal airports - error iata code is english only@@ref.main.modal.airport.err.iataCodeEnOnly">
                        IATA code is English only
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal airports - field icao code@@ref.main.modal.airports.field.icaoCode">ICAO code</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="airportIcao"
                             #airportIcao="ngModel"
                             [(ngModel)]="airport.icao"
                             minlength="4"
                             maxlength="4"
                             pattern="^[a-zA-Z0-9]{0,}$"
                             [required]="!airport.iata && !airport.icao && !airport.codeLocal">
                    <div *ngIf="!airportIcao?.valid &&
                          (airportIata?.dirty || airportIata?.touched
                          || airportIcao?.dirty || airportIcao?.touched
                          || airportCode1?.dirty || airportCode1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="airportIcao.errors.required"
                           i18n="ref|Modal airports - error icao code is required@@ref.main.modal.airport.err.iataIcaoLocalCodeReq">
                        IATA or ICAO or Local code is required
                      </div>
                      <div *ngIf="airportIcao.errors.minlength">
                        <span i18n="ref|Modal airports - error icao code is min length@@ref.main.modal.airport.err.icaoCodeMinLength">ICAO code minimum length is</span>&nbsp;{{airportIcao.errors.minlength?.requiredLength}}
                      </div>
                      <div *ngIf="airportIcao.errors.pattern"
                           i18n="ref|Modal airports - error icao code is english only@@ref.main.modal.airport.err.icaoCodeEnOnly">
                        ICAO code is English only
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal airports - english name@@ref.main.modal.airport.field.englishName">English name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="airportName0"
                             #airportName0="ngModel"
                             [(ngModel)]="airport.nameEnglish"
                             pattern="^[a-zA-Z0-9\.,-\/\\_*\s]{0,}$"
                             required>

                    <div *ngIf="!airportName0?.valid && (airportName0?.dirty || airportName0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="airportName0.errors.required"
                           i18n="ref|Modal airports - error english name is req@@ref.main.modal.airport.err.englishNameReq">
                        English name is required
                      </div>
                      <div *ngIf="airportName0.errors.pattern"
                           i18n="ref|Modal airports - error english name is english only@@ref.main.modal.airport.err.englishNameEnOnly">
                        Name is English only
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal airports - local name@@ref.main.modal.airport.field.localName">Local name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="airportName1"
                             #airportName1="ngModel"
                             [(ngModel)]="airport.nameLocal"
                             required>

                    <div *ngIf="!airportName1?.valid && (airportName1?.dirty || airportName1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="airportName1.errors.required"
                           i18n="ref|Modal airports - error local name is req@@ref.main.modal.airports.err.localNameReq">
                        Local name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal airports - english code@@ref.main.modal.airport.field.englishCode">English code</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="airportCode0"
                             #airportCode0="ngModel"
                             [(ngModel)]="airport.codeEnglish"
                             pattern="^[a-zA-Z0-9\.,-\/\\_*\s]{0,}$">
                    <div *ngIf="!airportCode0?.valid && (airportCode0?.dirty || airportCode0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="airportCode0.errors.required"
                           i18n="ref|Modal airports - error english code is req@@ref.main.modal.airport.err.englishCodeReq">
                        English code is required
                      </div>
                      <div *ngIf="airportCode0.errors.pattern"
                           i18n="ref|Modal airports - error english code is english only@@ref.main.modal.airport.err.englishCodeEnOnly">
                        Code is English only
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal airports - local code@@ref.main.modal.airport.field.localCode">Local code</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="airportCode1"
                             #airportCode1="ngModel"
                             [(ngModel)]="airport.codeLocal"
                             [required]="!airport.iata && !airport.icao && !airport.codeLocal">
                    <div *ngIf="!airportCode1?.valid &&
                          (airportIata?.dirty || airportIata?.touched
                          || airportIcao?.dirty || airportIcao?.touched
                          || airportCode1?.dirty || airportCode1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="airportCode1.errors.required"
                           i18n="ref|Modal airports - error local code is req@@ref.main.modal.airports.err.iataIcaoLocalCodeReq">
                        IATA or ICAO or Local code is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-3">
                    <label class="w-100">
                      <span i18n="ref|Modal airports - timezone winter@@ref.main.modal.airports.field.tzWinter">Timezone winter</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="number"
                             step="0.5"
                             class="form-control"
                             name="airportTzOffset0"
                             #airportTzOffset0="ngModel"
                             [(ngModel)]="airport.tzOffsetWinter"
                             min="-12"
                             max="14"
                             pattern="^(([-][0-9])([\.,][0-9])?)$|^(([-]1[0-2])([\.,][0-9])?)$|^([0-9]([\.,][0-9])?)$|^(1[0-4]([\.,][0-9])?)$"
                             required>

                    <div *ngIf="!airportTzOffset0?.valid && (airportTzOffset0?.dirty || airportTzOffset0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="airportTzOffset0.errors.required"
                           i18n="ref|Modal airports - error timezone winter is req@@ref.main.modal.airports.err.tzWinterReq">
                        Timezone winter is required
                      </div>
                      <div *ngIf="airportTzOffset0.errors.pattern"
                           i18n="ref|Modal airports - error timezone winter -12 +14 limit@@ref.main.modal.airports.err.tzWinterLimit">
                        Timezone limit -12 +14 hours
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <label class="w-100">
                      <span i18n="ref|Modal airports - timezone summer@@ref.main.modal.airports.field.tzSummer">Timezone summer</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="number"
                             step="0.5"
                             class="form-control"
                             name="airportTzOffset1"
                             #airportTzOffset1="ngModel"
                             [(ngModel)]="airport.tzOffsetSummer"
                             min="-12"
                             max="14"
                             pattern="^(([-][0-9])([\.,][0-9])?)$|^(([-]1[0-2])([\.,][0-9])?)$|^([0-9]([\.,][0-9])?)$|^(1[0-4]([\.,][0-9])?)$"
                             required>

                    <div *ngIf="!airportTzOffset1?.valid && (airportTzOffset1?.dirty || airportTzOffset1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="airportTzOffset1.errors.required"
                           i18n="ref|Modal airports - error timezone summer is req@@ref.main.modal.airports.err.tzSummerReq">
                        Timezone is required
                      </div>
                      <div *ngIf="airportTzOffset1.errors.pattern"
                           i18n="ref|Modal airports - error timezone summer -12 +14 limit@@ref.main.modal.airports.err.tzSummerLimit">
                        Timezone limit -12 +14 hours
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal airports - field icao code@@ref.main.modal.airports.field.city">City</span><span class="required-asterisk">*</span>
                    </label>
                    <ng-select class="form-control custom-sm p-0"
                               [items]="citiesForSelectList"
                               bindLabel="names"
                               bindValue="id"
                               [(ngModel)]="airport.cityId"
                               #airportCity="ngModel"
                               appendTo="body"
                               name="airportCity"
                               [virtualScroll]="true"
                               [loading]="citiesLoading"
                               (scroll)="onScrollCities($event)"
                               (scrollToEnd)="onScrollCitiesToEnd()"
                               required>
                      <ng-template ng-option-tmp let-item="item">
                        {{item.names}} ({{item.iata}} / {{item.icao}})
                      </ng-template>
                    </ng-select>
                    <!-- <select class="form-control form-control-sm"
                            [ngModel]="airport.cityId"
                            name="airportCity"
                            #airportCity="ngModel"
                            (input)="airport.cityId = +$event.target.value"
                            required>
                      <option *ngFor="let city of cities" [selected]="city.id == airport.cityId" value="{{city.id}}">
                        {{city.names}} ({{city.country.join('/')}})
                      </option>
                    </select> -->
                    <div *ngIf="!airportCity?.valid && (airportCity?.dirty || airportCity?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="airportCity.errors.required"
                           i18n="ref|Modal airports - error icao code is required@@ref.main.modal.airport.err.city">
                        City is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal airports - date range start@@ref.main.modal.airports.field.dtRangeStart">Date range start</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="airportDtRange0"
                             #airportDtRange0="ngModel"
                             [ngModel]="airport.dtRangeStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="airport.dtRangeStart = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!airportDtRange0?.valid && (airportDtRange0?.dirty || airportDtRange0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="airportDtRange0.errors.required"
                           i18n="ref|Modal airports - error date range start is req@@ref.main.modal.airports.err.dtRangeStartReq">
                        Date range start is required
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal airports - date rande finish@@ref.main.modal.airports.field.dtRangeFinish">Date range finish</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="airportDtRange1"
                             #airportDtRange1="ngModel"
                             [ngModel]="airport.dtRangeFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="airport.dtRangeFinish = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!airportDtRange1?.valid && (airportDtRange1?.dirty || airportDtRange1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="airportDtRange1.errors.required"
                           i18n="ref|Modal airports - error date range finish is req@@ref.main.modal.airports.err.dtRangeFinishReq">
                        Date range finish is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="row w-100 m-0">
                  <div class="col-6 p-0">
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="duplicateReference(airport)"
                            i18n="ref|Modal airports - button duplicate@@ref.main.modal.airports.button.duplicate">
                      Duplicate
                    </button>
                    <button type="button"
                            *ngIf="showButton('state-update')"
                            class="btn btn-red btn-sm"
                            (click)="confirmAction(deleteAirport)"
                            i18n="ref|Modal airports - button delete@@ref.main.modal.airports.button.delete">
                      Delete
                    </button>
                  </div>
                  <div class="col-6 p-0 text-right">
                    <button type="button" *ngIf="showButton('state-append')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="addAirport()"
                            i18n="ref|Modal airports - button append@@ref.main.modal.airports.button.append">
                      Append
                    </button>
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="updateAirport(airport.id)"
                            i18n="ref|Modal airports - button save@@ref.main.modal.airports.button.save">
                      Save
                    </button>
                    <button type="button"
                            class="btn btn-secondary btn-sm"
                            data-dismiss="modal"
                            i18n="ref|Modal airports - button close@@ref.main.modal.airports.button.close">
                      Close
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="errorMessage !== '' && activeTab == 'tab-airports'" class="ms-modal-message">
                <div class="modal-content w-50">
                  <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                                'bg-warning': errorType == 'warning',
                                                'bg-danger': errorType == 'error'}">
                    <h5 *ngIf="errorType == 'info'"
                        class="modal-title"
                        i18n="ref|Modal airports error - header information@@ref.main.modal.airports.errMes.header.inf">
                      Information
                    </h5>
                    <h5 *ngIf="errorType == 'warning'"
                        class="modal-title"
                        i18n="ref|Modal airports error - header warning@@ref.main.modal.airports.errMes.header.warn">
                      Warning
                    </h5>
                    <h5 *ngIf="errorType == 'error'"
                        class="modal-title"
                        i18n="ref|Modal airports error - header error@@ref.main.modal.airports.errMes.header.err">
                      Error
                    </h5>
                    <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    {{errorMessage}}
                  </div>
                  <div class="modal-footer">
                    <button type="button"
                            *ngIf="userAction == 'error'"
                            class="btn btn-sm btn-blue"
                            (click)="clearErrorMess()"
                            i18n="ref|Modal airports error - button edit@@ref.main.modal.airports.errMes.button.edit">
                      Edit
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'error'"
                            class="btn btn-sm btn-secondary"
                            (click)="closeWithoutSaving()"
                            i18n="ref|Modal airports error - button close@@ref.main.modal.airports.errMes.button.close">
                      Close without saving
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-blue"
                            (click)="executableFunction(airport.id)"
                            i18n="adm|Modal Master Data - delete yes@@ref.main.modal.button.deleteYes">
                      Yes
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-secondary"
                            (click)="clearErrorMess()"
                            i18n="adm|Modal Master Data - delete no@@ref.main.modal.button.deleteNo">
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- Modal airlines -->
    <div *ngIf="showTab('tab-airlines')">
      <form #airlineForm="ngForm">
        <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
             aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content bg-light-gray">
              <div class="loading" *ngIf="loading">
                <div class="cssload-spin-box"></div>
              </div>
              <div class="modal-header">
                <h5 *ngIf="showButton('state-append')"
                    class="modal-title">
                  <span i18n="ref|Modal airline - header for append mode@@ref.main.modal.airline.headerAppend">Airline append</span>&nbsp;{{airline.iata}}
                </h5>
                <h5 *ngIf="showButton('state-update')"
                    class="modal-title">
                  <span i18n="ref|Modal airline - header for edit mode@@ref.main.modal.airline.headerEdit">Edit airline</span>&nbsp;{{airline.iata}}
                </h5>
                <button #airlineFormFade type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal airline - field iata code@@ref.main.modal.airline.field.iataCode">IATA code</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="airlineIata"
                             #airlineIata="ngModel"
                             [(ngModel)]="airline.iata"
                             minlength="2"
                             maxlength="2"
                             pattern="^[a-zA-Z0-9]{0,}$"
                             [required]="!airline.iata && !airline.icao && !airline.codeLocal">

                    <div *ngIf="!airlineIata?.valid &&
                              (airlineIata?.dirty || airlineIata?.touched
                              || airlineIcao?.dirty || airlineIcao?.touched
                              || airlineCode1?.dirty || airlineCode1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="airlineIata.errors.required"
                           i18n="ref|Modal airline - error iata code is required@@ref.main.modal.airline.err.iataIcaoLocalCodeReq">
                        IATA or ICAO or Local code is required
                      </div>
                      <div *ngIf="airlineIata.errors.minlength">
                        <span i18n="ref|Modal airline - error iata code is min length@@ref.main.modal.airline.err.iataCodeMinLength">IATA code minimum length is</span>&nbsp;{{airlineIata.errors.minlength?.requiredLength}}
                      </div>
                      <div *ngIf="airlineIata.errors.pattern"
                           i18n="ref|Modal airline - error iata code is english only@@ref.main.modal.airline.err.iataCodeEnOnly">
                        IATA code is English only
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal airline - field icao code@@ref.main.modal.airline.field.icaoCode">ICAO code</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="airlineIcao"
                             #airlineIcao="ngModel"
                             [(ngModel)]="airline.icao"
                             minlength="3"
                             maxlength="3"
                             pattern="^[a-zA-Z0-9]{0,}$"
                             [required]="!airline.iata && !airline.icao && !airline.codeLocal">

                    <div *ngIf="!airlineIcao?.valid &&
                              (airlineIata?.dirty || airlineIata?.touched
                              || airlineIcao?.dirty || airlineIcao?.touched
                              || airlineCode1?.dirty || airlineCode1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="airlineIcao.errors.required"
                           i18n="ref|Modal airline - error icao code is required@@ref.main.modal.airline.err.iataIcaoLocalCodeReq">
                        IATA or ICAO or Local code is required
                      </div>
                      <div *ngIf="airlineIcao.errors.minlength">
                        <span i18n="ref|Modal airline - error icao code is min length@@ref.main.modal.airline.err.icaoCodeMinLength">ICAO code minimum length is</span>&nbsp;{{airlineIcao.errors.minlength?.requiredLength}}
                      </div>
                      <div *ngIf="airlineIcao.errors.pattern"
                           i18n="ref|Modal airline - error icao code is english only@@ref.main.modal.airline.err.icaoCodeEnOnly">
                        ICAO code is English only
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal airline - english code@@ref.main.modal.airline.field.englishCode">English code</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="airlineCode0"
                             #airlineCode0="ngModel"
                             [(ngModel)]="airline.codeEnglish"
                             pattern="^[a-zA-Z0-9\.,-\/\\_*\s]{0,}$">

                    <div *ngIf="!airlineCode0?.valid && (airlineCode0?.dirty || airlineCode0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="airlineCode0.errors.required"
                           i18n="ref|Modal airline - error english code is req@@ref.main.modal.airline.err.englishCodeReq">
                        English code is required
                      </div>
                      <div *ngIf="airlineCode0.errors.pattern"
                           i18n="ref|Modal airline - error english code is english only@@ref.main.modal.airline.err.englishCodeEnOnly">
                        Code is English only
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal airline - local code@@ref.main.modal.airline.field.localCode">Local code</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="airlineCode1"
                             #airlineCode1="ngModel"
                             [(ngModel)]="airline.codeLocal"
                             [required]="!airline.iata && !airline.icao && !airline.codeLocal">

                    <div *ngIf="!airlineCode1?.valid &&
                              (airlineIata?.dirty || airlineIata?.touched
                              || airlineIcao?.dirty || airlineIcao?.touched
                              || airlineCode1?.dirty || airlineCode1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="airlineCode1.errors.required"
                           i18n="ref|Modal airline - error local code is req@@ref.main.modal.airline.err.iataIcaoLocalCodeReq">
                        IATA or ICAO or Local code is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal airline - english name@@ref.main.modal.airline.field.englishName">English name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="airlineName0"
                             #airlineName0="ngModel"
                             [(ngModel)]="airline.nameEnglish"
                             pattern="^[a-zA-Z0-9\.,-\/\\_*\s]{0,}$"
                             required>

                    <div *ngIf="!airlineName0?.valid && (airlineName0?.dirty || airlineName0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="airlineName0.errors.required"
                           i18n="ref|Modal airline - error english name is req@@ref.main.modal.airline.err.englishNameReq">
                        English name is required
                      </div>
                      <div *ngIf="airlineName0.errors.pattern"
                           i18n="ref|Modal airline - error english name is english only@@ref.main.modal.airline.err.englishNameEnOnly">
                        Name is English only
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal airline - local name@@ref.main.modal.airline.field.localName">Local name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="airlineName1"
                             #airlineName1="ngModel"
                             [(ngModel)]="airline.nameLocal"
                             required>

                    <div *ngIf="!airlineName1?.valid && (airlineName1?.dirty || airlineName1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="airlineName1.errors.required"
                           i18n="ref|Modal airline - error local name is req@@ref.main.modal.airline.err.localNameReq">
                        Local name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <label class="w-100"
                               i18n="ref|Modal airline - weight@@ref.main.modal.airline.field.weight">
                          Weight
                        </label>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-2">
                        <label class="w-100"
                               i18n="ref|Modal airline - adult winter@@ref.main.modal.airline.field.adultWinter">
                          Adult winter
                        </label>
                          <input type="number"
                                 class="form-control"
                                 name="airlineWeightAdult1"
                                 [(ngModel)]="airline.weightAdultWinter">

                      </div>

                      <div class="col-2">
                        <label class="w-100"
                               i18n="ref|Modal airline - adult summer@@ref.main.modal.airline.field.adultSummer">
                          Adult summer
                        </label>
                          <input type="number"
                                 class="form-control"
                                 name="airlineWeightAdult0"
                                 [(ngModel)]="airline.weightAdultSummer">

                      </div>

                      <div class=" col-2">
                        <label class="w-100"
                               i18n="ref|Modal airline - child winter@@ref.main.modal.airline.field.childWinter">
                          Child winter
                        </label>
                          <input type="number"
                                 class="form-control"
                                 name="airlineWeightChild1"
                                 [(ngModel)]="airline.weightChildWinter">

                      </div>
                      <div class="col-2">
                        <label class="w-100"
                               i18n="ref|Modal airline - child summer@@ref.main.modal.airline.field.childSummer">
                          Child summer
                        </label>
                          <input type="number"
                                 class="form-control"
                                 name="airlineWeightChild0"
                                 [(ngModel)]="airline.weightChildSummer">

                      </div>

                      <div class=" col-2">
                        <label class="w-100"
                               i18n="ref|Modal airline - Infant winter@@ref.main.modal.airline.field.infantWinter">
                          Infant winter
                        </label>
                          <input type="number"
                                 class="form-control"
                                 name="airlineWeightInfant1"
                                 [(ngModel)]="airline.weightInfantWinter">

                      </div>

                      <div class="col-2">
                        <label class="w-100"
                               i18n="ref|Modal airline - infant summer@@ref.main.modal.airline.field.infantSummer">
                          Infant summer
                        </label>
                          <input type="number"
                                 class="form-control"
                                 name="airlineWeightInfant0"
                                 [(ngModel)]="airline.weightInfantSummer">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mm-2">
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal airline - field country@@ref.main.modal.airline.field.country">Country</span><span class="required-asterisk">*</span>
                    </label>
                    <ng-select class="form-control custom-sm p-0"
                               [items]="countriesForSelectList"
                               bindLabel="names"
                               bindValue="id"
                               [(ngModel)]="airline.countryId"
                               #airlineCountry="ngModel"
                               appendTo="body"
                               name="airlineCountry"
                               [virtualScroll]="true"
                               [loading]="countriesLoading"
                               (scroll)="onScrollCountries($event)"
                               (scrollToEnd)="onScrollCountriesToEnd()"
                               required>
                      <ng-template ng-option-tmp let-item="item">
                        {{item.names}} ({{item.iata}} / {{item.icao}})
                      </ng-template>
                    </ng-select>
                    <!-- <select class="form-control form-control-sm"
                            [ngModel]="airline.countryId"
                            name="airlineCountry"
                            #airlineCountry="ngModel"
                            (input)="airline.countryId = +$event.target.value"
                            required>
                      <option *ngFor="let country of countries" [selected]="country.id == airline.countryId" value="{{country.id}}">
                        {{country.names}} ({{country.iata}} / {{country.icao}})
                      </option>
                    </select> -->
                    <div *ngIf="!airlineCountry?.valid && (airlineCountry?.dirty || airlineCountry?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="airlineCountry.errors.required"
                           i18n="ref|Modal airline - error country is required@@ref.main.modal.airline.err.countryReq">
                        Country is required
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="pt-2 d-flex align-items-center">
                      <input type="checkbox" name="airlineInternational" id="airlineInternational"
                             [(ngModel)]="airline.international">
                      <label class="ml-2 mb-0"
                             i18n="ref|Modal airline - field international@@ref.main.modal.airline.field.international">
                        International
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal airline - date range start@@ref.main.modal.airline.field.dtRangeStart">Date range start</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="airlineDtRange0"
                             #airlineDtRange0="ngModel"
                             [ngModel]="airline.dtRangeStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="airline.dtRangeStart = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!airlineDtRange0?.valid && (airlineDtRange0?.dirty || airlineDtRange0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="airlineDtRange0.errors.required"
                           i18n="ref|Modal airline - error date range start is req@@ref.main.modal.airline.err.dtRangeStartReq">
                        Date range start is required
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal airline - date rande finish@@ref.main.modal.airline.field.dtRangeFinish">Date range finish</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="airlineDtRange1"
                             #airlineDtRange1="ngModel"
                             [ngModel]="airline.dtRangeFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="airline.dtRangeFinish = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!airlineDtRange1?.valid && (airlineDtRange1?.dirty || airlineDtRange1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="airlineDtRange1.errors.required"
                           i18n="ref|Modal airline - error date range finish is req@@ref.main.modal.airline.err.dtRangeFinishReq">
                        Date range finish is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="row w-100 m-0">
                  <div class="col-6 p-0">
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="duplicateReference(airline)"
                            i18n="ref|Modal airline - button duplicate@@ref.main.modal.airline.button.duplicate">
                      Duplicate
                    </button>
                    <button type="button"
                            *ngIf="showButton('state-update')"
                            class="btn btn-red btn-sm"
                            (click)="confirmAction(deleteAirline)"
                            i18n="ref|Modal airline - button delete@@ref.main.modal.airline.button.delete">
                      Delete
                    </button>
                  </div>
                  <div class="col-6 p-0 text-right">
                    <button type="button" *ngIf="showButton('state-append')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="addAirline()"
                            i18n="ref|Modal airline - button append@@ref.main.modal.airline.button.append">
                      Append
                    </button>
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="updateAirline(airline.id)"
                            i18n="ref|Modal airline - button save@@ref.main.modal.airline.button.save">
                      Save
                    </button>
                    <button type="button"
                            class="btn btn-secondary btn-sm"
                            data-dismiss="modal"
                            i18n="ref|Modal airline - button close@@ref.main.modal.airline.button.close">
                      Close
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="errorMessage !== '' && activeTab == 'tab-airlines'" class="ms-modal-message">
                <div class="modal-content w-50">
                  <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                                'bg-warning': errorType == 'warning',
                                                'bg-danger': errorType == 'error'}">
                    <h5 *ngIf="errorType == 'info'" class="modal-title"
                        i18n="ref|Modal airline error - header information@@ref.main.modal.airline.errMes.header.inf">
                      Information
                    </h5>
                    <h5 *ngIf="errorType == 'warning'" class="modal-title"
                        i18n="ref|Modal airline error - header warning@@ref.main.modal.airline.errMes.header.warn">
                      Warning
                    </h5>
                    <h5 *ngIf="errorType == 'error'" class="modal-title"
                        i18n="ref|Modal airline error - header error@@ref.main.modal.airline.errMes.header.err">
                      Error
                    </h5>
                    <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    {{errorMessage}}
                  </div>
                  <div class="modal-footer">
                    <button type="button"
                            class="btn btn-sm btn-blue"
                            *ngIf="userAction == 'error'"
                            (click)="clearErrorMess()"
                            i18n="ref|Modal airline error - button edit@@ref.main.modal.airline.errMes.button.edit">
                      Edit
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-secondary"
                            *ngIf="userAction == 'error'"
                            (click)="closeWithoutSaving()"
                            i18n="ref|Modal airline error - button close@@ref.main.modal.airline.errMes.button.close">
                      Close without saving
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-blue"
                            (click)="executableFunction(airline.id)"
                            i18n="adm|Modal Master Data - delete yes@@ref.main.modal.button.deleteYes">
                      Yes
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-secondary"
                            (click)="clearErrorMess()"
                            i18n="adm|Modal Master Data - delete no@@ref.main.modal.button.deleteNo">
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- Modal aircraft-type -->
    <div *ngIf="showTab('tab-aircraft-types')">
      <form #aircraftTypeForm="ngForm">
        <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
             aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content bg-light-gray">
              <div class="loading" *ngIf="loading">
                <div class="cssload-spin-box"></div>
              </div>
              <div class="modal-header">
                <h5 *ngIf="showButton('state-append')"
                    class="modal-title">
                  <span i18n="ref|Modal aircraft type - header for append mode@@ref.main.modal.aircraftType.headerAppend">Aircraft type append</span>&nbsp;{{aircraftType.iata}}
                </h5>
                <h5 *ngIf="showButton('state-update')"
                    class="modal-title">
                  <span i18n="ref|Modal aircraft type - header for edit mode@@ref.main.modal.aircraftType.headerEdit">Edit aircraft type</span>&nbsp;{{aircraftType.iata}}
                </h5>
                <button #aircraftTypeFormFade type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal aircraft type - field iata code@@ref.main.modal.aircraftType.field.iataCode">IATA code</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="aircraftTypeIata"
                             #aircraftTypeIata="ngModel"
                             [(ngModel)]="aircraftType.iata"
                             minlength="3"
                             maxlength="3"
                             pattern="^[a-zA-Z0-9]{0,}$"
                             required>

                    <div *ngIf="!aircraftTypeIata?.valid && (aircraftTypeIata?.dirty || aircraftTypeIata?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="aircraftTypeIata.errors.required"
                           i18n="ref|Modal aircraft type - error iata code is required@@ref.main.modal.aircraftType.err.iataCodeReq">
                        IATA code is required
                      </div>
                      <div *ngIf="aircraftTypeIata.errors.minlength">
                        <span i18n="ref|Modal aircraft type - error iata code is min length@@ref.main.modal.aircraftType.err.iataCodeMinLength">IATA code minimum length is</span>&nbsp;{{aircraftTypeIata.errors.minlength?.requiredLength}}
                      </div>
                      <div *ngIf="aircraftTypeIata.errors.pattern"
                           i18n="ref|Modal aircraft type - error iata code is english only@@ref.main.modal.aircraftType.err.iataCodeEnOnly">
                        IATA code is English only
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal aircraft type - field icao code@@ref.main.modal.aircraftType.field.icaoCode">ICAO code</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="aircraftTypeIcao"
                             #aircraftTypeIcao="ngModel"
                             [(ngModel)]="aircraftType.icao"
                             minlength="4"
                             maxlength="4"
                             pattern="^[a-zA-Z0-9]{0,}$"
                             required>
                    <div *ngIf="!aircraftTypeIcao?.valid && (aircraftTypeIcao?.dirty || aircraftTypeIcao?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="aircraftTypeIcao.errors.required"
                           i18n="ref|Modal aircraft type - error icao code is required@@ref.main.modal.aircraftType.err.icaoCodeReq">
                        ICAO code is required
                      </div>
                      <div *ngIf="aircraftTypeIcao.errors.minlength">
                        <span i18n="ref|Modal aircraft type - error icao code is min length@@ref.main.modal.aircraftType.err.icaoCodeMinLength">ICAO code minimum length is</span>&nbsp;{{aircraftTypeIcao.errors.minlength?.requiredLength}}
                      </div>
                      <div *ngIf="aircraftTypeIcao.errors.pattern"
                           i18n="ref|Modal aircraft type - error icao code is english only@@ref.main.modal.aircraftType.err.icaoCodeEnOnly">
                        ICAO code is English only
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal aircraft type - english name@@ref.main.modal.aircraftType.field.englishName">English name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="aircraftTypeName0"
                             #aircraftTypeName0="ngModel"
                             [(ngModel)]="aircraftType.nameEnglish"
                             pattern="^[a-zA-Z0-9\.,-\/\\_*\s]{0,}$"
                             required>

                    <div *ngIf="!aircraftTypeName0?.valid && (aircraftTypeName0?.dirty || aircraftTypeName0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="aircraftTypeName0.errors.required"
                           i18n="ref|Modal aircraft type - error english name is req@@ref.main.modal.aircraftType.err.englishNameReq">
                        English name is required
                      </div>
                      <div *ngIf="aircraftTypeName0.errors.pattern"
                           i18n="ref|Modal aircraft type - error english name is english only@@ref.main.modal.aircraftType.err.englishNameEnOnly">
                        Name is English only
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal aircraft type - local name@@ref.main.modal.aircraftType.field.localName">Local name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="aircraftTypeName1"
                             #aircraftTypeName1="ngModel"
                             [(ngModel)]="aircraftType.nameLocal"
                             required>

                    <div *ngIf="!aircraftTypeName1?.valid && (aircraftTypeName1?.dirty || aircraftTypeName1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="aircraftTypeName1.errors.required"
                           i18n="ref|Modal aircraft type - error local name is req@@ref.main.modal.aircraftType.err.localNameReq">
                        Local name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-4">
                    <label class="w-100"
                           i18n="ref|Modal aircraft type - MTOW@@ref.main.modal.aircraftType.field.MTOW">
                      MTOW
                    </label>
                      <input type="text"
                             class="form-control"
                             name="aircraftTypeMtow"
                             #aircraftTypeMtow="ngModel"
                             [ngModel]="aircraftType.mtow"
                             (input)="aircraftType.mtow = +$event.target.value"
                             maxlength="5"
                             pattern="^[\d]{0,5}$">

                    <div *ngIf="!aircraftTypeMtow?.valid && (aircraftTypeMtow?.dirty || aircraftTypeMtow?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="aircraftTypeMtow.errors.pattern"
                           i18n="ref|Modal aircraft type - error MTOW@@ref.main.modal.aircraftType.err.mtowIsKgRange">
                        MTOW weight in kilograms up to 99 999
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <label class="w-100"
                           i18n="ref|Modal aircraft type - kind@@ref.main.modal.aircraftType.field.kind">
                      Kind
                    </label>
                      <ng-select class="form-control custom p-0"
                                 [items]="aircraftKindsForList"
                                 bindLabel="names"
                                 bindValue="id"
                                 [(ngModel)]="aircraftType.kindId"
                                 #aircraftTypeKind="ngModel"
                                 name="aircraftTypeKind"
                                 required>
                      </ng-select>
                    <div *ngIf="!aircraftTypeKind?.valid && (aircraftTypeKind?.dirty || aircraftTypeKind?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="aircraftTypeKind.errors.required"
                           i18n="ref|Modal aircraft type - error kind is required@@ref.main.modal.aircraftType.err.kindReq">
                        Kind is required
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <label class="w-100"
                           i18n="ref|Modal aircraft type - class@@ref.main.modal.aircraftType.field.class">
                      Class
                    </label>
                    <ng-select class="form-control custom p-0"
                               [items]="aircraftClassesForList"
                               bindLabel="names"
                               bindValue="id"
                               [(ngModel)]="aircraftType.classId"
                               #aircraftClassKind="ngModel"
                               name="aircraftClassKind"
                               required>
                    </ng-select>
                    <div *ngIf="!aircraftClassKind?.valid && (aircraftClassKind?.dirty || aircraftClassKind?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="aircraftTypeKind.errors.required"
                           i18n="ref|Modal aircraft type - error class is required@@ref.main.modal.aircraftType.err.classReq">
                        Class is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal aircraft type - date range start@@ref.main.modal.aircraftType.field.dtRangeStart">Date range start</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="aircraftTypeDtRange0"
                             #aircraftTypeDtRange0="ngModel"
                             [ngModel]="aircraftType.dtRangeStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="aircraftType.dtRangeStart = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!aircraftTypeDtRange0?.valid && (aircraftTypeDtRange0?.dirty || aircraftTypeDtRange0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="aircraftTypeDtRange0.errors.required"
                           i18n="ref|Modal aircraft type - error date range start is req@@ref.main.modal.aircraftType.err.dtRangeStartReq">
                        Date range start is required
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal aircraft type - date rande finish@@ref.main.modal.aircraftType.field.dtRangeFinish">Date range finish</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="aircraftTypeDtRange1"
                             #aircraftTypeDtRange1="ngModel"
                             [ngModel]="aircraftType.dtRangeFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="aircraftType.dtRangeFinish = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!aircraftTypeDtRange1?.valid && (aircraftTypeDtRange1?.dirty || aircraftTypeDtRange1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="aircraftTypeDtRange1.errors.required"
                           i18n="ref|Modal aircraft type - error date range finish is req@@ref.main.modal.aircraftType.err.dtRangeFinishReq">
                        Date range finish is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="row w-100 m-0">
                  <div class="col-6 p-0">
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="duplicateReference(aircraftType)"
                            i18n="ref|Modal aircraft type - button duplicate@@ref.main.modal.aircraftType.button.duplicate">
                      Duplicate
                    </button>
                    <button type="button"
                            *ngIf="showButton('state-update')"
                            class="btn btn-red btn-sm"
                            (click)="confirmAction(deleteAircraftType)"
                            i18n="ref|Modal aircraft type - button delete@@ref.main.modal.aircraftType.button.delete">
                      Delete
                    </button>
                  </div>
                  <div class="col-6 p-0 text-right">
                    <button type="button" *ngIf="showButton('state-append')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="addAircraftType()"
                            i18n="ref|Modal aircraft type - button append@@ref.main.modal.aircraftType.button.append">
                      Append
                    </button>
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="updateAircraftType(aircraftType.id)"
                            i18n="ref|Modal aircraft type - button save@@ref.main.modal.aircraftType.button.save">
                      Save
                    </button>
                    <button type="button"
                            class="btn btn-secondary btn-sm"
                            data-dismiss="modal"
                            i18n="ref|Modal aircraft type - button close@@ref.main.modal.aircraftType.button.close">
                      Close
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="errorMessage !== '' && activeTab == 'tab-aircraft-types'" class="ms-modal-message">
                <div class="modal-content w-50">
                  <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                                'bg-warning': errorType == 'warning',
                                                'bg-danger': errorType == 'error'}">
                    <h5 *ngIf="errorType == 'info'" class="modal-title"
                        i18n="ref|Modal aircraft type error - header information@@ref.main.modal.aircraftType.errMes.header.inf">
                      Information
                    </h5>
                    <h5 *ngIf="errorType == 'warning'" class="modal-title"
                        i18n="ref|Modal aircraft type error - header warning@@ref.main.modal.aircraftType.errMes.header.warn">
                      Warning
                    </h5>
                    <h5 *ngIf="errorType == 'error'" class="modal-title"
                        i18n="ref|Modal aircraft type error - header error@@ref.main.modal.aircraftType.errMes.header.err">
                      Error
                    </h5>
                    <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    {{errorMessage}}
                  </div>
                  <div class="modal-footer">
                    <button type="button"
                            class="btn btn-sm btn-blue"
                            *ngIf="userAction == 'error'"
                            (click)="clearErrorMess()"
                            i18n="ref|Modal aircraft type error - button edit@@ref.main.modal.aircraftType.errMes.button.edit">
                      Edit
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-secondary"
                            *ngIf="userAction == 'error'"
                            (click)="closeWithoutSaving()"
                            i18n="ref|Modal aircraft type error - button close@@ref.main.modal.aircraftType.errMes.button.close">
                      Close without saving
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-blue"
                            (click)="executableFunction(aircraftType.id)"
                            i18n="adm|Modal Master Data - delete yes@@ref.main.modal.button.deleteYes">
                      Yes
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-secondary"
                            (click)="clearErrorMess()"
                            i18n="adm|Modal Master Data - delete no@@ref.main.modal.button.deleteNo">
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- Modal Movements -->
    <div *ngIf="showTab('tab-movements')">
      <form #movementForm="ngForm">
        <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
             aria-labelledby="myExtraLargeModalLabel"
             aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content bg-light-gray">
              <div class="loading" *ngIf="loading">
                <div class="cssload-spin-box"></div>
              </div>
              <div class="modal-header">
                <h5 *ngIf="showButton('state-append')"
                    class="modal-title">
                  <span i18n="ref|Modal movement - header for append mode@@ref.main.modal.movement.headerAppend">Movement append</span>&nbsp;{{movement.code}}
                </h5>
                <h5 *ngIf="showButton('state-update')"
                    class="modal-title">
                  <span i18n="ref|Modal movement - header for edit mode@@ref.main.modal.movement.headerEdit">Edit movement</span>&nbsp;{{movement.code}}
                </h5>
                <button #movementFormFade type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal movement - field iata code@@ref.main.modal.movement.field.iataCode">Code</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="movementCode"
                             #movementCode="ngModel"
                             [(ngModel)]="movement.code"
                             minlength="1"
                             maxlength="1"
                             pattern="^[a-zA-Z0-9]{0,}$"
                             required>

                    <div *ngIf="!movementCode?.valid && (movementCode?.dirty || movementCode?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="movementCode.errors.required"
                           i18n="ref|Modal movement - error iata code is required@@ref.main.modal.movement.err.iataCodeReq">
                        Code is required
                      </div>
                      <div *ngIf="movementCode.errors.minlength">
                        <span i18n="ref|Modal movement - error iata code is min length@@ref.main.modal.movement.err.iataCodeMinLength">Code minimum length is</span>&nbsp;{{movementCode.errors.minlength?.requiredLength}}
                      </div>
                      <div *ngIf="movementCode.errors.pattern"
                           i18n="ref|Modal movement - error iata code is english only@@ref.main.modal.movement.err.iataCodeEnOnly">
                        Code is English only
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal movement - field country@@ref.main.modal.movement.field.regularity">Regularity</span><span class="required-asterisk">*</span>
                    </label>
                    <select class="form-control form-control-sm"
                            [ngModel]="movement.regularityId"
                            name="movementRegularity"
                            #movementRegularity="ngModel"
                            (input)="movement.regularityId = +$event.target.value"
                            required>
                      <option *ngFor="let regularitie of regularities"
                              [selected]="regularitie.id == movement.regularityId" value="{{regularitie.id}}">
                        {{regularitie.names}}
                      </option>
                    </select>
                    <div *ngIf="!movementRegularity?.valid && (movementRegularity?.dirty || movementRegularity?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="movementRegularity.errors.required"
                           i18n="ref|Modal movement - error country is required@@ref.main.modal.movement.err.regularityReq">
                        Regularity is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal movement - english name@@ref.main.modal.movement.field.englishName">English name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="movementName0"
                             #movementName0="ngModel"
                             [(ngModel)]="movement.nameEnglish"
                             pattern="^[a-zA-Z0-9\.,-\/\\_*\s]{0,}$"
                             required>

                    <div *ngIf="!movementName0?.valid && (movementName0?.dirty || movementName0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="movementName0.errors.required"
                           i18n="ref|Modal movement - error english name is req@@ref.main.modal.movement.err.englishNameReq">
                        English name is required
                      </div>
                      <div *ngIf="movementName0.errors.pattern"
                           i18n="ref|Modal movement - error english name is english only@@ref.main.modal.movement.err.englishNameEnOnly">
                        Name is English only
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal movement - local name@@ref.main.modal.movement.field.localName">Local name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="movementName1"
                             #movementName1="ngModel"
                             [(ngModel)]="movement.nameLocal"
                             required>

                    <div *ngIf="!movementName1?.valid && (movementName1?.dirty || movementName1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="movementName1.errors.required"
                           i18n="ref|Modal movement - error local name is req@@ref.main.modal.movement.err.localNameReq">
                        Local name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal movement - date range start@@ref.main.modal.movement.field.dtRangeStart">Date range start</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="movementDtRange0"
                             #movementDtRange0="ngModel"
                             [ngModel]="movement.dtRangeStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="movement.dtRangeStart = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!movementDtRange0?.valid && (movementDtRange0?.dirty || movementDtRange0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="movementDtRange0.errors.required"
                           i18n="ref|Modal movement - error date range start is req@@ref.main.modal.movement.err.dtRangeStartReq">
                        Date range start is required
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal movement - date rande finish@@ref.main.modal.movement.field.dtRangeFinish">Date range finish</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="movementDtRange1"
                             #movementDtRange1="ngModel"
                             [ngModel]="movement.dtRangeFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="movement.dtRangeFinish = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!movementDtRange1?.valid && (movementDtRange1?.dirty || movementDtRange1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="movementDtRange1.errors.required"
                           i18n="ref|Modal movement - error date range finish is req@@ref.main.modal.movement.err.dtRangeFinishReq">
                        Date range finish is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="row w-100 m-0">
                  <div class="col-6 p-0">
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="duplicateReference(movement)"
                            i18n="ref|Modal movement - button duplicate@@ref.main.modal.movement.button.duplicate">
                      Duplicate
                    </button>
                    <button type="button"
                            *ngIf="showButton('state-update')"
                            class="btn btn-red btn-sm"
                            (click)="confirmAction(deleteMovement)"
                            i18n="ref|Modal movement - button delete@@ref.main.modal.movement.button.delete">
                      Delete
                    </button>
                  </div>
                  <div class="col-6 p-0 text-right">
                    <button type="button" *ngIf="showButton('state-append')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="addMovement()"
                            i18n="ref|Modal movement - button append@@ref.main.modal.movement.button.append">
                      Append
                    </button>
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="updateMovement(movement.id)"
                            i18n="ref|Modal movement - button save@@ref.main.modal.movement.button.save">
                      Save
                    </button>
                    <button type="button"
                            class="btn btn-secondary btn-sm"
                            data-dismiss="modal"
                            i18n="ref|Modal movement - button close@@ref.main.modal.movement.button.close">
                      Close
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="errorMessage !== '' && activeTab == 'tab-movements'" class="ms-modal-message">
                <div class="modal-content w-50">
                  <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                                'bg-warning': errorType == 'warning',
                                                'bg-danger': errorType == 'error'}">
                    <h5 *ngIf="errorType == 'info'"
                        class="modal-title"
                        i18n="ref|Modal movement error - header information@@ref.main.modal.movement.errMes.header.inf">
                      Information
                    </h5>
                    <h5 *ngIf="errorType == 'warning'"
                        class="modal-title"
                        i18n="ref|Modal movement error - header warning@@ref.main.modal.movement.errMes.header.warn">
                      Warning
                    </h5>
                    <h5 *ngIf="errorType == 'error'"
                        class="modal-title"
                        i18n="ref|Modal movement error - header error@@ref.main.modal.movement.errMes.header.err">
                      Error
                    </h5>
                    <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    {{errorMessage}}
                  </div>
                  <div class="modal-footer">
                    <button type="button"
                            class="btn btn-sm btn-blue"
                            *ngIf="userAction == 'error'"
                            (click)="clearErrorMess()"
                            i18n="ref|Modal movement error - button edit@@ref.main.modal.movement.errMes.button.edit">
                      Edit
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-secondary"
                            *ngIf="userAction == 'error'"
                            (click)="closeWithoutSaving()"
                            i18n="ref|Modal movement error - button close@@ref.main.modal.movement.errMes.button.close">
                      Close without saving
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-blue"
                            (click)="executableFunction(movement.id)"
                            i18n="adm|Modal Master Data - delete yes@@ref.main.modal.button.deleteYes">
                      Yes
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-secondary"
                            (click)="clearErrorMess()"
                            i18n="adm|Modal Master Data - delete no@@ref.main.modal.button.deleteNo">
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- Modal Delays -->
    <div *ngIf="showTab('tab-delays')">
      <form #delayForm="ngForm">
        <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
             aria-labelledby="myExtraLargeModalLabel"
             aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content bg-light-gray">
              <div class="loading" *ngIf="loading">
                <div class="cssload-spin-box"></div>
              </div>
              <div class="modal-header">
                <h5 *ngIf="showButton('state-append')"
                    class="modal-title">
                  <span i18n="ref|Modal delay - header for append mode@@ref.main.modal.delay.headerAppend">Delay append</span>&nbsp;{{delay.nameLocal}}
                </h5>
                <h5 *ngIf="showButton('state-update')"
                    class="modal-title">
                  <span i18n="ref|Modal delay - header for edit mode@@ref.main.modal.delay.headerEdit">Edit delay</span>&nbsp;{{delay.nameLocal}}
                </h5>
                <button #delayFormFade type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row mb-2">
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal delay - field iata num@@ref.main.modal.delay.field.iataNum">IATA number</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="number"
                             class="form-control"
                             name="delayIataNum"
                             #delayIataNum="ngModel"
                             [(ngModel)]="delay.iataNum"
                             pattern="^[0-9]{0,}$"
                             required>
                    <div *ngIf="!delayIataNum?.valid && (delayIataNum?.dirty || delayIataNum?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="delayIataNum.errors.required"
                           i18n="ref|Modal delay - error iata num is required@@ref.main.modal.delay.err.iataNumReq">
                        IATA number is required
                      </div>
                      <div *ngIf="delayIataNum.errors.pattern"
                           i18n="ref|Modal delay - error iata is number only@@ref.main.modal.delay.err.iataNumOnly">
                        IATA is number only
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal delay - field iata char@@ref.main.modal.delay.field.iataChar">IATA char</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="delayIataChar"
                             #delayIataChar="ngModel"
                             [(ngModel)]="delay.iataChar"
                             minlength="1"
                             maxlength="2"
                             pattern="^[a-zA-Z]{0,}$"
                             required>
                    <div *ngIf="!delayIataChar?.valid && (delayIataChar?.dirty || delayIataChar?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="delayIataChar.errors.required"
                           i18n="ref|Modal delay - error iata char is required@@ref.main.modal.delay.err.iataCharReq">
                        IATA char is required
                      </div>
                      <div *ngIf="delayIataChar.errors.minlength">
                        <span i18n="ref|Modal delay - error iata char is min length@@ref.main.modal.delay.err.iataCharMinLength">IATA char minimum length is</span>&nbsp;{{delayIataChar.errors.minlength?.requiredLength}}
                      </div>
                      <div *ngIf="delayIataChar.errors.pattern"
                           i18n="ref|Modal delay - error iata char is char only@@ref.main.modal.delay.err.iataCharnOnly">
                        IATA char is char only
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal delay - english name@@ref.main.modal.delay.field.englishName">English name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="delayName0"
                             #delayName0="ngModel"
                             [(ngModel)]="delay.nameEnglish"
                             pattern="^[a-zA-Z0-9\.,-\/\\_*\s]{0,}$"
                             required>

                    <div *ngIf="!delayName0?.valid && (delayName0?.dirty || delayName0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="delayName0.errors.required"
                           i18n="ref|Modal delay - error english name is req@@ref.main.modal.delay.err.englishNameReq">
                        English name is required
                      </div>
                      <div *ngIf="delayName0.errors.pattern"
                           i18n="ref|Modal delay - error english name is english only@@ref.main.modal.delay.err.englishNameEnOnly">
                        Name is English only
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal delay - local name@@ref.main.modal.delay.field.localName">Local name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="delayName1"
                             #delayName1="ngModel"
                             [(ngModel)]="delay.nameLocal"
                             required>

                    <div *ngIf="!delayName1?.valid && (delayName1?.dirty || delayName1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="delayName1.errors.required"
                           i18n="ref|Modal delay - error local name is req@@ref.main.modal.delay.err.localNameReq">
                        Local name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal delay - date range start@@ref.main.modal.delay.field.dtRangeStart">Date range start</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="delayDtRange0"
                             #delayDtRange0="ngModel"
                             [ngModel]="delay.dtRangeStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="delay.dtRangeStart = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!delayDtRange0?.valid && (delayDtRange0?.dirty || delayDtRange0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="delayDtRange0.errors.required"
                           i18n="ref|Modal delay - error date range start is req@@ref.main.modal.delay.err.dtRangeStartReq">
                        Date range start is required
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal delay - date rande finish@@ref.main.modal.delay.field.dtRangeFinish">Date range finish</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="delayDtRange1"
                             #delayDtRange1="ngModel"
                             [ngModel]="delay.dtRangeFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="delay.dtRangeFinish = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!delayDtRange1?.valid && (delayDtRange1?.dirty || delayDtRange1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="delayDtRange1.errors.required"
                           i18n="ref|Modal delay - error date range finish is req@@ref.main.modal.delay.err.dtRangeFinishReq">
                        Date range finish is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="row w-100 m-0">
                  <div class="col-6 p-0">
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="duplicateReference(delay)"
                            i18n="ref|Modal delay - button duplicate@@ref.main.modal.delay.button.duplicate">
                      Duplicate
                    </button>
                    <button type="button"
                            *ngIf="showButton('state-update')"
                            class="btn btn-red btn-sm"
                            (click)="confirmAction(deleteDelay)"
                            i18n="ref|Modal delay - button delete@@ref.main.modal.delay.button.delete">
                      Delete
                    </button>
                  </div>
                  <div class="col-6 p-0 text-right">
                    <button type="button" *ngIf="showButton('state-append')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="addDelay()"
                            i18n="ref|Modal delay - button append@@ref.main.modal.delay.button.append">
                      Append
                    </button>
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="updateDelay(delay.id)"
                            i18n="ref|Modal delay - button save@@ref.main.modal.delay.button.save">
                      Save
                    </button>
                    <button type="button"
                            class="btn btn-secondary btn-sm"
                            data-dismiss="modal"
                            i18n="ref|Modal delay - button close@@ref.main.modal.delay.button.close">
                      Close
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="errorMessage !== '' && activeTab == 'tab-delays'" class="ms-modal-message">
                <div class="modal-content w-50">
                  <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                                'bg-warning': errorType == 'warning',
                                                'bg-danger': errorType == 'error'}">
                    <h5 *ngIf="errorType == 'info'"
                        class="modal-title"
                        i18n="ref|Modal delay error - header information@@ref.main.modal.delay.errMes.header.inf">
                      Information
                    </h5>
                    <h5 *ngIf="errorType == 'warning'"
                        class="modal-title"
                        i18n="ref|Modal delay error - header warning@@ref.main.modal.delay.errMes.header.warn">
                      Warning
                    </h5>
                    <h5 *ngIf="errorType == 'error'"
                        class="modal-title"
                        i18n="ref|Modal delay error - header error@@ref.main.modal.delay.errMes.header.err">
                      Error
                    </h5>
                    <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    {{errorMessage}}
                  </div>
                  <div class="modal-footer">
                    <button type="button"
                            class="btn btn-sm btn-blue"
                            *ngIf="userAction == 'error'"
                            (click)="clearErrorMess()"
                            i18n="ref|Modal delay error - button edit@@ref.main.modal.delay.errMes.button.edit">
                      Edit
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-secondary"
                            *ngIf="userAction == 'error'"
                            (click)="closeWithoutSaving()"
                            i18n="ref|Modal delay error - button close@@ref.main.modal.delay.errMes.button.close">
                      Close without saving
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-blue"
                            (click)="executableFunction(delay.id)"
                            i18n="adm|Modal Master Data - delete yes@@ref.main.modal.button.deleteYes">
                      Yes
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-secondary"
                            (click)="clearErrorMess()"
                            i18n="adm|Modal Master Data - delete no@@ref.main.modal.button.deleteNo">
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- Modal Delay Sources -->
    <div *ngIf="showTab('tab-delay-sources')">
      <form #delaySourceForm="ngForm">
        <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
             aria-labelledby="myExtraLargeModalLabel"
             aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content bg-light-gray">
              <div class="loading" *ngIf="loading">
                <div class="cssload-spin-box"></div>
              </div>
              <div class="modal-header">
                <h5 *ngIf="showButton('state-append')"
                    class="modal-title">
                  <span i18n="ref|Modal delay-source - header for append mode@@ref.main.modal.delaySource.headerAppend">Delay source append</span>&nbsp;{{delaySource.nameLocal}}
                </h5>
                <h5 *ngIf="showButton('state-update')"
                    class="modal-title">
                  <span i18n="ref|Modal delay-source - header for edit mode@@ref.main.modal.delaySource.headerEdit">Edit delay source</span>&nbsp;{{delaySource.nameLocal}}
                </h5>
                <button #delaySourceFormFade type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal delay-source - english name@@ref.main.modal.delaySource.field.englishName">English name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="delaySourceName0"
                             #delaySourceName0="ngModel"
                             [(ngModel)]="delaySource.nameEnglish"
                             pattern="^[a-zA-Z0-9\.,-\/\\_*\s]{0,}$"
                             required>

                    <div *ngIf="!delaySourceName0?.valid && (delaySourceName0?.dirty || delaySourceName0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="delaySourceName0.errors.required"
                           i18n="ref|Modal delay-source - error english name is req@@ref.main.modal.delaySource.err.englishNameReq">
                        English name is required
                      </div>
                      <div *ngIf="delaySourceName0.errors.pattern"
                           i18n="ref|Modal delay-source - error english name is english only@@ref.main.modal.delaySource.err.englishNameEnOnly">
                        Name is English only
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal delay-source - local name@@ref.main.modal.delaySource.field.localName">Local name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="delaySourceName1"
                             #delaySourceName1="ngModel"
                             [(ngModel)]="delaySource.nameLocal"
                             required>

                    <div *ngIf="!delaySourceName1?.valid && (delaySourceName1?.dirty || delaySourceName1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="delaySourceName1.errors.required"
                           i18n="ref|Modal delay-source - error local name is req@@ref.main.modal.delaySource.err.localNameReq">
                        Local name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal delay-source - date range start@@ref.main.modal.delaySource.field.dtRangeStart">Date range start</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="delaySourceDtRange0"
                             #delaySourceDtRange0="ngModel"
                             [ngModel]="delaySource.dtRangeStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="delaySource.dtRangeStart = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!delaySourceDtRange0?.valid && (delaySourceDtRange0?.dirty || delaySourceDtRange0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="delaySourceDtRange0.errors.required"
                           i18n="ref|Modal delay-source - error date range start is req@@ref.main.modal.delaySource.err.dtRangeStartReq">
                        Date range start is required
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal delay-source - date rande finish@@ref.main.modal.delaySource.field.dtRangeFinish">Date range finish</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="delaySourceDtRange1"
                             #delaySourceDtRange1="ngModel"
                             [ngModel]="delaySource.dtRangeFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="delaySource.dtRangeFinish = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!delaySourceDtRange1?.valid && (delaySourceDtRange1?.dirty || delaySourceDtRange1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="delaySourceDtRange1.errors.required"
                           i18n="ref|Modal delay-source - error date range finish is req@@ref.main.modal.delaySource.err.dtRangeFinishReq">
                        Date range finish is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="row w-100 m-0">
                  <div class="col-6 p-0">
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="duplicateReference(delaySource)"
                            i18n="ref|Modal delay-source - button duplicate@@ref.main.modal.delaySource.button.duplicate">
                      Duplicate
                    </button>
                    <button type="button"
                            *ngIf="showButton('state-update')"
                            class="btn btn-red btn-sm"
                            (click)="confirmAction(deleteDelaySource)"
                            i18n="ref|Modal delay-source - button delete@@ref.main.modal.delaySource.button.delete">
                      Delete
                    </button>
                  </div>
                  <div class="col-6 p-0 text-right">
                    <button type="button" *ngIf="showButton('state-append')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="addDelaySource()"
                            i18n="ref|Modal delay-source - button append@@ref.main.modal.delaySource.button.append">
                      Append
                    </button>
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="updateDelaySource(delaySource.id)"
                            i18n="ref|Modal delay-source - button save@@ref.main.modal.delaySource.button.save">
                      Save
                    </button>
                    <button type="button"
                            class="btn btn-secondary btn-sm"
                            data-dismiss="modal"
                            i18n="ref|Modal delay-source - button close@@ref.main.delaySource.delay.button.close">
                      Close
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="errorMessage !== '' && activeTab == 'tab-delay-sources'" class="ms-modal-message">
                <div class="modal-content w-75">
                  <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                                'bg-warning': errorType == 'warning',
                                                'bg-danger': errorType == 'error'}">
                    <h5 *ngIf="errorType == 'info'"
                        class="modal-title"
                        i18n="ref|Modal delay-source error - header information@@ref.main.modal.delaySource.errMes.header.inf">
                      Information
                    </h5>
                    <h5 *ngIf="errorType == 'warning'"
                        class="modal-title"
                        i18n="ref|Modal delay-source error - header warning@@ref.main.modal.delaySource.errMes.header.warn">
                      Warning
                    </h5>
                    <h5 *ngIf="errorType == 'error'"
                        class="modal-title"
                        i18n="ref|Modal delay-source error - header error@@ref.main.modal.delaySource.errMes.header.err">
                      Error
                    </h5>
                    <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    {{errorMessage}}
                  </div>
                  <div class="modal-footer">
                    <button type="button"
                            class="btn btn-sm btn-blue"
                            *ngIf="userAction == 'error'"
                            (click)="clearErrorMess()"
                            i18n="ref|Modal delay-source error - button edit@@ref.main.modal.delaySource.errMes.button.edit">
                      Edit
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-secondary"
                            *ngIf="userAction == 'error'"
                            (click)="closeWithoutSaving()"
                            i18n="ref|Modal delay-source error - button close@@ref.main.modal.delaySource.errMes.button.close">
                      Close without saving
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-blue"
                            (click)="executableFunction(delaySource.id)"
                            i18n="adm|Modal Master Data - delete yes@@ref.main.modal.button.deleteYes">
                      Yes
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-secondary"
                            (click)="clearErrorMess()"
                            i18n="adm|Modal Master Data - delete no@@ref.main.modal.button.deleteNo">
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- Modal Seasons -->
    <div *ngIf="showTab('tab-seasons')">
      <form #seasonForm="ngForm">
        <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
             aria-labelledby="myExtraLargeModalLabel"
             aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content bg-light-gray">
              <div class="loading" *ngIf="loading">
                <div class="cssload-spin-box"></div>
              </div>
              <div class="modal-header">
                <h5 *ngIf="showButton('state-append')"
                    class="modal-title">
                  <span i18n="ref|Modal season - header for append mode@@ref.main.modal.season.headerAppend">Season append</span>&nbsp;{{season.name}}
                </h5>
                <h5 *ngIf="showButton('state-update')"
                    class="modal-title">
                  <span i18n="ref|Modal season - header for edit mode@@ref.main.modal.season.headerEdit">Edit season</span>&nbsp;{{season.name}}
                </h5>
                <button #seasonFormFade type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row mb-2">
                  <div class=" col-3">
                    <label class="w-100">
                      <span i18n="ref|Modal season - field type@@ref.main.modal.season.field.type">Type</span><span class="required-asterisk">*</span>
                    </label>
                    <select id="inputState"
                            class="form-control form-control-sm"
                            name="seasonType"
                            #seasonType="ngModel"
                            [ngModel]="season.type"
                            (input)="season.type = +$event.target.value"
                            required>
                      <option
                        i18n="ref|Modal season - field type value winter@@ref.main.modal.season.field.typeValWinter"
                        [value]=0
                        [selected]="season.type == 0">
                        Winter
                      </option>
                      <option
                        i18n="ref|Modal season - field type value summer@@ref.main.modal.season.field.typeValSummer"
                        [value]=1
                        [selected]="season.type == 1">
                        Summer
                      </option>
                    </select>
                    <div *ngIf="!seasonType?.valid && (seasonType?.dirty || seasonType?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="seasonType.errors.required"
                           i18n="ref|Modal season - error season type is required@@ref.main.modal.season.err.SeasonTypeReq">
                        Season type is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal season - field season start@@ref.main.modal.season.field.seasonStart">Season start</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="seasonRange0"
                             #seasonRange0="ngModel"
                             [ngModel]="season.dtSeasonStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="season.dtSeasonStart = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!seasonRange0?.valid && (seasonRange0?.dirty || seasonRange0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="seasonRange0.errors.required"
                           i18n="ref|Modal season - error season date begin is required@@ref.main.modal.season.err.SeasonStartReq">
                        Season date begin is required
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal season - field season finish@@ref.main.modal.season.field.seasonFinish">Season finish</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="seasonRange1"
                             #seasonRange1="ngModel"
                             [ngModel]="season.dtSeasonFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="season.dtSeasonFinish = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!seasonRange1?.valid && (seasonRange1?.dirty || seasonRange1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="seasonRange1.errors.required"
                           i18n="ref|Modal season - error season date finish is required@@ref.main.modal.season.err.SeasonFinishReq">
                        Season date finish is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal season - date range start@@ref.main.modal.season.field.dtRangeStart">Date range start</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="seasonDtRange0"
                             #seasonDtRange0="ngModel"
                             [ngModel]="season.dtRangeStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="season.dtRangeStart = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!seasonDtRange0?.valid && (seasonDtRange0?.dirty || seasonDtRange0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="seasonDtRange0.errors.required"
                           i18n="ref|Modal season - error date range start is req@@ref.main.modal.season.err.dtRangeStartReq">
                        Date range start is required
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal season - date rande finish@@ref.main.modal.season.field.dtRangeFinish">Date range finish</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="seasonDtRange1"
                             #seasonDtRange1="ngModel"
                             [ngModel]="season.dtRangeFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="season.dtRangeFinish = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!seasonDtRange1?.valid && (seasonDtRange1?.dirty || seasonDtRange1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="seasonDtRange1.errors.required"
                           i18n="ref|Modal season - error date range finish is req@@ref.main.modal.season.err.dtRangeFinishReq">
                        Date range finish is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="row w-100 m-0">
                  <div class="col-6 p-0">
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="duplicateReference(season)"
                            i18n="ref|Modal season - button duplicate@@ref.main.modal.season.button.duplicate">
                      Duplicate
                    </button>
                    <button type="button"
                            *ngIf="showButton('state-update')"
                            class="btn btn-red btn-sm"
                            (click)="confirmAction(deleteSeason)"
                            i18n="ref|Modal season - button delete@@ref.main.modal.season.button.delete">
                      Delete
                    </button>
                  </div>
                  <div class="col-6 p-0 text-right">
                    <button type="button" *ngIf="showButton('state-append')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="addSeason()"
                            i18n="ref|Modal season - button append@@ref.main.modal.season.button.append">
                      Append
                    </button>
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="updateSeason(season.id)"
                            i18n="ref|Modal season - button save@@ref.main.modal.season.button.save">
                      Save
                    </button>
                    <button type="button"
                            class="btn btn-secondary btn-sm"
                            data-dismiss="modal"
                            i18n="ref|Modal season - button close@@ref.main.modal.season.button.close">
                      Close
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="errorMessage !== '' && activeTab == 'tab-seasons'" class="ms-modal-message">
                <div class="modal-content w-50">
                  <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                                'bg-warning': errorType == 'warning',
                                                'bg-danger': errorType == 'error'}">
                    <h5 *ngIf="errorType == 'info'"
                        class="modal-title"
                        i18n="ref|Modal season error - header information@@ref.main.modal.season.errMes.header.inf">
                      Information
                    </h5>
                    <h5 *ngIf="errorType == 'warning'"
                        class="modal-title"
                        i18n="ref|Modal season error - header warning@@ref.main.modal.season.errMes.header.warn">
                      Warning
                    </h5>
                    <h5 *ngIf="errorType == 'error'"
                        class="modal-title"
                        i18n="ref|Modal season error - header error@@ref.main.modal.season.errMes.header.err">
                      Error
                    </h5>
                    <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    {{errorMessage}}
                  </div>
                  <div class="modal-footer">
                    <button type="button"
                            class="btn btn-sm btn-blue"
                            *ngIf="userAction == 'error'"
                            (click)="clearErrorMess()"
                            i18n="ref|Modal season error - button edit@@ref.main.modal.season.errMes.button.edit">
                      Edit
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-secondary"
                            *ngIf="userAction == 'error'"
                            (click)="closeWithoutSaving()"
                            i18n="ref|Modal season error - button close@@ref.main.modal.season.errMes.button.close">
                      Close without saving
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-blue"
                            (click)="executableFunction(season.id)"
                            i18n="adm|Modal Master Data - delete yes@@ref.main.modal.button.deleteYes">
                      Yes
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-secondary"
                            (click)="clearErrorMess()"
                            i18n="adm|Modal Master Data - delete no@@ref.main.modal.button.deleteNo">
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- Modal Regions -->
    <div *ngIf="showTab('tab-regions')">
      <form #regionForm="ngForm">
        <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
             aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content bg-light-gray">
              <div class="loading" *ngIf="loading">
                <div class="cssload-spin-box"></div>
              </div>
              <div class="modal-header">
                <h5 *ngIf="showButton('state-append')"
                    class="modal-title">
                  <span i18n="ref|Modal region - header for append mode@@ref.main.modal.region.headerAppend">Region append</span>&nbsp;{{region.iata}}
                </h5>
                <h5 *ngIf="showButton('state-update')"
                    class="modal-title">
                  <span i18n="ref|Modal region - header for edit mode@@ref.main.modal.region.headerEdit">Edit region</span>&nbsp;{{region.iata}}
                </h5>
                <button #regionFormFade type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal region - field iata code@@ref.main.modal.region.field.iataCode">IATA code</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control form-control-sm"
                             name="regionIata"
                             #regionIata="ngModel"
                             [(ngModel)]="region.iata"
                             minlength="3"
                             maxlength="3"
                             pattern="^[a-zA-Z0-9]{0,}$"
                             required>

                    <div *ngIf="!regionIata?.valid && (regionIata?.dirty || regionIata?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="regionIata.errors.required"
                           i18n="ref|Modal region - error iata code is required@@ref.main.modal.region.err.iataCodeReq">
                        IATA code is required
                      </div>
                      <div *ngIf="regionIata.errors.minlength">
                        <span i18n="ref|Modal region - error iata code is min length@@ref.main.modal.region.err.iataCodeMinLength">IATA code minimum length is</span>&nbsp;{{regionIata.errors.minlength?.requiredLength}}
                      </div>
                      <div *ngIf="regionIata.errors.pattern"
                           i18n="ref|Modal region - error iata code is english only@@ref.main.modal.region.err.iataCodeEnOnly">
                        IATA code is English only
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal region - field icao code@@ref.main.modal.region.field.icaoCode">ICAO code</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="regionIcao"
                             #regionIcao="ngModel"
                             [(ngModel)]="region.icao"
                             minlength="4"
                             maxlength="4"
                             pattern="^[a-zA-Z0-9]{0,}$"
                             required>
                    <div *ngIf="!regionIcao?.valid && (regionIcao?.dirty || regionIcao?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="regionIcao.errors.required"
                           i18n="ref|Modal region - error icao code is required@@ref.main.modal.region.err.icaoCodeReq">
                        ICAO code is required
                      </div>
                      <div *ngIf="regionIcao.errors.minlength">
                        <span i18n="ref|Modal region - error icao code is min length@@ref.main.modal.region.err.icaoCodeMinLength">ICAO code minimum length is</span>&nbsp;{{regionIcao.errors.minlength?.requiredLength}}
                      </div>
                      <div *ngIf="regionIcao.errors.pattern"
                           i18n="ref|Modal region - error icao code is english only@@ref.main.modal.region.err.icaoCodeEnOnly">
                        ICAO code is English only
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal region - english name@@ref.main.modal.region.field.englishName">English name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="regionName0"
                             #regionName0="ngModel"
                             [(ngModel)]="region.nameEnglish"
                             pattern="^[a-zA-Z0-9\.,-\/\\_*\s]{0,}$"
                             required>

                    <div *ngIf="!regionName0?.valid && (regionName0?.dirty || regionName0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="regionName0.errors.required"
                           i18n="ref|Modal region - error english name is req@@ref.main.modal.region.err.englishNameReq">
                        English name is required
                      </div>
                      <div *ngIf="regionName0.errors.pattern"
                           i18n="ref|Modal region - error english name is english only@@ref.main.modal.region.err.englishNameEnOnly">
                        Name is English only
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal region - local name@@ref.main.modal.region.field.localName">Local name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="regionName1"
                             #regionName1="ngModel"
                             [(ngModel)]="region.nameLocal"
                             required>

                    <div *ngIf="!regionName1?.valid && (regionName1?.dirty || regionName1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="regionName1.errors.required"
                           i18n="ref|Modal region - error local name is req@@ref.main.modal.region.err.localNameReq">
                        Local name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal region - date range start@@ref.main.modal.region.field.dtRangeStart">Date range start</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="regionDtRange0"
                             #regionDtRange0="ngModel"
                             [ngModel]="region.dtRangeStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="region.dtRangeStart = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>
                    <div *ngIf="!regionDtRange0?.valid && (regionDtRange0?.dirty || regionDtRange0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="regionDtRange0.errors.required"
                           i18n="ref|Modal region - error date range start is req@@ref.main.modal.region.err.dtRangeStartReq">
                        Date range start is required
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal region - date rande finish@@ref.main.modal.region.field.dtRangeFinish">Date range finish</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="regionDtRange1"
                             #regionDtRange1="ngModel"
                             [ngModel]="region.dtRangeFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="region.dtRangeFinish = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!regionDtRange1?.valid && (regionDtRange1?.dirty || regionDtRange1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="regionDtRange1.errors.required"
                           i18n="ref|Modal region - error date range finish is req@@ref.main.modal.region.err.dtRangeFinishReq">
                        Date range finish is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="row w-100 m-0">
                  <div class="col-6 p-0">
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="duplicateReference(region)"
                            i18n="ref|Modal region - button duplicate@@ref.main.modal.region.button.duplicate">
                      Duplicate
                    </button>
                    <button type="button"
                            *ngIf="showButton('state-update')"
                            class="btn btn-red btn-sm"
                            (click)="confirmAction(deleteRegion)"
                            i18n="ref|Modal region - button delete@@ref.main.modal.region.button.delete">
                      Delete
                    </button>
                  </div>
                  <div class="col-6 p-0 text-right">
                    <button type="button" *ngIf="showButton('state-append')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="addRegion()"
                            i18n="ref|Modal region - button append@@ref.main.modal.region.button.append">
                      Append
                    </button>
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="updateRegion(region.id)"
                            i18n="ref|Modal region - button save@@ref.main.modal.region.button.save">
                      Save
                    </button>
                    <button type="button"
                            class="btn btn-secondary btn-sm"
                            data-dismiss="modal"
                            i18n="ref|Modal region - button close@@ref.main.modal.region.button.close">
                      Close
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="errorMessage !== '' && activeTab == 'tab-regions'" class="ms-modal-message">
                <div class="modal-content w-50">
                  <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                                'bg-warning': errorType == 'warning',
                                                'bg-danger': errorType == 'error'}">
                    <h5 *ngIf="errorType == 'info'"
                        class="modal-title"
                        i18n="ref|Modal region error - header information@@ref.main.modal.region.errMes.header.inf">
                      Information
                    </h5>
                    <h5 *ngIf="errorType == 'warning'"
                        class="modal-title"
                        i18n="ref|Modal region error - header warning@@ref.main.modal.region.errMes.header.warn">
                      Warning
                    </h5>
                    <h5 *ngIf="errorType == 'error'"
                        class="modal-title"
                        i18n="ref|Modal region error - header error@@ref.main.modal.region.errMes.header.err">
                      Error
                    </h5>
                    <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    {{errorMessage}}
                  </div>
                  <div class="modal-footer">
                    <button type="button"
                            class="btn btn-sm btn-blue"
                            *ngIf="userAction == 'error'"
                            (click)="clearErrorMess()"
                            i18n="ref|Modal region error - button edit@@ref.main.modal.region.errMes.button.edit">
                      Edit
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-secondary"
                            *ngIf="userAction == 'error'"
                            (click)="closeWithoutSaving()"
                            i18n="ref|Modal region error - button close@@ref.main.modal.region.errMes.button.close">
                      Close without saving
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-blue"
                            (click)="executableFunction(region.id)"
                            i18n="adm|Modal Master Data - delete yes@@ref.main.modal.button.deleteYes">
                      Yes
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-secondary"
                            (click)="clearErrorMess()"
                            i18n="adm|Modal Master Data - delete no@@ref.main.modal.button.deleteNo">
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- Modal Countries -->
    <div *ngIf="showTab('tab-countries')">
      <form #countryForm="ngForm">
        <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
             aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content bg-light-gray">
              <div class="loading" *ngIf="loading">
                <div class="cssload-spin-box"></div>
              </div>
              <div class="modal-header">
                <h5 *ngIf="showButton('state-append')"
                    class="modal-title">
                  <span i18n="ref|Modal country - header for append mode@@ref.main.modal.country.headerAppend">Country append</span>&nbsp;{{country.iata}}
                </h5>
                <h5 *ngIf="showButton('state-update')"
                    class="modal-title">
                  <span i18n="ref|Modal country - header for edit mode@@ref.main.modal.country.headerEdit">Edit country</span>&nbsp;{{country.iata}}
                </h5>
                <button #countryFormFade type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row mb-2">
                  <div class=" col-3">
                    <label class="w-100">
                      <span i18n="ref|Modal country - field iata code@@ref.main.modal.country.field.iataCode">IATA code</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control form-control-sm"
                             name="countryIata"
                             #countryIata="ngModel"
                             [(ngModel)]="country.iata"
                             minlength="2"
                             maxlength="2"
                             pattern="^[a-zA-Z]{0,}$"
                             required>

                    <div *ngIf="!countryIata?.valid && (countryIata?.dirty || countryIata?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="countryIata.errors.required"
                           i18n="ref|Modal country - error iata code is required@@ref.main.modal.country.err.iataCodeReq">
                        IATA code is required
                      </div>
                      <div *ngIf="countryIata.errors.minlength">
                        <span i18n="ref|Modal country - error iata code is min length@@ref.main.modal.country.err.iataCodeMinLength">IATA code minimum length is</span>&nbsp;{{countryIata.errors.minlength?.requiredLength}}
                      </div>
                      <div *ngIf="countryIata.errors.pattern"
                           i18n="ref|Modal country - error iata code is english only@@ref.main.modal.country.err.iataCodeEnOnly">
                        IATA code is English only
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <label class="w-100">
                      <span i18n="ref|Modal country - field icao code@@ref.main.modal.country.field.icaoCode">ICAO code</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="countryIcao"
                             #countryIcao="ngModel"
                             [(ngModel)]="country.icao"
                             minlength="3"
                             maxlength="3"
                             pattern="^[a-zA-Z0-9]{0,}$"
                             required>

                    <div *ngIf="!countryIcao?.valid && (countryIcao?.dirty || countryIcao?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="countryIcao.errors.required"
                           i18n="ref|Modal country - error icao code is required@@ref.main.modal.country.err.icaoCodeReq">
                        ICAO code is required
                      </div>
                      <div *ngIf="countryIcao.errors.minlength">
                        <span i18n="ref|Modal country - error icao code is min length@@ref.main.modal.country.err.icaoCodeMinLength">ICAO code minimum length is</span>&nbsp;{{countryIcao.errors.minlength?.requiredLength}}
                      </div>
                      <div *ngIf="countryIcao.errors.pattern"
                           i18n="ref|Modal country - error icao code is english only@@ref.main.modal.country.err.icaoCodeEnOnly">
                        ICAO code is English only
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal country - field region@@ref.main.modal.country.field.region">Region</span><span class="required-asterisk">*</span>
                    </label>
                    <ng-select class="form-control custom-sm p-0"
                               [items]="regionsForSelectList"
                               bindLabel="names"
                               bindValue="id"
                               [(ngModel)]="country.regionId"
                               #countryRegion="ngModel"
                               appendTo="body"
                               name="countryRegion"
                               [virtualScroll]="true"
                               [loading]="regionsLoading"
                               (scroll)="onScrollRegions($event)"
                               (scrollToEnd)="onScrollRegionsToEnd()"
                               required>
                      <ng-template ng-option-tmp let-item="item">
                        {{item.names}} ({{item.iata}} / {{item.icao}})
                      </ng-template>
                    </ng-select>
                    <!-- <select class="form-control form-control-sm"
                            [ngModel]="country.regionId"
                            name="countryRegion"
                            #countryRegion="ngModel"
                            (input)="country.regionId = +$event.target.value"
                            required>
                      <option *ngFor="let region of regions" [selected]="region.id == country.regionId" value="{{region.id}}">
                        {{region.names}} ({{region.iata}} / {{region.icao}})
                      </option>
                    </select> -->
                    <div *ngIf="!countryRegion?.valid && (countryRegion?.dirty || countryRegion?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="countryRegion.errors.required"
                           i18n="ref|Modal country - error region is required@@ref.main.modal.country.err.regionReq">
                        Region is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal country - english name@@ref.main.modal.country.field.englishName">English name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="countryName0"
                             #countryName0="ngModel"
                             [(ngModel)]="country.nameEnglish"
                             pattern="^[a-zA-Z0-9\.,-\/\\_*\s]{0,}$"
                             required>

                    <div *ngIf="!countryName0?.valid && (countryName0?.dirty || countryName0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="countryName0.errors.required"
                           i18n="ref|Modal country - error english name is req@@ref.main.modal.country.err.englishNameReq">
                        English name is required
                      </div>
                      <div *ngIf="countryName0.errors.pattern"
                           i18n="ref|Modal country - error english name is english only@@ref.main.modal.country.err.englishNameEnOnly">
                        Name is English only
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal country - local name@@ref.main.modal.country.field.localName">Local name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="countryName1"
                             #countryName1="ngModel"
                             [(ngModel)]="country.nameLocal"
                             required>

                    <div *ngIf="!countryName1?.valid && (countryName1?.dirty || countryName1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="countryName1.errors.required"
                           i18n="ref|Modal country - error local name is req@@ref.main.modal.country.err.localNameReq">
                        Local name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal country - date range start@@ref.main.modal.country.field.dtRangeStart">Date range start</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="countryDtRange0"
                             #countryDtRange0="ngModel"
                             [ngModel]="country.dtRangeStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="country.dtRangeStart = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!countryDtRange0?.valid && (countryDtRange0?.dirty || countryDtRange0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="countryDtRange0.errors.required"
                           i18n="ref|Modal country - error date range start is req@@ref.main.modal.country.err.dtRangeStartReq">
                        Date range start is required
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal country - date rande finish@@ref.main.modal.country.field.dtRangeFinish">Date range finish</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="countryDtRange1"
                             #countryDtRange1="ngModel"
                             [ngModel]="country.dtRangeFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="country.dtRangeFinish = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!countryDtRange1?.valid && (countryDtRange1?.dirty || countryDtRange1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="countryDtRange1.errors.required"
                           i18n="ref|Modal country - error date range finish is req@@ref.main.modal.country.err.dtRangeFinishReq">
                        Date range finish is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="row w-100 m-0">
                  <div class="col-6 p-0">
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="duplicateReference(country)"
                            i18n="ref|Modal country - button duplicate@@ref.main.modal.country.button.duplicate">
                      Duplicate
                    </button>
                    <button type="button"
                            *ngIf="showButton('state-update')"
                            class="btn btn-red btn-sm"
                            (click)="confirmAction(deleteCountry)"
                            i18n="ref|Modal country - button delete@@ref.main.modal.country.button.delete">
                      Delete
                    </button>
                  </div>
                  <div class="col-6 p-0 text-right">
                    <button type="button" *ngIf="showButton('state-append')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="addCountry()"
                            i18n="ref|Modal country - button append@@ref.main.modal.country.button.append">
                      Append
                    </button>
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="updateCountry(country.id)"
                            i18n="ref|Modal country - button save@@ref.main.modal.country.button.save">
                      Save
                    </button>
                    <button type="button"
                            class="btn btn-secondary btn-sm"
                            data-dismiss="modal"
                            i18n="ref|Modal country - button close@@ref.main.modal.country.button.close">
                      Close
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="errorMessage !== '' && activeTab == 'tab-countries'" class="ms-modal-message">
                <div class="modal-content w-50">
                  <div class="modal-header"
                       [ngClass]="{'bg-info': errorType == 'info',
                       'bg-warning': errorType == 'warning',
                       'bg-danger': errorType == 'error'}">
                    <h5 *ngIf="errorType == 'info'"
                        class="modal-title"
                        i18n="ref|Modal country error - header information@@ref.main.modal.country.errMes.header.inf">
                      Information
                    </h5>
                    <h5 *ngIf="errorType == 'warning'"
                        class="modal-title"
                        i18n="ref|Modal country error - header warning@@ref.main.modal.country.errMes.header.warn">
                      Warning
                    </h5>
                    <h5 *ngIf="errorType == 'error'"
                        class="modal-title"
                        i18n="ref|Modal country error - header error@@ref.main.modal.country.errMes.header.err">
                      Error
                    </h5>
                    <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    {{errorMessage}}
                  </div>
                  <div class="modal-footer">
                    <button type="button"
                            class="btn btn-sm btn-blue"
                            *ngIf="userAction == 'error'"
                            (click)="clearErrorMess()"
                            i18n="ref|Modal country error - button edit@@ref.main.modal.country.errMes.button.edit">
                      Edit
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-secondary"
                            *ngIf="userAction == 'error'"
                            (click)="closeWithoutSaving()"
                            i18n="ref|Modal country error - button close@@ref.main.modal.country.errMes.button.close">
                      Close without saving
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-blue"
                            (click)="executableFunction(country.id)"
                            i18n="adm|Modal Master Data - delete yes@@ref.main.modal.button.deleteYes">
                      Yes
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-secondary"
                            (click)="clearErrorMess()"
                            i18n="adm|Modal Master Data - delete no@@ref.main.modal.button.deleteNo">
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- Modal Cities -->
    <div *ngIf="showTab('tab-cities')">
      <form #cityForm="ngForm">
        <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
             aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content bg-light-gray">
              <div class="loading" *ngIf="loading">
                <div class="cssload-spin-box"></div>
              </div>
              <div class="modal-header">
                <h5 *ngIf="showButton('state-append')"
                    class="modal-title">
                  <span i18n="ref|Modal city - header for append mode@@ref.main.modal.city.headerAppend">City append</span>&nbsp;{{city.iata}}
                </h5>
                <h5 *ngIf="showButton('state-update')"
                    class="modal-title">
                  <span i18n="ref|Modal city - header for edit mode@@ref.main.modal.city.headerEdit">Edit city</span>&nbsp;{{city.iata}}
                </h5>
                <button #cityFormFade type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row mb-2">
                  <div class=" col-3">
                    <label class="w-100">
                      <span i18n="ref|Modal city - field iata code@@ref.main.modal.city.field.iataCode">IATA code</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control form-control-sm"
                             name="cityIata"
                             #cityIata="ngModel"
                             [(ngModel)]="city.iata"
                             minlength="3"
                             maxlength="3"
                             pattern="^[a-zA-Z]{0,}$"
                             required>

                    <div *ngIf="!cityIata?.valid && (cityIata?.dirty || cityIata?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="cityIata.errors.required"
                           i18n="ref|Modal city - error iata code is required@@ref.main.modal.city.err.iataCodeReq">
                        IATA code is required
                      </div>
                      <div *ngIf="cityIata.errors.minlength">
                        <span i18n="ref|Modal city - error iata code is min length@@ref.main.modal.city.err.iataCodeMinLength">IATA code minimum length is</span>&nbsp;{{cityIata.errors.minlength?.requiredLength}}
                      </div>
                      <div *ngIf="cityIata.errors.pattern"
                           i18n="ref|Modal city - error iata code is english only@@ref.main.modal.city.err.iataCodeEnOnly">
                        IATA code is English only
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <label class="w-100">
                      <span i18n="ref|Modal city - field icao code@@ref.main.modal.city.field.icaoCode">ICAO code</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="cityIcao"
                             #cityIcao="ngModel"
                             [(ngModel)]="city.icao"
                             minlength="4"
                             maxlength="4"
                             pattern="^[a-zA-Z0-9]{0,}$"
                             required>

                    <div *ngIf="!cityIcao?.valid && (cityIcao?.dirty || cityIcao?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="cityIcao.errors.required"
                           i18n="ref|Modal city - error icao code is required@@ref.main.modal.city.err.icaoCodeReq">
                        ICAO code is required
                      </div>
                      <div *ngIf="cityIcao.errors.minlength">
                        <span i18n="ref|Modal city - error icao code is min length@@ref.main.modal.city.err.icaoCodeMinLength">ICAO code minimum length is</span>&nbsp;{{cityIcao.errors.minlength?.requiredLength}}
                      </div>
                      <div *ngIf="cityIcao.errors.pattern"
                           i18n="ref|Modal city - error icao code is english only@@ref.main.modal.city.err.icaoCodeEnOnly">
                        ICAO code is English only
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal city - field country@@ref.main.modal.city.field.country">Country</span><span class="required-asterisk">*</span>
                    </label>
                    <ng-select class="form-control custom-sm p-0"
                               [items]="countriesForSelectList"
                               bindLabel="names"
                               bindValue="id"
                               [(ngModel)]="city.countryId"
                               #cityCountry="ngModel"
                               appendTo="body"
                               name="cityCountry"
                               [virtualScroll]="true"
                               [loading]="countriesLoading"
                               (scroll)="onScrollCountries($event)"
                               (scrollToEnd)="onScrollCountriesToEnd()"
                               required>
                      <ng-template ng-option-tmp let-item="item">
                        {{item.names}} ({{item.iata}} / {{item.icao}})
                      </ng-template>
                    </ng-select>
                    <!-- <select class="form-control form-control-sm"
                            [ngModel]="city.countryId"
                            name="cityCountry"
                            #cityCountry="ngModel"
                            (input)="city.countryId = +$event.target.value"
                            required>
                      <option *ngFor="let country of countries" [selected]="country.id == city.countryId" value="{{country.id}}">
                        {{country.names}} ({{country.iata}} / {{country.icao}})
                      </option>
                    </select> -->
                    <div *ngIf="!cityCountry?.valid && (cityCountry?.dirty || cityCountry?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="cityCountry.errors.required"
                           i18n="ref|Modal city - error country is required@@ref.main.modal.city.err.countryReq">
                        Country is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal city - english name@@ref.main.modal.city.field.englishName">English name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="cityName0"
                             #cityName0="ngModel"
                             [(ngModel)]="city.nameEnglish"
                             pattern="^[a-zA-Z0-9\.,-\/\\_*\s]{0,}$"
                             required>

                    <div *ngIf="!cityName0?.valid && (cityName0?.dirty || cityName0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="cityName0.errors.required"
                           i18n="ref|Modal city - error english name is req@@ref.main.modal.city.err.englishNameReq">
                        English name is required
                      </div>
                      <div *ngIf="cityName0.errors.pattern"
                           i18n="ref|Modal city - error english name is english only@@ref.main.modal.city.err.englishNameEnOnly">
                        Name is English only
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal city - local name@@ref.main.modal.city.field.localName">Local name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="cityName1"
                             #cityName1="ngModel"
                             [(ngModel)]="city.nameLocal"
                             required>

                    <div *ngIf="!cityName1?.valid && (cityName1?.dirty || cityName1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="cityName1.errors.required"
                           i18n="ref|Modal city - error local name is req@@ref.main.modal.city.err.localNameReq">
                        Local name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal city - date range start@@ref.main.modal.city.field.dtRangeStart">Date range start</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="cityDtRange0"
                             #cityDtRange0="ngModel"
                             [ngModel]="city.dtRangeStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="city.dtRangeStart = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!cityDtRange0?.valid && (cityDtRange0?.dirty || cityDtRange0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="cityDtRange0.errors.required"
                           i18n="ref|Modal city - error date range start is req@@ref.main.modal.city.err.dtRangeStartReq">
                        Date range start is required
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal city - date rande finish@@ref.main.modal.city.field.dtRangeFinish">Date range finish</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="cityDtRange1"
                             #cityDtRange1="ngModel"
                             [ngModel]="city.dtRangeFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="city.dtRangeFinish = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!cityDtRange1?.valid && (cityDtRange1?.dirty || cityDtRange1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="cityDtRange1.errors.required"
                           i18n="ref|Modal city - error date range finish is req@@ref.main.modal.city.err.dtRangeFinishReq">
                        Date range finish is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="row w-100 m-0">
                  <div class="col-6 p-0">
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="duplicateReference(city)"
                            i18n="ref|Modal city - button duplicate@@ref.main.modal.city.button.duplicate">
                      Duplicate
                    </button>
                    <button type="button"
                            *ngIf="showButton('state-update')"
                            class="btn btn-red btn-sm"
                            (click)="confirmAction(deleteCity)"
                            i18n="ref|Modal city - button delete@@ref.main.modal.city.button.delete">
                      Delete
                    </button>
                  </div>
                  <div class="col-6 p-0 text-right">
                    <button type="button" *ngIf="showButton('state-append')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="addCity()"
                            i18n="ref|Modal city - button append@@ref.main.modal.city.button.append">
                      Append
                    </button>
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="updateCity(city.id)"
                            i18n="ref|Modal city - button save@@ref.main.modal.city.button.save">
                      Save
                    </button>
                    <button type="button"
                            class="btn btn-secondary btn-sm"
                            data-dismiss="modal"
                            i18n="ref|Modal city - button close@@ref.main.modal.city.button.close">
                      Close
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="errorMessage !== '' && activeTab == 'tab-cities'" class="ms-modal-message">
                <div class="modal-content w-50">
                  <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                                'bg-warning': errorType == 'warning',
                                                'bg-danger': errorType == 'error'}">
                    <h5 *ngIf="errorType == 'info'"
                        class="modal-title"
                        i18n="ref|Modal city error - header information@@ref.main.modal.city.errMes.header.inf">
                      Information
                    </h5>
                    <h5 *ngIf="errorType == 'warning'"
                        class="modal-title"
                        i18n="ref|Modal city error - header warning@@ref.main.modal.city.errMes.header.warn">
                      Warning
                    </h5>
                    <h5 *ngIf="errorType == 'error'"
                        class="modal-title"
                        i18n="ref|Modal city error - header error@@ref.main.modal.city.errMes.header.err">
                      Error
                    </h5>
                    <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    {{errorMessage}}
                  </div>
                  <div class="modal-footer">
                    <button type="button"
                            class="btn btn-sm btn-blue"
                            *ngIf="userAction == 'error'"
                            (click)="clearErrorMess()"
                            i18n="ref|Modal city error - button edit@@ref.main.modal.city.errMes.button.edit">
                      Edit
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-secondary"
                            *ngIf="userAction == 'error'"
                            (click)="closeWithoutSaving()"
                            i18n="ref|Modal city error - button close@@ref.main.modal.city.errMes.button.close">
                      Close without saving
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-blue"
                            (click)="executableFunction(city.id)"
                            i18n="adm|Modal Master Data - delete yes@@ref.main.modal.button.deleteYes">
                      Yes
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-secondary"
                            (click)="clearErrorMess()"
                            i18n="adm|Modal Master Data - delete no@@ref.main.modal.button.deleteNo">
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- Modal Kinds -->
    <div *ngIf="showTab('tab-aircraft-kinds')">
      <form #aircraftKindForm="ngForm">
        <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
             aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content bg-light-gray">
              <div class="loading" *ngIf="loading">
                <div class="cssload-spin-box"></div>
              </div>
              <div class="modal-header">
                <h5 *ngIf="showButton('state-append')"
                    class="modal-title">
                  <span i18n="ref|Modal aircraft-kind - header for append mode@@ref.main.modal.aircraftKind.headerAppend">Kind append</span>&nbsp;{{aircraftKind.nameLocal}}
                </h5>
                <h5 *ngIf="showButton('state-update')"
                    class="modal-title">
                  <span i18n="ref|Modal aircraft-kind - header for edit mode@@ref.main.modal.aircraftKind.headerEdit">Edit kind</span>&nbsp;{{aircraftKind.nameLocal}}
                </h5>
                <button #aircraftKindFormFade type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal aircraft-kind - english name@@ref.main.modal.aircraftKind.field.englishName">English name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="aircraftKindName0"
                             #aircraftKindName0="ngModel"
                             [(ngModel)]="aircraftKind.nameEnglish"
                             pattern="^[a-zA-Z0-9\.,-\/\\_*\s]{0,}$"
                             required>

                    <div *ngIf="!aircraftKindName0?.valid && (aircraftKindName0?.dirty || aircraftKindName0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="aircraftKindName0.errors.required"
                           i18n="ref|Modal aircraft-kind - error english name is req@@ref.main.modal.aircraftKind.err.englishNameReq">
                        English name is required
                      </div>
                      <div *ngIf="aircraftKindName0.errors.pattern"
                           i18n="ref|Modal aircraft-kind - error english name is english only@@ref.main.modal.aircraftKind.err.englishNameEnOnly">
                        Name is English only
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal aircraft-kind - local name@@ref.main.modal.aircraftKind.field.localName">Local name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="aircraftKindName1"
                             #aircraftKindName1="ngModel"
                             [(ngModel)]="aircraftKind.nameLocal"
                             required>

                    <div *ngIf="!aircraftKindName1?.valid && (aircraftKindName1?.dirty || aircraftKindName1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="aircraftKindName1.errors.required"
                           i18n="ref|Modal aircraft-kind - error local name is req@@ref.main.modal.aircraftKind.err.localNameReq">
                        Local name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal aircraft-kind - date range start@@ref.main.modal.aircraftKind.field.dtRangeStart">Date range start</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="aircraftKindDtRange0"
                             #aircraftKindDtRange0="ngModel"
                             [ngModel]="aircraftKind.dtRangeStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="aircraftKind.dtRangeStart = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!aircraftKindDtRange0?.valid && (aircraftKindDtRange0?.dirty || aircraftKindDtRange0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="aircraftKindDtRange0.errors.required"
                           i18n="ref|Modal aircraft-kind - error date range start is req@@ref.main.modal.aircraftKind.err.dtRangeStartReq">
                        Date range start is required
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal aircraft-kind - date rande finish@@ref.main.modal.aircraftKind.field.dtRangeFinish">Date range finish</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="aircraftKindDtRange1"
                             #aircraftKindDtRange1="ngModel"
                             [ngModel]="aircraftKind.dtRangeFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="aircraftKind.dtRangeFinish = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!aircraftKindDtRange1?.valid && (aircraftKindDtRange1?.dirty || aircraftKindDtRange1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="aircraftKindDtRange1.errors.required"
                           i18n="ref|Modal aircraft-kind - error date range finish is req@@ref.main.modal.aircraftKind.err.dtRangeFinishReq">
                        Date range finish is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="row w-100 m-0">
                  <div class="col-6 p-0">
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="duplicateReference(aircraftKind)"
                            i18n="ref|Modal aircraft-kind - button duplicate@@ref.main.modal.aircraftKind.button.duplicate">
                      Duplicate
                    </button>
                    <button type="button"
                            *ngIf="showButton('state-update')"
                            class="btn btn-red btn-sm"
                            (click)="confirmAction(deleteAircraftKind)"
                            i18n="ref|Modal aircraft-kind - button delete@@ref.main.modal.aircraftKind.button.delete">
                      Delete
                    </button>
                  </div>
                  <div class="col-6 p-0 text-right">
                    <button type="button" *ngIf="showButton('state-append')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="addAircraftKind()"
                            i18n="ref|Modal aircraft-kind - button append@@ref.main.modal.aircraftKind.button.append">
                      Append
                    </button>
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="updateAircraftKind(aircraftKind.id)"
                            i18n="ref|Modal aircraft-kind - button save@@ref.main.modal.aircraftKind.button.save">
                      Save
                    </button>
                    <button type="button"
                            class="btn btn-secondary btn-sm"
                            data-dismiss="modal"
                            i18n="ref|Modal aircraft-kind - button close@@ref.main.modal.aircraftKind.button.close">
                      Close
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="errorMessage !== '' && activeTab == 'tab-aircraft-kinds'" class="ms-modal-message">
                <div class="modal-content w-50">
                  <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                                'bg-warning': errorType == 'warning',
                                                'bg-danger': errorType == 'error'}">
                    <h5 *ngIf="errorType == 'info'"
                        class="modal-title"
                        i18n="ref|Modal aircraft-kind error - header information@@ref.main.modal.aircraftKind.errMes.header.inf">
                      Information
                    </h5>
                    <h5 *ngIf="errorType == 'warning'"
                        class="modal-title"
                        i18n="ref|Modal aircraft-kind error - header warning@@ref.main.modal.aircraftKind.errMes.header.warn">
                      Warning
                    </h5>
                    <h5 *ngIf="errorType == 'error'"
                        class="modal-title"
                        i18n="ref|Modal aircraft-kind error - header error@@ref.main.modal.aircraftKind.errMes.header.err">
                      Error
                    </h5>
                    <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    {{errorMessage}}
                  </div>
                  <div class="modal-footer">
                    <button type="button"
                            class="btn btn-sm btn-blue"
                            *ngIf="userAction == 'error'"
                            (click)="clearErrorMess()"
                            i18n="ref|Modal aircraft-kind error - button edit@@ref.main.modal.aircraftKind.errMes.button.edit">
                      Edit
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-secondary"
                            *ngIf="userAction == 'error'"
                            (click)="closeWithoutSaving()"
                            i18n="ref|Modal aircraft-kind error - button close@@ref.main.modal.aircraftKind.errMes.button.close">
                      Close without saving
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-blue"
                            (click)="executableFunction(aircraftKind.id)"
                            i18n="adm|Modal Master Data - delete yes@@ref.main.modal.button.deleteYes">
                      Yes
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-secondary"
                            (click)="clearErrorMess()"
                            i18n="adm|Modal Master Data - delete no@@ref.main.modal.button.deleteNo">
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- Modal Class -->
    <div *ngIf="showTab('tab-aircraft-classes')">
      <form #aircraftClassForm="ngForm">
        <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
             aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content bg-light-gray">
              <div class="loading" *ngIf="loading">
                <div class="cssload-spin-box"></div>
              </div>
              <div class="modal-header">
                <h5 *ngIf="showButton('state-append')"
                    class="modal-title">
                  <span i18n="ref|Modal aircraft-class - header for append mode@@ref.main.modal.aircraftClass.headerAppend">Class append</span>&nbsp;{{aircraftClass.nameLocal}}
                </h5>
                <h5 *ngIf="showButton('state-update')"
                    class="modal-title">
                  <span i18n="ref|Modal aircraft-class - header for edit mode@@ref.main.modal.aircraftClass.headerEdit">Edit class</span>&nbsp;{{aircraftClass.nameLocal}}
                </h5>
                <button #aircraftClassFormFade type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal aircraft-class - english name@@ref.main.modal.aircraftClass.field.englishName">English name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="aircraftClassName0"
                             #aircraftClassName0="ngModel"
                             [(ngModel)]="aircraftClass.nameEnglish"
                             pattern="^[a-zA-Z0-9\.,-\/\\_*\s]{0,}$"
                             required>

                    <div *ngIf="!aircraftClassName0?.valid && (aircraftClassName0?.dirty || aircraftClassName0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="aircraftClassName0.errors.required"
                           i18n="ref|Modal aircraft-class - error english name is req@@ref.main.modal.aircraftClass.err.englishNameReq">
                        English name is required
                      </div>
                      <div *ngIf="aircraftClassName0.errors.pattern"
                           i18n="ref|Modal aircraft-class - error english name is english only@@ref.main.modal.aircraftClass.err.englishNameEnOnly">
                        Name is English only
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal aircraft-class - local name@@ref.main.modal.aircraftClass.field.localName">Local name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="aircraftClassName1"
                             #aircraftClassName1="ngModel"
                             [(ngModel)]="aircraftClass.nameLocal"
                             required>

                    <div *ngIf="!aircraftClassName1?.valid && (aircraftClassName1?.dirty || aircraftClassName1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="aircraftClassName1.errors.required"
                           i18n="ref|Modal aircraft-class - error local name is req@@ref.main.modal.aircraftClass.err.localNameReq">
                        Local name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal aircraft-class - date range start@@ref.main.modal.aircraftClass.field.dtRangeStart">Date range start</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="aircraftClassDtRange0"
                             #aircraftClassDtRange0="ngModel"
                             [ngModel]="aircraftClass.dtRangeStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="aircraftClass.dtRangeStart = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!aircraftClassDtRange0?.valid && (aircraftClassDtRange0?.dirty || aircraftClassDtRange0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="aircraftClassDtRange0.errors.required"
                           i18n="ref|Modal aircraft-class - error date range start is req@@ref.main.modal.aircraftClass.err.dtRangeStartReq">
                        Date range start is required
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal aircraft-class - date rande finish@@ref.main.modal.aircraftClass.field.dtRangeFinish">Date range finish</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="aircraftClassDtRange1"
                             #aircraftClassDtRange1="ngModel"
                             [ngModel]="aircraftClass.dtRangeFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="aircraftClass.dtRangeFinish = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!aircraftClassDtRange1?.valid && (aircraftClassDtRange1?.dirty || aircraftClassDtRange1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="aircraftClassDtRange1.errors.required"
                           i18n="ref|Modal aircraft-class - error date range finish is req@@ref.main.modal.aircraftClass.err.dtRangeFinishReq">
                        Date range finish is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="row w-100 m-0">
                  <div class="col-6 p-0">
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="duplicateReference(aircraftClass)"
                            i18n="ref|Modal aircraft-class - button duplicate@@ref.main.modal.aircraftClass.button.duplicate">
                      Duplicate
                    </button>
                    <button type="button"
                            *ngIf="showButton('state-update')"
                            class="btn btn-red btn-sm"
                            (click)="confirmAction(deleteAircraftClass)"
                            i18n="ref|Modal aircraft-class - button delete@@ref.main.modal.aircraftClass.button.delete">
                      Delete
                    </button>
                  </div>
                  <div class="col-6 p-0 text-right">
                    <button type="button" *ngIf="showButton('state-append')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="addAircraftClass()"
                            i18n="ref|Modal aircraft-class - button append@@ref.main.modal.aircraftClass.button.append">
                      Append
                    </button>
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="updateAircraftClass(aircraftClass.id)"
                            i18n="ref|Modal aircraft-class - button save@@ref.main.modal.aircraftClass.button.save">
                      Save
                    </button>
                    <button type="button"
                            class="btn btn-secondary btn-sm"
                            data-dismiss="modal"
                            i18n="ref|Modal aircraft-class - button close@@ref.main.modal.aircraftClass.button.close">
                      Close
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="errorMessage !== '' && activeTab == 'tab-aircraft-classes'" class="ms-modal-message">
                <div class="modal-content w-50">
                  <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                                'bg-warning': errorType == 'warning',
                                                'bg-danger': errorType == 'error'}">
                    <h5 *ngIf="errorType == 'info'"
                        class="modal-title"
                        i18n="ref|Modal aircraft-class error - header information@@ref.main.modal.aircraftClass.errMes.header.inf">
                      Information
                    </h5>
                    <h5 *ngIf="errorType == 'warning'"
                        class="modal-title"
                        i18n="ref|Modal aircraft-class error - header warning@@ref.main.modal.aircraftClass.errMes.header.warn">
                      Warning
                    </h5>
                    <h5 *ngIf="errorType == 'error'"
                        class="modal-title"
                        i18n="ref|Modal aircraft-class error - header error@@ref.main.modal.aircraftClass.errMes.header.err">
                      Error
                    </h5>
                    <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    {{errorMessage}}
                  </div>
                  <div class="modal-footer">
                    <button type="button"
                            class="btn btn-sm btn-blue"
                            *ngIf="userAction == 'error'"
                            (click)="clearErrorMess()"
                            i18n="ref|Modal aircraft-class error - button edit@@ref.main.modal.aircraftClass.errMes.button.edit">
                      Edit
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-secondary"
                            *ngIf="userAction == 'error'"
                            (click)="closeWithoutSaving()"
                            i18n="ref|Modal aircraft-class error - button close@@ref.main.modal.aircraftClass.errMes.button.close">
                      Close without saving
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-blue"
                            (click)="executableFunction(aircraftClass.id)"
                            i18n="adm|Modal Master Data - delete yes@@ref.main.modal.button.deleteYes">
                      Yes
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-secondary"
                            (click)="clearErrorMess()"
                            i18n="adm|Modal Master Data - delete no@@ref.main.modal.button.deleteNo">
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- Modal Tails -->
    <div *ngIf="showTab('tab-tails')">
      <form #tailForm="ngForm">
        <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
             aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content bg-light-gray">
              <div class="loading" *ngIf="loading">
                <div class="cssload-spin-box"></div>
              </div>
              <div class="modal-header">
                <h5 *ngIf="showButton('state-append')"
                    class="modal-title">
                  <span i18n="ref|Modal tail - header for append mode@@ref.main.modal.tail.headerAppend">Tail append</span>&nbsp;{{tail.tail}}
                </h5>
                <h5 *ngIf="showButton('state-update')"
                    class="modal-title">
                  <span i18n="ref|Modal tail - header for edit mode@@ref.main.modal.tail.headerEdit">Edit tail</span>&nbsp;{{tail.tail}}
                </h5>
                <button #tailFormFade type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row mb-2">
                  <div class=" col-3">
                    <label class="w-100">
                      <span i18n="ref|Modal tail - tail@@ref.main.modal.tail.field.tail">Tail</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="tailTail"
                             #tailTail="ngModel"
                             [(ngModel)]="tail.tail"
                             required>

                    <div *ngIf="!tailTail?.valid && (tailTail?.dirty || tailTail?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="tailTail.errors.required"
                           i18n="ref|Modal tail - error tail is req@@ref.main.modal.tail.err.tailReq">
                        Tile is required
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <label class="w-100">
                      <span i18n="ref|Modal tail - field mtow@@ref.main.modal.tail.field.mtow">MTOW</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="number"
                             class="form-control"
                             name="tailMtow"
                             #tailMtow="ngModel"
                             [(ngModel)]="tail.mtow"
                             required>

                    <div *ngIf="!tailMtow?.valid && (tailMtow?.dirty || tailMtow?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="tailMtow.errors.required"
                           i18n="ref|Modal tail - error mtow is required@@ref.main.modal.tail.err.mtowReq">
                        MTOW is required
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal tail - field country@@ref.main.modal.tail.field.type">Type</span><span class="required-asterisk">*</span>
                    </label>
                    <ng-select class="form-control custom-sm p-0"
                               [items]="aircraftsForSelectList"
                               bindLabel="names"
                               bindValue="id"
                               [(ngModel)]="tail.aircraftTypeId"
                               #tailAircraftTypeId="ngModel"
                               appendTo="body"
                               name="tailAircraftTypeId"
                               [virtualScroll]="true"
                               [loading]="aircraftTypesLoading"
                               (scroll)="onScrollAircraftTypes($event)"
                               (scrollToEnd)="onScrollAircraftTypesToEnd()"
                               required>
                    </ng-select>
                    <div *ngIf="!tailAircraftTypeId?.valid && (tailAircraftTypeId?.dirty || tailAircraftTypeId?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="tailAircraftTypeId.errors.required"
                           i18n="ref|Modal tail - error country is required@@ref.main.modal.tail.err.typeReq">
                        Type is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal tail - date range start@@ref.main.modal.tail.field.dtRangeStart">Date range start</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="tailDtRange0"
                             #tailDtRange0="ngModel"
                             [ngModel]="tail.dtRangeStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="tail.dtRangeStart = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!tailDtRange0?.valid && (tailDtRange0?.dirty || tailDtRange0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="tailDtRange0.errors.required"
                           i18n="ref|Modal tail - error date range start is req@@ref.main.modal.tail.err.dtRangeStartReq">
                        Date range start is required
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal tail - date rande finish@@ref.main.modal.tail.field.dtRangeFinish">Date range finish</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="tailDtRange1"
                             #tailDtRange1="ngModel"
                             [ngModel]="tail.dtRangeFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="tail.dtRangeFinish = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!tailDtRange1?.valid && (tailDtRange1?.dirty || tailDtRange1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="tailDtRange1.errors.required"
                           i18n="ref|Modal tail - error date range finish is req@@ref.main.modal.tail.err.dtRangeFinishReq">
                        Date range finish is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="row w-100 m-0">
                  <div class="col-6 p-0">
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="duplicateReference(tail)"
                            i18n="ref|Modal tail - button duplicate@@ref.main.modal.tail.button.duplicate">
                      Duplicate
                    </button>
                    <button type="button"
                            *ngIf="showButton('state-update')"
                            class="btn btn-red btn-sm"
                            (click)="confirmAction(deleteTail)"
                            i18n="ref|Modal tail - button delete@@ref.main.modal.tail.button.delete">
                      Delete
                    </button>
                  </div>
                  <div class="col-6 p-0 text-right">
                    <button type="button" *ngIf="showButton('state-append')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="addTail()"
                            i18n="ref|Modal tail - button append@@ref.main.modal.tail.button.append">
                      Append
                    </button>
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="updateTail(tail.id)"
                            i18n="ref|Modal tail - button save@@ref.main.modal.tail.button.save">
                      Save
                    </button>
                    <button type="button"
                            class="btn btn-secondary btn-sm"
                            data-dismiss="modal"
                            i18n="ref|Modal tail - button close@@ref.main.modal.tail.button.close">
                      Close
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="errorMessage !== '' && activeTab == 'tab-tails'" class="ms-modal-message">
                <div class="modal-content w-50">
                  <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                                'bg-warning': errorType == 'warning',
                                                'bg-danger': errorType == 'error'}">
                    <h5 *ngIf="errorType == 'info'"
                        class="modal-title"
                        i18n="ref|Modal tail error - header information@@ref.main.modal.tail.errMes.header.inf">
                      Information
                    </h5>
                    <h5 *ngIf="errorType == 'warning'"
                        class="modal-title"
                        i18n="ref|Modal tail error - header warning@@ref.main.modal.tail.errMes.header.warn">
                      Warning
                    </h5>
                    <h5 *ngIf="errorType == 'error'"
                        class="modal-title"
                        i18n="ref|Modal tail error - header error@@ref.main.modal.tail.errMes.header.err">
                      Error
                    </h5>
                    <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    {{errorMessage}}
                  </div>
                  <div class="modal-footer">
                    <button type="button"
                            class="btn btn-sm btn-blue"
                            *ngIf="userAction == 'error'"
                            (click)="clearErrorMess()"
                            i18n="ref|Modal tail error - button edit@@ref.main.modal.tail.errMes.button.edit">
                      Edit
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-secondary"
                            *ngIf="userAction == 'error'"
                            (click)="closeWithoutSaving()"
                            i18n="ref|Modal tail error - button close@@ref.main.modal.tail.errMes.button.close">
                      Close without saving
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-blue"
                            (click)="executableFunction(tail.id)"
                            i18n="adm|Modal Master Data - delete yes@@ref.main.modal.button.deleteYes">
                      Yes
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-secondary"
                            (click)="clearErrorMess()"
                            i18n="adm|Modal Master Data - delete no@@ref.main.modal.button.deleteNo">
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- Modal regularities -->
    <div *ngIf="showTab('tab-regularities')">
      <form #regularitieForm="ngForm">
        <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
             aria-labelledby="myExtraLargeModalLabel"
             aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content bg-light-gray">
              <div class="loading" *ngIf="loading">
                <div class="cssload-spin-box"></div>
              </div>
              <div class="modal-header">
                <h5 *ngIf="showButton('state-append')"
                    class="modal-title">
                  <span i18n="ref|Modal regularitie - header for append mode@@ref.main.modal.regularitie.headerAppend">Regularity append</span>&nbsp;{{regularitie.nameLocal}}
                </h5>
                <h5 *ngIf="showButton('state-update')"
                    class="modal-title">
                  <span i18n="ref|Modal regularitie - header for edit mode@@ref.main.modal.regularitie.headerEdit">Edit regularity</span>&nbsp;{{regularitie.nameLocal}}
                </h5>
                <button #regularitieFormFade type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal regularitie - english name@@ref.main.modal.regularitie.field.englishName">English name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="regularitieName0"
                             #regularitieName0="ngModel"
                             [(ngModel)]="regularitie.nameEnglish"
                             pattern="^[a-zA-Z0-9\.,-\/\\_*\s]{0,}$"
                             required>

                    <div *ngIf="!regularitieName0?.valid && (regularitieName0?.dirty || regularitieName0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="regularitieName0.errors.required"
                           i18n="ref|Modal regularitie - error english name is req@@ref.main.modal.regularitie.err.englishNameReq">
                        English name is required
                      </div>
                      <div *ngIf="regularitieName0.errors.pattern"
                           i18n="ref|Modal regularitie - error english name is english only@@ref.main.modal.regularitie.err.englishNameEnOnly">
                        Name is English only
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal regularitie - local name@@ref.main.modal.regularitie.field.localName">Local name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="regularitiesName1"
                             #regularitiesName1="ngModel"
                             [(ngModel)]="regularitie.nameLocal"
                             required>

                    <div *ngIf="!regularitiesName1?.valid && (regularitiesName1?.dirty || regularitiesName1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="regularitiesName1.errors.required"
                           i18n="ref|Modal regularitie - error local name is req@@ref.main.modal.regularitie.err.localNameReq">
                        Local name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal regularitie - date range start@@ref.main.modal.regularitie.field.dtRangeStart">Date range start</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="regularitieDtRange0"
                             #regularitieDtRange0="ngModel"
                             [ngModel]="regularitie.dtRangeStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="regularitie.dtRangeStart = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!regularitieDtRange0?.valid && (regularitieDtRange0?.dirty || regularitieDtRange0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="regularitieDtRange0.errors.required"
                           i18n="ref|Modal regularitie - error date range start is req@@ref.main.modal.regularitie.err.dtRangeStartReq">
                        Date range start is required
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal regularitie - date rande finish@@ref.main.modal.regularitie.field.dtRangeFinish">Date range finish</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="regularitieDtRange1"
                             #regularitieDtRange1="ngModel"
                             [ngModel]="regularitie.dtRangeFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="regularitie.dtRangeFinish = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!regularitieDtRange1?.valid && (regularitieDtRange1?.dirty || regularitieDtRange1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="regularitieDtRange1.errors.required"
                           i18n="ref|Modal regularitie - error date range finish is req@@ref.main.modal.dregularitie.err.dtRangeFinishReq">
                        Date range finish is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="row w-100 m-0">
                  <div class="col-6 p-0">
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="duplicateReference(regularitie)"
                            i18n="ref|Modal regularitie - button duplicate@@ref.main.modal.regularitie.button.duplicate">
                      Duplicate
                    </button>
                    <button type="button"
                            *ngIf="showButton('state-update')"
                            class="btn btn-red btn-sm"
                            (click)="confirmAction(deleteRegularitie)"
                            i18n="ref|Modal regularitie - button delete@@ref.main.modal.regularitie.button.delete">
                      Delete
                    </button>
                  </div>
                  <div class="col-6 p-0 text-right">
                    <button type="button" *ngIf="showButton('state-append')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="addRegularitie()"
                            i18n="ref|Modal regularitie - button append@@ref.main.modal.regularitie.button.append">
                      Append
                    </button>
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="updateRegularitie(regularitie.id)"
                            i18n="ref|Modal regularitie - button save@@ref.main.modal.regularitie.button.save">
                      Save
                    </button>
                    <button type="button"
                            class="btn btn-secondary btn-sm"
                            data-dismiss="modal"
                            i18n="ref|Modal regularitie - button close@@ref.main.regularitie.delay.button.close">
                      Close
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="errorMessage !== '' && activeTab == 'tab-regularities'" class="ms-modal-message">
                <div class="modal-content w-50">
                  <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                                'bg-warning': errorType == 'warning',
                                                'bg-danger': errorType == 'error'}">
                    <h5 *ngIf="errorType == 'info'"
                        class="modal-title"
                        i18n="ref|Modal regularitie error - header information@@ref.main.modal.regularitie.errMes.header.inf">
                      Information
                    </h5>
                    <h5 *ngIf="errorType == 'warning'"
                        class="modal-title"
                        i18n="ref|Modal regularitie error - header warning@@ref.main.modal.regularitie.errMes.header.warn">
                      Warning
                    </h5>
                    <h5 *ngIf="errorType == 'error'"
                        class="modal-title"
                        i18n="ref|Modal regularitie error - header error@@ref.main.modal.regularitie.errMes.header.err">
                      Error
                    </h5>
                    <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    {{errorMessage}}
                  </div>
                  <div class="modal-footer">
                    <button type="button"
                            class="btn btn-sm btn-blue"
                            *ngIf="userAction == 'error'"
                            (click)="clearErrorMess()"
                            i18n="ref|Modal regularitie error - button edit@@ref.main.modal.regularitie.errMes.button.edit">
                      Edit
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-secondary"
                            *ngIf="userAction == 'error'"
                            (click)="closeWithoutSaving()"
                            i18n="ref|Modal regularitie error - button close@@ref.main.modal.regularitie.errMes.button.close">
                      Close without saving
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-blue"
                            (click)="executableFunction(regularitie.id)"
                            i18n="adm|Modal Master Data - delete yes@@ref.main.modal.button.deleteYes">
                      Yes
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-secondary"
                            (click)="clearErrorMess()"
                            i18n="adm|Modal Master Data - delete no@@ref.main.modal.button.deleteNo">
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- Modal Stand -->
    <div *ngIf="showTab('tab-stands')">
      <form #standForm="ngForm">
        <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
             aria-labelledby="myExtraLargeModalLabel"
             aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content bg-light-gray">
              <div class="loading" *ngIf="loading">
                <div class="cssload-spin-box"></div>
              </div>
              <div class="modal-header">
                <h5 *ngIf="showButton('state-append')"
                    class="modal-title">
                  <span i18n="ref|Modal stand - header for append mode@@ref.main.modal.stand.headerAppend">Stand append</span>&nbsp;{{stand.name}}
                </h5>
                <h5 *ngIf="showButton('state-update')"
                    class="modal-title">
                  <span i18n="ref|Modal stand - header for edit mode@@ref.main.modal.stand.headerEdit">Edit stand</span>&nbsp;{{stand.name}}
                </h5>
                <button #standFormFade type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row mb-2">
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal stand - local name@@ref.main.modal.stand.field.localName">Local name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="standName1"
                             #standName1="ngModel"
                             [(ngModel)]="stand.name"
                             required>
                    <div *ngIf="!standName1?.valid && (standName1?.dirty || standName1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="standName1.errors.required"
                           i18n="ref|Modal stand - error local name is req@@ref.main.modal.stand.err.localNameReq">
                        Local name is required
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal stand - field type@@ref.main.modal.stand.field.type">Type</span><span class="required-asterisk">*</span>
                    </label>
                    <ng-select class="form-control custom p-0"
                               [items]="standTypesForList"
                               bindLabel="names"
                               bindValue="id"
                               [(ngModel)]="stand.standTypeId"
                               #standType="ngModel"
                               name="standType"
                               required>
                    </ng-select>
                    <div *ngIf="!standType?.valid && (standType?.dirty || standType?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="standType.errors.required"
                           i18n="ref|Modal stand - error type is required@@ref.main.modal.stand.err.type">
                        Type is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal stand - date range start@@ref.main.modal.stand.field.dtRangeStart">Date range start</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="standDtRange0"
                             #standDtRange0="ngModel"
                             [ngModel]="stand.dtRangeStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="stand.dtRangeStart = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>
                    <div *ngIf="!standDtRange0?.valid && (standDtRange0?.dirty || standDtRange0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="standDtRange0.errors.required"
                           i18n="ref|Modal stand - error date range start is req@@ref.main.modal.stand.err.dtRangeStartReq">
                        Date range start is required
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal stand - date rande finish@@ref.main.modal.stand.field.dtRangeFinish">Date range finish</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="standDtRange1"
                             #standDtRange1="ngModel"
                             [ngModel]="stand.dtRangeFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="stand.dtRangeFinish = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!standDtRange1?.valid && (standDtRange1?.dirty || standDtRange1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="standDtRange1.errors.required"
                           i18n="ref|Modal stand - error date range finish is req@@ref.main.modal.stand.err.dtRangeFinishReq">
                        Date range finish is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="row w-100 m-0">
                  <div class="col-6 p-0">
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="duplicateReference(stand)"
                            i18n="ref|Modal stand - button duplicate@@ref.main.modal.stand.button.duplicate">
                      Duplicate
                    </button>
                    <button type="button"
                            *ngIf="showButton('state-update')"
                            class="btn btn-red btn-sm"
                            (click)="confirmAction(deleteStand)"
                            i18n="ref|Modal stand - button delete@@ref.main.modal.stand.button.delete">
                      Delete
                    </button>
                  </div>
                  <div class="col-6 p-0 text-right">
                    <button type="button" *ngIf="showButton('state-append')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="addStand()"
                            i18n="ref|Modal stand - button append@@ref.main.modal.stand.button.append">
                      Append
                    </button>
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="updateStand(stand.id)"
                            i18n="ref|Modal stand - button save@@ref.main.modal.stand.button.save">
                      Save
                    </button>
                    <button type="button"
                            class="btn btn-secondary btn-sm"
                            data-dismiss="modal"
                            i18n="ref|Modal stand - button close@@ref.main.stand.delay.button.close">
                      Close
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="errorMessage !== '' && activeTab == 'tab-stands'" class="ms-modal-message">
                <div class="modal-content w-50">
                  <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                                'bg-warning': errorType == 'warning',
                                                'bg-danger': errorType == 'error'}">
                    <h5 *ngIf="errorType == 'info'"
                        class="modal-title"
                        i18n="ref|Modal stand error - header information@@ref.main.modal.stand.errMes.header.inf">
                      Information
                    </h5>
                    <h5 *ngIf="errorType == 'warning'"
                        class="modal-title"
                        i18n="ref|Modal stand error - header warning@@ref.main.modal.stand.errMes.header.warn">
                      Warning
                    </h5>
                    <h5 *ngIf="errorType == 'error'"
                        class="modal-title"
                        i18n="ref|Modal stand error - header error@@ref.main.modal.stand.errMes.header.err">
                      Error
                    </h5>
                    <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    {{errorMessage}}
                  </div>
                  <div class="modal-footer">
                    <button type="button"
                            class="btn btn-sm btn-blue"
                            *ngIf="userAction == 'error'"
                            (click)="clearErrorMess()"
                            i18n="ref|Modal stand error - button edit@@ref.main.modal.stand.errMes.button.edit">
                      Edit
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-secondary"
                            *ngIf="userAction == 'error'"
                            (click)="closeWithoutSaving()"
                            i18n="ref|Modal stand error - button close@@ref.main.modal.stand.errMes.button.close">
                      Close without saving
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-blue"
                            (click)="executableFunction(stand.id)"
                            i18n="adm|Modal Master Data - delete yes@@ref.main.modal.button.deleteYes">
                      Yes
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-secondary"
                            (click)="clearErrorMess()"
                            i18n="adm|Modal Master Data - delete no@@ref.main.modal.button.deleteNo">
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- Modal Stand Types -->
    <div *ngIf="showTab('tab-stand-types')">
      <form #standTypeForm="ngForm">
        <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
             aria-labelledby="myExtraLargeModalLabel"
             aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content bg-light-gray">
              <div class="loading" *ngIf="loading">
                <div class="cssload-spin-box"></div>
              </div>
              <div class="modal-header">
                <h5 *ngIf="showButton('state-append')"
                    class="modal-title">
                  <span i18n="ref|Modal stand type - header for append mode@@ref.main.modal.standType.headerAppend">Stand type append</span>&nbsp;{{standType.nameEnglish}}
                </h5>
                <h5 *ngIf="showButton('state-update')"
                    class="modal-title">
                  <span i18n="ref|Modal stand - header for edit mode@@ref.main.modal.standType.headerEdit">Edit stand type</span>&nbsp;{{standType.nameEnglish}}
                </h5>
                <button #standTypeFormFade type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal stand type - english name@@ref.main.modal.standType.field.englishName">English name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="standTypeName0"
                             #standTypeName0="ngModel"
                             [(ngModel)]="standType.nameEnglish"
                             pattern="^[a-zA-Z0-9\.,-\/\\_*\s]{0,}$"
                             required>

                    <div *ngIf="!standTypeName0?.valid && (standTypeName0?.dirty || standTypeName0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="standTypeName0.errors.required"
                           i18n="ref|Modal stand type - error english name is req@@ref.main.modal.standType.err.englishNameReq">
                        English name is required
                      </div>
                      <div *ngIf="standTypeName0.errors.pattern"
                           i18n="ref|Modal stand type - error english name is english only@@ref.main.modal.standType.err.englishNameEnOnly">
                        Name is English only
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal stand type - local name@@ref.main.modal.standType.field.localName">Local name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="standTypeName1"
                             #standTypeName1="ngModel"
                             [(ngModel)]="standType.nameLocal"
                             required>

                    <div *ngIf="!standTypeName1?.valid && (standTypeName1?.dirty || standTypeName1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="standTypeName1.errors.required"
                           i18n="ref|Modal stand type - error local name is req@@ref.main.modal.standType.err.localNameReq">
                        Local name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal stand type - date range start@@ref.main.modal.standType.field.dtRangeStart">Date range start</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="standTypeDtRange0"
                             #standTypeDtRange0="ngModel"
                             [ngModel]="standType.dtRangeStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="standType.dtRangeStart = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>
                    <div *ngIf="!standTypeDtRange0?.valid && (standTypeDtRange0?.dirty || standTypeDtRange0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="standTypeDtRange0.errors.required"
                           i18n="ref|Modal stand type - error date range start is req@@ref.main.modal.standType.err.dtRangeStartReq">
                        Date range start is required
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal stand type - date rande finish@@ref.main.modal.standType.field.dtRangeFinish">Date range finish</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="standTypeDtRange1"
                             #standTypeDtRange1="ngModel"
                             [ngModel]="standType.dtRangeFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="standType.dtRangeFinish = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!standTypeDtRange1?.valid && (standTypeDtRange1?.dirty || standTypeDtRange1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="standTypeDtRange1.errors.required"
                           i18n="ref|Modal stand type - error date range finish is req@@ref.main.modal.standType.err.dtRangeFinishReq">
                        Date range finish is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="row w-100 m-0">
                  <div class="col-6 p-0">
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="duplicateReference(standType)"
                            i18n="ref|Modal stand type - button duplicate@@ref.main.modal.standType.button.duplicate">
                      Duplicate
                    </button>
                    <button type="button"
                            *ngIf="showButton('state-update')"
                            class="btn btn-red btn-sm"
                            (click)="confirmAction(deleteStandType)"
                            i18n="ref|Modal stand type - button delete@@ref.main.modal.standType.button.delete">
                      Delete
                    </button>
                  </div>
                  <div class="col-6 p-0 text-right">
                    <button type="button" *ngIf="showButton('state-append')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="addStandType()"
                            i18n="ref|Modal stand type - button append@@ref.main.modal.standType.button.append">
                      Append
                    </button>
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="updateStandType(standType.id)"
                            i18n="ref|Modal stand type - button save@@ref.main.modal.standType.button.save">
                      Save
                    </button>
                    <button type="button"
                            class="btn btn-secondary btn-sm"
                            data-dismiss="modal"
                            i18n="ref|Modal stand type - button close@@ref.main.standType.delay.button.close">
                      Close
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="errorMessage !== '' && activeTab == 'tab-stand-types'" class="ms-modal-message">
                <div class="modal-content w-50">
                  <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                                'bg-warning': errorType == 'warning',
                                                'bg-danger': errorType == 'error'}">
                    <h5 *ngIf="errorType == 'info'"
                        class="modal-title"
                        i18n="ref|Modal stand type error - header information@@ref.main.modal.standType.errMes.header.inf">
                      Information
                    </h5>
                    <h5 *ngIf="errorType == 'warning'"
                        class="modal-title"
                        i18n="ref|Modal stand type error - header warning@@ref.main.modal.standType.errMes.header.warn">
                      Warning
                    </h5>
                    <h5 *ngIf="errorType == 'error'"
                        class="modal-title"
                        i18n="ref|Modal stand type error - header error@@ref.main.modal.standType.errMes.header.err">
                      Error
                    </h5>
                    <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    {{errorMessage}}
                  </div>
                  <div class="modal-footer">
                    <button type="button"
                            class="btn btn-sm btn-blue"
                            *ngIf="userAction == 'error'"
                            (click)="clearErrorMess()"
                            i18n="ref|Modal stand type error - button edit@@ref.main.modal.standType.errMes.button.edit">
                      Edit
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-secondary"
                            *ngIf="userAction == 'error'"
                            (click)="closeWithoutSaving()"
                            i18n="ref|Modal stand type error - button close@@ref.main.modal.standType.errMes.button.close">
                      Close without saving
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-blue"
                            (click)="executableFunction(standType.id)"
                            i18n="adm|Modal Master Data - delete yes@@ref.main.modal.button.deleteYes">
                      Yes
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-secondary"
                            (click)="clearErrorMess()"
                            i18n="adm|Modal Master Data - delete no@@ref.main.modal.button.deleteNo">
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- Modal Terminal -->
    <div *ngIf="showTab('tab-terminals')">
      <form #terminalForm="ngForm">
        <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
             aria-labelledby="myExtraLargeModalLabel"
             aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content bg-light-gray">
              <div class="loading" *ngIf="loading">
                <div class="cssload-spin-box"></div>
              </div>
              <div class="modal-header">
                <h5 *ngIf="showButton('state-append')"
                    class="modal-title">
                  <span i18n="ref|Modal terminal - header for append mode@@ref.main.modal.terminal.headerAppend">Terminal append</span>&nbsp;{{terminal.name}}
                </h5>
                <h5 *ngIf="showButton('state-update')"
                    class="modal-title">
                  <span i18n="ref|Modal terminal - header for edit mode@@ref.main.modal.terminal.headerEdit">Edit terminal</span>&nbsp;{{terminal.name}}
                </h5>
                <button #terminalFormFade type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row mb-2">
                  <div class="col">
                    <label class="w-100">
                      <span i18n="ref|Modal terminal - english name@@ref.main.modal.terminal.field.name">Name</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="text"
                             class="form-control"
                             name="name0"
                             #name0="ngModel"
                             [(ngModel)]="terminal.name"
                             pattern="^[a-zA-Z0-9\.,-\/\\_*\s]{0,}$"
                             required>
                    <div *ngIf="!name0?.valid && (name0?.dirty || name0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="name0.errors.required"
                           i18n="ref|Modal terminal - error english name is req@@ref.main.modal.terminal.err.nameReq">
                        Name is required
                      </div>
                      <div *ngIf="name0.errors.pattern"
                           i18n="ref|Modal terminal - error english name is english only@@ref.main.modal.terminal.err.nameEnOnly">
                        Name is English only
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class=" col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal terminal - date range start@@ref.main.modal.terminal.field.dtRangeStart">Date range start</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="sterminalDtRange0"
                             #terminalDtRange0="ngModel"
                             [ngModel]="terminal.dtRangeStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="terminal.dtRangeStart = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>
                    <div *ngIf="!terminalDtRange0?.valid && (terminalDtRange0?.dirty || terminalDtRange0?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="terminalDtRange0.errors.required"
                           i18n="ref|Modal terminal - error date range start is req@@ref.main.modal.terminal.err.dtRangeStartReq">
                        Date range start is required
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="w-100">
                      <span i18n="ref|Modal terminal - date rande finish@@ref.main.modal.terminal.field.dtRangeFinish">Date range finish</span><span class="required-asterisk">*</span>
                    </label>
                      <input type="date"
                             class="form-control"
                             name="terminalDtRange1"
                             #terminalDtRange1="ngModel"
                             [ngModel]="terminal.dtRangeFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                             (blur)="terminal.dtRangeFinish = parseDate($event.target.value)"
                             max="2999-12-31"
                             required>

                    <div *ngIf="!terminalDtRange1?.valid && (terminalDtRange1?.dirty || terminalDtRange1?.touched)"
                         class="invalid-feedback d-block">
                      <div *ngIf="terminalDtRange1.errors.required"
                           i18n="ref|Modal terminal - error date range finish is req@@ref.main.modal.terminal.err.dtRangeFinishReq">
                        Date range finish is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="row w-100 m-0">
                  <div class="col-6 p-0">
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="duplicateReference(terminal)"
                            i18n="ref|Modal terminal - button duplicate@@ref.main.modal.terminal.button.duplicate">
                      Duplicate
                    </button>
                    <button type="button"
                            *ngIf="showButton('state-update')"
                            class="btn btn-red btn-sm"
                            (click)="confirmAction(deleteTerminal)"
                            i18n="ref|Modal terminal - button delete@@ref.main.modal.terminal.button.delete">
                      Delete
                    </button>
                  </div>
                  <div class="col-6 p-0 text-right">
                    <button type="button" *ngIf="showButton('state-append')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="addTerminal()"
                            i18n="ref|Modal terminal - button append@@ref.main.modal.terminal.button.append">
                      Append
                    </button>
                    <button type="button" *ngIf="showButton('state-update')"
                            class="btn btn-blue btn-sm mr-2"
                            (click)="updateTerminal(terminal.id)"
                            i18n="ref|Modal terminal - button save@@ref.main.modal.terminal.button.save">
                      Save
                    </button>
                    <button type="button"
                            class="btn btn-secondary btn-sm"
                            data-dismiss="modal"
                            i18n="ref|Modal terminal - button close@@ref.main.terminal.delay.button.close">
                      Close
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="errorMessage !== '' && activeTab == 'tab-terminals'" class="ms-modal-message">
                <div class="modal-content w-50">
                  <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                                'bg-warning': errorType == 'warning',
                                                'bg-danger': errorType == 'error'}">
                    <h5 *ngIf="errorType == 'info'"
                        class="modal-title"
                        i18n="ref|Modal terminal error - header information@@ref.main.modal.terminal.errMes.header.inf">
                      Information
                    </h5>
                    <h5 *ngIf="errorType == 'warning'"
                        class="modal-title"
                        i18n="ref|Modal terminal error - header warning@@ref.main.modal.terminal.errMes.header.warn">
                      Warning
                    </h5>
                    <h5 *ngIf="errorType == 'error'"
                        class="modal-title"
                        i18n="ref|Modal terminal error - header error@@ref.main.modal.terminal.errMes.header.err">
                      Error
                    </h5>
                    <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    {{errorMessage}}
                  </div>
                  <div class="modal-footer">
                    <button type="button"
                            class="btn btn-sm btn-blue"
                            *ngIf="userAction == 'error'"
                            (click)="clearErrorMess()"
                            i18n="ref|Modal terminal error - button edit@@ref.main.modal.terminal.errMes.button.edit">
                      Edit
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-secondary"
                            *ngIf="userAction == 'error'"
                            (click)="closeWithoutSaving()"
                            i18n="ref|Modal terminal error - button close@@ref.main.modal.terminal.errMes.button.close">
                      Close without saving
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-blue"
                            (click)="executableFunction(terminal.id)"
                            i18n="adm|Modal Master Data - delete yes@@ref.main.modal.button.deleteYes">
                      Yes
                    </button>
                    <button type="button"
                            *ngIf="userAction == 'deleteItem'"
                            class="btn btn-sm btn-secondary"
                            (click)="clearErrorMess()"
                            i18n="adm|Modal Master Data - delete no@@ref.main.modal.button.deleteNo">
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- Modal Import -->
    <form #airportFormImport="ngForm">
      <div class="modal fade bd-example-modal-xl modal-import" data-backdrop="static" tabindex="-1" role="dialog"
           aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
          <div class="modal-content bg-light-gray">
            <div class="modal-header">
              <h5 class="modal-title"
                  i18n="ref|Modal import - header@@ref.modal.import.header.importData">
                Import data
              </h5>
              <button #airportFormFadeImport type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col">
                  <p class="h4">
                    <span i18n="ref|Modal import - step counter@@ref.modal.import.stepCounter">Step</span>&nbsp;{{importStep}}&nbsp;<span
                          i18n="ref|Modal import - step counter@@ref.modal.import.stepCounterOf">of 5</span>
                  </p>
                </div>
                <div class="col">
                  <p class="h4 text-secondary float-right">{{importStepNote[importStep]}}</p>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col" *ngIf="importStep == 1">
                  <div *ngIf="!importLoadAnimation" class="custom-file">
                    <input type="file"
                           class="custom-file-input"
                           id="customFileLangHTML"
                           #uploadFileImport
                           accept=".csv"
                           (change)="onChangeFileImport(uploadFileImport.files)"
                           value="process"
                           lang="ru">
                    <label class="custom-file-label"
                           for="customFileLangHTML"
                           data-browse="Browse"
                           i18n="ref|Modal import - choose file@@ref.modal.import.step1.field.chooseFile">
                      Choose file
                    </label>
                  </div>
                  <div *ngIf="importLoadAnimation" class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only"
                            i18n="ref|Modal import - loading...@@ref.modal.import.step1.loadingProcess">
                        Loading...
                      </span>
                    </div>
                    <p class="h4 ml-2"
                       i18n="ref|Modal import - loading file...@@ref.modal.import.step1.loadingFile">
                      Loading file...
                    </p>
                  </div>
                </div>
                <div class="col" *ngIf="importStep === 2">
                  <div class="row">
                    <div class="col-4">
                      <label class="form-check-label"
                             for="autoSizingCheck"
                             i18n="ref|Modal import - dilimeter@@ref.modal.import.step2.field.dilimeter">
                        Delimiter
                      </label>
                      <select class="form-control form-control-sm"
                              (input)="importDelimiter = $event.target.value"
                              (change)="onChangeImportConfig()">
                        <option *ngFor="let field of importDelimiterArray"
                                value="{{field[0]}}">{{field[1]}}
                        </option>
                      </select>
                    </div>
                    <div class="col-4">
                      <label class="form-check-label"
                             for="autoSizingCheck"
                             i18n="ref|Modal import - new line chartec@@ref.modal.import.step2.field.newLineChartec">
                        New line chartec
                      </label>
                      <select class="form-control form-control-sm"
                              (input)="importNewLine = $event.target.value"
                              (change)="onChangeImportConfig()">
                        <option *ngFor="let field of importNewLineArray"
                                value="{{field[0]}}">{{field[1]}}
                        </option>
                      </select>
                    </div>
                    <div class="col-4">
                      <div class="input-group align-items-center mt-4 pt-1">
                        <input class="form-check-input" type="checkbox" id="autoSizingCheck"
                               [(ngModel)]="importHeaderParse"
                               [ngModelOptions]="{standalone: true}"
                               (change)="onChangeImportConfig()"
                               class="mr-2">
                        <label class="form-check-label"
                               for="autoSizingCheck"
                               i18n="ref|Modal import - the first line@@ref.modal.import.step2.field.firstLineHeaders">
                          The first line of the file contains headers
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <p>
                    <span i18n="ref|Modal import - table normal warning@@ref.modal.import.step2.field.tableNormalWarning.correctData">Make sure that the data from the file is displayed correctly in this table.</span><br>
                    <span i18n="ref|Modal import - table normal warning@@ref.modal.import.step2.field.tableNormalWarning.manualSettings">You can set the parsing parameters manually for the best result of parsing the file.</span></p>
                  <table *ngIf="!importLoadAnimation" class="table table-hover">
                    <thead>
                    <tr *ngIf="import.getFileHeader().length !== 0">
                      <th scope="col" *ngFor="let item of import.getFileHeader()">
                        {{item}}
                      </th>
                    </tr>
                    </thead>
                    <tbody
                      *ngIf="import.getFileHeader().length >= 1 && import.getFileHeader()[0] != import.headerLimitMessage">
                    <tr
                      *ngFor="let item of import.getDataFromFile() | paginate: { itemsPerPage: 5, currentPage: importPreviewDataPage }; let i = index"
                      [attr.data-index]="i">
                      <td *ngFor="let field of item">
                        {{field}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <pagination-controls *ngIf="!importLoadAnimation"
                                       (pageChange)="importPreviewDataPage = $event"
                                       maxSize="7"
                                       directionLinks="true"
                                       autoHide="true"
                                       previousLabel="Previous"
                                       nextLabel="Next"
                                       i18n-previousLabel="ref|Modal import - table normal warning@@ref.modal.import.step2.pagination.previousLabel"
                                       i18n-nextLabel="ref|Modal import - table normal warning@@ref.modal.import.step2.pagination.nextLabel">
                  </pagination-controls>
                  <div *ngIf="importLoadAnimation" class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only"
                            i18n="ref|Modal import - wait...@@ref.modal.import.step2.waitProcess">
                        Wait...
                      </span>
                    </div>
                    <p class="h4 ml-2"
                       i18n="ref|Modal import - loading file...@@ref.modal.import.step2.wait">
                      Wait...
                    </p>
                  </div>
                </div>
                <div class="col pl-0" *ngIf="importStep == 3">
                  <form #importBindDataForm="ngForm">
                    <div class="form-check">
                      <p>
                        <span i18n="ref|Modal import - bind data message@@ref.modal.import.step3.bindMessage.bind">Bind database fields with file fields</span><br>
                        <span i18n="ref|Modal import - bind data message@@ref.modal.import.step3.bindMessage.dbMark">Database fields marked with</span>&nbsp;<span class="required-asterisk">*</span>&nbsp;<span i18n="ref|Modal import - bind data message@@ref.modal.import.step3.bindMessage.bindFieldsToFile">are required for binding to file fields</span>
                      </p>
                      <div *ngIf="importBindingCheck && import.unmapedFields().length != 0"
                           class="alert alert-warning"
                           role="alert">
                        <span i18n="ref|Modal import - err bind data @@ref.modal.import.step3.err.bindMessageReq">Not all required fields are binded:</span>&nbsp;{{import.unmapedFields()}}
                      </div>
                      <table class="table table-hover">
                        <thead>
                        <tr>
                          <th
                            i18n="ref|Modal import - table column head db fields@@ref.modal.import.step3.table.columnDbFields">
                            Database field
                          </th>
                          <th
                            i18n="ref|Modal import - table column head file fields@@ref.modal.import.step3.table.columnFileFields">
                            File header/column
                          </th>
                          <th
                            i18n="ref|Modal import - table column head example@@ref.modal.import.step3.table.columnExample">
                            Example data from the file
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of import.fieldsImpotrtFromDB">
                          <td>{{item[0]}}<span *ngIf="item[1]" class="required-asterisk">*</span></td>
                          <td>
                            <select id="importField{{item[0]}}"
                                    class="form-control"
                                    name="importField{{item[0]}}"
                                    [ngClass]="importBindingCheck && import.unmapedField(importStep, item[0]) === true ? 'ng-invalid' : ''"
                                    (input)="import.fieldsForImpotrt[item[0]] = $event.target.value">
                              <option value=""
                                      i18n="ref|Modal import - choose DB field@@ref.modal.import.step3.field.chooseDbField">
                                Choose DB field
                              </option>
                              <option *ngFor="let field of import.getFileHeader()"
                                      [selected]="import.fieldsForImpotrt[item[0]] == field"
                                      value="{{field}}">{{field}}
                              </option>
                            </select>
                          </td>
                          <td>
                            {{import.dataExample(item[0])}}
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </form>
                </div>
                <div class="col pl-0" *ngIf="importStep == 4">
                  <div class="form-check">
                    <p>
                      <span i18n="ref|Modal import - import preview message@@ref.modal.import.step4.previewMessage.total">Total number of data records in a file:</span>&nbsp;<span
                      class="h5">{{import.dataValidationResult.length}}</span><br>
                      <span class="badge badge-primary">OK</span>&nbsp;<span i18n="ref|Modal import - import preview message@@ref.modal.import.step4.previewMessage.validFormat">it is a validated record format:</span>&nbsp;<span class="h5">{{import.dataValidationGoodSum}}</span><br>
                      <span class="badge badge-danger">ERROR</span>&nbsp;<span i18n="ref|Modal import - import preview message@@ref.modal.import.step4.previewMessage.notValidFormat">these are not validated format recordings:</span>&nbsp;<span class="h5">{{import.dataValidationBadSum}}</span><br><br>
                      <span class="h5" i18n="ref|Modal import - import preview message@@ref.modal.import.step4.previewMessage.attention">Attention!</span>&nbsp;<span i18n="ref|Modal import - import preview message@@ref.modal.import.step4.previewMessage.markerErrorNotImport">All lines marked as error will not be imported.</span><br>
                      <span i18n="ref|Modal import - import preview message@@ref.modal.import.step4.previewMessage.clickError">Click on the</span>&nbsp;<span class="badge badge-danger">ERROR</span>&nbsp;<span i18n="ref|Modal import - import preview message@@ref.modal.import.step4.previewMessage.iconForInfo">icon for detailed information, double-clicking on a row to edit it.</span>
                    </p>
                    <button type="button"
                            class="btn btn-blue btn-sm mb-1"
                            (click)="importShowNextError()"
                            i18n="ref|Modal import - button next error@@ref.modal.import.step4.button.nextError">
                      Next error
                    </button>
                    <table *ngIf="!importLoadAnimation"
                           class="table table-hover table-sm"
                           #importResultValidation>
                      <thead>
                      <tr>
                        <th></th>
                        <th scope="col" *ngFor="let item of import.getImportingHeaderFile()">
                          <span class="h5">
                            {{item}}
                          </span>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                        *ngFor="let item of import.getDataImporting() | paginate: { itemsPerPage: importItemsPerPage, currentPage: importDataPage }; let i = index"
                        [attr.data-index]="absoluteIndex(i)"
                        (dblclick)="editImportDataRow(absoluteIndex(i))"
                        [hidden]="import.dataValidationResultIsGood(absoluteIndex(i)) && showOnlyRecordsWithErrors">
                        <td
                          *ngIf="import.dataValidationResultIsGood(absoluteIndex(i)) && importDataEditRowIndex != absoluteIndex(i)"
                          class="alert alert-primary mp-0">
                          <div class="input-group input-group-sm mx-auto row-status-center">
                            <span class="badge badge-primary"
                                  placement="right"
                                  ngbPopover="{{import.dataValidationResult[absoluteIndex(i)]['message']}}">
                              {{import.dataValidationResult[absoluteIndex(i)]['type'].toUpperCase()}}
                            </span>
                          </div>
                        </td>
                        <td
                          *ngIf="!import.dataValidationResultIsGood(absoluteIndex(i)) && importDataEditRowIndex != absoluteIndex(i)"
                          class="alert alert-danger mp-0">
                          <div class="input-group input-group-sm mx-auto row-status-center">
                            <span class="badge badge-danger"
                                  placement="right"
                                  ngbPopover="{{import.dataValidationResult[absoluteIndex(i)]['message']}}"
                                  triggers="manual" #p="ngbPopover" [hidden]="isShowPopover(p, absoluteIndex(i))"
                                  (click)="clickShowPopover(absoluteIndex(i))">
                              {{import.dataValidationResult[absoluteIndex(i)]['type'].toUpperCase()}}
                            </span>
                          </div>
                        </td>
                        <td
                          *ngIf="importDataEditRowIndex === absoluteIndex(i)"
                          class="alert alert-danger mp-0">
                          <div class="input-group input-group-sm">
                            <button type="button"
                                    class="btn btn-blue btn-sm"
                                    (click)="saveEditImportDataRow()"
                                    i18n="ref|Modal import - button save edit row@@ref.modal.import.step4.button.saveEditRow">
                              Save
                            </button>
                          </div>
                        </td>
                        <td *ngFor="let field of item; let j = index"
                            [ngClass]="import.dataValidationResultIsGood(absoluteIndex(i)) ? 'alert alert-primary' : 'alert alert-danger'">
                          <div *ngIf="importDataEditRowIndex != absoluteIndex(i)"
                               class="input-group input-group-sm text-truncate">
                            {{field}}
                          </div>
                          <div *ngIf="importDataEditRowIndex === absoluteIndex(i)" class="input-group input-group-sm">
                            <input type="text" class="form-control" placeholder="" value="{{field}}"
                                   (blur)="saveEditImportDataField(j, $event.target.value)">
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <pagination-controls *ngIf="!importLoadAnimation"
                                         (pageChange)="importDataPage = $event"
                                         maxSize="7"
                                         directionLinks="true"
                                         autoHide="true"
                                         previousLabel="Previous"
                                         nextLabel="Next">
                    </pagination-controls>
                    <div *ngIf="importLoadAnimation" class="d-flex justify-content-center">
                      <div class="spinner-border" role="status">
                        <span class="sr-only">
                          Wait...
                        </span>
                      </div>
                      <p class="h4 ml-2">
                        Wait...
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col" *ngIf="importStep == 5">
                  <div class="form-check mb-2">
                    <div *ngIf="!importLoadAnimation">
                      <p>
                        <span i18n="ref|Modal import - import complete message@@ref.modal.import.step5.importComplateMessage.importComplate">Import complete.</span><br>
                        <span i18n="ref|Modal import - import complete message@@ref.modal.import.step5.successfullAdded">Successfully added:</span>&nbsp;<span class="h5">{{import.dataImportGoodSum}}</span><br>
                        <span i18n="ref|Modal import - import complete message@@ref.modal.import.step5.error">Errors</span>:&nbsp;<span class="h5">{{import.dataImportBadSum}}</span>
                      </p>
                      <div *ngIf="import.dataImportResultErrorMessage().length">
                        <label i18n="ref|Modal import - log error message@@ref.modal.import.step5.importErrorLog">
                          Import error log:
                        </label>
                        <div class="overflow-auto w-100 border border-dark p-2"
                             style="max-height: 200px; white-space: pre-wrap;">
                          {{import.dataImportResultErrorMessage()}}
                        </div>
                        <button type="button"
                                class="btn btn-blue btn-sm mr-2 mt-2"
                                (click)="saveToFileImportResut()"
                                i18n="ref|Modal import - button save log to file@@ref.modal.import.step5.button.saveLogToFile">
                          Save log to file
                        </button>
                      </div>
                    </div>
                    <div *ngIf="importLoadAnimation" class="d-flex justify-content-center">
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Wait...</span>
                      </div>
                      <p class="h4 ml-2">Wait...</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="row">
                <div class="col">
                  <button *ngIf="importStep != 1 && importStep != 5"
                          type="button"
                          class="btn btn-blue btn-sm mr-2"
                          (click)="decrementImportStep()"
                          i18n="ref|Modal import - button window contoll previous@@ref.modal.import.button.pagePrevious">
                    Previous
                  </button>
                  <button *ngIf="importStep > 1 && importStep < 4"
                          type="button"
                          class="btn btn-blue btn-sm mr-2"
                          (click)="incrementImportStep()"
                          i18n="ref|Modal import - button window contoll next@@ref.modal.import.button.pageNext">
                    Next
                  </button>
                  <button *ngIf="importStep == 4"
                          type="button"
                          class="btn btn-blue btn-sm mr-2"
                          (click)="startImport()"
                          i18n="ref|Modal import - button window import@@ref.modal.import.button.import">
                    Import
                  </button>
                  <button type="button"
                          class="btn btn-secondary btn-sm"
                          data-dismiss="modal"
                          i18n="ref|Modal import - button window close@@ref.modal.import.button.close">
                    Close
                  </button>
                </div>
              </div>
            </div>

            <div *ngIf="errorMessage !== ''" class="ms-modal-message">
              <div class="modal-content w-75">
                <div class="modal-header" [ngClass]="{'bg-info': errorType == 'info',
                                              'bg-warning': errorType == 'warning',
                                              'bg-danger': errorType == 'error'}">
                  <h5 *ngIf="errorType == 'info'"
                      class="modal-title"
                      i18n="ref|Modal import error - header information@@ref.modal.import.errMes.header.inf">
                    Information
                  </h5>
                  <h5 *ngIf="errorType == 'warning'"
                      class="modal-title"
                      i18n="ref|Modal import error - header warning@@ref.modal.import.errMes.header.warn">
                    Warning
                  </h5>
                  <h5 *ngIf="errorType == 'error'"
                      class="modal-title"
                      i18n="ref|Modal import error - header error@@ref.modal.import.errMes.header.err">
                    Error
                  </h5>
                  <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  {{errorMessage}}
                </div>
                <div class="modal-footer">
                  <button type="button"
                          class="btn btn-sm btn-blue"
                          (click)="clearErrorMess()"
                          i18n="ref|Modal import error - button edit@@ref.modal.import.errMes.button.edit">
                    Edit
                  </button>
                  <button type="button"
                          class="btn btn-sm btn-secondary"
                          (click)="closeWithoutSaving()"
                          i18n="ref|Modal import error - button close@@ref.modal.import.errMes.button.close">
                    Close without saving
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
