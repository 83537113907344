import { Component, OnInit } from '@angular/core';
import { Comission, CommercialAct, FilterParams, Statement, StatementData, Baggage, Flight } from '../lost-found-data/lost-found';
import { LostFoundModuleRestApiService } from '../lost-found-data/lost-found-rest-api.service';
import { GlobalSettings } from '../settings/global-settings';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-lost-found-module',
  templateUrl: './lost-found-module.component.html',
  styleUrls: ['./lost-found-module.component.less']
})

export class LostFoundModuleComponent implements OnInit {

  filter = {
    params: new FilterParams(),
    apply: false,
    show: false,
  };

  classes = {
    baggage: Baggage,
  };

  error = {
    errorMessage: '',
    errorType: '',
  };

  pagination = {
    countsPerPage: [20, 40, 80],
    perPage: 20,
    pageActive: 1,
  };

  marks: [];

  display = {
    activeWindow: 'tracingLuggage',
    loading: false,
    baggageBlock: 0,
  };

  references = {
    airlines: [],
    airports: [],
    baggage_categories: [],
    baggage_types: [],
    baggage_materials: [],
    baggage_colors: [],
    baggage_elements: [],
    baggage_statuses: [],
  };

  adminData = {
    storages: [{id: 1, name: 'ВВЛ'}, {id: 2, name: 'ВВТ'}],
    notifications: [{id: 1, name: 'Email'}, {id: 2, name: 'SMS'}],
    worldTracer: [{id: 1, name: 'AHL'}, {id: 2, name: 'AH'}],
  };

  reasons = [
    {id: 1, name: 'Неприбытие багажа'},
    {id: 2, name: 'Недостача багажа'},
    {id: 3, name: 'Повреждение багажа'},
    {id: 4, name: 'Утрата багажа'},
    {id: 5, name: 'Невостребованный багаж'},
    {id: 6, name: 'Засланный багаж'},
  ];

  selectLoadAnimation = {
    airlines: true,
    airports: true,
    baggage_categories: true,
    baggage_types: true,
    baggage_materials: true,
    baggage_colors: true,
    baggage_elements: true,
    baggage_statuses: true,
    storages: true,
    notifications: true,
    worldTracer: true,
  };

  buffer = {
    airlines: [],
    airports: [],
  }

  // Размер отображаемых данных в выпадающем списке
  bufferSize = {
    airlines: 50,
    airports: 50,
  }

  numberOfItemsFromEndBeforeFetchingMore = 10;

  statements: Array<Statement>;
  statement: StatementData = new StatementData();

  commercialAct: CommercialAct = new CommercialAct();

  closeResult: string;

  constructor(
    public restApi: LostFoundModuleRestApiService,
    public globalSettings: GlobalSettings,
    private modalService: NgbModal,
  ) {
    globalSettings.loadDefaultConfig();
  }

  ngOnInit(): void {
    this.loadReferences();
    this.loadStatements();
    // this.loadAdminData();
  }

  async loadStatements() {
    const xRequestId = this.globalSettings.randomUuid;
    const data = await this.restApi.getStatements(this.pagination, xRequestId);
    this.statements = [];
    data.forEach(el => {
      const statement = new Statement();
      Object.assign(statement, el);
      this.statements.push(statement);
    });
  }

  createStatement(content) {
    this.statement = new StatementData();
    if (content) {
      this.open(content);
    }
  }

  async loadStatement(id: number) {
    const xRequestId = this.globalSettings.randomUuid;
    const data = await this.restApi.getStatement(id, xRequestId);
    this.statement = new StatementData();
    Object.assign(this.statement, data);
    console.log(this.statement);
    this.display.baggageBlock = 0;
  }

  openStatement(id: number, content) {
    if (content) {
      this.open(content);
    }
  }

  async addStatement() {
    const xRequestId = this.globalSettings.randomUuid;
    const data = await this.restApi.addStatement(this.statement, xRequestId);
    console.log(data);
    return;
  }

  async saveStatement() {
    const xRequestId = this.globalSettings.randomUuid;
    const data = await this.restApi.updateStatement(this.statement, xRequestId);
    console.log(data);
  }

  async openCommercialAct(id: number, content) {
    const xRequestId = this.globalSettings.randomUuid;
    const data = await this.restApi.getCommercialAct(id, xRequestId);
    this.commercialAct = new CommercialAct();
    Object.assign(this.commercialAct, data);
    console.log(this.commercialAct);
    if (content) {
      this.open(content);
    }
  }

  createCommercialAct(baggageId, content) {
    this.commercialAct = new CommercialAct(baggageId);
    if (content) {
      this.open(content);
    }
  }

  addCommercialAct() {
    return;
  }

  async saveCommercialAct() {
    const xRequestId = this.globalSettings.randomUuid;
    const data = await this.restApi.updateCommercialAct(this.commercialAct, xRequestId);
    console.log(data);
  }

  // Открывает модалку
  open(content) {
    this.modalService.open(content, { size: 'xxl', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  async loadReferences() {
    const xRequestId = this.globalSettings.randomUuid;
    for (const key in this.references) {
      if (Object.prototype.hasOwnProperty.call(this.references, key)) {
        this.selectLoadAnimation[key] = true;
        await this.restApi.getReference(key, xRequestId).then(data => {
          this.references[key] = data;
        }, err => {
          this.displayError(err);
        });
        this.selectLoadAnimation[key] = false;
      }
    }
  }

  async loadAdminData() {
    const xRequestId = this.globalSettings.randomUuid;
    for (const key in this.adminData) {
      if (Object.prototype.hasOwnProperty.call(this.adminData, key)) {
        this.selectLoadAnimation[key] = true;
        await this.restApi.getAdminData(key, xRequestId).then(data => {
          this.adminData[key] = data;
        }, err => {
          this.displayError(err);
        });
        this.selectLoadAnimation[key] = false;
      }
    }
    console.log(this.adminData);

  }

  checkNotifications() {
    this.statement.notifications.enabled = !this.statement.notifications.enabled;
  }

  filterSwitch() {
    this.filter.apply = !this.filter.apply;
  }

  clearFilterParametr(field: string, event) {
    event.stopPropagation();
    if (field === 'date') {
      delete this.filter.params['start'];
      delete this.filter.params['finish'];
    } else {
      delete this.filter.params[field];
    }
  }

  clearFilter() {
    this.filter.params = new FilterParams();
    this.filter.apply = false;
  }

  filterApp() {
    this.filter.apply = true;
  }

  async changePerPage(perPage) {
    this.pagination.perPage = perPage;
  }

  onScrollToEndNgSelect(name) {
    this.fetchMore(name);
  }

  onScrollNgSelect({ end }, name) {
    if (this.selectLoadAnimation[name] || this.references[name].length <= this.buffer[name].length) {
      return;
    }
    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.buffer[name].length) {
      this.fetchMore(name);
    }
  }

  private fetchMore(name) {
    const len = this.buffer[name].length;
    const more = this.references[name].slice(len, this.bufferSize[name] + len);
    this.selectLoadAnimation[name] = true;
    this.buffer[name] = this.buffer[name].concat(more);
    this.selectLoadAnimation[name] = false;
  }

  /**
   * Функция поиска в выпадающим списке по нескольким параметрам
   * @param {string} term Строка для поиска введеня пользователем
   * @param {ReferanceAirline} item Элемент для поиска
   */
   customSelectSearch(term: string, item) {
    term = term.toLowerCase();
    return term.length < 4 ?
            (item.iata && item.iata.toLowerCase().indexOf(term) > -1) ||
            (item.code && item.code[1] && item.code[1].toLowerCase().indexOf(term) > -1)
          : term.length < 5 ?
            (item.icao && item.icao.toLowerCase().indexOf(term) > -1)
          :
            (item.name && item.name[0] && item.name[0].toLowerCase().indexOf(term) > -1) ||
            (item.name && item.name[1] && item.name[1].toLowerCase().indexOf(term) > -1)
  }

  addPersonComission() {
    const person = new Comission();
    this.commercialAct.comission.push(person);
  }

  selectRouteFullItinerary() {
    const routeLength = this.statement.route.full.routeId.length;
    const flightsLength = this.statement.route.full.flights.length;
    if (routeLength > flightsLength) {
      this.statement.route.full.flights.push(new Flight());
    } else if (routeLength < flightsLength) {
      this.statement.route.full.flights.pop();
    }
  }

  parseDate(date: string, time?): Date {
    if (time && date) {
      return new Date(date + 'T' + time);
    } else if (!time && date) {
      return new Date(date);
    }
    return null;
  }

  addItem(array, type) {
    const item = new type();
    array.push(item);
  }

  removeItem(array, item) {
    array.splice(item, 1);
  }

  displayError(err) {
    if (err.type) {
      this.error.errorType = err.type;
      if (typeof err.message === 'object') {
        if (this.globalSettings.language === 'en') {
          this.error.errorMessage = err.message[0];
        } else {
          this.error.errorMessage = err.message[1];
        }
      } else {
        this.error.errorMessage = err.message;
      }
    } else {
      this.error.errorMessage = err;
    }
  }

}
