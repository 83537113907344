import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { GlobalSettings } from '../settings/global-settings';
import { ScheduleModuleRestApiService } from '../schedule-module-data/schedule-module-rest-api.service';
import { ReferanceBoardPass, RandomPassanger } from './referances';
import {
  FlightListItem,
  Flight,
  Route,
  Flights,
  FlightCombined,
  ReferanceStatuses,
  Process,
  ProcessesResources,
  ReferanceDelaySourceOperator,
  FilterParams,
  ReferanceGeo
} from '../schedule-module-data/flight';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.less']
})
export class TestComponent implements OnInit {

  referanceBoardPass: ReferanceBoardPass = new ReferanceBoardPass();
  flights: Array<FlightListItem> = [];
  flight: Flight = new Flight(1);
  selectedFlightId = -1;
  passangerId = 0;
  barCodeText = '';
  channel: any;
  message: any;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  // Текущая просматриваемая вкладка
  activeTab = 'tab-comman';

  constructor(
    private http: HttpClient,
    public restApi: ScheduleModuleRestApiService,
    private globalSettings: GlobalSettings
  ){
    globalSettings.loadDefaultConfig();
    this.loadFlights();
  }

  ngOnInit(): void {
  }

  changeTab(event: { target: { id: string; }; }) {
    this.activeTab = event.target.id;
    this.loadTab();
  }

  loadTab() {
    switch (this.activeTab) {
      case 'tab-comman': {
        break;
      }
      case 'tab-barcode': {
        this.loadFlights();
        this.selectedFlightId = -1;
        break;
      }
    }
  }

  showTab(item: string): boolean {
    return item === this.activeTab;
  }

  sendMessage(channel: string, message: string) {
    return this.addMessage(channel, message).subscribe((data: {}) => {
      console.log('sended' + data);
      this.message = '';
      this.channel = '';
    });
  }

  addMessage(channel: string, message: string) {
    return this.http.post(this.globalSettings.apiTestURL + '/messages/' + channel, message)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // For Barcode generator
  loadFlights() {
    return this.restApi.getFlights(null).subscribe((data: Flights) => {
      console.log(data);
      for (const item of data.departure) {
        let flight = new FlightListItem();
        Object.assign(flight, item);
        this.flights.push(flight);
      }
      if (this.flights.length > 0) {
        this.generateBarcodeForFlight(this.flights[0].id);
      }
    });
  }

  loadFlight(id: number) {
    if (id == 0) {
      return;
    }
    console.log(id);
    return this.restApi.getFlight(id).subscribe((data: Flight) => {
      console.log(data);
      Object.assign(this.flight, data);
      this.flight.route = [];

      for (const item of data.route) {
        let route = new Route();
        Object.assign(route, item);
        this.flight.route.push(route);
      }

      for (const key in data.processes) {
        let processes = new Process();
        Object.assign(processes, data.processes[key]);
        if (processes.resources && processes.resources.length==0) {
          processes.resources.push(new ProcessesResources());
        }
        this.flight.processes[key] = processes;
      }
    });
  }

  async generateBarcodeForFlight(id) {
    console.log('generateBarcodeForFlight');
    this.selectedFlightId = id;
    await this.loadFlight(id);
    // Barcode string
    // M1MAMLEEVA/LARISA     EFVMUYR SVXSVOSU 1419 057Y008E0052 100
    //
    // Format size and example fields:
    // Format Code: size 1 - 'M'
    // Number of Legs Encoded: size 1 - '1'
    // Passenger Name: size 20 - 'MAMLEEVA/LARISA     '
    // Electronic Ticket Indicator: size 1 - 'E'
    // Operating carrier PNR Code: size 7 - 'FVMUYR '
    // FromCityAirportCode: size 3 - 'SVX'
    // To City Airport Code: size 3 - 'SVO'
    // Operating carrier Designator: size 3 - 'SU '
    // Flight Number: size 5 - '1419 '
    // Date of Flight (Julian Date): size 3 - '057'
    // Compartment Code: size 1 - 'Y'
    // Seat Number: size 4 - '008E'
    // Check-in Sequence Number: size 5 - '0052 '
    // Passenger Status: size 1 - '1'
    // Field Size of variable size field (Conditional + Airline item 4): size 2 - '00'
  }

  addName(value) {
    this.referanceBoardPass.name = value;
    this.updateBarcodeLine();
  }

  addTicket(value) {
    this.referanceBoardPass.ticketType = value;
    this.updateBarcodeLine();
  }

  addPNR(value) {
    this.referanceBoardPass.pnr = value;
    this.updateBarcodeLine();
  }

  addSeatNumber(value) {
    this.referanceBoardPass.seatNumber = value;
    this.updateBarcodeLine();
  }

  addSequenceNumber(value) {
    this.referanceBoardPass.sequenceNumber = value;
    this.updateBarcodeLine();
  }

  updateBarcodeLine() {
    this.barCodeText = this.referanceBoardPass.formatCode +
                          this.referanceBoardPass.name +
                          this.referanceBoardPass.ticketType +
                          this.referanceBoardPass.pnr +
                          this.referanceBoardPass.from +
                          this.referanceBoardPass.to +
                          this.referanceBoardPass.airline +
                          this.referanceBoardPass.flight +
                          this.referanceBoardPass.date +
                          this.referanceBoardPass.compartmentCode +
                          this.referanceBoardPass.seatNumber +
                          this.referanceBoardPass.sequenceNumber +
                          this.referanceBoardPass.pasangerStatus +
                          this.referanceBoardPass.fieldSize;
  }

  loadPassanger(number) {
    this.passangerId = number;
    switch (number) {
      case 1:
        this.referanceBoardPass = new ReferanceBoardPass();
        this.referanceBoardPass.name = 'MAMLEEVA/LARISA';
        this.referanceBoardPass.pnr = 'ABC123';
        this.referanceBoardPass.from = this.flight.route[0].airportIata;
        this.referanceBoardPass.to = this.flight.route[1].airportIata;
        this.referanceBoardPass.airline = this.flight.airline;
        this.referanceBoardPass.flight = this.flight.flight;
        this.referanceBoardPass.date = this.flight.route[0].dtDeparture[0].toString();
        this.referanceBoardPass.seatNumber = '8E';
        this.referanceBoardPass.sequenceNumber = '52';
        console.log('this.referanceBoardPass');
        console.log(this.referanceBoardPass);
        this.updateBarcodeLine();
      break;
      case 2:
        this.referanceBoardPass = new ReferanceBoardPass();
        this.referanceBoardPass.name = 'GRIGORYEV/DANIL';
        this.referanceBoardPass.pnr = 'DFH884';
        this.referanceBoardPass.from = this.flight.route[0].airportIata;
        this.referanceBoardPass.to = this.flight.route[1].airportIata;
        this.referanceBoardPass.airline = this.flight.airline;
        this.referanceBoardPass.flight = this.flight.flight;
        this.referanceBoardPass.date = this.flight.route[0].dtDeparture[0].toString();
        this.referanceBoardPass.seatNumber = '3B';
        this.referanceBoardPass.sequenceNumber = '78';
        console.log('this.referanceBoardPass');
        console.log(this.referanceBoardPass);
        this.updateBarcodeLine();
      break;
      case 3:
        this.referanceBoardPass = new ReferanceBoardPass();
        this.referanceBoardPass.name = 'SINITSYN/IGOR';
        this.referanceBoardPass.pnr = 'JJJ909';
        this.referanceBoardPass.from = this.flight.route[0].airportIata;
        this.referanceBoardPass.to = this.flight.route[1].airportIata;
        this.referanceBoardPass.airline = this.flight.airline;
        this.referanceBoardPass.flight = this.flight.flight;
        this.referanceBoardPass.date = this.flight.route[0].dtDeparture[0].toString();
        this.referanceBoardPass.seatNumber = '10A';
        this.referanceBoardPass.sequenceNumber = '107';
        console.log('this.referanceBoardPass');
        console.log(this.referanceBoardPass);
        this.updateBarcodeLine();
      break;
      case 4:
        this.referanceBoardPass = new ReferanceBoardPass();
        this.referanceBoardPass.name = 'BOLGARIN/EVGENII';
        this.referanceBoardPass.pnr = 'JH745';
        this.referanceBoardPass.from = this.flight.route[0].airportIata;
        this.referanceBoardPass.to = this.flight.route[1].airportIata;
        this.referanceBoardPass.airline = this.flight.airline;
        this.referanceBoardPass.flight = this.flight.flight;
        this.referanceBoardPass.date = this.flight.route[0].dtDeparture[0].toString();
        this.referanceBoardPass.seatNumber = '12F';
        this.referanceBoardPass.sequenceNumber = '45';
        console.log('this.referanceBoardPass');
        console.log(this.referanceBoardPass);
        this.updateBarcodeLine();
      break;
      case 5:
        // Random passanger
        let randomPassanger = new RandomPassanger();
        this.referanceBoardPass = new ReferanceBoardPass();
        this.referanceBoardPass.name = randomPassanger.name;
        this.referanceBoardPass.pnr = randomPassanger.pnr;
        this.referanceBoardPass.from = this.flight.route[0].airportIata;
        this.referanceBoardPass.to = this.flight.route[1].airportIata;
        this.referanceBoardPass.airline = this.flight.airline;
        this.referanceBoardPass.flight = this.flight.flight;
        this.referanceBoardPass.date = this.flight.route[0].dtDeparture[0].toString();
        this.referanceBoardPass.seatNumber = randomPassanger.seatNumber;
        this.referanceBoardPass.sequenceNumber = randomPassanger.sequenceNumber;
        console.log('this.referanceBoardPass');
        console.log(this.referanceBoardPass);
        this.updateBarcodeLine();
      break;
      case 6:
        this.referanceBoardPass = new ReferanceBoardPass();
        this.referanceBoardPass.name = 'PNEV/KONSTANTIN';
        this.referanceBoardPass.pnr = 'FGW394';
        this.referanceBoardPass.from = this.flight.route[0].airportIata;
        this.referanceBoardPass.to = this.flight.route[1].airportIata;
        this.referanceBoardPass.airline = this.flight.airline;
        this.referanceBoardPass.flight = this.flight.flight;
        this.referanceBoardPass.date = this.flight.route[0].dtDeparture[0].toString();
        this.referanceBoardPass.seatNumber = 'INF';
        this.referanceBoardPass.sequenceNumber = '345';
        console.log('this.referanceBoardPass');
        console.log(this.referanceBoardPass);
        this.updateBarcodeLine();
      break;
      case 7:
        this.referanceBoardPass = new ReferanceBoardPass();
        this.referanceBoardPass.name = 'MAZHARTSEV/DENIS';
        this.referanceBoardPass.pnr = 'RPT489';
        this.referanceBoardPass.from = this.flight.route[0].airportIata;
        this.referanceBoardPass.to = this.flight.route[1].airportIata;
        this.referanceBoardPass.airline = this.flight.airline;
        this.referanceBoardPass.flight = this.flight.flight;
        this.referanceBoardPass.date = this.flight.route[0].dtDeparture[0].toString();
        this.referanceBoardPass.seatNumber = '';
        this.referanceBoardPass.sequenceNumber = '345';
        console.log('this.referanceBoardPass');
        console.log(this.referanceBoardPass);
        this.updateBarcodeLine();
      break;
      default:
        this.referanceBoardPass = new ReferanceBoardPass();
        this.referanceBoardPass.name = 'KRUGLOV/SERGEI';
        this.referanceBoardPass.pnr = 'HIO583';
        this.referanceBoardPass.from = this.flight.route[0].airportIata;
        this.referanceBoardPass.to = this.flight.route[1].airportIata;
        this.referanceBoardPass.airline = this.flight.airline;
        this.referanceBoardPass.flight = this.flight.flight;
        this.referanceBoardPass.date = this.flight.route[0].dtDeparture[0].toString();
        this.referanceBoardPass.seatNumber = '34D';
        this.referanceBoardPass.sequenceNumber = '103';
        console.log('this.referanceBoardPass');
        console.log(this.referanceBoardPass);
        this.updateBarcodeLine();
        break;
    }
  }

  get currentFlightPlanDateTime(): string {
    if (this.flight && this.flight.route.length > 0) {
      return this.flight.route[0].dtDeparture[0].toString();
    } else {
      return '';
    }
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
