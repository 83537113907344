export class FilterParams {
  start: Date;
  finish: Date;
  status: string;
}

export class Statement {
  statementId: number;
  baggageId: number;
  mark: string;
  arrival: {
    flight: string;
    dt: Date;
  };
  statementName: string;
  dtInsert: Date;
  passenger: string;
  baggage: {
    reason: string;
    tag: string;
    status: string;
    statusId: number;
    wt: string;
  };
}

export class StatementData {
  constructor() {
    this.id = null;
    this.notifications = {
      enabled: false,
      typesId: [],
      typesName: []
    };
    this.notifications = {
      enabled: false,
      typesId: [],
      typesName: [],
    };
    this.route = new Route();
    this.passenger = {
      passenger: '',
      phone1: '',
      phone2: '',
      email: '',
      address: '',
    };
    this.baggage = [new Baggage()];
  }

  id: number;
  name: string;
  dtInsert: Date;
  note: string;
  notifications: {
    enabled: boolean;
    typesId: Array<number>;
    typesName: Array<string>;
  };
  route: Route;
  passenger: {
    passenger: string;
    phone1: string;
    phone2: string;
    email: string;
    address: string;
  };
  baggage: Array<Baggage>;

  get fullRoute() {
    return this.route.full.route.join('-');
  }
}

export class Flight {
  airlineId: number;
  airline: string;
  flight: string;
  dt: Date;
}

export class Baggage {
  constructor() {
    this.weight = {
      expected: null,
      actual: null,
    };
    this.characteristics = {
      categoryId: null,
      typeId: null,
      materialId: null,
      colorId: null,
      elements: [],
      description: null,
    },
    this.worldTracer = {
      typeId: null,
      typeName: null,
      statement: null,
    },
    this.forwarding = {
      arrival: {
        flight: null,
        dt: null,
        statusId: null,
        statusName: null,
      },
      departure: {
        flight: null,
        dt: null,
        statusId: null,
        statusName: null,
        tag: null,
      }
    },
    this.delivery = {
      enabled: null,
      address: null,
      note: null,
    },
    this.storage = {
      id: null,
    },
    this.media = [];
  }
  id: number;
  reasonId: number;
  reason: string;
  mark: string;
  tag: string;
  statusId: number;
  commercialAct: string;
  weight: {
    expected: number;
    actual: number;
  };
  characteristics: {
    categoryId: number;
    typeId: number;
    materialId: number;
    colorId: number;
    elements: Array<number>;
    description: string;
  };
  worldTracer: {
    typeId: number;
    typeName: string;
    statement: string;
  };
  forwarding: {
    arrival: {
      flight: string;
      dt: Date;
      statusId: number;
      statusName: string;
    };
    departure: {
      flight: string;
      dt: Date;
      statusId: number
      statusName: string;
      tag: string;
    }
  };
  delivery: {
    enabled: boolean;
    address: string;
    note: string;
  };
  storage: {
    id: number;
  };
  media: Array<Media>;
}

class Media {
  id: string;
  note: string;
}

class Route {
  constructor() {
    this.arrival = {
      airlineId: null,
      airline: null,
      flight: null,
      dt: null,
      departure: null,
      arrival: null,
      ticket: null,
      pnr: null,
    };
    this.full = {
      routeId: [],
      route: [],
      flights: [],
    };
  }
  arrival: {
    airlineId: number;
    airline: string;
    flight: string;
    dt: Date;
    departure: string;
    arrival: string;
    ticket: string;
    pnr: string;
  };
  full: {
    routeId: Array<number>;
    route: Array<string>;
    flights: Array<Flight>;
  };
}

export class CommercialAct {
  constructor(baggageId?) {
    this.baggageId = baggageId || null;
    this.comission = [];
    this.dtInsert = null;
    this.passenger = {
      passenger: null,
      phone1: null,
      phone2: null,
      passport: null,
      dateOfBirth: null,
    };
    this.route = new Route();
  }
  id: number;
  baggageId: number;
  name: string;
  dtInsert: Date;
  airportId: number;
  airlineId: number;
  reasonId: number;
  passenger: {
    passenger: string;
    phone1: string;
    phone2: string;
    passport: string;
    dateOfBirth: Date;
  };
  route: Route;
  comission: Array<Comission>;
  baggage: {
    tag: string;
    declaredValue: number;
    receipt: string;
    defectFromBaggageStatement: string;
    infoFromTag: {
      pieces: number;
      weight: number;
      departure: number;
      arrival: number;
    },
    pieces: number;
    weight: number;
    isPacked: boolean;
    isOpened: boolean;
    defect: string;
    additionalInfo: string;
    note: string;
    seal: {
      enabled: boolean;
      name: string;
    }
  };
}

export class Comission {
  position: string;
  fio: string;
}
