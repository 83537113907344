<ul class="nav nav-tabs mt-2">
  <li class="nav-item">
    <a id="tab-comman" [ngClass]="{'active': showTab('tab-comman')}" class="nav-link"
       (click)="changeTab($event)">
       Comman
    </a>
  </li>
  <li class="nav-item">
    <a id="tab-barcode" [ngClass]="{'active': showTab('tab-barcode')}" class="nav-link"
       (click)="changeTab($event)">
       Barcode generator
    </a>
  </li>
</ul>

<div *ngIf="showTab('tab-comman')" class="bg-white p-3">
  <div class="row">
    <div class="col-12 mb-4">
      <label>Channel</label>
      <select id="channel" class="form-control" name="channel" [value]="channel" (input)="channel = $event.target.value">
        <option value="aftn" selected>aftn</option>
        <option value="sitatex">sitatex</option>
      </select>
    </div>
    <div class="col-12 mb-4">
      <label>Text message (header + body)</label>
      <textarea name="message" id="message" class="form-control" [(ngModel)]="message" cols="30" rows="10"></textarea>
    </div>
    <div class="col-12">
      <button class="btn btn-primary" (click)="sendMessage(channel, message)">Отправить</button>
    </div>
  </div> 

</div>

<div *ngIf="showTab('tab-barcode')" class="bg-white p-3">
  <div class="row">
    <div class="col-12 mb-4">
      <label>Select flight</label>
      <select id="flight"
              class="form-control"
              name="flight"
              [value]="selectedFlightId"
              (input)="generateBarcodeForFlight(+$event.target.value)">
        <option *ngFor="let item of flights" 
                value="{{item.id}}">
          <span class="font-weight-bold">Flight: </span>{{item.flight}} <span class="font-weight-bold">To: </span>{{item.route[0].iata}} ({{item.route[0].name}}) <span class="font-weight-bold">Sch: </span>{{item.times[0]}}
        </option>
      </select>
    </div>
    <div class="col-12 mb-4">
      <button class="btn btn-primary mr-2"
              data-toggle="modal"
              data-target=".bd-example-modal-xl"
              (click)="loadPassanger(1)">
        Passanger 1
      </button>
      <button class="btn btn-primary mx-2"
              data-toggle="modal"
              data-target=".bd-example-modal-xl"
              (click)="loadPassanger(2)">
        Passanger 2
      </button>
      <button class="btn btn-primary mx-2"
              data-toggle="modal"
              data-target=".bd-example-modal-xl"
              (click)="loadPassanger(3)">
        Passanger 3
      </button>
      <button class="btn btn-primary mx-2"
              data-toggle="modal"
              data-target=".bd-example-modal-xl"
              (click)="loadPassanger(4)">
        Passanger 4
      </button>
      <button class="btn btn-primary mx-2"
              data-toggle="modal"
              data-target=".bd-example-modal-xl"
              (click)="loadPassanger(6)">
        Infant SEAT: INF
      </button>
      <button class="btn btn-primary mx-2"
              data-toggle="modal"
              data-target=".bd-example-modal-xl"
              (click)="loadPassanger(7)">
        Infant SEAT: Empty
      </button>
      <button class="btn btn-primary mx-2"
              data-toggle="modal"
              data-target=".bd-example-modal-xl"
              (click)="loadPassanger(5)">
        Random Passanger
      </button>
    </div>
  </div> 
</div>

<div *ngIf="showTab('tab-barcode')">
    <div #airportFormFade class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog"
         aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content bg-light-gray">
          <div class="modal-header">
            <h5 class="modal-title">
              Passanger board pass
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-2">
              <div class="col-12">
                  <p class="h4 text-dark">Edit field and press "Enter" to generate new Barcode</p>
              </div>
            </div>
            <hr>
            <div class="row mb-2">
              <div class="col-2">
                <label class="w-100">
                  Format Code
                </label>
                <input type="text"
                       class="form-control form-control-sm"
                       name="formatCode"
                       #formatCode="ngModel"
                       [ngModel]="referanceBoardPass.formatCode"
                       minlength="2"
                       maxlength="2"
                       disabled>
              </div>
              <div class="col-4">
                <label class="w-100">
                  Passenger Name
                </label>
                  <input type="text"
                         class="form-control form-control-sm"
                         name="name"
                         #name="ngModel"
                         [ngModel]="referanceBoardPass.name"
                         (keyup.enter)="addName($event.target.value)"
                         minlength="0"
                         maxlength="20">
              </div>
              <div class=" col-3">
                <label class="w-100">
                  Ticket Type
                </label>
                  <input type="text"
                         class="form-control form-control-sm"
                         name="ticketType"
                         #ticketType="ngModel"
                         [ngModel]="referanceBoardPass.ticketType"
                         (keyup.enter)="addTicket($event.target.value)"
                         minlength="1"
                         maxlength="1">
              </div>
              <div class=" col-3">
                <label class="w-100">
                  PNR Code
                </label>
                  <input type="text"
                         class="form-control form-control-sm"
                         name="pnr"
                         #pnr="ngModel"
                         [ngModel]="referanceBoardPass.pnr"
                         (keyup.enter)="addPNR($event.target.value)"
                         minlength="0"
                         maxlength="7">
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-2">
                <label class="w-100">
                  From Airport
                </label>
                  <input type="text"
                         class="form-control form-control-sm"
                         name="from"
                         #from="ngModel"
                         [ngModel]="referanceBoardPass.from"
                         minlength="3"
                         maxlength="3"
                         disabled>
              </div>
              <div class="col-2">
                <label class="w-100">
                  To Airport
                </label>
                  <input type="text"
                         class="form-control form-control-sm"
                         name="to"
                         #to="ngModel"
                         [ngModel]="referanceBoardPass.to"
                         minlength="3"
                         maxlength="3"
                         disabled>
              </div>
              <div class="col-2">
                <label class="w-100">
                  Airline
                </label>
                  <input type="text"
                         class="form-control form-control-sm"
                         name="airline"
                         #airline="ngModel"
                         [ngModel]="referanceBoardPass.airline"
                         minlength="2"
                         maxlength="3"
                         disabled>
              </div>
              <div class="col-2">
                <label class="w-100">
                  Flight
                </label>
                  <input type="text"
                         class="form-control form-control-sm"
                         name="flight"
                         #flight="ngModel"
                         [ngModel]="referanceBoardPass.flight"
                         minlength="0"
                         maxlength="5"
                         disabled>
              </div>
              <div class="col-2">
                <label class="w-100">
                  Day of Flight
                </label>
                  <input type="text"
                         class="form-control form-control-sm"
                         name="date"
                         #date="ngModel"
                         [ngModel]="referanceBoardPass.date"
                         minlength="0"
                         maxlength="3"
                         disabled>
              </div>
              <div class="col-2">
                <label class="w-100">
                  Date of Flight
                </label>
                  <input type="text"
                         class="form-control form-control-sm"
                         name="date"
                         #date="ngModel"
                         [ngModel]="currentFlightPlanDateTime | date: 'dd.MM.yyyy'"
                         disabled>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-2">
                <label class="w-100">
                  Compartment Code
                </label>
                  <input type="text"
                         class="form-control form-control-sm"
                         name="compartmentCode"
                         #compartmentCode="ngModel"
                         [ngModel]="referanceBoardPass.compartmentCode"
                         disabled>
              </div>
              <div class="col-3">
                <label class="w-100">
                  Seat Number
                </label>
                  <input type="text"
                         class="form-control form-control-sm"
                         name="seatNumber"
                         #seatNumber="ngModel"
                         [ngModel]="referanceBoardPass.seatNumber"
                         (keyup.enter)="addSeatNumber($event.target.value)"
                         minlength="0"
                         maxlength="4">
              </div>
              <div class="col-3">
                <label class="w-100">
                  Sequence Number
                </label>
                  <input type="text"
                         class="form-control form-control-sm"
                         name="sequenceNumber"
                         #sequenceNumber="ngModel"
                         [ngModel]="referanceBoardPass.sequenceNumber"
                         (keyup.enter)="addSequenceNumber($event.target.value)"
                         minlength="0"
                         maxlength="5">
              </div>
              <div class="col-2">
                <label class="w-100">
                  Passenger Status
                </label>
                  <input type="text"
                         class="form-control form-control-sm"
                         name="pasangerStatus"
                         #pasangerStatus="ngModel"
                         [ngModel]="referanceBoardPass.pasangerStatus"
                         disabled>
              </div>
              <div class="col-2">
                <label class="w-100">
                  Field Size
                </label>
                  <input type="text"
                         class="form-control form-control-sm"
                         name="fieldSize"
                         #fieldSize="ngModel"
                         [ngModel]="referanceBoardPass.fieldSize"
                         disabled>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12 pb-4">
                <label class="w-100">
                  Barcode PDF417 text
                </label>
                  <input type="text"
                         class="form-control form-control-sm"
                         name="compartmentCode"
                         #compartmentCode="ngModel"
                         [(ngModel)]="barCodeText">
              </div>
              <div class="col-12 pt-1 bg-white d-flex justify-content-center">
                <pdf417barcode [text]="barCodeText"
                     [opts]="{ pxw: 2, pxh: 2, errlvl: -1 }">
                </pdf417barcode>  
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button *ngIf="passangerId == 5"
                    type="button"
                    class="btn btn-primary btn-sm"
                    (click)="loadPassanger(5)">
              Random
            </button>
            <button type="button"
                    class="btn btn-secondary btn-sm"
                    data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
</div>

