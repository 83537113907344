export class Flight {
  constructor() {
    this.name = '';
    this.standTypeId = null;
    this.dtRange = [new Date(), new Date('2099-01-01')];
  }
  id: number;
  name: string;
  standTypeId: number;
  standTypeName: Array<string>;
  dtRange: Array<Date>;
  lastupdate: Date;

  get dtRangeStart(): Date {
      if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0]!==null) {
      return new Date(this.dtRange[0]);
      } else {
      return null;
      }
  }

  set dtRangeStart(value: Date) {
      this.dtRange[0] = value;
  }

  get dtRangeFinish(): Date {
      if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1]!==null) {
      return new Date(this.dtRange[1]);
      } else {
      return null;
      }
  }

  set dtRangeFinish(value: Date) {
      this.dtRange[1] = value;
  }
}

export class Terminal {
  constructor() {
    this.name = '';
    this.dtRange = [new Date(), new Date('2099-01-01')];
  }
  id: number;
  name: string;
  dtRange: Array<Date>;
  lastupdate: Date;

  get dtRangeStart(): Date {
      if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0]!==null) {
      return new Date(this.dtRange[0]);
      } else {
      return null;
      }
  }

  set dtRangeStart(value: Date) {
      this.dtRange[0] = value;
  }

  get dtRangeFinish(): Date {
      if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1]!==null) {
      return new Date(this.dtRange[1]);
      } else {
      return null;
      }
  }

  set dtRangeFinish(value: Date) {
      this.dtRange[1] = value;
  }
}
