import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  ReferanceAirport,
  ReferanceAirline,
  ReferanceAircraft,
  ReferanceMovement,
  ReferanceDelay,
  ReferanceSeason,
  ReferanceRegion,
  ReferanceCountry,
  ReferanceCity,
  ReferanceAircraftKind,
  ReferanceAircraftClass,
  ReferanceAircraftType,
  ReferanceTail,
  ReferanceDelaySource,
  ReferanceRegularitie,
  ReferanceStand,
  ReferanceStandType,
  ReferanceTerminal
} from '../referance-module-data/referance';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { GlobalSettings } from '../settings/global-settings';

@Injectable({
  providedIn: 'root'
})
export class ReferanceModuleRestApiService {

  constructor(private http: HttpClient, private globalSettings: GlobalSettings) {
    globalSettings.loadDefaultConfig();
  }

  httpOptions = {
    headers: new HttpHeaders({
                               'Content-Type': 'application/json'
                             })
  };

  // Airports
  getAirports(): Observable<ReferanceAirport[]> {
    return this.http.get<ReferanceAirport[]>(this.globalSettings.apiReferanceURL +
                                               '/airports')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getAirport(id: number): Observable<ReferanceAirport> {
    return this.http.get<ReferanceAirport>(this.globalSettings.apiReferanceURL +
                                             '/airports/' +
                                             id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  createAirport(airport: any): Observable<ReferanceAirport> {
    return this.http.post<ReferanceAirport>(this.globalSettings.apiReferanceURL +
                                              '/airports',
                                            JSON.stringify(airport),
                                            this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateAirport(id: string, airport: ReferanceAirport): Observable<ReferanceAirport> {
    return this.http.put<ReferanceAirport>(this.globalSettings.apiReferanceURL +
                                             '/airports/' +
                                             id,
                                           JSON.stringify(airport),
                                           this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  deleteAirport(id: string) {
    return this.http.delete<ReferanceAirport>(this.globalSettings.apiReferanceURL +
                                                '/airports/' + id,
                                              this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Airlines
  getAirlines(): Observable<ReferanceAirline[]> {
    return this.http.get<ReferanceAirline[]>(this.globalSettings.apiReferanceURL +
                                               '/airlines')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getAirline(id: number): Observable<ReferanceAirline> {
    return this.http.get<ReferanceAirline>(this.globalSettings.apiReferanceURL +
                                             '/airlines/' +
                                             id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  createAirline(airline): Observable<ReferanceAirline> {
    return this.http.post<ReferanceAirline>(this.globalSettings.apiReferanceURL +
                                              '/airlines',
                                            JSON.stringify(airline),
                                            this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateAirline(id: string, airline: ReferanceAirline): Observable<ReferanceAirline> {
    return this.http.put<ReferanceAirline>(this.globalSettings.apiReferanceURL +
                                           '/airlines/' +
                                           id,
                                           JSON.stringify(airline),
                                           this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  deleteAirline(id: string) {
    return this.http.delete<ReferanceAirline>(this.globalSettings.apiReferanceURL +
                                              '/airlines/' +
                                              id,
                                              this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Aircraft types
  getAircraftTypes(): Observable<ReferanceAircraftType[]> {
    return this.http.get<ReferanceAircraftType[]>(this.globalSettings.apiReferanceURL +
                                                  '/aircraft_types')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getAircraftType(id: number): Observable<ReferanceAircraftType> {
    return this.http.get<ReferanceAircraftType>(this.globalSettings.apiReferanceURL +
                                                '/aircraft_types/' +
                                                id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  createAircraftType(aircraftType: ReferanceAircraftType): Observable<ReferanceAircraftType> {
    return this.http.post<ReferanceAircraftType>(this.globalSettings.apiReferanceURL +
                                                '/aircraft_types',
                                                JSON.stringify(aircraftType),
                                                this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateAircraftType(id: string, aircraftType: ReferanceAircraftType): Observable<ReferanceAircraftType> {
    return this.http.put<ReferanceAircraftType>(this.globalSettings.apiReferanceURL +
                                                '/aircraft_types/' +
                                                id,
                                                JSON.stringify(aircraftType),
                                                this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  deleteAircraftType(id: string) {
    return this.http.delete<ReferanceAircraftType>(this.globalSettings.apiReferanceURL +
                                                   '/aircraft_types/' +
                                                   id,
                                                   this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Movements
  getMovements(): Observable<ReferanceMovement[]> {
    return this.http.get<ReferanceMovement[]>(this.globalSettings.apiReferanceURL +
                                                '/movement_types')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getMovement(id: number): Observable<ReferanceMovement> {
    return this.http.get<ReferanceMovement>(this.globalSettings.apiReferanceURL +
                                              '/movement_types/' +
                                              id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  createMovement(movement: ReferanceMovement): Observable<ReferanceMovement> {
    return this.http.post<ReferanceMovement>(this.globalSettings.apiReferanceURL +
                                               '/movement_types',
                                             JSON.stringify(movement),
                                             this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateMovement(id, movement): Observable<ReferanceMovement> {
    return this.http.put<ReferanceMovement>(this.globalSettings.apiReferanceURL +
                                              '/movement_types/' +
                                              id,
                                            JSON.stringify(movement),
                                            this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  deleteMovement(id: string) {
    return this.http.delete<ReferanceMovement>(this.globalSettings.apiReferanceURL +
                                                 '/movement_types/' +
                                                 id,
                                               this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Delays
  getDelays(): Observable<ReferanceDelay[]> {
    return this.http.get<ReferanceDelay[]>(this.globalSettings.apiReferanceURL +
                                             '/delays')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getDelay(id: number): Observable<ReferanceDelay> {
    return this.http.get<ReferanceDelay>(this.globalSettings.apiReferanceURL +
                                           '/delays/' +
                                           id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  createDelay(delay: ReferanceDelay): Observable<ReferanceDelay> {
    return this.http.post<ReferanceDelay>(this.globalSettings.apiReferanceURL +
                                            '/delays',
                                          JSON.stringify(delay),
                                          this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateDelay(id: string, delay: ReferanceDelay): Observable<ReferanceDelay> {
    return this.http.put<ReferanceDelay>(this.globalSettings.apiReferanceURL +
                                           '/delays/' +
                                           id,
                                         JSON.stringify(delay),
                                         this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Delay Sources
  getDelaySources(): Observable<ReferanceDelaySource[]> {
    return this.http.get<ReferanceDelaySource[]>(this.globalSettings.apiReferanceURL +
                                             '/delay_sources')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getDelaySource(id: number): Observable<ReferanceDelaySource> {
    return this.http.get<ReferanceDelaySource>(this.globalSettings.apiReferanceURL +
                                           '/delay_sources/' +
                                           id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  createDelaySource(delay: ReferanceDelaySource): Observable<ReferanceDelaySource> {
    return this.http.post<ReferanceDelaySource>(this.globalSettings.apiReferanceURL +
                                            '/delay_sources',
                                          JSON.stringify(delay),
                                          this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateDelaySource(id: string, delay: ReferanceDelaySource): Observable<ReferanceDelaySource> {
    return this.http.put<ReferanceDelaySource>(this.globalSettings.apiReferanceURL +
                                           '/delay_sources/' +
                                           id,
                                         JSON.stringify(delay),
                                         this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Seasons
  getSeasons(): Observable<ReferanceSeason[]> {
    return this.http.get<ReferanceSeason[]>(this.globalSettings.apiReferanceURL +
                                              '/seasons')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getSeason(id: number): Observable<ReferanceSeason> {
    return this.http.get<ReferanceSeason>(this.globalSettings.apiReferanceURL +
                                            '/seasons/' +
                                            id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  createSeason(season: ReferanceSeason): Observable<ReferanceSeason> {
    return this.http.post<ReferanceSeason>(this.globalSettings.apiReferanceURL +
                                             '/seasons',
                                           JSON.stringify(season),
                                           this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateSeason(id: string, season: ReferanceSeason): Observable<ReferanceSeason> {
    return this.http.put<ReferanceSeason>(this.globalSettings.apiReferanceURL +
                                            '/seasons/' +
                                            id,
                                          JSON.stringify(season),
                                          this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  deleteSeason(id: string) {
    return this.http.delete<ReferanceSeason>(this.globalSettings.apiReferanceURL +
                                               '/seasons/' +
                                               id,
                                             this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Regions
  getRegions(): Observable<ReferanceRegion[]> {
    return this.http.get<ReferanceRegion[]>(this.globalSettings.apiReferanceURL +
                                              '/regions')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getRegion(id: number): Observable<ReferanceRegion> {
    return this.http.get<ReferanceRegion>(this.globalSettings.apiReferanceURL +
                                            '/regions/' +
                                            id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  createRegion(region: ReferanceRegion): Observable<ReferanceRegion> {
    return this.http.post<ReferanceRegion>(this.globalSettings.apiReferanceURL +
                                             '/regions',
                                           JSON.stringify(region),
                                           this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateRegion(id: string, region: ReferanceRegion): Observable<ReferanceRegion> {
    return this.http.put<ReferanceRegion>(this.globalSettings.apiReferanceURL +
                                            '/regions/' +
                                            id,
                                          JSON.stringify(region),
                                          this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Countries
  getCountries(): Observable<ReferanceCountry[]> {
    return this.http.get<ReferanceCountry[]>(this.globalSettings.apiReferanceURL +
                                              '/countries')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCountry(id: number): Observable<ReferanceCountry> {
    return this.http.get<ReferanceCountry>(this.globalSettings.apiReferanceURL +
                                            '/countries/' +
                                            id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  createCountry(country: ReferanceCountry): Observable<ReferanceCountry> {
    return this.http.post<ReferanceCountry>(this.globalSettings.apiReferanceURL +
                                             '/countries',
                                           JSON.stringify(country),
                                           this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateCountry(id: string, country: ReferanceCountry): Observable<ReferanceCountry> {
    return this.http.put<ReferanceCountry>(this.globalSettings.apiReferanceURL +
                                            '/countries/' +
                                            id,
                                          JSON.stringify(country),
                                          this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Cities
  getCities(): Observable<ReferanceCity[]> {
    return this.http.get<ReferanceCity[]>(this.globalSettings.apiReferanceURL +
                                              '/cities')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCity(id: number): Observable<ReferanceCity> {
    return this.http.get<ReferanceCity>(this.globalSettings.apiReferanceURL +
                                            '/cities/' +
                                            id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  createCity(city: ReferanceCity): Observable<ReferanceCity> {
    return this.http.post<ReferanceCity>(this.globalSettings.apiReferanceURL +
                                             '/cities',
                                           JSON.stringify(city),
                                           this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateCity(id: string, city: ReferanceCity): Observable<ReferanceCity> {
    return this.http.put<ReferanceCity>(this.globalSettings.apiReferanceURL +
                                            '/cities/' +
                                            id,
                                          JSON.stringify(city),
                                          this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Aircraft Kinds
  getAircraftKinds(): Observable<ReferanceAircraftKind[]> {
    return this.http.get<ReferanceAircraftKind[]>(this.globalSettings.apiReferanceURL +
                                              '/aircraft_kinds')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getAircraftKind(id: number): Observable<ReferanceAircraftKind> {
    return this.http.get<ReferanceAircraftKind>(this.globalSettings.apiReferanceURL +
                                            '/aircraft_kinds/' +
                                            id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  createAircraftKind(kind: ReferanceAircraftKind): Observable<ReferanceAircraftKind> {
    return this.http.post<ReferanceAircraftKind>(this.globalSettings.apiReferanceURL +
                                             '/aircraft_kinds',
                                           JSON.stringify(kind),
                                           this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateAircraftKind(id: string, kind: ReferanceAircraftKind): Observable<ReferanceAircraftKind> {
    return this.http.put<ReferanceAircraftKind>(this.globalSettings.apiReferanceURL +
                                            '/aircraft_kinds/' +
                                            id,
                                          JSON.stringify(kind),
                                          this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Aircraft Classes
  getAircraftClasses(): Observable<ReferanceAircraftClass[]> {
    return this.http.get<ReferanceAircraftClass[]>(this.globalSettings.apiReferanceURL +
                                              '/aircraft_classes')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getAircraftClass(id: number): Observable<ReferanceAircraftClass> {
    return this.http.get<ReferanceAircraftClass>(this.globalSettings.apiReferanceURL +
                                            '/aircraft_classes/' +
                                            id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  createAircraftClass(aircraft_class: ReferanceAircraftClass): Observable<ReferanceAircraftClass> {
    return this.http.post<ReferanceAircraftClass>(this.globalSettings.apiReferanceURL +
                                             '/aircraft_classes',
                                           JSON.stringify(aircraft_class),
                                           this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateAircraftClass(id: string, aircraft_class: ReferanceAircraftClass): Observable<ReferanceAircraftClass> {
    return this.http.put<ReferanceAircraftClass>(this.globalSettings.apiReferanceURL +
                                            '/aircraft_classes/' +
                                            id,
                                          JSON.stringify(aircraft_class),
                                          this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Tails
  getTails(): Observable<ReferanceTail[]> {
    return this.http.get<ReferanceTail[]>(this.globalSettings.apiReferanceURL +
                                              '/tails')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getTail(id: number): Observable<ReferanceTail> {
    return this.http.get<ReferanceTail>(this.globalSettings.apiReferanceURL +
                                            '/tails/' +
                                            id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  createTail(tail: ReferanceTail): Observable<ReferanceTail> {
    return this.http.post<ReferanceTail>(this.globalSettings.apiReferanceURL +
                                             '/tails',
                                           JSON.stringify(tail),
                                           this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateTail(id: string, tail: ReferanceTail): Observable<ReferanceTail> {
    return this.http.put<ReferanceTail>(this.globalSettings.apiReferanceURL +
                                            '/tails/' +
                                            id,
                                          JSON.stringify(tail),
                                          this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Regularities
  getRegularities(): Observable<ReferanceRegularitie[]> {
    return this.http.get<ReferanceRegularitie[]>(this.globalSettings.apiReferanceURL +
                                             '/regularities')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getRegularitie(id: number): Observable<ReferanceRegularitie> {
    return this.http.get<ReferanceRegularitie>(this.globalSettings.apiReferanceURL +
                                           '/regularities/' +
                                           id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  createRegularitie(regularitie: ReferanceRegularitie): Observable<ReferanceRegularitie> {
    return this.http.post<ReferanceRegularitie>(this.globalSettings.apiReferanceURL +
                                            '/regularities',
                                          JSON.stringify(regularitie),
                                          this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateRegularitie(id: string, regularitie: ReferanceRegularitie): Observable<ReferanceRegularitie> {
    return this.http.put<ReferanceRegularitie>(this.globalSettings.apiReferanceURL +
                                           '/regularities/' +
                                           id,
                                         JSON.stringify(regularitie),
                                         this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Stand
  getStands(): Observable<ReferanceStand[]> {
    return this.http.get<ReferanceStand[]>(this.globalSettings.apiReferanceURL +
                                             '/stands')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getStand(id: number): Observable<ReferanceStand> {
    return this.http.get<ReferanceStand>(this.globalSettings.apiReferanceURL +
                                           '/stands/' +
                                           id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  createStand(stand: ReferanceStand): Observable<ReferanceStand> {
    return this.http.post<ReferanceStand>(this.globalSettings.apiReferanceURL +
                                          '/stands',
                                          JSON.stringify(stand),
                                          this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateStand(id: string, stand: ReferanceStand): Observable<ReferanceStand> {
    return this.http.put<ReferanceStand>(this.globalSettings.apiReferanceURL +
                                           '/stands/' +
                                           id,
                                           JSON.stringify(stand),
                                           this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Stand Type
  getStandTypes(): Observable<ReferanceStandType[]> {
    return this.http.get<ReferanceStandType[]>(this.globalSettings.apiReferanceURL +
                                             '/stand_types')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getStandType(id: number): Observable<ReferanceStandType> {
    return this.http.get<ReferanceStandType>(this.globalSettings.apiReferanceURL +
                                           '/stand_types/' +
                                           id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  createStandType(standType: ReferanceStandType): Observable<ReferanceStandType> {
    return this.http.post<ReferanceStandType>(this.globalSettings.apiReferanceURL +
                                          '/stand_types',
                                          JSON.stringify(standType),
                                          this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateStandType(id: string, standType: ReferanceStandType): Observable<ReferanceStandType> {
    return this.http.put<ReferanceStandType>(this.globalSettings.apiReferanceURL +
                                           '/stand_types/' +
                                           id,
                                           JSON.stringify(standType),
                                           this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Terminals
  getTerminals(): Observable<ReferanceTerminal[]> {
    return this.http.get<ReferanceTerminal[]>(this.globalSettings.apiReferanceURL +
                                             '/terminals')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getTerminal(id: number): Observable<ReferanceTerminal> {
    return this.http.get<ReferanceTerminal>(this.globalSettings.apiReferanceURL +
                                           '/terminals/' +
                                           id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  createTerminal(terminal: ReferanceTerminal): Observable<ReferanceTerminal> {
    return this.http.post<ReferanceTerminal>(this.globalSettings.apiReferanceURL +
                                          '/terminals',
                                          JSON.stringify(terminal),
                                          this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateTerminal(id: string, terminal: ReferanceTerminal): Observable<ReferanceTerminal> {
    return this.http.put<ReferanceTerminal>(this.globalSettings.apiReferanceURL +
                                           '/terminals/' +
                                           id,
                                           JSON.stringify(terminal),
                                           this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  downloadFile(link: string) {
    return this.http.get(this.globalSettings.apiReferanceURL + '/' + link, {responseType: 'blob'})
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getFieldsFromBD(referanceName: string): Observable<[]> {
    return this.http.get<[]>(this.globalSettings.apiReferanceURL + '/fields/' + referanceName)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  importDataValidation(importData: any, referanceName: string) {
    return this.http.post(this.globalSettings.apiReferanceURL +
                            '/validate/' + referanceName,
                          JSON.stringify(importData),
                          this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  startImport(importData: any, referanceName: string) {
    return this.http.post(this.globalSettings.apiReferanceURL +
                            '/import/' + referanceName,
                          JSON.stringify(importData),
                          this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Error handling
  handleError(error) {
    let errorMessage = '';
    let errorDetail: any = null;
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorDetail = error.error;
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if (errorDetail) {
      return throwError(errorDetail);
    } else {
      return throwError(errorMessage);
    }
  }
}
