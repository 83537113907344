import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { GlobalSettings, ModuleName } from '../settings/global-settings';
import { NGXLogger } from 'ngx-logger';
import { ECSEvent, EventProvider } from 'src/app/settings/logger-monitor';
import { AhmData, CalculationAhm, CalculationManual, Flight } from './weight-balance';
import { identifierName } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})

export class WeightBalanceModuleRestApiService {

  constructor(private http: HttpClient,
              private globalSettings: GlobalSettings,
              private logger: NGXLogger) {
    globalSettings.loadDefaultConfig();
  }

  setDefaultHttpHeader(requestId?): Object {
    // Формирование заголовков для отслеживания запросов
    // X-Correlation-ID идентификатор пользовательской сессии
    // X-Request-ID идентификатор события / запроса
    let httpOptions = {};
    httpOptions['headers'] = { 'Content-Type' : 'application/json',
                               'X-Correlation-ID' : this.globalSettings.userSessionUUID,
                               'X-Request-ID' : (requestId === undefined) ? this.globalSettings.randomUuid : requestId };
    return httpOptions;
  }

  // Ahms
  getAhms(xRequestId?): Promise<AhmData[]> {
    this.logger.trace('getAhms',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getAhms',
                                  'get',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                              '/ahm_data'));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<AhmData[]>(this.globalSettings.apiWeightBalanceURL +
                                              '/ahm_data', httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getAhm(id, xRequestId?): Promise<AhmData> {
    this.logger.trace('getAhm',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getAhm',
                                  'get',
                                  id,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                              '/ahm_data/' + id));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<AhmData>(this.globalSettings.apiWeightBalanceURL +
                                  '/ahm_data/' + id, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Flights
  getFlights(filterParams?, xRequestId?): Promise<Flight[]> {
    let url = '/flights';
    if (filterParams) {
      var params = [];
      for (const key in filterParams) {
        if ((key == 'start' || key == 'finish') && filterParams[key]) {
          params.push(key + '=' + filterParams[key].toISOString());
        } else if (filterParams[key]) {
          params.push(key + '=' + filterParams[key]);
        }
      }
      if (params.length > 0) {
        url += '?' + params.join('&');
      }
    }

    this.logger.trace('getFlights',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getFlights',
                                  'get',
                                  filterParams,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL + url));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<Flight[]>(this.globalSettings.apiWeightBalanceURL + url,
                                   httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getFlight(id, xRequestId?): Promise<Flight> {
    this.logger.trace('getFlight',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getFlight',
                                  'get',
                                  identifierName,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/flights/' + id));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<Flight>(this.globalSettings.apiWeightBalanceURL +
                                '/flights/' + id, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  addFlight(flight: any, xRequestId?): Observable<HttpResponse<any>> {
    this.logger.trace('addFlight',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.addFlight',
                                  'post',
                                  JSON.stringify(flight),
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/flights'));
    let httpOptions = this.setDefaultHttpHeader(xRequestId);
    httpOptions['observe'] = 'response';
    return this.http.post<HttpResponse<any>>(this.globalSettings.apiWeightBalanceURL +
                                             '/flights',
                                             JSON.stringify(flight),
                                             httpOptions)
    .pipe(
      map(resp => {
        return resp;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  updateFlight(flight: Flight, xRequestId?): Promise<Flight> {
    this.logger.trace('updateFlight',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.updateFlight',
                                  'put',
                                  JSON.stringify(flight),
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/flights/' + flight.id));
   const httpOptions = this.setDefaultHttpHeader(xRequestId);
   return this.http.put<Flight>(this.globalSettings.apiWeightBalanceURL +
                                '/flights/' +
                                flight.id,
                                JSON.stringify(flight),
                                httpOptions)
   .pipe(
     retry(1),
     catchError(this.handleError)
   ).toPromise();
  }

  // Calculate
  calculate(calculate, xRequestId?): Observable<CalculationAhm> {
    this.logger.trace('calculate',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.calculate',
                                  'post',
                                  JSON.stringify(calculate),
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/calculate'));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.post<CalculationAhm>(this.globalSettings.apiWeightBalanceURL +
                                          '/calculate',
                                          JSON.stringify(calculate),
                                          httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Chart data
  getChart(calculate, xRequestId?): Promise<any> {
    this.logger.trace('getChart',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getChart',
                                  'post',
                                  JSON.stringify(calculate),
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/chart'));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.post<any>(this.globalSettings.apiWeightBalanceURL +
                              '/chart',
                              JSON.stringify(calculate),
                              httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Get delta ballast
  getDeltaBallast(calculate, xRequestId?): Promise<any> {
    this.logger.trace('getDeltaBallast',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getDeltaBallast',
                                  'post',
                                  JSON.stringify(calculate),
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/ballast_delta'));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.post<any>(this.globalSettings.apiWeightBalanceURL +
                              '/ballast_delta',
                              JSON.stringify(calculate),
                              httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Calculations
  getCalculation(flightId, xRequestId?): Promise<CalculationAhm> {
    this.logger.trace('getCalculation',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getCalculation',
                                  'get',
                                  flightId,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/calculations/' + flightId));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<CalculationAhm>(this.globalSettings.apiWeightBalanceURL +
                                         '/calculations/' + flightId,
                                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  addCalculate(calculate: any, xRequestId?): Promise<HttpResponse<any>> {
    this.logger.trace('addCalculate',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.addCalculate',
                                  'post',
                                  JSON.stringify(calculate),
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/calculations'));
    let httpOptions = this.setDefaultHttpHeader(xRequestId);
    httpOptions['observe'] = 'response';
    return this.http.post<HttpResponse<any>>(this.globalSettings.apiWeightBalanceURL +
                                             '/calculations',
                                             JSON.stringify(calculate),
                                             httpOptions)
      .pipe(
        map(resp => {
          return resp;
        }),
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  updateCalculate(id, calculate, xRequestId?): Promise<CalculationAhm> {
    this.logger.trace('updateCalculate',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.updateCalculate',
                                  'post',
                                  [id, JSON.stringify(calculate)],
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/calculations/' + id));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.put<CalculationAhm>(this.globalSettings.apiWeightBalanceURL +
                                         '/calculations/' + id,
                                         JSON.stringify(calculate),
                                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // calculations manual
  getCalculationManual(flightId, xRequestId?): Promise<CalculationManual> {
    this.logger.trace('getCalculationManual',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getCalculationManual',
                                  'get',
                                  flightId,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/manual_loadsheet/' + flightId));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<CalculationManual>(this.globalSettings.apiWeightBalanceURL +
                                            '/manual_loadsheet/' + flightId,
                                            httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  addCalculateManual(calculate: any, xRequestId?): Observable<HttpResponse<any>> {
    this.logger.trace('addCalculateManual',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.addCalculateManual',
                                  'post',
                                  JSON.stringify(calculate),
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/manual_loadsheet'));
    let httpOptions = this.setDefaultHttpHeader(xRequestId);
    httpOptions['observe'] = 'response';
    return this.http.post<HttpResponse<any>>(this.globalSettings.apiWeightBalanceURL +
                                             '/manual_loadsheet',
                                             JSON.stringify(calculate),
                                             httpOptions)
      .pipe(
        map(resp => {
          return resp;
        }),
        retry(1),
        catchError(this.handleError)
      );
  }

  updateCalculateManual(id, calculate, xRequestId?): Promise<CalculationManual> {
    this.logger.trace('updateCalculateManual',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.updateCalculateManual',
                                  'put',
                                  [id, JSON.stringify(calculate)],
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/manual_loadsheet/' + id));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.put<CalculationManual>(this.globalSettings.apiWeightBalanceURL +
                                            '/manual_loadsheet/' + id,
                                            JSON.stringify(calculate),
                                            httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  createDocuments(flightId, xRequestId?) {
    this.logger.trace('createDocuments',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.createDocuments',
                                  'post',
                                  JSON.stringify(flightId),
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/documents/' + flightId + '/create'));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.post(this.globalSettings.apiWeightBalanceURL +
                          '/documents/' + flightId + '/create',
                          JSON.stringify(flightId),
                          httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  loadDocument(flightId, edition, url, xRequestId?): Promise<any> {
    this.logger.trace('loadDocument',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.loadDocument',
                                  'get',
                                  [flightId, edition, url],
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/documents/' + flightId + '/editions/' +
                                  edition + url));
    // return Observable.of('TODO: change to real data');
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<any>(this.globalSettings.apiWeightBalanceURL +
                              '/documents/' + flightId + '/editions/' +
                              edition + url,
                              httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getDocumentEditions(flightId, xRequestId?): Promise<[]> {
    this.logger.trace('getDocumentEditions',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getDocumentEditions',
                                  'get',
                                  flightId,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/documents/' + flightId + '/editions'));
    // return new Promise(resolve => {
    //     let data: Array<number> = [1,2,3];
    //     resolve(data);
    // });
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<[]>(this.globalSettings.apiWeightBalanceURL +
                             '/documents/' + flightId + '/editions',
                             httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    ).toPromise();
  }

  getLoadsheet(url, id, xRequestId): Observable<any> {
    this.logger.trace('getLoadsheet',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getLoadsheet',
                                  'get',
                                  [url, id],
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  url + id));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get(this.globalSettings.apiWeightBalanceURL + url + id,
                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  saveDocument(flightId, edition, url, xRequestId?): Observable<any> {
    this.logger.trace('saveDocument',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.saveDocument',
                                  'get',
                                  [flightId, edition, url],
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/documents/' + flightId + '/editions/' +
                                  edition + url));
    let params = new HttpParams();
    params = params.append('export', 'pdf');
    let httpOptions = this.setDefaultHttpHeader(xRequestId);
    httpOptions['params'] = params;
    httpOptions['responseType'] = 'blob';
    return this.http.get(this.globalSettings.apiWeightBalanceURL +
                         '/documents/' + flightId + '/editions/' + edition + url,
                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // ahmData
  getAhmData(tailId, flightId, xRequestId?): Promise<AhmData> {
    this.logger.trace('getAhmData',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getAhmData',
                                  'get',
                                  [tailId, flightId],
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/ahm_data_by_tail/' + tailId + '/' +
                                  flightId));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<AhmData>(this.globalSettings.apiWeightBalanceURL +
                                  '/ahm_data_by_tail/' + tailId + '/' + flightId,
                                  httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // reference
  getReference(name, xRequestId?): Promise<any[]> {
    this.logger.trace('getReference',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'rest.getReference',
                                  'get',
                                  name,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId,
                                  this.globalSettings.apiWeightBalanceURL +
                                  '/master_data/' + name));
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<any[]>(this.globalSettings.apiWeightBalanceURL +
                                '/master_data/' + name, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Error handling
  handleError(error) {
    let errorMessage = '';
    let errorDetail: any = null;
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorDetail = error.error;
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if (errorDetail) {
      return throwError(errorDetail);
    } else {
      return throwError(errorMessage);
    }
  }
}
