import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ng-input-formatted',
  templateUrl: './input-formatted.html',
  styleUrls: ['./input-formatted.less'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputFormatted),
    multi: true
  }]
})

export class InputFormatted implements ControlValueAccessor {
  private _value;

  get value() {
    return this._value;
  }

  @Input()
  set value(val) {
    this._value = val;
    this.onChange(this._value);
  }

  @Input() type: string;
  @Input() disabled: boolean;

  onChange(_: any) { }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched() { }

}
