import { NGXLoggerMonitor, NGXLogInterface, NgxLoggerLevel } from "ngx-logger";
import { HttpClient, HttpResponse } from '@angular/common/http';
import { GlobalSettings } from '../settings/global-settings';
import { throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { ModuleName } from 'src/app/settings/global-settings';

/**
 * Класс реализует сбор логов приложения и отсылку в место накопления по HTTP
 * Лог-сообщение приходит в виде JSON массива, из поля additional берется
 * структура для отправки на сервер сбора логов. Поле additional должно
 * содержать обьект класса в формате ECS****. Классы представленны ниже в
 * данном файле
 *
 * Пример структуры лог сообщения:
 * {
 *   additional: [ECSEvent{module: 7, component: "app.component.constructor", …}]
 *   fileName: "./src/app/app.component.ts"
 *   level: 5
 *   lineNumber: "30"
 *   message: "text"
 *   timestamp: "2021-03-02T20:51:57.135Z"
 * }
 */
export class LoggerMonitor implements NGXLoggerMonitor {

  deviceInfo = null;
  serverLogLevel = NgxLoggerLevel.OFF;

  constructor(info,
              private http: HttpClient,
              private globalSettings: GlobalSettings) {
    globalSettings.loadDefaultConfig();
    this.deviceInfo = info;
  }

  onLog(log: NGXLogInterface) {
    if (log.level >= this.serverLogLevel) {
      if (log.additional.length > 0) {
        log.additional.forEach(element => {
          if (typeof element === 'object' && element !== null) {
            if (element instanceof ECSEvent) {
              element = element as ECSEvent;
              element.xCorrelationId = this.globalSettings.userSessionUUID;
              element.dataset = log.level;
              this.sendToCollector(element.toJSON() as ECSEvent);
            } else if (element instanceof ECSOperatingSystemFields) {
              element = element as ECSOperatingSystemFields;
              element.xCorrelationId = this.globalSettings.userSessionUUID;
              this.sendToCollector(element.toJSON() as ECSOperatingSystemFields);
            } else if (element instanceof ECSUser) {
              element = element as ECSUser;
              this.sendToCollector(element.toJSON() as ECSUser);
            } else {
              // TODO
              // Если лог сообщение не структурированное, нужно перенаправлять
              // в общий сборщик мусора для анализа что за исключение поймали
              //
              // В текущий момент сборщика мусора нету
            }
          }
        });
      }
    }
  }

  // Функция установки уровня логов отсылаемых на сервер
  setServerLogLevel(level) {
    this.serverLogLevel = level;
    if (this.serverLogLevel != NgxLoggerLevel.OFF) {
      // Отсылка информации об авторизованном пользователи
      this.sendToCollector(new ECSUser(this.globalSettings.getUserDetail().login,
                                       this.globalSettings.getUserDetail().fullName,
                                       this.globalSettings.getUserDetail().roles).toJSON());
      // Отсылка параметрах рабочей среды авторизованого пользователя
      this.sendToCollector(new ECSOperatingSystemFields(this.deviceInfo.os,
                                                        this.deviceInfo.os_version,
                                                        this.deviceInfo.browser,
                                                        this.deviceInfo.browser_version,
                                                        this.deviceInfo.deviceType,
                                                        this.deviceInfo.orientation,
                                                        this.deviceInfo.userAgent,
                                                        this.globalSettings.userSessionUUID).toJSON());
    }
  }

  sendToCollector(message) {
    let httpOptions = {};
    httpOptions["headers"] = { 'Content-Type' : 'text/plain',
                               'x-aero-flood': this.globalSettings.loggerSettings.authorization
                              };
    this.http.post<HttpResponse<any>>(this.globalSettings.apiLogServer +
          '/aero.json',
          JSON.stringify(message),
          httpOptions)
    .pipe(
      map(response => {
        if (response) {
          console.log(response);
        }
      }),
      retry(1),
      catchError(this.handleError)
    )
    .subscribe();
  }

  handleError(error) {
    console.error(error);
    let errorDetail: any = null;
    errorDetail = error.error;
    return throwError(errorDetail);
  }
}

/**
 * Список поставщиков событий приложения
 */
export enum EventProvider {
  // Собитие создаваемое приложением
  APPLICATION,
  // События создаваемые через вызовы в пользовательском интерфейсе
  UI
}

/**
 * Список типов журнала
 */
export enum EventDataSet {
  TRACE,
  DEBUG,
  INFO,
  LOG,
  WARN,
  ERROR
}

/**
 * Класс интерфейса Elastic Common Schema
 *
 * Описывает обязательные реализуемые функции при наследовании
 * для корректной работы коллектора логов
 */
interface ElasticCommonSchema {
  toJSON(): void;
}

/**
 * Класс реализует описание структуры Elastic Common Schema (ECS)
 * для регистрации события
 *
 * В конструкторе класс описываются все поля для создания полноценной записи
 * в Elastic.
 *
 * Метод toJSON() создает формализованную структуру для отправки на сервис сбора
 * логов
 */
export class ECSEvent implements ElasticCommonSchema {

  constructor(module: ModuleName,
              component: string,
              action: string,
              data: any,
              provider: EventProvider,
              original?: string,
              xRequestId?: string,
              url?: string
              ) {
    this.module = module;
    this.component = component;
    this.action = action;
    this.data = data;
    this.provider = provider;
    this.created = new Date();
    this.original = original;
    this.xRequestId = xRequestId;
    this.url = url;
  }

  // Наименования модуля системы
  module: ModuleName;
  // Наименование компонента: mainView.button.newMessage
  component: string;
  // Наименование действия: button-click, row-click, form-show, form-hide
  action: string;
  // Данные генерируемые при событие: пользовательский ввод, параметры запросов
  data: any;
  // Тип журнала для события: comman.access, comman.log, comman.error
  dataset: EventDataSet;
  // Поставщик события
  provider: EventProvider;
  // Дата события
  created: Date;
  // Адрес обращения для сетевого события
  url: string;
  // Оригинальный текст сообщения
  original: string;
  // Идентификатор пользовательской сессии
  xCorrelationId: string;
  // Идентификатор события или запроса
  xRequestId: string;


  toJSON(): string {
    return JSON.stringify({
      'event.module': ModuleName[this.module],
      'event.component': this.component,
      'event.action': this.action,
      'event.data': this.data,
      'event.dataset': ModuleName[this.module] + '.' + EventDataSet[this.dataset],
      'event.provider': EventProvider[this.provider],
      'event.created': this.created,
      'event.url': this.url,
      'event.original': this.original,
      'x-correlation-id': this.xCorrelationId,
      'x-request-id': this.xRequestId
    });
  }
}

/**
 * Класс реализует описание структуры Elastic Common Schema (ECS)
 * для регистрации параметров операцинной системы на рабочем месте
 *
 * Метод toJSON() создает формализованную структуру для отправки на сервис сбора
 * логов
 */
export class ECSOperatingSystemFields implements ElasticCommonSchema {

  constructor(family: string,
              kernel: string,
              browser: string,
              browserVersion: string,
              deviceType: string,
              orientation: string,
              userAgent: string,
              xCorrelationId: string) {
    this.family = family;
    this.kernel = kernel;
    this.browser = browser;
    this.browserVersion = browserVersion;
    this.deviceType = deviceType;
    this.orientation = orientation;
    this.userAgent = userAgent;
    this.xCorrelationId = xCorrelationId;
  }

  // Тип операционной системы
  family: string;
  // Тип ядра операционной системы
  kernel: string;
  // Браузер используемый в текущем сеансе
  browser: string;
  // Версия браузера
  browserVersion: string;
  // Тип устройства: настольный ПК, мобильный телефон, плашнет
  deviceType: string;
  // Ориентация экрана
  orientation: string;
  // Пользовательский агент предоставляемый браузером
  userAgent: string;
  // Идентификатор пользовательской сессии
  xCorrelationId: string;

  toJSON(): string {
    return JSON.stringify({
      'os.family': this.family,
      'os.kernel': this.kernel,
      'os.browser': this.browser,
      'os.browser-version': this.browserVersion,
      'os.device-type': this.deviceType,
      'os.orientation': this.orientation,
      'os.user-agent': this.userAgent,
      'x-correlation-id': this.xCorrelationId
    });
  }
}

/**
 * Класс реализует описание структуры Elastic Common Schema (ECS)
 * для регистрации параметров пользователя авторизованного в системе
 *
 * Метод toJSON() создает формализованную структуру для отправки на сервис сбора
 * логов
 */
export class ECSUser implements ElasticCommonSchema {

  constructor(name, fullName, roles: string) {
    this.name = name;
    this.fullName = fullName;
    this.roles  = roles ;
  }

  // Логин пользователя
  name: string;
  // Фимилия и имя пользователя
  fullName: string;
  // Роли пользователя
  roles: string;

  toJSON(): string {
    return JSON.stringify({
      'user.name': this.name,
      'user.full_name': this.fullName,
      'user.roles': this.roles
    });
  }
}
