import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GlobalSettings, ModuleName } from 'src/app/settings/global-settings';
import { Ahm, AhmConfiguration, AhmData, CentreOfGravityChart, CentreOfGravityChartCondition, CentreOfGravityChartItem,
         ConfigurationSection, DowChangesCrew, HoldsAndCompartments,
         StabilizerTrim, StabToTrims, StabToValues,
         UldTypes, WeightLimit, AdminFilterParams,
         DowChangesPantry, DowChangesPotableWater
       } from 'src/app/weight-balance-data/weight-balance';
import { AdministrationRestApiService } from 'src/app/administration-data/administration-rest-api.service';
import { GlobalI18n } from 'src/app/settings/global-i18n';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileSaverService } from 'ngx-filesaver';
import { createCanvas, drawGravity } from 'src/app/core/gravity';

@Component({
  selector: 'app-am-balance',
  templateUrl: './am-balance.component.html',
  styleUrls: ['./am-balance.component.less']
})
export class AmBalanceComponent implements OnInit {

  @ViewChild('closeform') closeform: any;
  @ViewChild('modalAhm') modalAhm: TemplateRef<any>;
  @ViewChild('modalAhmForm', { static: false }) modalAhmForm: TemplateRef<any>;

  // Текущее окно
  activeWindow = 'ahm-list';
  // Текущая вкладка
  activeTab = 'standard-units';
  // Текущее действие пользователя
  userAction = '';
  modalType = '';

  // Данные авиакомпании для записи весов пассажиров
  selectedAirlineData;

  // Переменная содержащая описание ошибки возникающей при работе модуля
  errorMessage = '';
  // Тип ошибки: error, warning, info
  errorType = '';
  // Ответ пользователя
  userAnswer = null;

  _ahmsData: AhmData[];
  // ahmsData: AhmData[];
  ahmData: AhmData = new AhmData();
  ahms: Ahm[];
  ahm: Ahm = new Ahm();
  selectedAhmDataId = null;

  // Массив багажников для select в лимитах
  compartmentsGroup = [];

  centreOfGravityChart = CentreOfGravityChart;
  itemEl = CentreOfGravityChartItem;
  configurationSection = ConfigurationSection;
  dowChangesCrew = DowChangesCrew;
  dowChangesPantry = DowChangesPantry;
  dowChangesPotableWater = DowChangesPotableWater;
  holdsAndCompartments = HoldsAndCompartments;
  stabilizerTrim = StabilizerTrim;
  stabToValues = StabToValues;
  stabToTrims = StabToTrims;
  uldTypes = UldTypes;
  weightLimit = WeightLimit;
  centreOfGravityChartCondition = CentreOfGravityChartCondition;

  units = {
    weight: [{
      id: 1,
      englishName: 'kg',
      localName: 'кг'
    }, {
      id: 2,
      englishName: 'lbs',
      localName: 'фунт'
    }],
    length: [{
      id: 1,
      englishName: 'm',
      localName: 'м'
    }, {
      id: 2,
      englishName: 'sm',
      localName: 'см'
    }, {
      id: 3,
      englishName: 'inches',
      localName: 'дюйм'
    }],
    volume: [{
      id: 1,
      englishName: 'm3',
      localName: 'м3'
    },{
      id: 2,
      englishName: 'ft3',
      localName: 'фунт3'
    }],
    liquidVolume: [{
      id: 1,
      englishName: 'liter',
      localName: 'литр'
    },{
      id: 2,
      englishName: 'gallon',
      localName: 'галлон'
    }]
  };

  chartTypes = [
    {
      id: 'landing weight',
      nameEnglish: 'Landing weight',
      nameRussian: 'Посадочный вес',
    },{
      id: 'fuel weight',
      nameEnglish: 'Fuel weight',
      nameRussian: 'Вес топлива',
    },{
      id: 'passengers',
      nameEnglish: 'Passengers',
      nameRussian: 'Пассажиры',
    }
  ]

  references = {
    'aircraft_types': [],
    'airlines': [],
    'airports': [],
    'seasons': [],
    'tails': [],
    'tailsFiltered': [],
    'dow_items': []
  }

  selectLoadAnimation = {
    tails: true,
    tailsFiltered: true,
    airlines: true,
    aircraft_types: true,
  }

  buffer = {
    tails: [],
    tailsFiltered: [],
    airlines: [],
  }

  // Размер отображаемых данных в выпадающем списке
  bufferSize = {
    tails: 50,
    tailsFiltered: 50,
    airlines: 50,
  }

  numberOfItemsFromEndBeforeFetchingMore = 10;

  filterParams: AdminFilterParams = new AdminFilterParams();
  filterApply = false;
  showFilter = false;
  // showRemoved = false;

  constructor(
    public restApi: AdministrationRestApiService,
    public globalSettings: GlobalSettings,
    public globalI18n: GlobalI18n,
    private modalService: NgbModal,
    private fileSaverService: FileSaverService
  ) {
    globalSettings.loadDefaultConfig();
  }

  ngOnInit(): void {
    // this.loadReferences();
    this.loadWindow();
    this.loadReference('airlines');
    this.loadReference('aircraft_types');
    this.loadReference('tails');
  }

  // AHM
  async loadAhms() {
    this.activeWindow = 'ahm-list';
    const data = await this.restApi.getAhms(this.filterApply ? this.filterParams : null);
    this.ahms = [];
    data.forEach(el => {
      let ahm = new Ahm();
      Object.assign(ahm, el);
      this.ahms.push(ahm);
    });
    this.ahms.sort((a, b) => a.airlineName > b.airlineName ? 1 : -1)
    this._ahmsData = [];
    this.ahm = new Ahm();
    console.log('ahms', this.ahms);

  }

  showArchiveAhm() {
    this.filterParams.removed = !this.filterParams.removed;
    // this.showRemoved = !this.showRemoved;
    this.loadAhms();
    // TODO set flag for show outdate revisions
  }

  async selectAhm(id) {
    this.selectedAhmDataId = null;
    const data = await this.restApi.getAhm(id);
    this.ahm = new Ahm();
    Object.assign(this.ahm, data);
    this.loadAhmsData(this.ahm.id);

    this.selectedAirlineData = this.getById(this.references.airlines, this.ahm.airlineId);
  }

  // Открывает модалку
  open(content) {
    this.modalService.open(content, { size: 'xl', backdrop: 'static' });
  }

  createAhm(modal) {
    this.ahm = new Ahm();
    this.userAction = 'add-ahm';
    this.open(modal);
  }

  addAhm(action) {
    return this.restApi.addAhm(this.ahm).subscribe((data: {}) => {
      this.activeWindow = 'ahm-list';
      action();
      this.loadAhms();
    });
  }

  async deleteAhm() {
    this.userAction = 'delete-ahm';
    this.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'deleteAhm');
    this.errorType = 'warning';
    this.modalType = 'setAnswer'
    await this.waitAnswer().then(async res => {
      if (res) {
        this.ahm.dtRangeFinish = new Date();
        return this.restApi.updateAhm(this.ahm).subscribe(() => {
          this.loadAhms();
        });
      }
    });
  }

  async copyAhm(modal) {
    this.userAction = 'copy-ahm';
    this.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'copyAhm');
    this.errorType = 'warning';
    this.modalType = 'setAnswer'
    await this.waitAnswer().then(async res => {
      if (res) {
        // TODO copy AHM
        this.ahm.revision = null;
        this.ahm.dtRangeStart = new Date();
        this.ahm.dtRangeFinish = new Date('2099-01-01');
        this.open(modal);
      }
    });
  }

  saveCopedAhm(action) {
    return this.restApi.copyAhm(this.ahm).subscribe((data: {}) => {
      this.activeWindow = 'ahm-list';
      action();
      this.loadAhms();
    });
  }

  editAhm(modal) {
    this.userAction = 'edit-ahm';
    this.open(modal);
  }

  saveAhm(action) {
    return this.restApi.updateAhm(this.ahm).subscribe((data: {}) => {
      this.activeWindow = 'ahm-list';
      action();
      this.loadAhms();
    });
  }

  // AHM Data
  async loadAhmsData(ahmId) {
    this.activeWindow = 'ahm-list';
    const data = await this.restApi.getAhmsData(ahmId, this.filterParams.removed);
    this._ahmsData = [];
    data.forEach(el => {
      let ahm = new AhmData();
      Object.assign(ahm, el);
      this._ahmsData.push(ahm);
    });
    this._ahmsData.sort((a, b) => a.tailName > b.tailName ? 1 : -1);
  }

  get ahmsData() {
    if (this.filterParams.tail && this.filterParams.tail.length) {
      return this._ahmsData.filter(el => this.filterParams.tail.includes(el.tailName));
    } else {
      return this._ahmsData;
    }
  }

   /**
    * Функция загрузка данных по конкретному бортовому номеру из AHM
    * @param id Идентификатор загружаемых данных AHM
    */
  async loadAhmData(id) {
    this.restApi.getAhmData(id).then((data) => {
      this.ahmData = new AhmData();
      console.log('data ahm', data);

      Object.assign(this.ahmData, data);
      this.ahmData.holdsAndCompartments = {fwd: [], aft: [], limits: []};

      for (const key in data.holdsAndCompartments) {
        if (Object.prototype.hasOwnProperty.call(data.holdsAndCompartments, key) && key !== 'limits') {
          const element = data.holdsAndCompartments[key];
          if (element && element.length > 0) {
            element.forEach(hold => {
              if (hold.bays.length > 0) {
                hold.bays.forEach(bay => {
                  let el = new HoldsAndCompartments();
                  Object.assign(el, bay);
                  el.group = hold.name[0];
                  this.ahmData.holdsAndCompartments[key].push(el);
                });
              }
              let el = new HoldsAndCompartments();
              Object.assign(el, hold);
              if (el.maxWeight !==0 && el.index !== 0) {
                el.group = hold.name[0];
                el.bays = [];
                this.ahmData.holdsAndCompartments[key].push(el);
              }
            });
            this.ahmData.holdsAndCompartments[key].sort((a, b) => a.name > b.name ? 1 : -1);
          }
        } else if (key === 'limits') {
          const element = data.holdsAndCompartments[key];
          if (element && element.length > 0) {
            element.forEach(limit => {
              let el = new WeightLimit();
              Object.assign(el, limit);
              this.ahmData.holdsAndCompartments[key].push(el);
            });
          }
        }
      }

      if (this.ahmData.configurations.length > 0) {
        this.ahmData.configurations = [];
        data.configurations.forEach(el => {
          let config = new AhmConfiguration();
          Object.assign(config, el);
          config.sections = [];
          el.sections.forEach(section => {
            let res = new ConfigurationSection();
            Object.assign(res, section);
            config.sections.push(res);
          });
          this.ahmData.configurations.push(config);
        });
      }

      this.getCompartments();

      this.getGravity(data.centreOfGravityChart);
    });
  }

  getGravity(centreOfGravityChart) {
    setTimeout(() => {
      for (const key in centreOfGravityChart) {
        let gravityCentreBlock = document.getElementById('gravityCentreBlock_' + key);
        let width = gravityCentreBlock.clientWidth;
        let context = createCanvas(width, width, gravityCentreBlock);
        drawGravity(width, width, centreOfGravityChart[key], [], this.ahmData, context);
      }
    }, 1000);
  }

  async editAhmData(id) {
    this.activeWindow = 'ahm-card-data';
    this.activeTab = 'standard-units';
    this.userAction = 'edit-ahm-data';
    await this.loadAhmData(id);
    this.loadReference('tails').then(() => {
      this.selectLoadAnimation.tailsFiltered = true;
      this.references.tailsFiltered = this.references.tails.filter(el => el.aircraftTypeId === this.ahm.aircraftTypeId);
      this.selectLoadAnimation.tailsFiltered = false;
    });
  }

  createAhmData() {
    this.activeWindow = 'ahm-card-data';
    this.activeTab = 'standard-units';
    this.userAction = 'add-ahm-data';
    this.ahmData = new AhmData();
    this.ahmData.ahmId = this.ahm.id;
    this.ahmData.standartWeights.passengers = {
      winter: {
        adult: this.selectedAirlineData.weightAdult[0],
        female: 0,
        child: this.selectedAirlineData.weightChild[0],
        infant: this.selectedAirlineData.weightInfant[0],
        handLuggage: 0,
        handLuggageIncluded: false,
      },
      summer: {
        adult: this.selectedAirlineData.weightAdult[1],
        female: 0,
        child: this.selectedAirlineData.weightChild[1],
        infant: this.selectedAirlineData.weightInfant[1],
        handLuggage: 0,
        handLuggageIncluded: false,
      },
      handLuggageIncluded: true

    }
    this.loadReference('tails').then(() => {
      this.selectLoadAnimation.tailsFiltered = true;
      this.references.tailsFiltered = this.references.tails.filter(el => el.aircraftTypeId === this.ahm.aircraftTypeId);
      this.selectLoadAnimation.tailsFiltered = false;
    });
  }

  addAhmData() {
    let element = {fwd: [], aft: [], limits: []};

    for (const key in this.ahmData.holdsAndCompartments) {
      if (
        Object.prototype.hasOwnProperty.call(this.ahmData.holdsAndCompartments, key)
        && this.ahmData.holdsAndCompartments[key].length > 0
        && key !== 'limits'
      ) {
        element[key] = this.conversionCompartments(this.ahmData.holdsAndCompartments[key]);
      } else if (key === 'limits') {
        element[key] = this.ahmData.holdsAndCompartments[key];
      }
    }
    return this.restApi.addAhmData(this.ahmData, element).subscribe((data: {}) => {
      this.activeWindow = 'ahm-list';
      this.loadAhmsData(this.ahm.id);
    });
  }

  getCompartments() {
    this.compartmentsGroup = this.ahmData.holdsAndCompartments.aft.filter(el => el.name.length === 1)
                        .concat(this.ahmData.holdsAndCompartments.fwd.filter(el => el.name.length === 1));
  }

  saveAhmData() {
    let element = {fwd: [], aft: [], limits: []};

    for (const key in this.ahmData.holdsAndCompartments) {
      if (
        Object.prototype.hasOwnProperty.call(this.ahmData.holdsAndCompartments, key)
        && this.ahmData.holdsAndCompartments[key].length > 0
        && key !== 'limits'
      ) {
        element[key] = this.conversionCompartments(this.ahmData.holdsAndCompartments[key]);
      } else if (key === 'limits') {
        element[key] = this.ahmData.holdsAndCompartments[key];
      }
    }

    return this.restApi.updateAhmData(this.ahmData.id, this.ahmData, element).subscribe((data: {}) => {
      this.activeWindow = 'ahm-list';
      this.loadAhmsData(this.ahm.id);
    });
  }

  exportAhmData() {
    return this.restApi.exportAhmData(this.ahmData.id).subscribe(data => {
      const blob = new Blob([data], {type: 'application'});
      this.fileSaverService.save(blob, this.ahmData.airlineIata + this.ahm.aircraftTypeName + ' ' + this.ahmData.tailName + '.xls');
    });
  }

  conversionCompartments(holdsAndCompartments) {
    // let element = {fwd: [], aft: [], limits: []};
    let element = [];

    // for (const key in holdsAndCompartments) {
      // if (Object.prototype.hasOwnProperty.call(holdsAndCompartments, key) && holdsAndCompartments[key].length > 0) {

        holdsAndCompartments.sort((a, b) => a.name > b.name ? 1 : -1);

        let group = holdsAndCompartments[0].group;
        let index = 0;

        holdsAndCompartments.forEach(hold => {
          hold.name = hold.name.replace(/<\/?[^>]+(>|$)/g, '');
          let el = new HoldsAndCompartments();
          Object.assign(el, hold);

          if (group !== hold.group) {
            index++;
            group = hold.group;
          }

          if (group === hold.name) {
            element.push(el);
          } else {
            if(!element[index]) {
              let compartment = new HoldsAndCompartments();
              compartment.name = group;
              element.push(compartment);
            }
            element[index].bays.push(el);
          }

          /*if (group === hold.group) {
            if (group === hold.name) {
              element[key].push(el);
            } else {
              if(!element[key][index]) {
                let compartment = new HoldsAndCompartments();
                compartment.name = group;
                element[key].push(compartment);
              }
              element[key][index].bays.push(el);
            }
          } else {
            index++;
            group = hold.group;
            if (group === hold.name) {
              element[key].push(el);
            } else {
              let compartment = new HoldsAndCompartments();
              compartment.name = group;
              element[key].push(compartment);
              element[key][index].bays.push(el);
            }
          }*/
        //});
      //}
    });
    return element;
  }

  async deleteAhmData(id) {
    this.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'deleteAhmData');
    this.errorType = 'warning';
    this.modalType = 'setAnswer'
    await this.waitAnswer().then(async res => {
      if (res) {
        return this.restApi.deleteAhmData(id).subscribe((data: {}) => {
          this.loadAhmsData(this.ahm.id);
        });
      }
    });
  }

  drop(event: CdkDragDrop<string[]>, array) {
    moveItemInArray(array, event.previousIndex, event.currentIndex);
  }

  async copyAhmData(id) {
    this.activeWindow = 'ahm-card-data';
    this.activeTab = 'standard-units';
    this.userAction = 'add-ahm-data';
    await this.loadAhmData(id);
    this.ahmData.id = undefined;
    this.loadReference('tails').then(() => {
      this.selectLoadAnimation.tailsFiltered = true;
      this.references.tailsFiltered = this.references.tails.filter(el => el.aircraftTypeId === this.ahm.aircraftTypeId);
      this.selectLoadAnimation.tailsFiltered = false;
    });
  }

  setStandard(array, prop, index: number) {
    array.forEach(element => {
      element[prop] = false;
    });
    array[index][prop] = true;
  }

  async loadReferences() {
    for (const key in this.references) {
      if (Object.prototype.hasOwnProperty.call(this.references, key)) {
        this.references[key] = await this.restApi.getReference(key);
        this.selectLoadAnimation[key] = false;
      }
    }
  }

  async loadReference(name) {
    this.selectLoadAnimation[name] = true;
    this.references[name] = await this.restApi.getReference(name);
    this.selectLoadAnimation[name] = false;
  }

  onScrollToEndAirlines(name) {
    this.airlinesFetchMore(name);
  }

  onScrollAirlines({ end }, name) {
    if (this.selectLoadAnimation[name] || this.references[name].length <= this.buffer[name].length) {
      return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.buffer[name].length) {
      this.airlinesFetchMore(name);
    }
  }

  private airlinesFetchMore(name) {
    const len = this.buffer[name].length;
    const more = this.references[name].slice(len, this.bufferSize[name] + len);
    this.selectLoadAnimation[name] = true;
    this.buffer[name] = this.buffer[name].concat(more);
    this.selectLoadAnimation[name] = false;
  }

  /* TODO: Переписать функцию, чтобы вырезалось все кроме точек, запятых и цифр */
  inputNotLetter(event, type = '') {
    let allowed = [',', 'Backspace', '-', '.', 'ArrowRight', 'ArrowLeft', 'Tab', 'Meta', 'Control', 'v', 'c', 'с', 'м'];

    if (isNaN(Number(event.key)) && !allowed.includes(event.key)) {
      return false;
    }
    if (type === 'int' && (event.key === ',' || event.key === '.')) {
      return false;
    }
    if (type === 'int_positive' && (event.key === '-' ||event.key === ',' || event.key === '.')) {
      return false;
    }
  }

  /**
   * Функция обработки значения из редактируемой таблицы
   * @param {string} value Строка, введеная пользователем
   */
  toNumber(value) {
    if (value === '') return null;
    let num = parseFloat(value.replace(/<\/?[^>]+(>|$)/g, '').replace(/^(-)|[^0-9.,]+/ig, '$1').replace(/^-+/g, '-').replace(',', '.'));

    //var validPattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    //var result = validPattern.test(element.html());
    return num ?? null;
  }

  clearTags(value) {
    let str = value.replace(/<\/?[^>]+(>|$)/g, '').replace('&nbsp;', '');
    return str ?? null;
  }

  hexEncode(str){
    var hex, i;
    var result = "";
    for (i=0; i< str.length; i++) {
        hex = str.charCodeAt(i).toString(16);
        result += (hex + " ").slice(-4);
    }
    return result
  }


  /**
   * Функция поиска в выпадающим списке по нескольким параметрам
   * @param {string} term Строка для поиска введеня пользователем
   * @param {ReferanceAirport} item Элемент для поиска
   */
  customSelectSearch(term: string, item) {
    term = term.toLowerCase();
    return (item.iata && item.iata.toLowerCase().indexOf(term) > -1) ||
      (item.icao && item.icao.toLowerCase().indexOf(term) > -1) ||
      (item.aircraftTypeIata && item.aircraftTypeIata.toLowerCase().indexOf(term) > -1) ||
      (item.aircraftTypeIcao && item.aircraftTypeIcao.toLowerCase().indexOf(term) > -1) ||
      (item.name && item.name[0] && item.name[0].toLowerCase().indexOf(term) > -1) ||
      (item.name && item.name[1] && item.name[1].toLowerCase().indexOf(term) > -1) ||
      (item.tail && item.tail.toLowerCase().indexOf(term) > -1)
  }

  getById(array, id: number) {
    if (!array || array.length === 0) return null;
    let res = array.filter(el => el.id === id)[0];
    if (res) return res;
    else return null;
  }

  getProp(object, id, prop) {
    if(object && object.length > 0 && id) {
      let res = this.getById(object, id);
      if (res && res[prop])
        return res[prop];
      else
        return null;
    } else return null;
  }

  setProperty(prop, value) {
    prop = value;
    return true;
  }

  addItemByClass(array, item) {
    // TODO предусмотреть  array=null
    if (array !== null) {
      array.push(new item());
    }
  }

  copyItem(array, element) {
    let test = JSON.parse(JSON.stringify(element));
    array.push(test);
  }

  async deleteElement(array, index) {
    this.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'deleteItem');
    this.errorType = 'warning';
    this.modalType = 'setAnswer'
    await this.waitAnswer().then(async res => {
      if (res) {
        array.splice(index, 1);
      }
    });
  }

  async waitAnswer() {
    let res = await this.checkAnswer();
    return res;
  }

  checkAnswer() {
    return new Promise(resolve => {
      let interval = setInterval(() => {
        if (this.userAnswer !== null) {
          const answer = this.userAnswer;
          this.clearErrorMess();
          clearInterval(interval);
          resolve(answer);
        }
      }, 300)
   });
  }

  clearErrorMess() {
    this.errorMessage = '';
    this.errorType = '';
    this.modalType = '';
    this.userAnswer = null;
  }

  addItem(array, item) {
    array.push(item);
  }

  loadWindow() {
    switch (this.activeWindow) {
      case 'ahm-list': {
        this.loadAhms();
        break;
      }
    }
  }

  changeTab(event: { target: { id: string; }; }) {
    this.activeTab = event.target.id;
    // this.loadTab();
  }

  showTab(item: string): boolean {
    return item === this.activeTab;
  }

  parseDate(dateString: string): Date {
    if (dateString) {
      return new Date(dateString);
    }
    return null;
  }

  checkFilterEmpty() {
    let newFilter = new AdminFilterParams();
    return JSON.stringify(this.filterParams) === JSON.stringify(newFilter);
  }

  clearFilter() {
    this.filterParams = new AdminFilterParams();
    this.filterApply = false;
    this.filterAhms();
  }

  filterApp() {
    this.filterApply = true;
    this.filterAhms();
  }

  filterSwitch() {
    this.filterApply = !this.filterApply;
    this.filterAhms();
  }

  filterAhms() {
    this.showFilter = false;
    this.loadAhms();
  }

  clearFilterParametr(field: string, event) {
    event.stopPropagation();
    if (field === 'date') {
      delete this.filterParams['start'];
      delete this.filterParams['finish'];
    } else {
      delete this.filterParams[field];
    }
    if (this.checkFilterEmpty()) {
      this.filterApply = false;
    }
    this.filterAhms();
  }

}
