import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommercialAct, Statement, StatementData } from '../lost-found-data/lost-found';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { GlobalSettings } from '../settings/global-settings';

@Injectable({
  providedIn: 'root'
})

export class LostFoundModuleRestApiService {

  constructor(private http: HttpClient,
              private globalSettings: GlobalSettings) {
    globalSettings.loadDefaultConfig();
  }

  setDefaultHttpHeader(requestId?): Object {
    // Формирование заголовков для отслеживания запросов
    // X-Correlation-ID идентификатор пользовательской сессии
    // X-Request-ID идентификатор события / запроса
    let httpOptions = {};
    httpOptions['headers'] = { 'Content-Type' : 'application/json',
                               'X-Correlation-ID' : this.globalSettings.userSessionUUID,
                               'X-Request-ID' : (requestId === undefined) ? this.globalSettings.randomUuid : requestId };
    return httpOptions;
  }

  getStatements(getParams, xRequestId): Promise<Statement[]> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<Statement[]>(this.globalSettings.apiLostFoundURL +
                                      `/statements/${getParams.perPage}/${getParams.pageActive}`,
                                      httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getStatement(id: number, xRequestId): Promise<StatementData> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<StatementData>(this.globalSettings.apiLostFoundURL +
                                        `/statements/${id}`,
                                        httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  addStatement(statement: StatementData, xRequestId?): Promise<any> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.post(this.globalSettings.apiLostFoundURL +
                          `/statements`,
                          statement,
                          httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  updateStatement(statement, xRequestId?): Promise<StatementData> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.put<StatementData>(this.globalSettings.apiLostFoundURL +
                         `/statements/${statement.id}`,
                         JSON.stringify(statement),
                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getCommercialAct(id: number, xRequestId): Promise<CommercialAct> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<CommercialAct>(this.globalSettings.apiLostFoundURL +
                                        `/commercial-act/${id}`,
                                        httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  updateCommercialAct(commercialAct, xRequestId?): Promise<CommercialAct> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.put<CommercialAct>(this.globalSettings.apiLostFoundURL +
                         `/commercial-act/${commercialAct.id}`,
                         JSON.stringify(commercialAct),
                         httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Marks
  // getMarks(xRequestId?): Observable<any> {
  //   const httpOptions = this.setDefaultHttpHeader(xRequestId);
  //   return this.http.get<any>(this.globalSettings.apiLostFoundURL +
  //                             '/marks',
  //                             httpOptions)
  //     .pipe(
  //       retry(1),
  //       catchError(this.handleError)
  //     );
  // }

  // setMark(id: number, folderId: number, mark: string, xRequestId?) {
  //   const httpOptions = this.setDefaultHttpHeader(xRequestId);
  //   return this.http.put(this.globalSettings.apiLostFoundURL +
  //                        `/messages/${id}/folders/${folderId}/mark/${mark}`,
  //                        httpOptions)
  //     .pipe(
  //       retry(1),
  //       catchError(this.handleError)
  //     );
  // }

  // unsetMark(id: number, folderId: number, xRequestId?) {
  //   const httpOptions = this.setDefaultHttpHeader(xRequestId);
  //   return this.http.put(this.globalSettings.apiLostFoundURL +
  //                        `/messages/${id}/folders/${folderId}/unmark`,
  //                        httpOptions)
  //     .pipe(
  //       retry(1),
  //       catchError(this.handleError)
  //     );
  // }

  // reference
  getReference(name, xRequestId?): Promise<any[]> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<any[]>(this.globalSettings.apiLostFoundURL +
                                '/master_data/' + name, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  getAdminData(name, xRequestId?): Promise<any[]> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);
    return this.http.get<any[]>(this.globalSettings.apiLostFoundURL +
                                '/admin/' + name, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  // Error handling
  handleError(error) {
    let errorMessage = '';
    let errorDetail: any = null;
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorDetail = error.error;
      errorDetail.status = error.status;
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    if (errorDetail) {
      return throwError(errorDetail);
    } else {
      return throwError(errorMessage);
    }
  }
}
