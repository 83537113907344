import { MessageTemplate, Message, MessageDetail, RecipientStatuses } from '../communication-module-data/message';
import { Urgency, Channel, Type, Flight, FilterParams } from '../communication-module-data/reference';
import { Component, OnInit, ViewChild, Input, TemplateRef, HostListener } from '@angular/core';
import { CommunicationModuleRestApiService } from '../communication-module-data/communication-module-rest-api.service';
import { FileSaverService } from 'ngx-filesaver';
import { Folder, FolderSetting } from '../communication-module-data/folder';
import { AdministrationSender } from '../administration-data/com-module';
import { ReferanceAirline, ReferanceAirport } from '../referance-module-data/referance';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Md5 } from 'ts-md5/dist/md5';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, delay, tap, filter, map, takeUntil } from 'rxjs/operators';
import { GlobalI18n } from '../settings/global-i18n';
import { ModuleName, UserFilter } from '../settings/global-settings';
import { NotificationsComponent } from '../notifications/notifications.component';
import { GlobalSettings, TypeEnterprise  } from '../settings/global-settings';
import { NGXLogger } from 'ngx-logger';
import { ECSEvent, EventProvider } from 'src/app/settings/logger-monitor';
import { arrRu, arrLat, isCyrillic, transliterationEnRu, transliterationRuEn, transliterationRuEnMessage, isLatin } from '../core/transliteration';
import { checkValidAddress } from '../core/validationAddresses';;
import { languageList } from '../core/reference';

export enum TextSize {
  Small = 14,
  Medium = 18,
  Large = 22
}

@Component({
  selector: 'app-communication-module',
  templateUrl: './communication-module.component.html',
  styleUrls: ['./communication-module.component.less']
})

export class CommunicationModuleComponent implements OnInit {

  @ViewChild('modalMessageFade', { static: true }) modalMessageFade: any;
  @ViewChild('modalAddFolder', { static: true }) modalAddFolder: any;
  @ViewChild('modalSelectTemplate', { static: true }) modalSelectTemplate: any;
  @ViewChild('mymodal') mymodal: TemplateRef<any>;
  @ViewChild('modalMessage', { static: false }) modalMessage: TemplateRef<any>;

  typeEnterprise = TypeEnterprise;
  private privateFlights: Array<Flight> = [];
  private md5 = new Md5();
  private requestQueue: number[] = [];
  // Максимальное количество запрос на обновление данных в очереди
  private requestQueueMaxLength = 22;
  // Максимальное количество пропущенных обновлений, при превышении значения
  // данного флага показывается ошибка работы с сервером с просьбой
  // перезагрузить страницу
  private requestQueueMaxSkipUpdate = 3;
  // Счетчик пропущенных обновлений данных
  private requestQueueCurrentSkipUpdate = 0;

  messages: Array<Message> = [];
  messagesHash: string | Int32Array;
  message: MessageDetail = new MessageDetail();
  // Пустое сообщение чтобы сравнивать с ним при закрытии
  emptyMessage: MessageDetail;
  // Выбранное сообщение, чтобы панель с деталями не летела в трубу
  // когда создаем, отвечаем или пересылаем
  selectedMessage: MessageDetail = new MessageDetail();
  messageGroup: Array<MessageDetail> = [];
  messageId: number;
  // Храним активную строку, чтобы можно было перемещаться кнопками
  messageTrActive;
  messageFlightsId: Array<number> = [];

  // копировать ли текст при "Ответить всем"
  copyTextForReply = false;

  // Список папок с параметрами (всего сообщений, новых и т.д.)
  folders: Folder[] = [];
  allFolders: Folder[] = [];
  folderDraft: Folder = null;
  showFolderThree = true;
  // Вид предпросмотра, one или chain
  messageView = 'one';
  // Автоматический номер
  autoNumber = true;
  // Доступные размеры текста описанные перечислением
  availableTextSizes = TextSize;
  // Текущий размер текста
  textSize = this.availableTextSizes.Medium;
  userAction: string;
  userAnswer = null;
  currentFolderInfo = {
    folderUnread: '', // количество новых сообщений в папке
    folderUnreadFiltered: '', // количество новых сообщений в папке с примененным фильтром
    folderAll: '', // общее количество сообщений в папке
    baseLink: '' // URL текущей папки для запросов
  };

  folder: Folder = new Folder();
  channels: Channel;
  // Общий список контактов для всех каналов
  private contacts_: Array<any> = [];
  // Список контактов для выбранного канала
  contactsForChannel: Array<any> = [];
  contactsForChannels: Array<any> = [];
  // Список контактов пользователя
  userContactsForChannel: Array<any> = [];
  senders: Array<AdministrationSender> = [];
  sendersForChannels: Array<any> = [];
  urgencies: Array<Urgency> = [];
  urgenciesForChannel: Array<any> = [];
  types: Array<Type> = [];
  folderSetting: FolderSetting;
  // Справочник авиакомпаний
  airlines: Array<ReferanceAirline> = [];

  // Статус загрузки/обновления справочника для отображения анимации
  // в компоненте ng-select
  airlinesLoading = false;
  // Подгружаемый список для вывода в выпадающий список
  airlinesBuffer = [];
  // Размер отображаемых данных в выпадающем списке
  airlinesBufferSize = 50;

  // Справочник аэропортов
  airport: Array<ReferanceAirport> = [];
  // Статус загрузки/обновления справочника для отображения анимации
  // в компоненте ng-select
  airportLoading = false;
  // Подгружаемый список для вывода в выпадающий список
  airportBuffer = [];
  // Размер отображаемых данных в выпадающем списке
  airportBufferSize = 50;
  numberOfItemsFromEndBeforeFetchingMore = 10;

  // Templates
  privateTemplates: Array<MessageTemplate> = [];
  template: MessageTemplate = new MessageTemplate();
  // Выбранное поле чтобы отобржать подсказки
  selectedTemplateInput: any = null;
  // Текст сообщения созданного по шаблону
  textMessageTemplate: Array<any> = [];
  // Валидация имени шаблона (нельзя сохранять с одинаковыми)
  validTemplateName = false;

  marks: Array<string> = [];
  selectedFolder: number = null;
  selectedFolderName = '';
  messageIsSelected = false;
  isNewMessage = false;
  // Кол-во всех сообщений
  countAllMessages = 0;

  // Время первого прочтения сообщения
  processedTime: Date = new Date();

  // Чтобы не сбрасывалась выбранная папка для копирования при обновлении
  folderForCopy: number = null;

  // ID каналов
  channelIds = {
    aftnId: 1,
    sitatexId: 2,
    emailId: 3
  };

  // Пагинация
  countsPerPage = [20, 40, 80];
  messagesInPage = 20;
  messagesPageActive = 1;
  // messagesPageAll = null;
  messagesCount = 1;
  messagesPagesNav = [];

  // Выбор действия - копировать сообщение или переместить из папки в папку. если не пустое, отображается модалка выбора папки
  selectedAction = '';
  // Родительская папка - чтобы понимать, откуда брать дочерние для списка перемещени/копирования сообщения по папкам
  selectedParentFolder: number;

  // Папки для копирования
  copyIds = new Set();
  foldersForMessage = [];

  // Какую модалку показать
  showModal = null;

  // Глобальные ошибки модуля
  errorComman = null;

  // Модалка рейсов
  // Выбранные рейсы
  flightsList: number;

  // Переменная содержащая описание ошибки возникающей при работе модуля
  errorMessage = '';
  errorRecipientAddress = {1: '', 2: '', 3: ''};
  // Тип ошибки: error, warning, info
  errorType = '';

  // Фильтр
  showFilter = false;

  // Отображение списка сообщений
  commVertical = true;

  // Показывать ли детали
  showDetail = true;

  // Для подтверждения действия - больше не нужно, пока оставила на всякий, потом удалить
  /*
  // Текст подтверждения
  confirmMessage = '';
  // Функция ОК
  okFunc: Function;
  // Функция - отмена
  cancelFunc: Function;
  // id для действий, нужен потому что в функции не передать аргументы
  selectId: number;
  */

  // Запрет просмотра в чужой папке
  cannotView = false;

  // Ошибка в работе сервера, флаг устанавливается и показывается сообщение
  // пользователю с просьбой перезагрузить страницу
  serverError = false;

  // Загрузка
  loading = false;
  // Загрузка
  loadingContact = false;
  loadingMessagesList = true;
  loadingMessagesDetail = false;
  // Флаг что сообщение отправлено
  messageSended = false;
  // Доступна кнопка создания сообщения
  // пока не прогрузились настройки папки, новое создается с ошибкой
  buttonAvailable = false;

  // Переменные для поиска
  @Input() searchInput = '';
  @Input() filterApply = false;
  searchTemplate = {
    name: '',
    recipient: '',
    text: ''
  }
  // Замена в шаблоне
  replaceMode = {
    on: false,
    option: 'recipient',
    textFrom: '',
    textTo: '',
    replacingId: []
  }

  filterParams: FilterParams = new FilterParams();
  // Массив пользовательских фильтров
  userFilters: Array<UserFilter> = [];
  // Выбранный фильтр
  selectedFilter: UserFilter = new UserFilter();
  countAllFilters = 0;

  closeResult: string;

  // Сообщения (об отправке и тп, полученные из глобальных настроек)
  systemMessagesTexts: Array<string> = [];
  messageFlights = []; // Массив для записи рейсов, чтобы потом отображать в поле
  verifiedMessage: Array<any> = []; // для валидации сообщения

  messagePanel = 'small'; // Вид панели в окне сообщения, full или small
  activeChannel = 1;

  // Таймер обновления данных
  interval: any;

  // Таймер слежения за стабильностью работы бэка
  intervalCheckResponseFromBackend: any;

  // Флаг включающий/выключающий анимацию обработки разбора массива данных для
  // ng-select
  recipientLoadAnimation = false;
  filterFlightLoadAnimation = false;
  filterRecipientLoadAnimation = false;
  filterSenderLoadAnimation = false;
  filterAFTNNumberLoadAnimation = false;
  filterLoadAnimation = {
    aftnNumber: false,
    flight: false,
    recipient: false,
    sender: false
  };

  // Для хранения данных, введенных в поля в фильтре -
  // чтобы при потере фокуса они парсились и вставлялись в поле
  selectEnteredData = {
    recipient: '',
    sender: '',
    aftnNumber: '',
    flight: '',
    textValue: '',
    aftn: '',
    sitatex: '',
    email: '',
  };

  // Для быстрого фильтра по airline
  // Start
  /** control for the selected airline for server side filtering */
  public airlineServerSideCtrl: FormControl = new FormControl();

  /** control for filter for server side. */
  public airlineServerSideFilteringCtrl: FormControl = new FormControl();

  /** indicate search operation is in progress */
  public searching = false;

  /** list of airlines filtered after simulating server side search */
  public filteredServerSideAirline: ReplaySubject<ReferanceAirline[]> = new ReplaySubject<ReferanceAirline[]>(1);

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();
  // End

  // Разрешенные символы в тексте сообщения
  mtk2 = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
    'А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ж', 'З', 'И', 'Й', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Х', 'Ц', 'Ч', 'Ш', 'Щ', 'Ь', 'Ы', 'Э', 'Ю', 'Я',
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-', '?', ':', '(', ')', '.', ',', '=', '`', '/', '+', ' ', '\r', '\n', '\''];

  // Запрещенные последовательности символов
  prohibitedSequences = ['ЗЦЗЦ', 'ZCZC', '+:+:', 'НННН', 'NNNN', ',,,,'];

  defaultTelegramLanguageBindingsList = languageList;

  selectedTelegramLanguage = null;

  directionList = [];

  public addTagContactRefernace: (name) => void;
  public addTagContactRefernaceAftn: (name) => void;
  public addTagContactRefernaceSitatex: (name) => void;
  public addTagContactRefernaceEmail: (name) => void;
  public addTagFilterFlights: (name) => void;
  public addTagFiltertTextValue: (name) => void;
  public addTagFilterRecipient: (name) => void;
  public addTagFilterSender: (name) => void;
  public addTagFilterAFTNNumber: (name) => void;

  constructor(public restApi: CommunicationModuleRestApiService,
              public notifications: NotificationsComponent,
              public globalI18n: GlobalI18n,
              private fileSaverService: FileSaverService,
              private modalService: NgbModal,
              private cookieService: CookieService,
              private activatedroute: ActivatedRoute,
              public globalSettings: GlobalSettings,
              private logger: NGXLogger) {
    globalSettings.loadDefaultConfig();
    this.activatedroute.params.subscribe(data => {
      this.selectedFolder = +data['homeFolder'];
      this.selectedParentFolder = +data['homeFolder'];
      this.serverError = false;
    });
    this.addTagContactRefernace = (name) => this.addTagContactFromArray(name);
    this.addTagContactRefernaceAftn = (name) => this.addTagContactFromArray(name, this.channelIds.aftnId);
    this.addTagContactRefernaceSitatex = (name) => this.addTagContactFromArray(name, this.channelIds.sitatexId);
    this.addTagContactRefernaceEmail = (name) => this.addTagContactFromArray(name, this.channelIds.emailId);
    this.addTagFilterFlights = (name) => this.addTagFilterFromArray(name, 'flight'); // this.addTagFilterFlightsFromArray(name);
    this.addTagFiltertTextValue = (name) => this.addTagFilterFromArray(name, 'textValue');
    this.addTagFilterRecipient = (name) => this.addTagFilterFromArray(name, 'recipient'); // this.addTagFilterRecipientFromArray(name);
    this.addTagFilterSender = (name) => this.addTagFilterFromArray(name, 'sender'); // this.addTagFilterSenderFromArray(name);
    this.addTagFilterAFTNNumber = (name) => this.addTagFilterFromArray(name, 'aftnNumber'); // this.addTagFilterAFTNNumberFromArray(name);
  }

  async ngOnInit() {
    await this.globalSettings.loadComplete();
    this.showFolderThree = this.globalSettings.showFolderThree;
    this.copyTextForReply = this.globalSettings.copyTextForReply;
    Promise.all([
      this.loadChannels(),
      this.loadContacts(),
      this.loadTypes(),
      this.loadAirlines(),
      this.loadAirports(),
      this.loadMarks(),
      this.loadUrgency()
    ]).then(() => {
      if (this.globalSettings.typeEnterprise == this.typeEnterprise.Airport) {
        this.loadFlights();
      }
      this.loadFolders();
      this.loadAllFolders();
      this.filterAirline();
      this.loadFilters();
      this.loadFolder(this.selectedFolder);
      //this.loadAllSenders(this.selectedFolder);
      this.setMessages();
    });

    this.commVertical = (this.cookieService.get('view') == 'vertical');

    this.getPerPage();

    // Обновление каждые 15 секунд
    this.interval = setInterval(() => {
      this.refreshData();
    }, 15000);

    // Проверка ответов от бэка каждую минуту
    this.intervalCheckResponseFromBackend = setInterval(() => {
      this.checkBackendStable();
    }, 60000);
  }

  getPerPage() {
    this.messagesInPage = this.globalSettings.perPage;
  }

  async changePerPage(perPage) {
    await this.globalSettings.setPerPage(perPage);
    this.messagesInPage = perPage;
    this.loadMessages();
  }

  setMessages() {
    this.selectedTelegramLanguage = this.defaultTelegramLanguageBindingsList[0]
    this.systemMessagesTexts['textLineLong'] = this.globalI18n.getMessage(ModuleName.ComMan, 'textLineLong');
    this.systemMessagesTexts['allOnFilter'] = this.globalI18n.getMessage(ModuleName.ComMan, 'allOnFilter');
    this.systemMessagesTexts['allOnFolder'] = this.globalI18n.getMessage(ModuleName.ComMan, 'allOnFolder');
    this.systemMessagesTexts['messageSent'] = this.globalI18n.getMessage(ModuleName.ComMan, 'messageSent');
    this.systemMessagesTexts['messageSave'] = this.globalI18n.getMessage(ModuleName.ComMan, 'messageSave');
    this.directionList = [
      { id: -1, label: this.globalI18n.getMessage(ModuleName.ComMan, 'directionIncomingOutgoing') },
      { id: 0, label: this.globalI18n.getMessage(ModuleName.ComMan, 'directionIncoming') },
      { id: 1, label: this.globalI18n.getMessage(ModuleName.ComMan, 'directionOutgoing') }
    ];
  }

  reloadPage() {
    window.location.reload();
  }

  /**
   * Функция проверки тайм-меток запроса данных на обновлени информации
   *
   * Если запрос есть в очереди, то данные можно обновить.
   * Если перед запросом были другие, ответы на которые не получены, их нужно
   * удалить и игнорировать полученные обновления по старым запросам.
   *
   * @param {number} timestamp Временная метка проверяемого запроса
   * @return {boolean} Истина если запрос актуальный и данные можно обновить, иначе ложь
   */
  checkRequestQueue(timestamp: number): boolean {
    // Признак найдена ли тайм-метка в ожидающих ответ запросах
    let timestampIsExist = false;
    // Позиция элемента в общем списке, все элементы стоящие до него
    // необходимо удалить, так как ответы на ранее сформированные запросы
    // являются устаревшими данными
    let timestampIsExistIndex = -1;
    for (let i = 0; i < this.requestQueue.length; i++) {
      if (this.requestQueue[i] == timestamp) {
        timestampIsExist = true;
        timestampIsExistIndex = i;
        break;
      }
    }

    // Проверка найденой тайм-метки и удаление всех данных перед ней и самой
    // метки
    if (timestampIsExist) {
      // Удаление с начала массива, количество удаляемых элементов, так как
      // массив начинается с 0, то индекс всегда отстает на 1, по этому + 1
      this.requestQueue.splice(0, timestampIsExistIndex + 1);
      // Если дошел любой запрос обновления данных, скидывается флаг
      // пропущенных обновлений
      this.requestQueueCurrentSkipUpdate = 0;
    }

    return timestampIsExist;
  }

  /**
   * Функция добавления нового запроса обновления данных в общую очередь
   * @param {number} timestamp
   * @return {boolean} Истина если запрос может быть выполнен, иначе ложь
   */
  addQueryToRequestQueue(timestamp: number): boolean {
    if (this.serverError) {
      return false;
    }
    if (this.requestQueue.length >= this.requestQueueMaxLength) {
      // Увеличение счетчика пропущенных обновлений данных
      this.requestQueueCurrentSkipUpdate++;
      return false;
    } else {
      this.requestQueue.push(timestamp);
      return true;
    }
  }

  checkBackendStable() {
    if (this.requestQueueCurrentSkipUpdate >= this.requestQueueMaxSkipUpdate) {
      this.serverError = true;
    }
  }

  onChangeTelegramLanguage(event) {
    this.logger.info('onChangeTelegramLanguage',
                     new ECSEvent(ModuleName.ComMan,
                                  'modal.messageEdit.telegramLamguage',
                                  'change',
                                  '',
                                  EventProvider.UI));
    this.selectLanguage();
  }

  async refreshData() {
    await this.loadFolders();
  }

  onScrollToEnd() {
    this.airlinesFetchMore();
  }

  onScroll({ end }) {
    if (this.airlinesLoading || this.airlines.length <= this.airlinesBuffer.length) {
      return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.airlinesBuffer.length) {
      this.airlinesFetchMore();
    }
  }

  private airlinesFetchMore() {
    const len = this.airlinesBuffer.length;
    const more = this.airlines.slice(len, this.airlinesBufferSize + len);
    this.airlinesLoading = true;
    this.airlinesBuffer = this.airlinesBuffer.concat(more);
    this.airlinesLoading = false;
  }

  /**
   * Функция поиска в выпадающим списке по нескольким параметрам
   * @param {string} term Строка для поиска введеня пользователем
   * @param {ReferanceAirline} item Элемент для поиска
   */
  customSelectSearchAirline(term: string, item: ReferanceAirline) {
    term = term.toLowerCase();
    return item.iata.toLowerCase().indexOf(term) > -1 ||
      item.icao.toLowerCase().indexOf(term) > -1 ||
      item.names.toLowerCase().indexOf(term) > -1
  }

  onScrollToEndAirport() {
    this.airlinesFetchMore();
  }

  onScrollAirport({ end }) {
    if (this.airportLoading || this.airport.length <= this.airportBuffer.length) {
      return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.airportBuffer.length) {
      this.airportFetchMore();
    }
  }

  private airportFetchMore() {
    const len = this.airportBuffer.length;
    const more = this.airport.slice(len, this.airportBufferSize + len);
    this.airportLoading = true;
    this.airportBuffer = this.airportBuffer.concat(more);
    this.airportLoading = false;
  }

  /**
   * Функция поиска в выпадающим списке по нескольким параметрам
   * @param {string} term Строка для поиска введеня пользователем
   * @param {ReferanceAirport} item Элемент для поиска
   */
  customSelectSearchAirport(term: string, item: ReferanceAirport) {
    term = term.toLowerCase();
    return item.iata.toLowerCase().indexOf(term) > -1 ||
      item.icao.toLowerCase().indexOf(term) > -1 ||
      item.names.toLowerCase().indexOf(term) > -1
  }

  // Для быстрого фильтра по airline
  filterAirline() {
    this.airlineServerSideFilteringCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          if (!this.airlines) {
            return [];
          }
          return this.airlines.filter(airline => airline.name[0].toLowerCase().indexOf(search) > -1
            || airline.name[1].toLowerCase().indexOf(search) > -1
            || airline.iata.toLowerCase().indexOf(search) > -1
            || airline.icao.toLowerCase().indexOf(search) > -1);
        }),
        delay(500),
        takeUntil(this._onDestroy)
      )
      .subscribe(filteredBanks => {
        this.searching = false;
        this.filteredServerSideAirline.next(filteredBanks);
      },
        error => {
          this.searching = false;
        });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Функция загрузки списка сообщений
   * @param {number} activePage Активная страница отображения сообщений
   */
  loadMessages(activePage = 1) {
    // Создание уникального идентификатора события
    let xRequestId = this.globalSettings.randomUuid;
    this.logger.info('loadMessages',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.loadMessages',
                                  'call',
                                  activePage,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    let paramsGetMessage: FilterParams = new FilterParams();
    this.getFolderMessageInfo();


    if (this.filterApply) {
      // Дублируем. чтобы не менять исходный
      Object.assign(paramsGetMessage, this.filterParams);
    }

    this.messagesPageActive = activePage;
    paramsGetMessage.folder = this.selectedFolder.toString();
    paramsGetMessage.limit = this.messagesInPage.toString();
    paramsGetMessage.page = this.messagesPageActive.toString();
    paramsGetMessage.mark = this.filterParams.mark;
    paramsGetMessage.depth = this.filterParams.depth;

    const timestamp = Date.now();
    if (this.addQueryToRequestQueue(timestamp)) {
      return this.restApi.getMessages(paramsGetMessage,
                                      timestamp,
                                      this.currentFolderInfo.baseLink,
                                      xRequestId)
      .subscribe((data: [Message[], number]) => {
        this.logger.trace('loadMessages',
                         new ECSEvent(ModuleName.ComMan,
                                      'func.loadMessages',
                                      'reply',
                                      data,
                                      EventProvider.APPLICATION,
                                      '',
                                      xRequestId));
        this.logger.debug('Messages', data);
        this.loadFilters();
        if (this.checkRequestQueue(data[1])) {
          this.cannotView = false;
          this.md5.start();
          const newDataHash = this.md5.appendStr(JSON.stringify(data[0])).end();
          // Проверка хеша текущих и новых данных
          if (this.messagesHash !== newDataHash) {
            // Обновление данных
            this.messages = [];
            data[0].forEach(item => {
              const message = new Message();
              Object.assign(message, item);
              this.messages.push(message);
            });
            // Обновление хеша сохраненных данных
            this.messagesHash = newDataHash;
            // Загрузка просмотра активного собщения
            if (this.messageId && this.selectedMessage.id) {
              // Если в списке загруженных сообщений последнего не оказалось
              // то оно было перемещено в архив, показывать превью не надо
              if (this.selectedFolder === -1) {
                this.loadDraft(this.selectedMessage.id, this.message.folderId);
              } else if (this.getById(this.messages, this.selectedMessage.id) != null) {
                // Перезагрузка цепочки
                xRequestId = this.globalSettings.randomUuid;
                this.restApi.getChainMessage(this.selectedMessage.id, this.selectedFolder, xRequestId).subscribe(data => {
                  this.logger.trace('loadMessages',
                                   new ECSEvent(ModuleName.ComMan,
                                                'func.loadMessages',
                                                'reply',
                                                data,
                                                EventProvider.APPLICATION,
                                                '',
                                                xRequestId));
                  if (data != null) {
                    this.messageGroup = [];
                    data.forEach(mess => {
                      mess.flightsId = [];
                      this.messageGroup.push(mess);
                    });
                  }
                });
              } else {
                this.selectedMessage = new MessageDetail();
                this.messageGroup = [];
              }
            }
          }
          this.logger.debug('Load messages for page', activePage , this.messages);
          this.loadingMessagesList = false;
        }
      }, err => {
        if (err.status == 403) {
          this.cannotView = true;
        } else if (err.status == 500) {
          this.displayError(err);
        }
      });
    }
  }

  /**
   * Функция проверки какая папка с сообщениями выбрана
   * @param {number} Идентификатор открытой папки
   * @return {boolean} Истина если открыта, иначе ложь
   */
  checkOpen(id: number) {
    let mass = this.getById(this.folders, id).children;
    if (mass != null) {
      for (const item of mass) {
        if (item.id == this.selectedFolder) {
          return true;
        }
      }
    }
  }

  /**
   * Загружает цепочку сообщений и показывает ее (либо одно сообщение)
   *
   * @param {id} id сообщения
   * @param {read} флаг, прочитано ли сообдщение
   * @param {folderId} id папки
   * @param {event} строка, по которой мы кликнули (для перехода по сообщениям клавиатурой)
   */
  messageDetail(id: number, read?: boolean, folderId?: number, event?) {
    this.messageTrActive = event;
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.info('messageDetail',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.messageDetail',
                                  'load',
                                  id,
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    this.logger.debug('Message detail for id:', id);
    this.template = new MessageTemplate();
    this.messageView = 'one';
    this.messageGroup = [];
    this.loadingMessagesDetail = this.loading = true;
    if (this.selectedFolder === -1) {
      this.loadDraft(id, folderId);
    } else {
      this.restApi.getChainMessage(id, this.selectedFolder, xRequestId).subscribe(data => {
        this.logger.trace('messageDetail',
                         new ECSEvent(ModuleName.ComMan,
                                      'main.messageDetail',
                                      'reply',
                                      data,
                                      EventProvider.APPLICATION,
                                      '',
                                      xRequestId));
        this.logger.debug('messageDetail', data);
        if (data != null) {
          data.forEach(mess => {
            // Было для рейсов
            // mess.flightsId = [];
            this.messageGroup.push(mess);
          });
          this.selectMessage(id, read);
          if (this.checkFolderRuleIsFull() && !read && this.selectedFolder != 0 && this.message.direction == 0) {
            this.readMessage(id);
            this.getById(this.messages, id).noOneRead = false;
            this.getById(this.messages, id).read = true;
          }
          // Было для получения рейсов, но теперь они приходят в объекте, пока оставила на всякий случай
          // this.getMessageFlights(id);
          this.logger.debug('Get message:', this.message);
          this.loadingMessagesDetail = this.loading = false;
        }
      });
    }
  }

  /**
   * Открывает черновик сообщения
   *
   * @param {id} id сообщения
   * @param {folderId} id папки
   * @param {isOpen} открыто ли превью сообщения в нижней части экрана
   */
  loadDraft(id, folderId, isOpen = false) {
    const xRequestId = this.globalSettings.randomUuid;
    this.restApi.loadDraft(id, folderId, xRequestId).subscribe(async data => {
      this.logger.trace('loadDraft',
                   new ECSEvent(ModuleName.ComMan,
                                'main.loadDraft',
                                'reply',
                                data,
                                EventProvider.APPLICATION,
                                '',
                                xRequestId));
      if (data != null) {
        if (isOpen) {
          await this.loadAllSenders(data.folderId);
        }
        this.messagePanel = 'small';
        this.message = Object.assign(new MessageDetail(), data);
        this.selectedMessage = Object.assign(new MessageDetail(), data);
        this.selectedMessage.sender = this.toString(this.selectedMessage.sender);
        this.selectedMessage.recipient = this.toString(this.selectedMessage.recipient);

        this.message.urgencyIds = data.urgencyId;

        this.message.urgencyId = null;
        this.message.sender = '';
        this.message.recipient = '';

        if (data.sender) {
          this.message.senders = data.sender;
        }

        for (const key in this.message.senders) {
          let searchSender = this.sendersForChannels[key].filter(el => el.addressEnglish === this.message.senders[key] || el.addressLocal === this.message.senders[key]);
          if (searchSender.length === 0) {
            this.message.senders[key] = '';
          }
        }

        if (data.recipient[this.channelIds.aftnId].length > 0) {
          if (isCyrillic(this.message.senders[this.channelIds.aftnId])) {
            this.selectedTelegramLanguage = this.defaultTelegramLanguageBindingsList[1];
          } else {
            this.selectedTelegramLanguage = this.defaultTelegramLanguageBindingsList[0];
          }
          this.message.recipient = '';
          this.selectLanguage();
        }

        for (const key in this.channelIds) {
          if (Object.prototype.hasOwnProperty.call( this.channelIds, key)) {
            const channel = this.channelIds[key];
            if (data.recipient) {
              this.message.recipentObjectList[channel] = [];
              data.recipient[channel].split(',').forEach(item => {
                if (item.length !== 0) {
                  if (this.activeChannel === 0) {
                    this.activeChannel = channel;
                  }
                  this.message.recipentObjectList[channel] =
                    [...this.message.recipentObjectList[channel], this.searchInRecipients(item, channel)];
                }
              });
            }
          }
        }
        if (this.activeChannel === 0) {
          this.activeChannel = 1;
        }

        this.messageId = this.message.id;
        this.messageIsSelected = true;
        this.isNewMessage = true;
        this.verifiedMessage = [];
        this.loadingMessagesDetail = this.loading = false;
        // Сохраняем исходное сообщение для сравнения при закрытии
        this.emptyMessage = JSON.parse(JSON.stringify(this.message));

        this.messageGroup = [];
        this.messageGroup.push(this.message);
      }
    });
  }

  /**
   * Удаляет черновик сообщения
   *
   * @param {id} id сообщения
   * @param {folderId} id папки
   * @param {action} действие, которое выполнится по завершении
   */
  deleteDraft(id, folderId, action?) {
    const xRequestId = this.globalSettings.randomUuid;
    this.loading = true;
    this.userAction = '';
    this.restApi.deleteDraft(id, folderId, xRequestId).subscribe(data => {
      this.logger.trace('deleteDraft',
                   new ECSEvent(ModuleName.ComMan,
                                'main.deleteDraft',
                                'reply',
                                data,
                                EventProvider.APPLICATION,
                                '',
                                xRequestId));

      setTimeout(() => {
        this.loading = false;
        if (action) action();
        this.changeActiveFolder(-1, -1);
      }, 3000);
    }, err => {
      this.displayError(err);
    });
  }

  /**
   * Меняет вид отображения сообщения - свернута шапка или нет
   */
  changeMessageView() {
    this.messagePanel = this.messagePanel === 'small' ? 'full' : 'small';
    this.logger.info('changeMessageView',
                      new ECSEvent(ModuleName.ComMan,
                                  'modal.messageEdit.messageHeaders',
                                  'change-view',
                                  this.messagePanel,
                                  EventProvider.UI));
  }

  loadTemplates() {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.info('loadTemplates',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.loadTemplates',
                                  'click',
                                  '',
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    this.loading = true;
    this.searchTemplate = {
      name: '',
      recipient: '',
      text: ''
    }
    this.restApi.getTemplates(this.selectedFolder, xRequestId).subscribe(data => {
      this.logger.trace('loadTemplates',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.loadTemplates',
                                  'reply',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
      if (data != null) {
        this.privateTemplates = [];
        data.forEach(template => {
          this.privateTemplates.push(template);
        });
        this.loading = false;

      }
    }, err => this.displayError(err));
  }

  selectTemplate() {
    this.loadTemplates();
    this.showModal = 'template';
    setTimeout(() => {
      let search = document.getElementById('searchTemplateName');
      search.focus();
    }, 0);
    this.logger.debug('Load templates', this.privateTemplates);
    this.replaceMode = {
      on: false,
      option: 'recipient',
      textFrom: '',
      textTo: '',
      replacingId: []
    }
  }

  get templates(): Array<MessageTemplate> {
    return this.privateTemplates
      .slice()
      .filter(item => {
        return (this.searchTemplate.name === '' || item.name.toLowerCase().includes(this.searchTemplate.name.toLowerCase()))
          && (this.replaceMode.textFrom === ''
              || this.replaceMode.option === 'name' && item.name.toLowerCase().includes(this.replaceMode.textFrom.toLowerCase())
              || this.replaceMode.option === 'recipient' && this.sliseText(item.recipient, 1000).toLowerCase().includes(this.replaceMode.textFrom.toLowerCase()))
          && (this.searchTemplate.recipient == '' || this.sliseText(item.recipient, 1000).toLowerCase().includes(this.searchTemplate.recipient.toLowerCase()))
          && (this.searchTemplate.text === '' || item.message.toLowerCase().includes(this.searchTemplate.text.toLowerCase()));
      });
  }

  loadTemplate(id: number) {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.info('loadTemplate',
                     new ECSEvent(ModuleName.ComMan,
                                  'modalTemplate.buttons.load',
                                  'click',
                                  id,
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    this.loading = true;
    const direction = this.message.direction;
    const errored = this.message.errored;

    this.restApi.getTemplate(this.selectedFolder, id, xRequestId).subscribe(data => {
      this.logger.trace('loadTemplate',
                     new ECSEvent(ModuleName.ComMan,
                                  'modalTemplate.buttons.load',
                                  'reply',
                                  id,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
      if (data != null) {
        this.message = new MessageDetail();
        this.template = new MessageTemplate();
        Object.assign(this.message, data);
        Object.assign(this.template, data);

        this.message.recipient = '';
        this.message.sender = '';

        if (this.template.sender) {
          this.message.senders = this.template.sender;
        }
        for (const key in this.message.senders) {
          let searchSender = this.sendersForChannels[key].filter(el => el.addressEnglish === this.message.senders[key] || el.addressLocal === this.message.senders[key]);
          if (searchSender.length === 0) {
            this.message.senders[key] = '';
          }
        }

        if (this.template.recipient[this.channelIds.aftnId].length > 0) {
          if (isCyrillic(this.message.senders[this.channelIds.aftnId])) {
            this.selectedTelegramLanguage = this.defaultTelegramLanguageBindingsList[1];
          } else {
            this.selectedTelegramLanguage = this.defaultTelegramLanguageBindingsList[0];
          }
          this.selectLanguage();
          this.activeChannel = this.channelIds.aftnId;
          this.activeChannel = this.channelIds.aftnId;
        } else if (this.template.recipient[this.channelIds.sitatexId].length > 0) {
          this.activeChannel = this.channelIds.sitatexId;
        } else if (this.template.recipient[this.channelIds.emailId].length > 0) {
          this.activeChannel = this.channelIds.emailId;
        } else {
          this.activeChannel = this.channelIds.aftnId;
        }

        for (const key in this.channelIds) {
          let channel = this.channelIds[key];
          if (this.template.recipient) {
            this.message.recipentObjectList[channel] = [];
            this.template.recipient[channel].split(',').forEach(item => {
              if (item.length != 0) {
                this.message.recipentObjectList[channel] = [...this.message.recipentObjectList[channel], this.searchInRecipients(item, channel)];
              }
            });
          }
        }
        if(this.template.urgencyId) {
          this.message.urgencyIds = this.template.urgencyId;
        }
        this.message.direction = direction;
        this.message.errored = errored;
        this.loading = false;
        this.showModal = null;
        this.logger.debug('Load template message', this.message);
      }
    }, err => this.displayError(err));
  }

  modalSaveTemplate() {
    this.loadTemplates();
    if (!this.template.id) {
      this.template.name = '';
      this.userAction = 'saveTemplate';
    } else {
      this.userAction = 'updateTemplate';
    }
    this.validTemplateName = false;
  }

  inputTemplateName(value: string) {
    if (value) {
      if (this.templates.filter(t => t.name === value).length > 0) {
        this.validTemplateName = false;
      } else {
        this.validTemplateName = true;
      }
    }
  }

  saveTemplate() {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.info('saveTemplate',
                     new ECSEvent(ModuleName.ComMan,
                                  'modalMessage.buttons.saveTemplate',
                                  'click',
                                  '',
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    // Чтобы не терялось имя шаблона
    const name = this.template.name;
    this.template = new MessageTemplate();
    Object.assign(this.template, this.message);
    this.logger.debug('Save template for message', this.message);
    this.template.name = name;

    this.template.sender = {1: '', 2: '', 3: ''};
    this.template.recipient = {1: '', 2: '', 3: ''};
    this.template.urgencyId = {1: null, 2: null, 3: null};

    if (!this.isNewMessage) {
      if (this.message.direction !== 0) {
        this.message.recipentObjectList[this.message.channelId] = this.message.recipentObjectList[this.message.channelId];
        this.message.senders[this.message.channelId] = this.message.sender;
      }
      this.message.urgencyIds[this.message.channelId] = this.message.urgencyId;
    }
    if (this.message.direction !== 0) {
      for (const key in this.channelIds) {
        let recipientArray = [];
        let channel = this.channelIds[key];
        if (channel=='') {
          continue;
        }
        if (this.contactsForChannels[channel]) {
          this.contactsForChannels[channel].forEach(element => {
            element.addresses.forEach(address => {
              if (this.message.recipentObjectList[channel].indexOf(address.id) != -1) {
                recipientArray.push(address.name);
              }
            });
          });
        }

        if (recipientArray.length > 0) {
          this.template.recipient[channel] = recipientArray.join(',');
        }
      }
      if(this.message.senders) {
        this.template.sender = this.message.senders;
      }
    }
    if(this.message.urgencyIds) {
      this.template.urgencyId = this.message.urgencyIds;
    }

    this.logger.debug('Template', this.template);

    this.loading = true;
    this.logger.debug('Save template', this.template);
    this.restApi.saveTemplate(this.selectedFolder, this.template, xRequestId).subscribe(data => {
      this.logger.trace('saveTemplate',
                     new ECSEvent(ModuleName.ComMan,
                                  'modalMessage.buttons.saveTemplate',
                                  'reply',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
      if (data != null) {
        this.loading = false;
        this.userAction = '';
      }
    }, err => this.displayError(err));
  }

  async deleteTemplate(id) {
    this.errorComman = this.globalI18n.getMessage(ModuleName.ComMan, 'deleteTemplate');
    this.errorType = 'warning';
    this.userAction = 'setAnswer'
    await this.waitAnswer().then(async res => {
      if (res) {
        const xRequestId = this.globalSettings.randomUuid;
        this.logger.info('deleteTemplate',
                        new ECSEvent(ModuleName.ComMan,
                                      'modalTemplate.buttons.delete',
                                      'click',
                                      '',
                                      EventProvider.UI,
                                      '',
                                      xRequestId));
        this.loading = true;
        this.restApi.getTemplate(this.selectedFolder, id, xRequestId).subscribe(data => {
          this.logger.trace('deleteTemplate',
                        new ECSEvent(ModuleName.ComMan,
                                      'modalTemplate.buttons.delete',
                                      'reply',
                                      data,
                                      EventProvider.APPLICATION,
                                      '',
                                      xRequestId));
          if (data != null) {
            this.template = new MessageTemplate();
            Object.assign(this.template, data);
            this.template.dtRangeFinish = new Date();
            this.restApi.updateTemplate(this.selectedFolder, id, this.template, xRequestId).then(data => {
              this.logger.trace('deleteTemplate',
                        new ECSEvent(ModuleName.ComMan,
                                      'modalTemplate.buttons.delete',
                                      'reply',
                                      data,
                                      EventProvider.APPLICATION,
                                      '',
                                      xRequestId));
              if (data != null) {
                this.loadTemplates();
                this.showFilter = false;
              }
            }, err => this.displayError(err));
          }
        }, err => this.displayError(err));
      }
    });
  }

  updateTemplate(id: number) {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.info('updateTemplate',
                     new ECSEvent(ModuleName.ComMan,
                                  'modalTemplate.buttons.save',
                                  'click',
                                  id,
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    Object.assign(this.template, this.message);
    this.loading = true;
    this.template.recipient = {1: '', 2: '', 3: ''};
    for (const key in this.channelIds) {
      let recipientArray = [];
      let channel = this.channelIds[key];
      if (channel=='') {
        continue;
      }
      if (this.contactsForChannels[channel]) {
        this.contactsForChannels[channel].forEach(element => {
          element.addresses.forEach(address => {
            if (this.message.recipentObjectList[channel].indexOf(address.id) != -1) {
              recipientArray.push(address.name);
            }
          });
        });
      }

      if (recipientArray.length > 0) {
        this.template.recipient[channel] = recipientArray.join(',');
      }
    }
    if(this.message.senders) {
      this.template.sender = this.message.senders;
    }
    if(this.message.urgencyIds) {
      this.template.urgencyId = this.message.urgencyIds;
    }

    this.restApi.updateTemplate(this.selectedFolder, id, this.template, xRequestId).then(data => {
      this.logger.trace('updateTemplate',
                     new ECSEvent(ModuleName.ComMan,
                                  'modalTemplate.buttons.save',
                                  'click',
                                  id,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
      if (data != null) {
        this.loading = false;
        this.userAction = '';
      }
    }, err => this.displayError(err));
  }

  setReplacingAllTemplates() {
    if (this.replaceMode.replacingId.length === this.templates.length) {
      this.replaceMode.replacingId = [];
    } else {
      for (const key in this.templates) {
        if (Object.prototype.hasOwnProperty.call(this.templates, key)) {
          if (!this.replaceMode.replacingId.includes(this.templates[key].id)) {
            this.replaceMode.replacingId.push(this.templates[key].id)
          }
        }
      }
    }
  }

  async updateTemplateForReplace(id, template) {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.info('updateTemplate',
                  new ECSEvent(ModuleName.ComMan,
                  'modalTemplate.buttons.replace',
                  'click',
                  id,
                  EventProvider.UI,
                  '',
                  xRequestId));
    await this.restApi.updateTemplate(this.selectedFolder, id, template, xRequestId).then(data => {
      this.logger.trace('updateTemplate',
                        new ECSEvent(ModuleName.ComMan,
                        'modalTemplate.buttons.replace',
                        'click',
                        id,
                        EventProvider.APPLICATION,
                        '',
                        xRequestId));
    }, err => this.displayError(err));
  }

  async replaceInTemplates() {
    this.errorComman = this.globalI18n.getMessage(ModuleName.ComMan, 'replaceInTheTemplate');
    this.errorType = 'warning';
    this.userAction = 'setAnswer'
    await this.waitAnswer().then(async res => {
      if (res) {
        let count = 0;
        for (const id of this.replaceMode.replacingId) {
          let template = this.getById(this.templates, id);
          if (this.replaceMode.option === 'recipient' && template) {
            for (const key in template.recipient) {
              if (Object.prototype.hasOwnProperty.call(template.recipient, key)) {
                template.recipient[key] = template.recipient[key].replaceAll(this.replaceMode.textFrom, this.replaceMode.textTo);
              }
            }
          } else if (this.replaceMode.option === 'name' && template) {
            template.name = template.name.replaceAll(this.replaceMode.textFrom, this.replaceMode.textTo);
          }
          await this.updateTemplateForReplace(id, template);

          this.errorComman = this.globalI18n.getMessage(ModuleName.ComMan, 'replacedIn')
                              + ` ${++count} `
                              + this.globalI18n.getMessage(ModuleName.ComMan, 'templates');
        }

        this.errorComman = this.globalI18n.getMessage(ModuleName.ComMan, 'replaced');
        this.errorType = 'info';
        this.userAction = 'replaced';
        this.loadTemplates();

        this.replaceMode = {
          on: false,
          option: 'recipient',
          textFrom: '',
          textTo: '',
          replacingId: []
        }
      } else {
        console.log('Cancel');
      }
    });
  }

  toggleId(array, id) {
    if (array.includes(id)) {
      array.splice(array.indexOf(id), 1);
    } else {
      array.push(id);
    }
  }

  checkReplaceMode(on) {
    this.replaceMode = {
      on: !on,
      option: 'recipient',
      textFrom: '',
      textTo: '',
      replacingId: []
    }
    this.searchTemplate = {
      name: '',
      recipient: '',
      text: ''
    }
  }

  selectMessage(id: number, read = true) {
    this.logger.info('selectMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.selectMessage',
                                  'load-preview',
                                  id,
                                  EventProvider.UI));
    this.message = new MessageDetail();
    this.selectedMessage = new MessageDetail();
    const message = this.getById(this.messageGroup, id);
    Object.assign(this.message, message);
    this.selectedMessage.recipient = this.toString(this.selectedMessage.recipient);
    this.selectedMessage.sender = this.toString(this.selectedMessage.sender);
    // this.loadUrgency(this.message.channelId);
    this.loadSenders(this.message.channelId, this.selectedFolder);
    if (this.message.channelId == this.channelIds.aftnId) {
      if (isCyrillic(this.message.sender)) {
        this.selectedTelegramLanguage = this.defaultTelegramLanguageBindingsList[1];
      } else {
        this.selectedTelegramLanguage = this.defaultTelegramLanguageBindingsList[0];
      }
      this.selectLanguage();
    }
    this.messageId = this.message.id;
    this.messageIsSelected = true;
    this.isNewMessage = (this.selectedFolder === -1) ? true : false;
    this.verifiedMessage = [];
    if (this.checkFolderRuleIsFull()) {
      this.processedTime = new Date();
    } else {
      this.processedTime = null;
    }

    if (this.message.readConfirmations) {
      this.message.readConfirmations.forEach(el => {
        const time = new Date(el.time);
        if (time < this.processedTime && time.getFullYear() > 2000) {
          this.processedTime = time;
        }
        if (el.folderId === this.selectedFolder && !read && this.checkFolderRuleIsFull()) {
          el.time = new Date().toISOString();
        }
      });
    }

    Object.assign(this.selectedMessage, this.message);

    this.message.recipentObjectList[this.message.channelId] = [];

    if (this.message.recipient) {
      this.message.recipient.split(',').forEach(item => {
        if (item.length != 0) {
          this.message.recipentObjectList[this.message.channelId]
            = [...this.message.recipentObjectList[this.message.channelId], this.searchInRecipients(item)];
        }
      });
    }
    this.activeChannel = this.message.channelId;
    this.messagePanel = this.isNewMessage ? 'small' : 'full';
    this.selectedMessage.recipientListAndStatuses();
  }

  getMessageFlights(id: number) {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.trace('getMessageFlights',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.getMessageFlights',
                                  'call',
                                  id,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    this.restApi.getMessageFlights(id, xRequestId).subscribe(data => {
      this.logger.trace('getMessageFlights',
                     new ECSEvent(ModuleName.ComMan,
                                  'getMessageFlights',
                                  'reply',
                                  data,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
      this.message.links = [];
      this.messageFlightsId = [];

      data.forEach(flight => {
        this.message.links.push(flight);
        this.messageFlightsId.push(flight);
      });
    });
  }

  attachToFlight(id?: number) {
    this.logger.info('attachToFlight',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.selectMessage',
                                  'attach-to-flight',
                                  id,
                                  EventProvider.UI));
    this.searchInput = '';
    if (!this.isNewMessage) {
      // Берем объект текущего сообщения
      Object.assign(this.message, this.getById(this.messageGroup, id));
      // Получаем рейсы
      this.getMessageFlights(id);
      if (this.message.flights) {
        this.messageFlights = [...this.message.flights];
      }
    } else {
      this.messageFlightsId = this.message.links.concat();
    }

    this.showModal = '';
  }

  attachToAirline(id: number) {
    this.logger.info('attachToAirline',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.selectMessage',
                                  'attach-to-airline',
                                  id,
                                  EventProvider.UI));
    if (!this.isNewMessage) {
    }
    this.showModal = 'airline';
  }

  updateFlightsList(flight) {
    this.logger.info('updateFlightsList',
                     new ECSEvent(ModuleName.ComMan,
                                 'main.updateFlightsList',
                                 'update',
                                 flight,
                                 EventProvider.UI));
    const index = this.messageFlightsId.indexOf(flight.id);
    if (index > -1) {
      this.messageFlightsId.splice(index, 1);
      this.messageFlights.splice(index, 1);
    } else {
      this.messageFlightsId.push(flight.id);
      this.messageFlights.push(flight);
    }
  }

  async createMessage() {
    this.logger.info('createMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.button.newMessage',
                                  'click',
                                  '',
                                  EventProvider.UI));
    let message: MessageDetail;
    message = new MessageDetail();
    this.template = new MessageTemplate();

    // Сбросим получателей
    this.clearUserContactsForChannel();
    this.message.recipentObjectList = {1: [], 2: [], 3: []};
    this.template.id = undefined;

    this.messagePanel = 'small';
    this.autoNumber = true;

    await this.loadSenders(this.folderSetting.channelId, this.selectedFolder);

    // Установка типа сообщения и канала из настроек для папки
    if (this.folderSetting.channelId !== 0 && this.sendersForChannels[this.folderSetting.channelId].length !== 0) {
      this.activeChannel = Number(this.folderSetting.channelId);
    } else {
      for (const key in this.channelIds) {
        if (Object.prototype.hasOwnProperty.call(this.channelIds, key)
          && this.sendersForChannels[this.channelIds[key]].length > 0) {
          this.activeChannel = this.channelIds[key];
        }
      }
    }

    message.senders[this.channelIds.aftnId] = this.folderSetting.aftnSenderName;
    message.senders[this.channelIds.sitatexId] = this.folderSetting.sitatexSenderName;
    message.senders[this.channelIds.emailId] = this.folderSetting.emailSenderName;

    this.selectedTelegramLanguage = languageList.filter(el => el.value === (this.folderSetting.defaultAftnLang || 1))[0];

    message.urgencyIds[this.channelIds.aftnId] = this.folderSetting.defaultAftnUrgencyId;
    message.urgencyIds[this.channelIds.sitatexId] = 6;

    return message;
  }

  async newMessage(mymodal) {
    if (!this.checkFolderRuleIsFull()) {
      this.errorComman = this.globalI18n.getMessage(ModuleName.ComMan, 'newMessageReadOnlyFolder');
      return;
    }
    if (this.selectedFolder == 0) {
      this.errorComman = this.globalI18n.getMessage(ModuleName.ComMan, 'newMessagePublicFolder');
      return;
    }
    if (this.selectedFolder == -1) {
      this.errorComman = this.globalI18n.getMessage(ModuleName.ComMan, 'newMessageDraftFolder');
      return;
    }

    this.errorRecipientAddress = {1: '', 2: '', 3: ''};

    this.selectedTelegramLanguage = this.defaultTelegramLanguageBindingsList[0];
    this.isNewMessage = true;
    // this.messageIsSelected = false;
    this.messageFlightsId = [];
    this.messageFlights = [];
    // this.messageId = undefined;
    this.verifiedMessage = [];

    this.message = new MessageDetail();

    this.createMessage().then(message => {
      Object.assign(this.message, message);
      // Для канала AFTN перевод списка получателей на язык сообщения по умолчанию
      this.selectLanguage();
      this.emptyMessage = JSON.parse(JSON.stringify(message));
    });

    this.template = new MessageTemplate();

    if (this.message.channelId === 0) {
      this.message.channelId = null;
    }

    this.open(mymodal);
  }

  /**
   * Разбивает сообщение на строки
   *
   * @param {text} текст сообщения
   * @param {textarea} элемент поля ввода
   */
  splitIntoLines(text: string, textarea?) {
    let coursorPosition;
    if (textarea) {
      coursorPosition = textarea.selectionStart;
    }
    let contentArray = [];
    if (text && text != '') {
      contentArray = text.toUpperCase().split(/\n|\r\n/);
    }
    const maxLength = 69;
    const newContent = [];
    this.verifiedMessage = [];
    contentArray.forEach(element => {

      if (element.length > maxLength) {
        if (element[maxLength] != ' ') {
          let line = element.slice(0, maxLength);
          let lineEndIndex = line.lastIndexOf(' ') + 1;
          if (lineEndIndex == 0) {
            newContent.push(element);
            this.verifiedMessage.push(Array(true, element, this.systemMessagesTexts['textLineLong']));
          } else {
            while (element.length > maxLength) {
              const lineSlice = line.slice(0, lineEndIndex - 1);
              newContent.push(lineSlice);
              this.verifiedMessage.push(Array(false, lineSlice));
              element = element.slice(lineEndIndex);
              line = element.slice(0, maxLength);
              lineEndIndex = line.lastIndexOf(' ') + 1;
            }
            newContent.push(element);
            this.verifiedMessage.push(Array(false, element));
          }
        } else {
          newContent.push(element.slice(0, maxLength));
          this.verifiedMessage.push(Array(false, element.slice(0, maxLength)));
          newContent.push(element.slice(maxLength));
          this.verifiedMessage.push(Array(false, element.slice(maxLength)));
        }
      } else {
        newContent.push(element);
        this.verifiedMessage.push(Array(false, element));
      }
    });
    if (textarea) {
      textarea.value = newContent.join('\r\n');
      textarea.setSelectionRange(coursorPosition, coursorPosition);
    }
    return newContent.join('\r\n');
  }

  aftnSliceMessage(text, length): string {
    let currentLanguageIsLat = true;
    let languageChangeSymbols = 0;
    for (let i = 0; i < text.length; i++) {
      if (currentLanguageIsLat) {
        if (arrRu.indexOf(text[i]) !== -1) {
          languageChangeSymbols++;
          currentLanguageIsLat = false;
        }
      } else {
        if (arrLat.indexOf(text[i]) !== -1) {
          languageChangeSymbols++;
          currentLanguageIsLat = true;
        }
      }

      if ((i + languageChangeSymbols) > length) {
        return text.slice(0, i);
      }
    }
  }

  /**
   * Функция возвращает точную длинну служебных заголовков
   * Считается длинна всех символов, каждый перенос строки +2 символа
   *
   * Между служебными заголовками будет текст, после которого будет перенос
   * строки, не забывать +2 символа
   *
   * В русском языке может появится символ смены языка после 1 заголовка
   * и перед вторым, то есть еще +2 символа для русского языка
   */
  aftnAdditionalTextLength(i, count, language): number {
    if (language === 'RU') {
      if (i === 1) {
        // ЧАСТЬ 11
        //
        // ПРОДОЛЖЕНИЕ СЛЕДУЕТ 19
        return 33;
      } else if (i === count) {
        // ПРОДОЛЖЕНИЕ. ЧАСТЬ 11
        //
        // КОНЕЦ
        return 32;
      } else {
        // ПРОДОЛЖЕНИЕ. ЧАСТЬ 11
        //
        // ПРОДОЛЖЕНИЕ СЛЕДУЕТ
        return 46;
      }
    } else if (language === 'LAT') {
      if (i === 1) {
        // PART 11
        //
        // TO BE CONTINUED
        return 26;
      } else if (i === count) {
        // CONTINUATION. PART 11
        //
        // END
        return 28;
      } else {
        // CONTINUATION. PART 11
        //
        // TO BE CONTINUED
        return 40;
      }
    }
  }

  /**
   * Разбивает сообщение на части для AFTN, для SITA и email добавляет как есть
   *
   * @param {channel} канал сообщения
   */
   async splitMessageToParts(channel) {
    let parts = [];

    if (channel === this.channelIds.aftnId &&
        this.message.message &&
        this.message.textLength(
          this.message.recipentObjectList[this.channelIds.aftnId].length > 0
            ? true
            : false) > this.globalSettings.aftnMaxMessageLength) {
      let text = this.message.message;
      // Получение количества частей телеграммы
      const count = Math.ceil(
        this.message.textLength(
          this.message.recipentObjectList[this.channelIds.aftnId].length > 0
            ? true
            : false) / (this.globalSettings.aftnMaxMessageLength - 50)
      );

      let i = 1;
      while (i <= count) {
        let part;
        // Последняя часть копируется целиком
        if (i === count) {
          part = text;
        } else {
          // Копирование части телеграммы по максимальной длинне
          // part = text.slice(0, this.globalSettings.aftnMaxMessageLength - 50);
          part = this.aftnSliceMessage(text, this.globalSettings.aftnMaxMessageLength -
                                             this.aftnAdditionalTextLength(i, count, this.selectedTelegramLanguage.label));
          // Поиск последнего переноса строки, для целостного разбиения
          const length = part.lastIndexOf('\n');
          // Копирование части телеграмы до переноса строки
          part = part.slice(0, length);
          // Обрезание текста телеграммы по скопированной части
          text = text.slice(length + 1);
        }

        // ВНИМАНИЕ!!!
        // При измнении текста в служебных заголовках, поправить длинны
        // в функции aftnAdditionalTextLength()
        // Добавление служебных заголовков
        if (this.selectedTelegramLanguage.label === 'RU') {
          if (i === 1) {
            part = `ЧАСТЬ ${i}\r\n${part}\nПРОДОЛЖЕНИЕ СЛЕДУЕТ`;
          } else if (i === count) {
            part = `ПРОДОЛЖЕНИЕ. ЧАСТЬ ${i}\r\n${part}\r\nКОНЕЦ`;
          } else {
            part = `ПРОДОЛЖЕНИЕ. ЧАСТЬ ${i}\r\n${part}\r\nПРОДОЛЖЕНИЕ СЛЕДУЕТ`;
          }
        } else if (this.selectedTelegramLanguage.label === 'LAT') {
          if (i === 1) {
            part = `PART ${i}\r\n${part}\r\nTO BE CONTINUED`;
          } else if (i === count) {
            part = `CONTINUATION. PART ${i}\r\n${part}\r\nEND`;
          } else {
            part = `CONTINUATION. PART ${i}\r\n${part}\r\nTO BE CONTINUED`;
          }
        }
        // Помещение сформированной части в массив частей
        parts.push(part);
        i++;
      }
    } else {
      parts.push(this.message.message);
    }
    return parts;
  }

  /**
   * Разбивает получателей на подмассивы по 7 штук AFTN, для SITA и email добавляет как есть
   *
   * @param {channel} канал сообщения
   * @param {recipients} массив получателей
   */
  splitRecipients(channel, recipients) {
      let result = [];

      if (channel === this.channelIds.aftnId && recipients && recipients.length > 7) {

        while (recipients.length > 7) {
          let part = recipients.splice(0, 7);
          result.push(part);
        }
        if (recipients.length > 0) {
          result.push(recipients);
        }

      } else {
        result.push(recipients);
      }
      return result;
    }

  // Проверка сообщения на валидность
  messageValid(message): boolean {
    let error = false;

    // Проверка на пустоту
    if (!message.message) {
      this.errorMessage = this.globalI18n.getMessage(ModuleName.ComMan, 'textEmpty');
      error = true;
      this.loading = false;
    }

    // Проверки для AFTN
    if (message.message && message.recipentObjectList[1] && message.recipentObjectList[1].length > 0) {
      const textMass = message.message.split('');

      // Проверка на запрещенные символы
      const characters = [];
      textMass.forEach(symbol => {
        if (!this.mtk2.includes(symbol.toUpperCase()) && symbol.charCodeAt(0) != 10) {
          characters.push(symbol);
        }
      });
      if (characters.length > 0) {
        this.errorMessage += this.globalI18n.getMessage(ModuleName.ComMan, 'textBadCharacters') + ' ' + characters.join(' ') + '\n';
        error = true;
        this.loading = false;
      }

      // Проверка на запрещенные последовательности символов
      const sequences = [];
      this.prohibitedSequences.forEach(sequence => {
        if (message.message.toUpperCase().indexOf(sequence) > -1) {
          sequences.push(sequence);
        }
      });
      if (sequences.length > 0) {
        this.errorMessage += this.globalI18n.getMessage(ModuleName.ComMan, 'textBadSequences') + ' ' + sequences.join(' ');
        error = true;
        this.loading = false;
      }

      // Проверка на длину строк
      this.verifiedMessage.forEach(element => {
        if (element[0] == true) {
          this.errorMessage += this.globalI18n.getMessage(ModuleName.ComMan, 'errorTextValidation');
          error = true;
          this.loading = false;
        }
      });
    }

    // Проврека количества адресов получателей
    // С АРМ АФТН можно передать 56 адресов : 8 строчек по  7 адресов.
    // При работе в однострочном режиме передается 8 тлг по 7 адресов в каждой.
    // При многострочном режиме передается три телеграммы в двух тлг по 21 адресу
    // в одной 14 адресов

    for (const key in this.channelIds) {
      if (Object.prototype.hasOwnProperty.call(this.channelIds, key)) {
        const channel = this.channelIds[key];
        if (this.message.recipentObjectList[channel].length > 56) {
          this.errorMessage = this.globalI18n.getMessage(ModuleName.ComMan, 'longRecipientList');
          error = true;
          this.loading = false;
        }
      }
    }

    // Проверка первой буквы адресов получателей, если начинаются не на У/U
    // или если начинаются на УА, УБ, УГ, УЦ, УК, УТ, УД, УМ (кроме УМК),
    // то нужно предупредить, что сообщение должно быть на латинице или изменить
    // адрес получателся (если пользователь опечатался)

    // Перебор списка адресов получателей для канала + пользовательский лист
    if (message.recipentObjectList[1].length > 0 && this.selectedTelegramLanguage.label == 'RU') {
      const notRussianAddresses = [];
      this.contactsForChannels[1].forEach(item => {
        // В каждом наборе перебор списка адресов
        item.addresses.forEach(address => {
          // Если в списке идентификаторов получателей сообщения есть
          // текущий элемент, то проверка первой буквы
          if (message.recipentObjectList[1].indexOf(address.id) !== -1) {
            const firstChartec = address.name.toUpperCase().slice(0, 1);
            if (firstChartec !== 'U' && firstChartec !== 'У') {
              notRussianAddresses.push(address.name);
            }
            // Затем проверка первых двух букв
            const startChartec = address.name.toUpperCase().slice(0, 2);
            /* if (startChartec == 'УА' || startChartec == 'УБ' || startChartec == 'УГ' || startChartec == 'УЦ'
              || startChartec == 'УК' || startChartec == 'УТ' || startChartec == 'УД') {
              notRussianAddresses.push(address.name);
            }
            if (startChartec == 'УМ' && address.name.toUpperCase().slice(0, 3) !== 'УМК') {
              notRussianAddresses.push(address.name);
            } */
            if (startChartec == 'УБ' || startChartec == 'УК'
              || startChartec == 'УГ' || startChartec == 'УЦ'
              || startChartec == 'УД') {
              notRussianAddresses.push(address.name);
            }
          }
        });
      });
      if (notRussianAddresses.length !== 0) {
        this.errorMessage = notRussianAddresses.join(',') +
          ': ' +
          this.globalI18n.getMessage(ModuleName.ComMan, 'addressesContainRecipientsNotAcceptCyrillic');
        error = true;
        this.loading = false;
      }
    }

    // Проверка содержания русских букв в сообщение посылаемом на латинском
    if (message.recipentObjectList[1].length > 0 && this.selectedTelegramLanguage.label == 'LAT') {
      if (isCyrillic(message.message.toLowerCase())) {
        this.errorMessage = this.globalI18n.getMessage(ModuleName.ComMan, 'textNotAcceptCyrillic');
        error = true;
        this.loading = false;
      }
    }

    // Проверка содержания русских букв в сообщение посылаемом по Sitatex
    if (message.recipentObjectList[2].length > 0) {
      if (isCyrillic(message.message.toLowerCase())) {
        this.errorMessage = this.globalI18n.getMessage(ModuleName.ComMan, 'textNotAcceptCyrillicForSita');
        error = true;
        this.loading = false;
      }
    }

    if (error) {
      this.errorType = 'error';
      this.userAction = 'noValid';
    }

    return error;
  }

  /**
   * Отправляет сообщение
   *
   * @param {action} действие, которое выполняется по завершению
   * @param {form} форма создания сообщения
   * @param {folderId} x, id папки
   */
  async sendMessage(action?: Function, form?, folderId = this.selectedFolder) {
    if (this.messagePanel === 'small') {
      // if (!this.message.channelId || !this.message.recipient || !this.message.sender) {
      if (!this.message.channelId || !this.message.recipient || !this.message.sender) {
        this.messagePanel = 'full';
      }
    }

    form.form.markAllAsTouched();
    if (form.valid) {
      this.loading = true;
      let error = false;
      // Если создавали по шаблону - соберем текст
      /*
      if (this.textMessageTemplate.length > 0) {
        let index = 0;
        this.template.parts.forEach(part => {
          if (part.required && this.textMessageTemplate[index] == '') {
            this.errorMessage = this.globalI18n.getMessage(ModuleName.ComMan, 'fillRequiredFields');
            this.errorType = 'error';
            this.userAction = 'noValid';
            error = true;
            this.loading = false;
          }
          index++;
        });
        if (!error) {
          this.message.message = this.textMessageTemplate.join('').replace(/ +/g, ' ').trim();
        }
      }*/

      // Замена знаков табуляции сообщения на пробелы
      var re = new RegExp(String.fromCharCode(160), "gi");
      this.message.message = this.message.message.replace(re, ' ').replace(/\t/g, '  ');

      error = this.messageValid(this.message);

      // Проверка даты для АФТН
      if (this.message.recipentObjectList[1] && this.message.recipentObjectList[1].length > 0) {
        if (this.autoNumber) {
          const currentDate = new Date(Date.now() - 3600 * 1000);
          if (this.message.dtMessage < new Date(currentDate)) {
            error = true;
            this.loading = false;
            this.userAction = 'invalidDate';
          } else {
            this.message.dtMessage = new Date();
          }
        }
      }

      this.errorRecipientAddress = {1: '', 2: '', 3: ''};

      // Собираем получаетелей в массив и проверяем корректность
      let recipientArray = [];
      for(let key in this.channelIds) {
        const channel = this.channelIds[key];
        recipientArray[channel] = [];
        if ( this.contactsForChannels[channel] && this.contactsForChannels[channel].length > 0) {
          this.contactsForChannels[channel].forEach(item => {
            // Обработка адресов по выбранному каналу
            if (item.channelId == channel) {
              item.addresses.forEach(address => {
                if (this.message.recipentObjectList[channel].indexOf(address.id) != -1) {
                  recipientArray[channel].push(address.name);
                }
              });
            }
          });

          if (this.validateContactArray(recipientArray[channel], channel)) {
            error = true;
            this.loading = false;
          }
        }

      }

      if (!error) {
        this.message.channelId = Number(this.message.channelId);
        this.message.typeId = Number(this.message.typeId);

        let errorSend = false;

        for (const key in this.channelIds) {
          if (Object.prototype.hasOwnProperty.call(this.channelIds, key)) {
            const channel = this.channelIds[key];
            // если есть получатели для этого канала
            if (recipientArray[channel].length > 0) {
              // Не у АФТН надо сделать дату текущую
              if (channel !== this.channelIds.aftnId) {
                this.message.dtMessage = new Date();
              }
              this.message.channelId = channel;
              this.message.urgencyId = this.message.urgencyIds[channel] ? this.message.urgencyIds[channel] : null;
              this.message.sender = this.message.senders[channel];

              recipientArray[channel] = this.splitRecipients(channel, recipientArray[channel]);

              let answer = true;

              if (channel === this.channelIds.aftnId && this.message.message
                  && this.message.textLength(this.message.recipentObjectList[this.channelIds.aftnId].length > 0 ? true : false) > this.globalSettings.aftnMaxMessageLength) {

                  this.errorComman = this.globalI18n.getMessage(ModuleName.ComMan, 'splitMessage');
                  this.errorType = 'warning';
                  this.userAction = 'setAnswer';
                  await this.waitAnswer().then((res: boolean) => answer = res);
              }

              if (!answer) {
                return false;
              }

              for (let key_recipient in recipientArray[channel]) {
                this.message.recipient = recipientArray[channel][key_recipient].join(',');
                let parts = await this.splitMessageToParts(channel);

                for (let key in parts) {
                   let mess = this.message.message;
                  this.message.message = parts[key];
                  await this.restApi.createMesssage(this.message, folderId).then(() => {
                    this.messageSended = true;
                    this.loading = false;
                    [mess, this.message.message] = [this.message.message, mess]
                  }, err => {
                    this.displayError(err);
                    errorSend = true;
                  });
                }
              }

              if (!errorSend) {
                setTimeout(() => {
                  this.messageSended = false;
                  action();
                }, 3000);
              }
            }
          }
        }
        if (this.selectedFolder === -1) {
          this.deleteDraft(this.message.id, folderId, action)
        }

        this.loadMessages(this.messagesPageActive);

      } else {
        this.logger.info('sendMessage',
                         new ECSEvent(ModuleName.ComMan,
                                      'modal.messageEdit.sendMessage',
                                      'check-message-error',
                                      error,
                                      EventProvider.UI));
        return false;
      }
    } else {
      this.logger.info('sendMessage',
                       new ECSEvent(ModuleName.ComMan,
                                      'modal.messageEdit.sendMessage',
                                      'form-invalid',
                                      '',
                                      EventProvider.UI));
      return false;
    }
  }

  setDataAndSent(action?: Function, form?) {
    this.message.dtMessage = new Date();
    this.userAction = '';
    this.sendMessage(action, form);
  }

  closeModalMessage(action) {
    this.logger.info('closeModalMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'modal.editMessage',
                                  'close',
                                  '',
                                  EventProvider.UI));
    if (this.isNewMessage) {
      if (this.message.dtInsert) {
        this.emptyMessage.dtInsert = this.message.dtInsert;
      }
      if (this.message.dtMessage) {
        this.emptyMessage.dtMessage = this.message.dtMessage;
      }

      if (JSON.stringify(this.message) === JSON.stringify(this.emptyMessage)) {
        action();
        this.isNewMessage = false;
      } else {
        this.errorMessage = this.globalI18n.getMessage(ModuleName.ComMan, 'saveDraft');
        this.errorType = 'warning';
        this.userAction = 'close';
      }
    } else {
      action();
      this.isNewMessage = false;
    }
  }

  closeWithoutSaving(action) {
    this.logger.info('closeWithoutSaving',
                     new ECSEvent(ModuleName.ComMan,
                                  'modal.closeWithoutSaving',
                                  'click',
                                  '',
                                  EventProvider.UI));
    this.clearErrorMess();
    action();
  }

  saveDraft(action) {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.info('saveDraft',
                     new ECSEvent(ModuleName.ComMan,
                                  'modal.saveDraft',
                                  'click',
                                  '',
                                  EventProvider.UI,
                                  '',
                                  xRequestId));

    let draft = JSON.parse(JSON.stringify(this.message));

    draft.recipient = {1: '', 2: '', 3: ''};
    for (const key in this.channelIds) {
      let recipientArray = [];
      let channel = this.channelIds[key];
      if (channel=='') {
        continue;
      }
      if (this.contactsForChannels[channel]) {
        this.contactsForChannels[channel].forEach(element => {
          element.addresses.forEach(address => {
            if (this.message.recipentObjectList[channel].indexOf(address.id) != -1) {
              recipientArray.push(address.name);
            }
          });
        });
      }
      if (recipientArray.length > 0) {
        draft.recipient[channel] = recipientArray.join(',');
      }
    }

    if (this.message.message) {
      draft.message = this.message.message.toUpperCase();
    }

    if (this.message.senders) {
      draft.sender = this.message.senders;
    }
    if (this.message.urgencyIds) {
      draft.urgencyId = this.message.urgencyIds;
    }

    if (this.selectedFolder == -1) {
      return this.restApi.updateDraft(draft, this.message.id, this.message.folderId, xRequestId).subscribe(data => {
        this.logger.trace('updateDraft',
                     new ECSEvent(ModuleName.ComMan,
                                  'modal.updateDraft',
                                  'reply',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
        this.clearErrorMess();
        this.userAction = 'close';
        this.loading = false;
        this.messageSended = true;
        setTimeout(() => {
          action();
          this.messageSended = false;
          this.userAction = '';
        }, 3000);
      }, err => this.displayError(err));

    } else {
      return this.restApi.saveDraft(draft, this.selectedFolder, xRequestId).subscribe(data => {
        this.logger.trace('saveDraft',
                     new ECSEvent(ModuleName.ComMan,
                                  'modal.saveDraft',
                                  'reply',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
        this.clearErrorMess();
        this.userAction = 'close';
        this.loading = false;
        this.messageSended = true;
        setTimeout(() => {
          action();
          this.messageSended = false;
          this.userAction = '';
        }, 3000);
      }, err => this.displayError(err));
    }

  }

  updateMessage(id: number) {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.trace('updateMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.updateMessage',
                                  'call',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    this.loading = true;
    this.message.message = this.message.message.toUpperCase();
    this.message.urgencyId = Number(this.message.urgencyId);
    this.message.channelId = Number(this.message.channelId);
    this.message.typeId = Number(this.message.typeId);
    this.restApi.updateMessage(id, this.message, xRequestId).subscribe(data => {
      this.logger.trace('updateMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'modal.updateMessage',
                                  'reply',
                                  data,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
      this.loading = false;
      this.messageSended = true;
      setTimeout(() => {
        this.messageSended = false;
      }, 3000);
    });
  }

  addZeroForDateTime(value: any): string {
    return value < 10 ? '0' + value : '' + value;
  }

  searchInRecipients(sender, channelId = this.message.channelId) {
    let recipienttId = null;
    if (this.contactsForChannels[channelId]) {
      this.contactsForChannels[channelId].forEach(item => {
        // Поиск отправителя в списке получателей для текущего канала
        if (item.channelId == channelId) {
          item.addresses.forEach(address => {
            if (address.name == transliterationEnRu(sender) ||
                address.name == transliterationRuEn(sender)) {
              recipienttId = address.id;
            }
          });
        }
      });
    }

    // Если найден в списках получателей отправитель, возврат его ID
    if (recipienttId != null) {
      return recipienttId;
    } else {
      // Если отправителя нету в списке получателей, добавлений новой записи
      // в список пользовательских контактов и возврат его ID
      recipienttId = this.addTagContact(sender, channelId);
      return recipienttId;
    }
  }

  async replyMessage(type = 'one', id?: number, content?) {
    this.logger.info('replyMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.button.reply',
                                  'click',
                                  id?.toString(),
                                  EventProvider.UI));
    Object.assign(this.message, this.getById(this.messageGroup, id));
    this.message.direction = 1;
    this.activeChannel = this.message.channelId;
    if (this.message.channelId == this.channelIds.aftnId) {
      if (isCyrillic(this.message.sender)) {
        this.selectedTelegramLanguage = this.defaultTelegramLanguageBindingsList[1];
      } else {
        this.selectedTelegramLanguage = this.defaultTelegramLanguageBindingsList[0];
      }
      this.selectLanguage();
    }
    const originalMessage = { ...this.message };
    this.message.recipentObjectList = {1: [], 2: [], 3: []};
    const messageText = originalMessage.message;
    let messageSender = originalMessage.sender;
    // Для AFTN канала необходимо перевести список отправителей на текущий язык
    // просмотра/редактирования сообщения перед показом формы отправки
    if (this.message.channelId == this.channelIds.aftnId) {
      if (this.selectedTelegramLanguage.label == 'RU') {
        messageSender = transliterationEnRu(originalMessage.sender);
      } else if (this.selectedTelegramLanguage.label == 'LAT') {
        messageSender = transliterationRuEn(originalMessage.sender);
      }
    }
    const messageSubject = originalMessage.subject ? originalMessage.subject : '';

    await this.loadSenders(this.folderSetting.channelId, this.selectedFolder);

    this.message.incomingId = originalMessage.id;

    // Добавление формализованной строки при ответе на AFTN сообщение
    if (this.message.channelId == this.channelIds.aftnId) {
      // Для AFTN имеется формализованный формат ответа
      if (this.selectedTelegramLanguage.label === 'RU') {
        this.message.message = 'В ОТВЕТ НА ВАШУ ';
      } else if (this.selectedTelegramLanguage.value === this.channelIds.aftnId) {
        this.message.message = 'IN ANSWER TO YOUR ';
      }
      const date = new Date(this.message.dtMessage);
      this.message.message += this.addZeroForDateTime(date.getUTCDate()) +
        this.addZeroForDateTime(date.getUTCHours()) +
        this.addZeroForDateTime(date.getUTCMinutes()) +
        ' ' + messageSender;
    } else {
      this.message.message = '';
    }

    // Формирование текста письма
    if (this.message.channelId === this.channelIds.emailId) {
      // Для EMAIL при ответе добавялем приставку Re и текст сообщения
      this.message.subject = 'Re: ' + messageSubject;
      this.message.message = '\r\n' + messageText;
    } else if (this.copyTextForReply) {
      this.message.message += '\r\n' + messageText;
    }

    this.message.id = 0;
    this.message.dtMessage = new Date();

    // Поиск отправителя по умолчанию для текущей папки и канала
    this.message.urgencyIds = {1: 1, 2: 6};
    this.message.senders = {1: [], 2: [], 3: []};
    this.message.senders[this.channelIds.sitatexId] = this.folderSetting.sitatexSenderName;
    this.message.senders[this.channelIds.emailId] = this.folderSetting.emailSenderName;
    if (this.selectedTelegramLanguage.label === 'RU') {
      this.message.senders[this.channelIds.aftnId] = transliterationEnRu(this.folderSetting.aftnSenderName);
    } else {
      this.message.senders[this.channelIds.aftnId] = this.folderSetting.aftnSenderName;
    }
    this.messagePanel = 'small';
    this.autoNumber = true;

    this.clearUserContactsForChannel();

    this.message.flights = [];

    if (content) {
      this.open(content);
    }

    this.isNewMessage = true;

    this.message.recipentObjectList[this.message.channelId]
          = [...this.message.recipentObjectList[this.message.channelId], this.searchInRecipients(messageSender)];

    if (type === 'all') {
      const recipientsList = originalMessage.recipient.split(',');
      recipientsList.forEach(el => {
        this.message.recipentObjectList[this.message.channelId]
          = [...this.message.recipentObjectList[this.message.channelId], this.searchInRecipients(el)];
      });
    }

    this.emptyMessage = JSON.parse(JSON.stringify(this.message));
  }

  async forwardMessage(id?: number, content?) {
    this.logger.info('forwardMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.button.forward',
                                  'click',
                                  id.toString(),
                                  EventProvider.UI));
    Object.assign(this.message, this.getById(this.messageGroup, id));
    this.message.recipentObjectList = {1: [], 2: [], 3: []};
    this.message.direction = 1;
    const messageSubject = this.message.subject ? this.message.subject : '';
    await this.loadSenders(this.message.channelId, this.selectedFolder);
    this.activeChannel = this.message.channelId;

    if (this.message.channelId == this.channelIds.aftnId) {
      let info = this.selectedTelegramLanguage.label === 'LAT' ? 'READDRESS' : 'ПЕРЕАДРЕСОВКА';
      const date = new Date(this.message.dtMessage);
      const recipientArray = this.message.recipient.split(',');
      const urgency = this.selectedTelegramLanguage.label === 'LAT' ? this.message.urgencyName[0] : this.message.urgencyName[1];

      for (let i = 0; i < recipientArray.length; i++) {
        if (i % 7 == 0) {
          info += '\r\n' + urgency + ' ';
        }
        info += recipientArray[i] + ' ';
      }

      info += '\r\n' + this.addZeroForDateTime(date.getUTCDate()) +
      this.addZeroForDateTime(date.getUTCHours()) +
      this.addZeroForDateTime(date.getUTCMinutes()) +
      ' ' + this.message.sender + '\r\n';
      this.message.message = info + this.message.message;
    }

    this.message.id = 0;
    this.message.recipient = '';
    this.clearUserContactsForChannel();
    // Отчистка списка отправителей при пересылке
    this.message.sender = null;
    // Поиск отправителя по умолчанию для текущей папки и канала
    if (this.folderSetting.senderId && this.folderSetting.channelId == this.message.channelId) {
      const senderObj = this.getById(this.senders, this.folderSetting.senderId);
      if (senderObj && this.selectedTelegramLanguage.label === 'RU') {
        this.message.sender = senderObj.addressLocal;
      } else if (senderObj && this.selectedTelegramLanguage.label === 'LAT') {
        this.message.sender = senderObj.addressEnglish;
      }
    }
    this.message.dtMessage = new Date();
    if (this.message.channelId == this.channelIds.emailId) {
      this.message.subject = 'Fwd: ' + messageSubject;
    }

    // Поиск отправителя по умолчанию для текущей папки и канала
    this.message.urgencyIds = {1: 1, 2: 6};
    this.message.senders = {1: [], 2: [], 3: []};
    this.message.senders[this.channelIds.sitatexId] = this.folderSetting.sitatexSenderName;
    this.message.senders[this.channelIds.emailId] = this.folderSetting.emailSenderName;
    if (this.selectedTelegramLanguage.label == 'RU') {
      this.message.senders[this.channelIds.aftnId] = transliterationEnRu(this.folderSetting.aftnSenderName);
    } else {
      this.message.senders[this.channelIds.aftnId] = this.folderSetting.aftnSenderName;
    }
    this.message.errored = false;
    this.messagePanel = 'small';
    this.autoNumber = true;
    if (content) {
      this.open(content);
    }

    this.isNewMessage = true;
    this.emptyMessage = JSON.parse(JSON.stringify(this.message));

  }

  readMessage(id: number) {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.info('readMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.readMessage',
                                  'call',
                                  id,
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    return this.restApi.readMessage(id, this.selectedFolder, xRequestId).then((data: {}) => {
      this.logger.trace('readMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'readMessage',
                                  'reply',
                                  data,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
      this.checkFolders();
      this.loadFilters();
    }, err => this.displayError(err));
  }

  async readAllMessages(folderId: number) {
    this.errorComman = this.globalI18n.getMessage(ModuleName.ComMan, 'readAll');
    this.errorType = 'warning';
    this.userAction = 'setAnswer';
    await this.waitAnswer().then(async res => {
      if (res) {
        const xRequestId = this.globalSettings.randomUuid;
        this.logger.info('readAllMessages',
                         new ECSEvent(ModuleName.ComMan,
                                      'func.readAllMessages',
                                      'call',
                                      folderId,
                                      EventProvider.UI,
                                      '',
                                      xRequestId));
        return this.restApi.readAllMessages(folderId, xRequestId).then((data: {}) => {
          this.logger.trace('readAllMessage',
                         new ECSEvent(ModuleName.ComMan,
                                      'readAllMessages',
                                      'reply',
                                      data,
                                      EventProvider.APPLICATION,
                                      '',
                                      xRequestId));
          this.checkFolders();
          this.loadMessages(this.messagesPageActive);
        }, err => this.displayError(err));
      }
    });
    this.errorComman = this.globalI18n.getMessage(ModuleName.ComMan, 'readAllOk');
    this.errorType = 'info';
  }

  unreadMessage(id: number, action?) {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.info('unreadMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.button.markUnread',
                                  'click',
                                  id,
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    this.messageIsSelected = false;
    this.messageGroup = [];
    return this.restApi.unreadMessage(id, this.selectedFolder, xRequestId).then((data: {}) => {
      this.logger.trace('unreadMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.button.markUnread',
                                  'reply',
                                  data,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
      this.checkFolders();
      this.loadMessages(this.messagesPageActive);
      this.message = new MessageDetail();
      this.messageId = undefined;
      this.selectedMessage = new MessageDetail();
      if (action) action();
      this.getById(this.messages, id).read = false;
    }, err => {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        this.logger.error('Error', new ECSEvent(ModuleName.ComMan,
                                                'main.button.markUnread',
                                                'error',
                                                err,
                                                EventProvider.APPLICATION,
                                                'Error: ' + err.message + '\ndetail:' + err.detail,
                                                xRequestId));
      } else {
        this.errorMessage = err;
      }
    });
  }

  // Get messages list
  loadFolders() {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.trace('loadFolders',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.loadFolders',
                                  'call',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    return this.restApi.getFolders(xRequestId).subscribe((data: Folder[]) => {
      this.logger.trace('loadFolders',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.loadFolders',
                                  'reply',
                                  data,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
      this.folders = [];
      let countNewMessages = 0;
      data.forEach(item => {
        const folder = new Folder();
        if (item.id === -1) {
          this.folderDraft = new Folder();
          Object.assign(this.folderDraft, item);
        }
        Object.assign(folder, item);
        this.folders.push(folder);
        countNewMessages += folder.unread;
      });
      if (this.countAllMessages != countNewMessages) {
        this.countAllMessages = countNewMessages;
      }
      this.selectedFolderName = this.folders.find(e => e.id == this.selectedFolder).name;
      if (data != null) {
        this.loadMessages(this.messagesPageActive);
      }

    });
  }

  getFolderMessageInfo() {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.trace('getFolderMessageInfo',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.getFolderMessageInfo',
                                  'call',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    let paramsGetMessage: FilterParams = new FilterParams();
    if (this.filterApply || this.filterParams.mark != null) {
      // Дублируем. чтобы не менять исходный
      Object.assign(paramsGetMessage, this.filterParams);
    }
    paramsGetMessage.folder = this.selectedFolder.toString();
    paramsGetMessage.limit = this.messagesInPage.toString();
    paramsGetMessage.mark = this.filterParams.mark;
    paramsGetMessage.depth = this.filterParams.depth;

    const folder = this.getById(this.folders, this.selectedFolder);
    this.currentFolderInfo.folderUnread = folder.unread.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
    this.currentFolderInfo.baseLink = folder.baseLink;
    if (this.filterApply || this.filterParams.mark != null) {
      this.restApi.getCountFilteredMessages(paramsGetMessage, this.selectedFolder, xRequestId).subscribe((data: number) => {
        this.logger.trace('getFolderMessageInfo',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.getFolderMessageInfo',
                                  'reply',
                                  data,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
        this.currentFolderInfo.folderUnreadFiltered = data.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
      });
    }
    this.restApi.getCountMessages(paramsGetMessage, this.currentFolderInfo.baseLink, xRequestId).subscribe((data: number) => {
      this.logger.trace('getFolderMessageInfo',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.getFolderMessageInfo',
                                  'reply',
                                  data,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
      this.currentFolderInfo.folderAll = data.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
      this.messagesCount = data;
    });
  }

  // Get all folders
  loadAllFolders() {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.trace('loadAllFolders',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.loadAllFolders',
                                  'call',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    return this.restApi.getAllFolders(xRequestId).subscribe((data: Folder[]) => {
      this.logger.trace('loadAllFolders',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.loadAllFolders',
                                  'reply',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
      this.allFolders = [];
      data.forEach(item => {
        const folder = new Folder();
        Object.assign(folder, item);
        this.allFolders.push(folder);
      });
    }, err => this.displayError(err));
  }

  loadFolder(id: number) {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.trace('loadFolder',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.loadFolder',
                                  'call',
                                  id,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    if (id != -1) {
      return this.restApi.getFolder(id, xRequestId).subscribe((data: FolderSetting) => {
        this.logger.trace('loadFolder',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.loadFolder',
                                  'reply',
                                  data,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
        this.folderSetting = data;
        this.logger.debug('Folder setting', this.folderSetting);
        this.buttonAvailable = true;
        this.loadAllSenders(id);
      });
    }
  }

  // Get marks
  loadMarks() {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.trace('loadMarks',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.loadMarks',
                                  'call',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    return this.restApi.getMarks(xRequestId).subscribe((data: any) => {
      this.logger.trace('loadMarks',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.loadMarks',
                                  'reply',
                                  data,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
      this.marks = data;
    });
  }

  getById(array: any, search) {
    for (const value in array) {
      if (array[value].id == search) {
        return array[value];
      }
    }
    return null;
  }

  // Get messages list
  checkFolders() {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.trace('checkFolders',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.checkFolders',
                                  'call',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    return this.restApi.getFolders(xRequestId).subscribe((data: Folder[]) => {
      this.logger.trace('checkFolders',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.checkFolders',
                                  'reply',
                                  data,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
      this.folders = [];
      data.forEach(item => {
        const folder = new Folder();
        Object.assign(folder, item);
        this.folders.push(folder);
      });
      this.getFolderMessageInfo();
    }, err => this.displayError(err));
  }

  async loadChannels() {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.trace('loadChannels',
                     new ECSEvent(ModuleName.ComMan,
                                 'func.loadChannels',
                                 'call',
                                 '',
                                 EventProvider.APPLICATION,
                                 '',
                                 xRequestId));
    await this.restApi.getChannels(xRequestId).then(data => {
      this.logger.trace('loadChannels',
                     new ECSEvent(ModuleName.ComMan,
                                 'func.loadChannels',
                                 'reply',
                                 data,
                                 EventProvider.APPLICATION,
                                 '',
                                 xRequestId));
      this.channels = data;
    });
  }

  // Senders
  async loadAllSenders(folder) {
    this.sendersForChannels = [];
    this.sendersForChannels[1] = [];
    this.sendersForChannels[2] = [];
    this.sendersForChannels[3] = [];
    for (const el in this.channelIds) {
      const channel = this.channelIds[el];
      this.sendersForChannels[channel] = [];
      await this.restApi.getSenders(channel, folder).then((data: AdministrationSender[]) => {
        for (const item of data) {
          const sender = new AdministrationSender();
          Object.assign(sender, item);
          this.sendersForChannels[channel] = [...this.sendersForChannels[channel], sender];
        }
      });
    }
  }

  // Senders
  async loadSenders(channel, folder) {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.info('loadSenders',
                     new ECSEvent(ModuleName.ComMan,
                                 'func.loadSenders',
                                 'call',
                                 'channel: ' + channel + ', folder: ' + folder,
                                 EventProvider.UI,
                                 '',
                                 xRequestId));
    this.senders = [];
    return await this.restApi.getSenders(channel, folder, xRequestId).then((data: AdministrationSender[]) => {
      this.logger.trace('loadSenders',
                     new ECSEvent(ModuleName.ComMan,
                                 'func.loadSenders',
                                 'reply',
                                 data,
                                 EventProvider.APPLICATION,
                                 '',
                                 xRequestId));
      data = data.filter(el => el.baseApId == this.globalSettings.homeAirport);
      this.senders = [];
      for (const item of data) {
        const sender = new AdministrationSender();
        Object.assign(sender, item);
        this.senders.push(sender);
      }
      const temporaryBuffer = this.senders;
      this.senders = [];
      temporaryBuffer.forEach(item => this.senders.push(item));
    });
  }

  clearUserContactsForChannel() {
    this.userContactsForChannel = [];
    this.reloadContactsForChannel();
  }

  changeRecipientList(id = this.message.channelId) {
    if (id == this.channelIds.aftnId) {
      switch (this.selectedTelegramLanguage.label) {
        case 'LAT':
          if (this.message.recipentObjectList[id].length != 0) {
            this.message.recipentObjectList[id] = transliterationRuEn(this.message.recipentObjectList[id].join(',')).split(',');
          }
          break;
        case 'RU':
          if (this.message.recipentObjectList[id].length != 0) {
            this.message.recipentObjectList[id] = transliterationRuEn(this.message.recipentObjectList[id].join(',')).split(',')
          }
          break;
      }
    }
  }

  reloadContactsForChannel(channelId = this.message.channelId) {
    this.contactsForChannels[channelId] = this.contacts_.filter(item => {
      return item.channelId == channelId;
    });
    if (this.userContactsForChannel.length != 0) {
      this.userContactsForChannel.forEach(item => {
        if (item.channelId == channelId) {
          this.contactsForChannels[channelId] = [...this.contactsForChannels[channelId], item];
        }
      });
    }
    // Для коректной отрисовке после перевода контактов, необходимо удалить
    // и добавить обратно в объект, что бы ng-select верно скрыл группы уже
    // выбранных контактов
    if (channelId == this.channelIds.aftnId && this.message.recipentObjectList) {
      const temporaryBuffer = this.message.recipentObjectList[channelId];
      this.message.recipentObjectList[channelId] = [];
      temporaryBuffer.forEach(item => this.message.recipentObjectList[channelId].push(item));
    }
  }

  /**
   * Функция загрузки списка контактов для отправки сообщений
   * все записи преобразуется к формату: ID, NAME который необходим
   * для корректной работы ng-select
   */
  async loadContacts() {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.trace('loadContacts',
                     new ECSEvent(ModuleName.ComMan,
                                 'func.loadContacts',
                                 'call',
                                 '',
                                 EventProvider.APPLICATION,
                                 '',
                                 xRequestId));
    await this.restApi.getContacts(xRequestId).then(data => {
      this.logger.trace('loadContacts',
                     new ECSEvent(ModuleName.ComMan,
                                 'func.loadContacts',
                                 'reply',
                                 data,
                                 EventProvider.APPLICATION,
                                 '',
                                 xRequestId));
      for (const item of data) {
        const contact = { id: item.id, name: item.name, addresses: [], channelId: item.channelId };
        item.addresses.forEach((value, index) => {
          // Формирование идентификатора вложенных элементов:
          // берется родительский идентификатор (он имеется с бэка)
          // к нему добавляется текущий индекс элемента в массиве
          const childId = contact.id.toString() + index.toString();
          contact.addresses.push({ id: childId, name: value });
        });
        this.contacts_ = [...this.contacts_, contact];
        this.contactsForChannels[item.channelId] = this.contactsForChannels[item.channelId]
                                                  ? [...this.contactsForChannels[item.channelId], contact]
                                                  : [contact];
      }
    });
  }

  // При наборе в поле получатель фильтра записываем то, что набрал пользователь
  // в переменную, чтобы при потере фокуса вставлять это в поле
  onSearchFilterInput($event, key) {
    this.selectEnteredData[key] = $event.term.replace('\t', '');
  }

  // При очистке поля сбросим сохраненные значения
  onClearFilterInput(key) {
    this.selectEnteredData[key] = null;
  }

  // При удалении элемента удалим его из списка
  onClearTagFilterInput(event, key) {
    let target = event.label.toUpperCase();
    if (this.selectedTelegramLanguage.label === 'LAT' && !isLatin(this.selectEnteredData[key]) && key === 'aftn') {
      this.selectEnteredData[key] = transliterationRuEn(this.selectEnteredData[key]);
    } else if (this.selectedTelegramLanguage.label === 'RU' && !isCyrillic(this.selectEnteredData[key]) && key === 'aftn') {
      this.selectEnteredData[key] = transliterationEnRu(this.selectEnteredData[key]);
    }
    let arr = this.selectEnteredData[key].trim().replace(/\s{2,}/ig, ' ').toUpperCase().split(' ');
    if (arr.includes(target)) {
       arr.splice(arr.indexOf(target), 1);
       this.selectEnteredData[key] = arr.join(' ');
    }
  }

  // Обработка значения, введенного в поле фильтра
  addTagFilterFromArray(names, key) {
    if (!names) {
      return;
    }

    // Разделение массива на элементы
    if (key === 'textValue') {
      names = [names];
    } else {
      names = names.split(' ');
    }

    if (key === 'aftnNumber') {
      const res = [];
      for (let i = 0; i <= names.length; i++) {
        if (names[i] && isNaN(+names[i]) && names[i + 1]) {
          res.push(names[i] + ' ' + names[i + 1]);
          i++;
        } else if (names[i]) {
          res.push(names[i]);
        }
      }
      names = res;
    }

    // Скрытие оригинальной строки ng-select, так как
    // не корректно отрабатывает добавление элементов.
    // После скрытия и повторной отрисовки - все отлично.
    // Нюанс работы с компонентом.
    this.filterLoadAnimation[key] = true;
    if (names.length > 1) {
      // Добавление всех элементов в адреса, если выше проверки пройденны
      names.forEach(element => {
        if (element.length !== 0 && !this.filterParams[key].includes(element)) {
          this.filterParams[key] = [...this.filterParams[key], element.trim()];
        }
      });
    } else if (!this.filterParams[key].includes(names[0])) {
      // Если в массиве один элемент, использование стандартного метода добавления
      // единичной записи для ng-select
      this.filterParams[key] = [...this.filterParams[key], names[0].trim()];
    }
    this.interval = setTimeout(() => {
      this.filterLoadAnimation[key] = false;
    }, 250);
  }

  /**
   * Функция проверка всех адресов получателей на содержание дубликатов
   * Дубликаты могут содержаться в записых заведенных в модуле администрирования
   * и содержаться в разных группах
   */
  checkDuplicateAddressesRecipients() {
    // TODO осталась проблема накопления дублирующися адресов в пользовательском списке
    // копится из за того что из recipientObjectList пытается удалить дубликаты которых там нету,
    // они лежат в contactsForChannels

    for (const key in this.channelIds) {
      if (Object.prototype.hasOwnProperty.call(this.channelIds, key)) {
        // Получение списка выбранных обьектов хранящих адреса получателей
        const addresses = [];
        const addressesName = [];
        const channel = this.channelIds[key];
        if (this.contactsForChannels[channel]) {
          this.contactsForChannels[channel].forEach(item => {
            item.addresses.forEach((element) => {
              if (this.message.recipentObjectList[channel].indexOf(element.id) > -1) {
                console.log(this.message.recipentObjectList[channel]);
                console.log(this.contactsForChannels[channel]);


                addresses.push(element);
                addressesName.push(element.name);
              }
            });
          });
        }

        // Анализ списка обьектов на повторяющиеся адреса и удаление найденных
        addresses.forEach((item, index) => {
          const position = addressesName.indexOf(item.name);
          // Если элемент найден и его позиция не равна самому себе, то это дубликат
          // который стоит до него или после него в списке
          if (position > -1 && position != index) {
            // Удаление найденного элемента с текущей позиции
            this.message.recipentObjectList[channel].splice(this.message.recipentObjectList[channel].indexOf(item.id), 1);
          }
        });
      }
    }
  }

  onAddContactRefernace($event) {
    this.checkDuplicateAddressesRecipients();
  }

  checkAnswer() {
    return new Promise(resolve => {
      let interval = setInterval(() => {
        if (this.userAnswer !== null) {
          const answer = this.userAnswer;
          this.clearErrorMess();
          clearInterval(interval);
          resolve(answer);
        }
      }, 300)
   });
  }

  async waitAnswer() {
    let res = await this.checkAnswer();
    return res;
  }

  /**
   * Функция обработки адресной строки получателей, где адреса перечислены через
   * разделитель знак пробела.
   */
   /*async*/ addTagContactFromArray(names, channelId = this.message.channelId) {
    /*if (channelId === this.channelIds.aftnId) {
      if(this.selectedTelegramLanguage.label === 'LAT' && !isLatin(names) && !isLatin(this.message.recipentObjectList[channelId].join())) {
        this.errorComman = 'сменить язык на русский?';
        this.errorType = 'warning';
        this.userAction = 'setAnswer'
        await this.waitAnswer().then(res => {
          if (res) {
            this.selectedTelegramLanguage = this.defaultTelegramLanguageBindingsList[1];
            this.selectLanguage();
          }
        });
      } else if(this.selectedTelegramLanguage.label === 'RU' && !isCyrillic(names) && !isCyrillic(this.message.recipentObjectList[channelId].join())) {
        this.errorComman = 'сменить язык на Латинский?';
        this.errorType = 'warning';
        this.userAction = 'setAnswer'
        await this.waitAnswer().then(res => {
          if (res) {
            this.selectedTelegramLanguage = this.defaultTelegramLanguageBindingsList[0];
            this.selectLanguage();
          }
        });
      }
    }*/

    // Разделение массива на элементы
    names = names.trim().split(' ');

/*
    // Перед началом сброс всех старых ошибок
    this.errorRecipientAddress = {1: '', 2: '', 3: ''};
    names.forEach(element => {
      // Проверка длинны элемента, так как если между элементами два и более
      // пробелов, то будут путсые элементы в массиве
      if (element.length !== 0) {
        this.errorRecipientAddress[channelId] += checkValidAddress(element, channelId, this.selectedTelegramLanguage.label);
      }
    });

    // Если сообщение об ошибках не пустое, то обработка адресов прекращается
    if (this.errorRecipientAddress[channelId].length > 0) {
      return false;
    }
*/
    if (names.length > 1) {
      // Скрытие оригинальной строки ng-select, так как она работает только
      // для одиночного ввода и не корректно отрабатывает множественное добавление
      // элементов. После скрытия и повторной отрисовки - все отлично.
      // Нюанс работы с компонентом.
      this.recipientLoadAnimation = true;
      // Добавление всех элементов в адреса, если выше проверки пройденны
      names.forEach(element => {
        if (element.length !== 0) {
          this.message.recipentObjectList[channelId] =
            [...this.message.recipentObjectList[channelId], this.searchInRecipients(element, channelId)];
          this.checkDuplicateAddressesRecipients();
        }
      });
      this.interval = setTimeout(() => {
        this.recipientLoadAnimation = false;
      }, 250);
    } else if (names.length > 0 && names[0].length > 0) {
      // Если в массиве один элемент, использование стандартного метода добавления
      // единичной записи для ng-select
      const id = this.searchInRecipients(names[0], channelId);
      this.message.recipentObjectList[channelId] = [...this.message.recipentObjectList[channelId], id];
      this.checkDuplicateAddressesRecipients();
      return id;
    } else return;
  }

  validateContactArray(names, channelId = this.message.channelId) {

    let error = false;

    names.forEach(element => {
      // Проверка длинны элемента, так как если между элементами два и более
      // пробелов, то будут путсые элементы в массиве
      if (element.length !== 0) {
        this.errorRecipientAddress[channelId] += checkValidAddress(element, channelId, this.selectedTelegramLanguage.label);
      }
    });

    // Если сообщение об ошибках не пустое, то обработка адресов прекращается
    if (this.errorRecipientAddress[channelId].length > 0) {
      error = true;
    }

    return error;
  }

  /**
   * Функция добавления пользовательского адреса получателя в общий список
   * далее пользовательский список будет обьединятся с общим списком адресов
   * для быстро доступа в течении работы к ранее вводимым адресам
   */
  addTagContact(name, channelId = this.message.channelId) {
    if (this.isNewMessage) {
      this.errorRecipientAddress[channelId] = checkValidAddress(name, channelId, this.selectedTelegramLanguage.label);
    }
    // Отчистка ранее записанной ошибки для получателей, если такая имелась
    this.errorRecipientAddress = {1: '', 2: '', 3: ''};
    // Флаг сохранения данных, истина если данных сохранены в существующий объект
    let dataSave = false;
    // Идентификатор новой записи
    let addId = null;
    // Для канала AFTN перевод адреса получателя в текущий язык на котором
    // осуществляется набор сообщения
    if (channelId == this.channelIds.aftnId) {
      if (this.selectedTelegramLanguage.label === 'RU') {
        name = transliterationEnRu(name);
      } else if (this.selectedTelegramLanguage.label === 'LAT') {
        name = transliterationRuEnMessage(name);
      }
    }
    if (channelId == this.channelIds.sitatexId) {
        name = name.toUpperCase();
    }
    // Перебор списка адресов созданных пользователем
    this.userContactsForChannel.forEach(item => {
      // Ищем набор адресов созданных для текущего активного канала
      if (item.channelId == channelId) {
        // Формирование идентификатора элемента
        const itemId = item.id.toString() + item.addresses.length.toString();
        // Добавление нового элемента в массив
        item.addresses = [...item.addresses, { id: itemId, name: name }];
        dataSave = true;
        // Сохранение идентификатора добавленого элемента что бы вернуть
        // его как значение для ng-select, тогда элемент верно добавится в строку
        addId = itemId;
        // Добавляем элемент в модель сообщения
        this.message.recipentObjectList[channelId] = [...this.message.recipentObjectList[channelId], itemId];
      }
    });

    // Если элемент не был сохранен, так как не нашлось группы с нужным
    // идентификатором канала
    if (!dataSave) {
      // Создание нового идентификатор канала из тайм-метки
      const userId = Date.now();
      // Добавление новой записи для канала с пустым списком адресов
      this.userContactsForChannel = [...this.userContactsForChannel,
      {
        id: userId,
        name: 'User addresses',
        addresses: [],
        channelId: +channelId
      }
      ];
      // Создание идентификатора элемента массива в новом канале
      const arrayId = userId.toString() + '0';
      // Сохранение идентификатора добавленого элемента что бы вернуть
      // его как значение для ng-select, тогда элемент верно добавится в строку
      addId = arrayId;
      // Запись нового значения адреса в созданный объект, записывается в последний
      // по этому this.userContactsForChannel.length - 1

      this.userContactsForChannel[this.userContactsForChannel.length - 1].addresses =
        [...this.userContactsForChannel[this.userContactsForChannel.length - 1].addresses, { id: arrayId, name: name }];
        this.message.recipentObjectList[channelId] = [...this.message.recipentObjectList[channelId], arrayId];

    }
    this.reloadContactsForChannel(channelId);
    return addId;
  }

  async loadUrgency(channel?) {
    const xRequestId = this.globalSettings.randomUuid;
    this.urgenciesForChannel.length = 0;
    this.logger.trace('loadUrgency',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.loadUrgency',
                                  'call',
                                  channel,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    channel = this.channelIds.aftnId;
    await this.restApi.getUrgencies(channel, xRequestId).then(data => {
      this.logger.trace('loadUrgency',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.loadUrgency',
                                  'reply',
                                  data,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
      if (data) {
        this.urgenciesForChannel[channel] = [];
        for (const item of data) {
          const urgency = new Urgency();
          Object.assign(urgency, item);
          this.urgencies.push(urgency);
          this.urgenciesForChannel[channel] = [...this.urgenciesForChannel[1], urgency];
        }
      }
    });
    channel = this.channelIds.sitatexId;
    await this.restApi.getUrgencies(channel, xRequestId).then(data => {
      this.logger.trace('loadUrgency',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.loadUrgency',
                                  'reply',
                                  data,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
      if (data) {
        this.urgenciesForChannel[channel] = [];
        for (const item of data) {
          const urgency = new Urgency();
          Object.assign(urgency, item);
          this.urgenciesForChannel[channel] = [...this.urgenciesForChannel[channel], urgency];
        }
      }
    });
  }

  async loadTypes() {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.trace('loadTypes',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.loadTypes',
                                  'call',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    await this.restApi.getTypes(xRequestId).then(data => {
      this.logger.trace('loadTypes',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.loadTypes',
                                  'reply',
                                  data,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
      for (const item of data) {
        const type = new Type();
        Object.assign(type, item);
        if (type.nameEnglish != '') {
          this.types.push(type);
        }
      }
    });
  }

  loadFlights() {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.trace('loadFlights',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.loadFlights',
                                  'call',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    return this.restApi.getFligtsList(xRequestId).subscribe((data: Flight[]) => {
      this.logger.trace('loadFlights',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.loadFlights',
                                  'reply',
                                  data,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
      for (const item of data) {
        const airport = new Flight();
        Object.assign(airport, item);
        this.privateFlights.push(airport);
      }
    });
  }

  // Рейсы
  get flights(): Array<Flight> {
    if (this.globalSettings.typeEnterprise == this.typeEnterprise.Airline) {
      return this.privateFlights
        .filter(item => {
          return (this.message.links == undefined || this.message.links.includes(item.id));
        }).concat(this.privateFlights
          .filter(item => {
            return (this.message.links == undefined || !this.message.links.includes(item.id));
          })).filter(item => {
            return (this.searchInput == '' || item.flight.toLowerCase().includes(this.searchInput.toLowerCase()))
              || (this.searchInput == '' || item.airlineIata.toLowerCase().includes(this.searchInput.toLowerCase()))
              || (this.searchInput == '' || item.airlineIcao.toLowerCase().includes(this.searchInput.toLowerCase()));
          });
    } else {
      return;
    }
  }

  saveMessageFlight(ids: Array<number>, messageId: number) {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.info('saveMessageFlight',
                     new ECSEvent(ModuleName.ComMan,
                                 'main.saveMessageFlight',
                                 'click',
                                 'messageId: ' + messageId + ', ' + ids,
                                 EventProvider.UI,
                                 '',
                                 xRequestId));
    this.loading = true;
    if (!this.isNewMessage) {
      return this.restApi.saveMessageFligts(messageId, ids, xRequestId).subscribe((data: {}) => {
        this.logger.trace('saveMessageFlight',
                     new ECSEvent(ModuleName.ComMan,
                                 'main.saveMessageFlight',
                                 'reply',
                                 data,
                                 EventProvider.APPLICATION,
                                 '',
                                 xRequestId));
        this.messageDetail(messageId, true);
        this.messageFlightsId = [];
        this.showModal = null;
        this.loading = false;
      }, err => this.displayError(err));
    } else {
      this.message.links = ids;
      this.showModal = null;
      this.messageFlightsId = [];
      this.loading = false;
      this.message.flights = this.messageFlights;
    }
  }

  // Авиакомпании
  saveMessageAirline(ids: Array<number>, messageId: number) {
    if (!this.isNewMessage) {
      this.showModal = null;
    }
  }

  async selectChannel(id: number) {
    this.logger.info('selectChannel',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.channel',
                                  'select',
                                  id,
                                  EventProvider.UI));
    if (!id) return;
    if (this.message.channelId === 0) {
      this.message.channelId = null;
    } else {
      this.message.channelId = id;
      if (id == this.channelIds.aftnId) {
        this.message.urgencyId = 1;
        this.message.message = this.splitIntoLines(this.message.message);
      } else {
        this.verifiedMessage = [];
        if (id == this.channelIds.sitatexId)
          this.message.urgencyId = 6;
        else
          this.message.urgencyId = null;
      }
      this.message.recipient = null;
      this.message.sender = null;
      await this.loadSenders(id, this.selectedFolder);
      this.reloadContactsForChannel();
    }
  }

  checkFolderRuleIsFull(): boolean {
    // Для папки черновики скрываем
    if (this.selectedFolder == -1) {
      return true;
    }
    for (let element of this.globalSettings.getComManFolders()) {
      if (element.id == this.selectedFolder.toString() && element.rule == 'full') {
        return true;
      }
    }

    return false;
  }

  changeActiveFolder(newFolder?: number, parentFolder?: number) {
    this.logger.info('changeActiveFolder',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.folderTree',
                                  'change-folder',
                                  newFolder,
                                  EventProvider.UI));
    this.loadingMessagesList = true;
    let clearFilterMark = false;
    if (this.selectedFolder != newFolder) {
      clearFilterMark = true;
    }
    this.selectedFilter.id = null;
    this.selectedFolder = newFolder;
    this.selectedFolderName = this.folders.find(e => e.id == newFolder).name;
    this.selectedParentFolder = parentFolder;
    this.messageIsSelected = false;
    this.messageId = undefined;
    this.messageGroup = [];
    this.message = new MessageDetail();
    this.selectedMessage = new MessageDetail();

    // Сброс фильтра при переходе
    this.clearFilter();
    if (clearFilterMark) {
      this.filterMark(null);
    }
    this.loadFolder(this.selectedFolder);
    this.loadMessages();
  }

  saveToFile(id: number) {
    this.logger.info('saveToFile',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.button.save',
                                  'click',
                                  id,
                                  EventProvider.UI,
                                  'Save current message to file'));
    let date = new Date(this.message.dtMessage);
    let telegramText = this.urgencyNameSelectedMessage + ' ' +
      this.message.recipient + '\r\n' +
      this.addZeroForDateTime(date.getUTCDate()) +
      this.addZeroForDateTime(date.getUTCHours()) +
      this.addZeroForDateTime(date.getUTCMinutes()) +
      ' ' + this.message.sender + '\r\n' +
      this.message.message;
    const TEXT = new Blob([telegramText], { type: 'application/pdf' });
    this.fileSaverService.save(TEXT, id + '.txt');
  }

  printMessage(id: number) {
    this.logger.info('printMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.button.print',
                                  'click',
                                  id,
                                  EventProvider.UI,
                                  'Print current message'));
  }

  testFilter(item: { folders: string | string[]; }) {
    if (item.folders.indexOf(this.selectedFolder.toString()) !== -1) {
      return item;
    }
  }

  sliseText(text, length: number) {
    if (typeof text == 'object') {
      let res = ''
      for (const key in text) {
        if (Object.prototype.hasOwnProperty.call(text, key)) {
          res += text[key] != '' ? text[key].replaceAll(',', ', ') + ', ' : '';
        }
      }
      text = res.slice(0, -2);
    }
    let sliced = text
    if (length < text.length) {
      sliced = text.slice(0, length) + '...';
    }
    return sliced;
  }

  toString(object) {
    if (typeof object == 'object') {
      let res = ''
      for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
          res += object[key] != '' ? object[key] + ', ' : '';
        }
      }
      object = res.slice(0, -2);
    } else if (typeof object == 'string') {
      object = object.split(',').join(', ');
    }
    return object;
  }

  copyToFolder(messageId: number) {
    this.loading = true;
    let folderIds = Array.from(this.copyIds).join(',');
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.info('copyToFolder',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.copyToFolder',
                                  'click',
                                  'messageId: ' + messageId + ', folders: ' + folderIds,
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    return this.restApi.copyToFolder(messageId, this.selectedFolder, folderIds, xRequestId).subscribe((data: {}) => {
      this.logger.trace('copyToFolder',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.copyToFolder',
                                  'reply',
                                  data,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
      this.checkFolders();
      this.loadMessages(this.messagesPageActive);
      this.selectedAction = '';
      this.folderForCopy = null;
      this.loading = false;
    }, err => this.displayError(err));
  }

  async sendToArchive(id = this.message.id) {
    this.errorComman = this.globalI18n.getMessage(ModuleName.ComMan, 'sendToArchive');
    this.errorType = 'warning';
    this.userAction = 'setAnswer'
    await this.waitAnswer().then(async res => {
      if (res) {
        const xRequestId = this.globalSettings.randomUuid;
        this.logger.trace('sendToArchive',
                        new ECSEvent(ModuleName.ComMan,
                                      'func.sendToArchive',
                                      'call',
                                      'messageId: ' + this.message.id + ', folder: ' + this.selectedFolder,
                                      EventProvider.APPLICATION,
                                      '',
                                      xRequestId));
        return this.restApi.sendToArchive(this.message.id, this.selectedFolder, xRequestId).subscribe((data: {}) => {
          this.logger.trace('sendToArchive',
                        new ECSEvent(ModuleName.ComMan,
                                      'func.sendToArchive',
                                      'reply',
                                      data,
                                      EventProvider.APPLICATION,
                                      '',
                                      xRequestId));
          this.messageId = undefined;
          this.selectedMessage = new MessageDetail();
          this.messageGroup = [];
          this.clearErrorMess();
          this.checkFolders();
          this.loadMessages(this.messagesPageActive);
        }, err => this.displayError(err));
      }
    });
  }

  async notSendToArchive(id: number) {
    this.errorComman = this.globalI18n.getMessage(ModuleName.ComMan, 'notSendToArchive');
    this.errorType = 'warning';
    this.userAction = 'setAnswer'
    await this.waitAnswer().then(async res => {
      if (res) {
        const xRequestId = this.globalSettings.randomUuid;
        this.logger.trace('notSendToArchive',
                        new ECSEvent(ModuleName.ComMan,
                                      'func.notSendToArchive',
                                      'call',
                                      'messageId: ' + id + ', folder: ' + this.selectedFolder,
                                      EventProvider.APPLICATION,
                                      '',
                                      xRequestId));
        return this.restApi.notSendToArchive(id, this.selectedFolder, xRequestId).subscribe((data: {}) => {
          this.logger.trace('notSendToArchive',
                        new ECSEvent(ModuleName.ComMan,
                                      'func.notSendToArchive',
                                      'reply',
                                      data,
                                      EventProvider.APPLICATION,
                                      '',
                                      xRequestId));
          this.clearErrorMess();
          this.checkFolders();
          this.loadMessages(this.messagesPageActive);
        }, err => this.displayError(err));
      }
    });
  }

  async restoreFromArchive(id = this.message.id) {
    this.errorComman = this.globalI18n.getMessage(ModuleName.ComMan, 'restoreFromArchive');
    this.errorType = 'warning';
    this.userAction = 'setAnswer'
    await this.waitAnswer().then(async res => {
      if (res) {
        const xRequestId = this.globalSettings.randomUuid;
        this.logger.trace('restoreFromArchive',
                        new ECSEvent(ModuleName.ComMan,
                                      'func.restoreFromArchive',
                                      'call',
                                      'messageId: ' + id + ', folder: ' + this.selectedFolder,
                                      EventProvider.APPLICATION,
                                      '',
                                      xRequestId));
        return this.restApi.restoreFromArchive(id, this.selectedFolder, xRequestId).subscribe((data: {}) => {
          this.logger.trace('restoreFromArchive',
                        new ECSEvent(ModuleName.ComMan,
                                      'func.restoreFromArchive',
                                      'reply',
                                      data,
                                      EventProvider.APPLICATION,
                                      '',
                                      xRequestId));
          this.messageId = undefined;
          this.selectedMessage = new MessageDetail();
          this.clearErrorMess();
          this.messageGroup = [];
          this.checkFolders();
          this.loadMessages(this.messagesPageActive);
        }, err => this.displayError(err));
      }
    });
  }

  reparseMessage(action?: Function) {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.info('reparseMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'moda.messageEdit.saveReprocess',
                                  'click',
                                  '',
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    return this.restApi.reparseMessage(this.message.id, this.selectedFolder,
                                       this.message.message,
                                       xRequestId).subscribe((data: {}) => {
      this.logger.trace('reparseMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'moda.messageEdit.saveReprocess',
                                  'reply',
                                  data,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
      action();
      this.loadMessages(this.messagesPageActive);
    }, err => this.displayError(err));
  }

  reSendMessage(action?: Function, form?) {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.info('reSendMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'modal.messageEdit.reSendMessage',
                                  'click',
                                  '',
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    form.form.markAllAsTouched();
    if (form.valid) {
      this.loading = true;
      let error = false;
      this.message.message = this.message.message.replace(/\t/g, '  ');
      error = this.messageValid(this.message);

      if (!error) {
        this.message.urgencyId = Number(this.message.urgencyId);
        this.message.channelId = Number(this.message.channelId);
        this.message.typeId = Number(this.message.typeId);
        this.message.readConfirmations = [];
        let recipientArray = [];
        this.contactsForChannels[this.message.channelId].forEach(item => {
          // Обработка адресов по выбранному каналу
          if (item.channelId == this.message.channelId) {
            item.addresses.forEach(address => {
              if (this.message.recipentObjectList[this.message.channelId].indexOf(address.id) != -1) {
                recipientArray.push(address.name);
              }
            });
          }
        });
        if (recipientArray.length != 0) {
          this.message.recipient = recipientArray.join(',');
        }

        return this.restApi.reSendMessage(this.message.id, this.message, xRequestId).subscribe((data: {}) => {
          this.logger.trace('reSendMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'modal.messageEdit.reSendMessage',
                                  'reply',
                                  data,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
          this.messageSended = true;
          this.loading = false;
          this.loadMessages(this.messagesPageActive);
          setTimeout(() => {
            this.messageSended = false;
            action();
          }, 3000);
        }, err => this.displayError(err));

      } else {
        return false;
      }
    }
  }

  setMark(messageId: number, color: string) {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.info('setMark',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.setMark',
                                  'click',
                                  'messageId:' + messageId + ', color: ' + color,
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    return this.restApi.setMark(messageId, this.selectedFolder, color, xRequestId).subscribe(data =>  {
      this.logger.info('setMark',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.setMark',
                                  'reply',
                                  data,
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
      this.selectedMessage.mark = color;
      this.message.mark = color;
      this.checkFolders();
      this.loadMessages(this.messagesPageActive);
    }, err => this.displayError(err));
  }

  unsetMark(messageId: number, color?: string) {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.info('unsetMark',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.unsetMark',
                                  'click',
                                  'messageId: ' + messageId + ', color: ' + color,
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    return this.restApi.unsetMark(messageId, this.selectedFolder, xRequestId).subscribe(data => {
      this.logger.info('unsetMark',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.unsetMark',
                                  'reply',
                                  data,
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
      this.selectedMessage.mark = '';
      this.message.mark = '';
      this.checkFolders();
      this.loadMessages(this.messagesPageActive);
    }, err => this.displayError(err));
  }

  selectAction(action: string, id?: number) {
    this.logger.debug('Select action id', id);
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.info('selectAction',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.button.copyToFolder',
                                  'click',
                                  action + ',' + id.toString(),
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    this.selectedAction = action;
    this.copyIds = new Set();
    if (action == 'copy') {
      return this.restApi.getFoldersForMessage(id, xRequestId).subscribe((data: any) => {
        this.logger.trace('selectAction',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.button.copyToFolder',
                                  'reply',
                                  data,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
        this.foldersForMessage = data;
      });
    }
  }

  updateCopyIds(id) {
    if (this.copyIds.has(id)) {
      this.copyIds.delete(id)
    } else {
      this.copyIds.add(id)
    }
  }

  get deleteFilterMessage(): string {
    return this.globalI18n.getMessage(ModuleName.ComMan, 'deleteFilter');
  }

  /*
  confirmFunction(id: number, text: string, okFunc: Function, cancelFunc: Function) {
    this.logger.info('confirmFunction',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.confirm',
                                  'wait',
                                  'id: ' +  id + ', text: ' + text,
                                  EventProvider.UI));
    this.confirmMessage = text;
    this.selectId = id;
    this.okFunc = okFunc;
    this.cancelFunc = cancelFunc;
  }

  clearConfirmMessage() {
    this.confirmMessage = '';
  }
  */

  // Airlines
  async loadAirlines() {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.trace('loadAirlines',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.loadAirlines',
                                  'call',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    this.airlinesLoading = true;
    await this.restApi.getAirlines(xRequestId).then(data => {
      this.logger.trace('loadAirlines',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.loadAirlines',
                                  'reply',
                                  data,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
      this.airlines.length = 0;
      for (const item of data) {
        const airline = new ReferanceAirline();
        Object.assign(airline, item);
        // Вмесето this.airlines.push(airline) применен способ вставки
        // позволяющий компоненту ng-select увидеть измнения в массиве
        // раздел документации: Change Detection с официального сайта
        this.airlines = [...this.airlines, airline];
      }
      this.airlinesLoading = false;
      // Загрука первой партии элементов для отображания в выпадающем списке
      this.airlinesBuffer.length = 0;
      this.airlinesBuffer = this.airlines.slice(0, this.airlinesBufferSize);
    });
  }

  // Airports
  async loadAirports() {
    const xRequestId = this.globalSettings.randomUuid;
    this.logger.trace('loadAirports',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.loadAirports',
                                  'call',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    this.airportLoading = true;
    await this.restApi.getAirports(xRequestId).then(data => {
      this.logger.trace('loadAirports',
                     new ECSEvent(ModuleName.ComMan,
                                  'func.loadAirports',
                                  'reply',
                                  data,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
      this.airport.length = 0;
      for (const item of data) {
        const airport = new ReferanceAirport();
        Object.assign(airport, item);
        // Вмесето this.airlines.push(airline) применен способ вставки
        // позволяющий компоненту ng-select увидеть измнения в массиве
        // раздел документации: Change Detection с официального сайта
        this.airport = [...this.airport, airport];
      }
      this.airportLoading = false;
      // Загрука первой партии элементов для отображания в выпадающем списке
      this.airportBuffer.length = 0;
      this.airportBuffer = this.airport.slice(0, this.airportBufferSize);
    });
  }

  // Aвиакомпании
  getAirlines(): ReferanceAirline[] {
    return this.airlines;
  }

  checkToday(date) {
    let today = new Date();
    let dateMessage = new Date(date);
    if (today.setUTCHours(0, 0, 0, 0) === dateMessage.setUTCHours(0, 0, 0, 0)) {
      return true;
    } else {
      return false;
    }
  }

  checkRead(date: string) {
    let today = new Date("0001-01-01T00:00:00Z");
    let dateMessage = new Date(date);
    dateMessage.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    if (dateMessage > today) {
      return true;
    } else {
      return false;
    }
  }

  // Включить/выключить фильтр
  filterApp() {
    if (!this.quickSearchCheck()) {
      this.filterApply = !this.filterApply;
      this.showFilter = false;
      this.loadMessages();
    } else {
      this.showFilter = true;
    }
    this.logger.info('filterApp',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.filter',
                                  'show',
                                  this.showFilter,
                                  EventProvider.UI));
  }

  openFilter() {
    if (this.selectedFolder == -1) {
      return;
    }
    this.showFilter = !this.showFilter;
    this.logger.info('openFilter',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.filter.open',
                                  'change-status',
                                  this.showFilter,
                                  EventProvider.UI));
  }

  filterOn() {
    if (!this.quickSearchCheck()) {
      this.logger.info('filterOn',
                      new ECSEvent(ModuleName.ComMan,
                                    'main.filter',
                                    'enable',
                                    '',
                                    EventProvider.UI));
      this.filterApply = true;
      this.showFilter = false;
      this.loadingMessagesList = true;
      this.loadMessages();
    }
    this.showFilter = false;
  }

  clearFilter(close = true) {
    this.logger.info('clearFilter',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.filter',
                                  'disable',
                                  '',
                                  EventProvider.UI));
    this.selectedFilter = new UserFilter();
    this.filterParams = new FilterParams();
    if (close) this.showFilter = false;
    this.filterApply = false;
    this.loadMessages();
  }

  loadFilters() {
    let data = this.globalSettings.getComManUserFilters();
    this.userFilters = [];
    data.sort((i1, i2) => i1.folderId - i2.folderId);
    // this.countAllFilters = 0;
    let count = 0;
    data.forEach(element => {
      let params = new FilterParams();
      Object.assign(element.data, params);
      this.restApi.getCountFilteredMessages(JSON.parse(element.data), element.folderId).subscribe((data: any) => {
        element.count = data;
      });
      count += element.count;
      this.userFilters.push(element);
      if (this.selectedFilter.id == element.id) {
        this.selectedFilter = Object.assign({}, element);
        if (this.userAction === 'saveFilter') this.selectedFilter.name = '';
      }
    });
    if (this.countAllFilters != count) {
      this.countAllFilters = count;
    }
    this.logger.debug('User filters', this.userFilters);
  }

  loadFilter(id: number) {
    this.loadingMessagesList = true;
    this.logger.info('loadFilter',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.folderTree.filters',
                                  'load-filter',
                                  id,
                                  EventProvider.UI));
    this.selectFilter(id);
    this.filterApply = true;
    this.loadMessages();
  }

  selectFilter(id: number) {
    this.logger.info('selectFilter',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.filter',
                                  'select',
                                  id.toString(),
                                  EventProvider.UI));
    this.selectedFilter = new UserFilter();
    this.filterParams = new FilterParams();
    if (id > -1) {
      this.selectedFilter = Object.assign({}, this.getById(this.userFilters, id));
      let params = this.getById(this.userFilters, id);
      Object.assign(this.filterParams, JSON.parse(params.data));
      this.selectedFolder = this.selectedFilter.folderId;
      this.selectedFolderName = this.folders.find(e => e.id == this.selectedFolder).name;
    } else {
      this.selectedFilter.id = null;
    }
    this.logger.debug('Selected filter', this.selectedFilter);
  }

  createFilter(action) {
    this.logger.info('createFilter',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.filter',
                                  'create-update',
                                  action,
                                  EventProvider.UI));
    if (this.userFilters && this.userFilters.length >= 5 && action === 'saveFilter') {
      this.errorComman = this.globalI18n.getMessage(ModuleName.ComMan, 'numberOfFiltersExceeded');
    } else {
      this.userAction = action;
    }
  }

  saveFilterAsNew() {
    if (this.userFilters && this.userFilters.length < 5) {
      this.userAction = 'saveFilter';
      this.selectedFilter.name = '';
    } else {
      this.errorComman = this.globalI18n.getMessage(ModuleName.ComMan, 'numberOfFiltersExceeded');
    }
  }

  saveFilter() {
    this.logger.info('saveFilter',
                     new ECSEvent(ModuleName.ComMan,
                                  'modal.saveFilter',
                                  'click',
                                  '',
                                  EventProvider.UI));
    if (this.userFilters && this.userFilters.length < 5) {
      let folder = this.getById(this.folders, this.selectedFolder)
      let result = this.globalSettings.addComManUserFilter(this.selectedFilter.name, folder, this.filterParams);
      this.userAction = '';
      this.selectedFilter = new UserFilter();
      this.selectedFilter.id = result;
      this.loadFilters();
    } else {
      this.errorComman = this.globalI18n.getMessage(ModuleName.ComMan, 'numberOfFiltersExceeded');
    }
  }

  updateFilter(id) {
    this.logger.info('updateFilter',
                     new ECSEvent(ModuleName.ComMan,
                                  'modal.updateFilter',
                                  'click',
                                  id,
                                  EventProvider.UI));
    this.globalSettings.updateComManUserFilter(id, this.filterParams);
    this.userAction = '';
    this.loadFilters();
  }

  async deleteFilter(id) {
    this.errorComman = this.globalI18n.getMessage(ModuleName.ComMan, 'deleteFilter');
    this.errorType = 'warning';
    this.userAction = 'setAnswer'
    await this.waitAnswer().then(async res => {
      if (res) {
        this.logger.info('deleteFilter',
                        new ECSEvent(ModuleName.ComMan,
                                      'modal.deleteFilter',
                                      'click',
                                      '',
                                      EventProvider.UI));
        this.globalSettings.removeComManUserFilter(id);
        this.clearErrorMess();
        this.selectedFilter = new UserFilter();
        this.filterParams = new FilterParams();
        this.loadFilters();
      }
    });
  }

  filterMark(mark) {
    this.logger.info('filterMark',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.button.mark',
                                  'click',
                                  mark,
                                  EventProvider.UI));
    this.filterParams.mark = mark;
    this.loadMessages();
  }

  filterArchive() {
    this.filterParams.depth = this.filterParams.depth == 'current' ? 'archive' : 'current';
    this.logger.info('filterArchive',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.filter.archive',
                                  'set',
                                  this.filterParams.depth,
                                  EventProvider.UI));
    this.loadMessages();
  }

  updateShowFolder(value: boolean) {
    this.logger.info('updateShowFolder',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.folderTree',
                                  'max-view',
                                  value,
                                  EventProvider.UI));
    this.globalSettings.updateShowFolderThree(value);
    this.showFolderThree = value;
  }

  updateCopyTextForReply(value: boolean) {
    this.logger.info('updateCopyTextForReply',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.copyText',
                                  'change',
                                  value,
                                  EventProvider.UI));
    this.globalSettings.updateCopyTextForReply(value);
    this.copyTextForReply = value;
  }

  // Выбираем поле для отображения подсказок в правой части (длина, тип и тд)
  selectTemplateInput(index: number) {
    //this.selectedTemplateInput = this.template.parts[index];
  }

  // Отменяем выбор поля
  selectTemplateInputNone() {
    this.selectedTemplateInput = null;
  }

  // Валидация даты
  patternDate(template: string) {
    let pattern = '';
    let pos_dot = template.indexOf('.');
    if (pos_dot > 0) {
      template = template.replace('.', '');
    }
    let array = template.match(/.{1,2}/g);
    if (pos_dot > 0) {
      array.splice(pos_dot / 2, 0, ".");
    }
    array.forEach(element => {
      switch (element) {
        case 'dd':
          let today = new Date();
          if ([0, 2, 4, 6, 7, 9, 11].includes(today.getMonth())) {
            pattern += '(0[1-9]|[12][0-9]|3[01])';
          } else if ([3, 5, 8, 10].includes(today.getMonth())) {
            pattern += '(0[1-9]|[12][0-9]|3[0])';
          } else if (today.getMonth() == 1) {
            if ((today.getFullYear() % 400 == 0) || (today.getFullYear() % 4 == 0 && today.getFullYear() % 100 != 0)) {
              pattern += '(0[1-9]|[12][0-8])';
            } else {
              pattern += '(0[1-9]|[12][0-9])';
            }
          }
          break;
        case 'hh':
          pattern += '(0[1-9]|[1][0-2])';
          break;
        case 'HH':
          pattern += '(0[1-9]|[1][0-9]|[2][0-3])';
          break;
        case 'mm':
          pattern += '(0[1-9]|[12345][0-9])';
          break;
        case '.':
          pattern += '\\.';
          break;
      }
      if (element == 'dd') {
      }
    });
    return pattern;
  }

  quickSearchCheck() {
    let newFilter = new FilterParams();
    newFilter.folder = this.filterParams.folder;
    newFilter.limit = this.filterParams.limit;
    newFilter.page = this.filterParams.page;
    newFilter.mark = this.filterParams.mark;
    newFilter.depth = this.filterParams.depth;
    return JSON.stringify(this.filterParams) === JSON.stringify(newFilter);
  }

  quickSearch(value?: string, field?: string) {
    if (field == 'flight') {
      this.filterParams.flight.push(value);
    } else if (field == 'airline') {
      // TODO функция быстрого поиска не работает, так как теперь airlineValue
      // это массив
      // this.filterParams.airlineValue = +value;
      this.airlineServerSideCtrl = null;
    }
    this.showFilter = false;
    this.filterApply = true;
    this.loadMessages();
  }

  clearFilterParametr(field: string) {
    this.logger.info('clearFilterParametr',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.filter',
                                  'clear',
                                  field,
                                  EventProvider.UI));
    switch (field) {
      case 'flight':
        this.filterParams.flight = [];
        this.selectEnteredData.flight = null;
        break;
      case 'airline':
        this.filterParams.airlineFlag = '';
        this.filterParams.airlineValue = [];
        break;
      case 'type':
        this.filterParams.typeFlag = '';
        this.filterParams.typeValue = [];
        break;
      case 'route':
        this.filterParams.routeFlag = '';
        this.filterParams.routeValue = [];
        break;
      case 'recipient':
        this.filterParams.recipient = [];
        this.filterParams.recipientFlag = '';
        this.selectEnteredData.recipient = null;
        break;
      case 'sender':
        this.filterParams.sender = [];
        this.filterParams.senderFlag = '';
        this.selectEnteredData.sender = null;
        break;
      case 'channel':
        this.filterParams.channelFlag = '';
        this.filterParams.channelValue = [];
        break;
      case 'category':
        this.filterParams.urgencyFlag = '';
        this.filterParams.urgencyValue = [];
        break;
      case 'subject':
        this.filterParams.subject = '';
        break;
      case 'text':
        this.filterParams.textFlag = '';
        this.filterParams.textValue = [];
        break;
      case 'user':
        this.filterParams.userFlag = '';
        this.filterParams.userValue = '';
        break;
      case 'aftnNumber':
        this.filterParams.aftnNumber = [];
        this.selectEnteredData.aftnNumber = null;
        break;
      case 'depth':
        this.filterParams.depth = 'current';
        break;
      case 'direction':
        this.filterParams.direction = -1;
        break;
      case 'unread':
        this.filterParams.unread = false;
        break;
      case 'noFlight':
        this.filterParams.noFlight = false;
        break;
      case 'start':
        this.filterParams.start = null;
        break;
      case 'finish':
        this.filterParams.finish = null;
        break;
      case 'all':
        this.filterParams.all = null;
        break;
      default:
        break;
    }
    if (this.quickSearchCheck()) {
      this.filterApply = false;
    }
    this.showFilter = false;
    this.loadMessages();
  }

  openMessage(content, id: number, folderId?: number) {
    this.logger.info('openMessage',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.messageList',
                                  'open-message',
                                  id,
                                  EventProvider.UI));
    if (this.selectedFolder === -1) {
      this.loadDraft(id, folderId, true);
    } else {
      this.selectMessage(id);
    }
    this.isNewMessage = this.selectedFolder === -1 ? true : false;
    this.open(content);
  }

  // Открывает модалку
  open(content, id?: number) {
    if (id) {
      Object.assign(this.message, this.getById(this.messageGroup, id));
      this.isNewMessage = this.selectedFolder === -1 ? true : false;
    }
    this.modalService.open(content, { size: 'xl', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  selectView(value: string) {
    this.logger.info('selectView',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.view',
                                  'change',
                                  value,
                                  EventProvider.UI));
    this.cookieService.set('view', value);
    this.commVertical = (value == 'vertical');
  }

  parseDateTime(date: string, time: string): string {
    if (date && date + 'T' + time) {
      return new Date(date + 'T' + time).toISOString();
    }
    return null;
  }

  parseDateTimeUtc(date: string, time: string): string {
    if (date && date + 'T' + time) {
      let utcDate = Date.parse(date + 'T' + time + ':00.000Z');
      return new Date(utcDate).toISOString();
    }
    return null;
  }

  // Функция приводит дату к RFC3339, без милисекунд.
  // Пока оставила, хотя сейчас и через .toISOString() норм работает
  ISODateString(date) {
    function pad(n) { return n < 10 ? '0' + n : n }
    return date.getUTCFullYear() + '-'
      + pad(date.getUTCMonth() + 1) + '-'
      + pad(date.getUTCDate()) + 'T'
      + pad(date.getUTCHours()) + ':'
      + pad(date.getUTCMinutes()) + ':'
      + pad(date.getUTCSeconds()) + 'Z'
  }

  recipientListClose() {
    this.errorRecipientAddress = {1: '', 2: '', 3: ''};
  }

  selectLanguage() {
    this.reloadContactsForChannel(this.channelIds.aftnId);
    if (this.selectedTelegramLanguage.label == 'RU') {
      this.message.sender = transliterationEnRu(this.message.sender);
      if (this.message.senders && this.message.senders[this.channelIds.aftnId]) {
        this.message.senders[this.channelIds.aftnId] = transliterationEnRu(this.message.senders[this.channelIds.aftnId]);
      }
      this.message.recipient = transliterationEnRu(this.message.recipient);
      this.contactsForChannel.forEach(item => {
        // Ищем AFTN так как перевод актуален только для него
        if (item.channelId == this.channelIds.aftnId) {
          item.addresses.forEach(address => {
            address.name = transliterationEnRu(address.name);
          });
        }
      });
      this.contactsForChannels[this.channelIds.aftnId].forEach(item => {
          item.addresses.forEach(address => {
            address.name = transliterationEnRu(address.name);
          });
      });
    } else if (this.selectedTelegramLanguage.label === 'LAT') {
      this.message.message = transliterationRuEnMessage(this.message.message);
      this.message.recipient = transliterationRuEnMessage(this.message.recipient);
      this.message.sender = transliterationRuEn(this.message.sender);
      if (this.message.senders && this.message.senders[this.channelIds.aftnId]) {
        this.message.senders[this.channelIds.aftnId] = transliterationRuEn(this.message.senders[this.channelIds.aftnId]);
      }
      this.contactsForChannel.forEach(item => {
        // Ищем AFTN так как перевод актуален только для него
        if (item.channelId == this.channelIds.aftnId) {
          item.addresses.forEach(address => {
            address.name = transliterationRuEn(address.name);
          });
        }
      });
      this.contactsForChannels[this.channelIds.aftnId].forEach(item => {
        // Ищем AFTN так как перевод актуален только для него
          item.addresses.forEach(address => {
            address.name = transliterationRuEn(address.name);
          });
      });
    }
  }

  setDateMessage(event) {
    const classList = event.target.classList;
    if (!classList.contains('ng-invalid')) {
      this.logger.info('setDateMessage',
                      new ECSEvent(ModuleName.ComMan,
                                    'modal.editMessage',
                                    'enter-date',
                                    event.target.value,
                                    EventProvider.UI));
      const date = new Date(this.message.dtMessage);
      const newDate = event.target.value.match(/.{1,2}/g);
      date.setUTCDate(newDate[0]);
      date.setUTCHours(newDate[1]);
      date.setUTCMinutes(newDate[2]);
      this.message.dtMessage = date;
    }
  }

  datesEqual(first, second) {
    first = new Date(first);
    second = new Date(second);
    let res = (first.getFullYear() === second.getFullYear() &&
          first.getMonth() === second.getMonth() &&
          first.getDate() === second.getDate());
    console.log(first, second, res);
    return res;
  }

  getAirlinesName(): string {
    let names = '';
    this.filterParams.airlineValue.forEach(element => {
      if (names == '') {
        names = this.getById(this.airlines, element).iata;
      } else {
        names += ', ' + this.getById(this.airlines, element).iata;
      }
    });
    return names;
  }

  getTypesName(): string {
    let names = '';
    this.filterParams.typeValue.forEach(element => {
      if (names == '') {
        if (this.globalSettings.language == 'en') {
          names = this.getById(this.types, element).nameEnglish;
        } else {
          names = this.getById(this.types, element).nameLocal;
        }
      } else {
        if (this.globalSettings.language == 'en') {
          names += ', ' + this.getById(this.types, element).nameEnglish;
        } else {
          names += ', ' + this.getById(this.types, element).nameLocal;
        }
      }
    });
    return names;
  }

  getChannelsName(): string {
    let names = '';
    this.filterParams.channelValue.forEach(element => {
      if (names == '') {
        names = this.getById(this.channels, element).name;
      } else {
        names += ', ' + this.getById(this.channels, element).name;
      }
    });
    return names;
  }

  getRoutesName(): string {
    let names = '';
    this.filterParams.routeValue.forEach(element => {
      if (names == '') {
        names = this.getById(this.airport, element).iata;
      } else {
        names += ', ' + this.getById(this.airport, element).iata;
      }
    });
    return names;
  }

  getUrgenciesName(): string {
    let names = '';
    this.filterParams.urgencyValue.forEach(element => {
      if (names == '') {
        if (this.globalSettings.language == 'en') {
          names = this.getById(this.urgencies, element).nameEnglish;
        } else {
          names = this.getById(this.urgencies, element).nameLocal;
        }
      } else {
        if (this.globalSettings.language == 'en') {
          names += ', ' + this.getById(this.urgencies, element).nameEnglish;
        } else {
          names += ', ' + this.getById(this.urgencies, element).nameLocal;
        }
      }
    });
    return names;
  }

  getRowsForTextArea(): number {
    if (this.messagePanel == 'small' || !this.isNewMessage) {
      return 20;
    } else {
      return 11;
    }
  }

  clearErrorMess() {
    this.logger.info('clearErrorMess',
                     new ECSEvent(ModuleName.ComMan,
                                  'main.clearErrorMess',
                                  'click',
                                  '',
                                  EventProvider.UI));
    this.errorMessage = '';
    this.errorComman = '';
    this.errorType = '';
    this.userAction = '';
    this.userAnswer = null;
    this.loading = false;
  }

  get urgencyNameSelectedMessage(): string {
    if (isCyrillic(this.message.sender)) {
      return this.selectedMessage.urgencyNameLocal;
    } else {
      return this.selectedMessage.urgencyNameEnglish;
    }
  }

  setTextSize(value) {
    this.logger.info('setTextSize',
                      new ECSEvent(ModuleName.ComMan,
                                  'main.setTextSize',
                                  'change-size',
                                  value,
                                  EventProvider.UI));
    this.textSize = value;
  }

  copyToBuffer() {
    let target = document.getElementById('detailRecipients');
    if (document.createRange) {
      let rng = document.createRange();
      rng.selectNode(target)
      let sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(rng);
      document.execCommand('copy');
      sel.removeAllRanges();
      let copied = document.getElementById('detailCopied');
      copied.classList.remove('d-none');
      setTimeout(() => {
        copied.classList.add('d-none');
      }, 3000)
    }
  }

  displayError(err) {
    this.loading = false;
    if (err.type) {
      this.errorType = err.type;
      this.errorComman = err.message;
      this.logger.error('displayError', new ECSEvent(ModuleName.ComMan,
                                              'main.displayError',
                                              'call',
                                              err,
                                              EventProvider.APPLICATION,
                                              'Error: ' + err.message + '\ndetail:' + err.detail));
      this.userAction = '';
    } else {
      this.errorComman = err;
    }
  }

  @HostListener('window:keydown.arrowdown', ['$event'])
	public moveDown($event) {
    // При открытых модальных окнах, игнорировать событие, т.к. мешает набору текста
    if (!this.modalService.hasOpenModals()) {
		  $event.preventDefault();
      let element = this.messageTrActive.srcElement.nextElementSibling;
      if (element) {
        element.focus();
      }
    }
	}

  @HostListener('window:keydown.arrowup', ['$event'])
	public moveUp($event) {
		// При открытых модальных окнах, игнорировать событие, т.к. мешает набору текста
    if (!this.modalService.hasOpenModals()) {
      $event.preventDefault();
      let element = this.messageTrActive.srcElement.previousElementSibling;
      if (element) {
        element.focus();
      }
    }
  }
}
