export class ResourceType {
  constructor() {
    this.id = undefined;
    this.name = '';
    this.dtRange = [new Date(), new Date(2099, 1, 1, 23, 59)];
  }

  id: number;
  name: string;
  classId: number;
  className: string;
  dtRange: Array<Date>;
  lastupdate: Date;

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0] !== null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    this.dtRange[0] = value;
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1] !== null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    this.dtRange[1] = value;
  }
}

export class Operation {
  constructor() {
    this.id = undefined;
    this.name = '';
    this.versions = [new OperationVersion()];
    this.dtRange = [new Date(), new Date(2099, 1, 1, 23, 59)];
  }
  id: number;
  name: string;
  versions: Array<OperationVersion>;
  dtRange: Array<Date>;
  lastupdate: Date;

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0] !== null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    this.dtRange[0] = value;
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1] !== null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    this.dtRange[1] = value;
  }
}

export class OperationVersion {
  constructor() {
    this.id = 0;
    this.duration = null;
    this.description = '';
    this.resources = [new OperationResource()];
  }
  id: number;
  duration: number;
  description: string;
  resources: Array<OperationResource>;
}

export class OperationResource {
  constructor() {
    this.typeId = undefined;
    this.duration = null;
    this.amount = null;
  }
  typeId: number;
  typeName: string;
  duration: number;
  amount: number;
}

export class Modification {
  constructor() {
    this.id = undefined;
    this.modifiers = [new ModificationModifiers()];
    this.dtRange = [new Date(), new Date(2099, 1, 1, 23, 59)];
    this.name = '';
  }

  id: number;
  name: string;
  modifiers: Array<ModificationModifiers>;
  dtRange: Array<Date>;
  lastupdate: Date;

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0] !== null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    this.dtRange[0] = value;
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1] !== null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    this.dtRange[1] = value;
  }
}

export class ModificationModifiers {
  constructor() {
    this.id = null;
    this.value = null;
    this.name = [null, null];
  }
  id: number;
  name: Array<string>;
  value: number;

  get nameEnglish(): string {
    if ((this.name) && (this.name.length >= 1)) {
      return this.name[0];
    } else {
      return null;
    }
  }
  get nameLocal(): string {
    if ((this.name) && (this.name.length === 2)) {
      return this.name[1];
    } else {
      return null;
    }
  }
}

export class Reference {
  id: number;
  name: Array<string>;

  get nameEnglish(): string {
    if ((this.name) && (this.name.length >= 1)) {
      return this.name[0];
    } else {
      return null;
    }
  }
  get nameLocal(): string {
    if ((this.name) && (this.name.length === 2)) {
      return this.name[1];
    } else {
      return null;
    }
  }
}

export class ServiceSchedule {
  constructor() {
    this.modification = new Modification();
    this.description = '';
    this.processes = [new ServiceSchedulesProcesses()];
    this.dtRange = [new Date(), new Date(2099, 1, 1, 23, 59)];
  }
  id: number;
  modification: Modification;
  description: string;
  serviceScheduleTypeId: number;
  serviceScheduleTypeName: string;
  time: number;
  processes: Array<ServiceSchedulesProcesses>;
  dtRange: Array<Date>;

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0] !== null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    this.dtRange[0] = value;
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1] !== null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    this.dtRange[1] = value;
  }
}

export class ServiceSchedulesProcesses {
  constructor() {
    this.id = null;
    this.name = '';
    this.start = null;
    this.finish = null;
    // this.operation = new OperationVersion();
  }
  id: number;
  name: string;
  start: number;
  finish: number;
  // operation: OperationVersion;

  get nameEnglish(): string {
    if ((this.name) && (this.name.length >= 1)) {
      return this.name[0];
    } else {
      return null;
    }
  }
  get nameLocal(): string {
    if ((this.name) && (this.name.length === 2)) {
      return this.name[1];
    } else {
      return null;
    }
  }
}

export class AllReference {
  constructor() {
    this.aircraft_types = [];
    this.aircraft_kinds = [];
    this.aircraft_types = [];
    this.airlines = [];
    this.airports = [];
    this.countries = [];
    this.geo_types = [];
    this.ladder_types = [];
    this.movement_types = [];
    this.regularities = [];
    this.stand_types = [];
    this.resource_classes = []
  }
  aircraft_classes: Array<Reference>;
  aircraft_kinds: Array<Reference>;
  aircraft_types: Array<Reference>;
  airlines: Array<Reference>;
  airports: Array<Reference>;
  countries: Array<Reference>;
  geo_types: Array<Reference>;
  ladder_types: Array<Reference>;
  movement_types: Array<Reference>;
  regularities: Array<Reference>;
  stand_types: Array<Reference>;
  resource_classes: Array<Reference>;
}

export class Resource {
  constructor() {
    this.id = undefined;
    this.name = ['', ''];
    this.typeId = null;
    this.typeName = '';
    this.divisionId = null;
    this.divisionName = '';
    this.classId = null;
    this.dtRange = [new Date(), new Date(2099, 1, 1, 23, 59)];
  }

  id: number;
  name: Array<string>;
  typeId: number;
  typeName: string;
  divisionId: number;
  divisionName: string;
  classId: number;
  dtRange: Array<Date>;
  lastupdate: Date;

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0] !== null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    this.dtRange[0] = value;
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1] !== null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    this.dtRange[1] = value;
  }

  get nameFirst(): string {
    if ((this.name) && (this.name.length >= 1)) {
      return this.name[0];
    } else {
      return null;
    }
  }

  set nameFirst(value: string) {
    this.name[0] = value;
  }

  get nameSecond(): string {
    if ((this.name) && (this.name.length === 2)) {
      return this.name[1];
    } else {
      return null;
    }
  }

  set nameSecond(value: string) {
    this.name[1] = value;
  }

  get names(): string {
    if (this.name) {
      return this.name.join(' ');
    } else {
      return null;
    }
  }
}

export class Division {
  constructor() {
    this.id = undefined;
    this.name = '';
    this.parentId = null;
    this.dtRange = [new Date(), new Date(2099, 1, 1, 23, 59)];
  }

  id: number;
  name: string;
  parentId: number;
  dtRange: Array<Date>;
  lastupdate: Date;

  get dtRangeStart(): Date {
    if ((this.dtRange) && (this.dtRange.length >= 1) && this.dtRange[0] !== null) {
      return new Date(this.dtRange[0]);
    } else {
      return null;
    }
  }

  set dtRangeStart(value: Date) {
    this.dtRange[0] = value;
  }

  get dtRangeFinish(): Date {
    if ((this.dtRange) && (this.dtRange.length === 2) && this.dtRange[1] !== null) {
      return new Date(this.dtRange[1]);
    } else {
      return null;
    }
  }

  set dtRangeFinish(value: Date) {
    this.dtRange[1] = value;
  }
}
