import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs/Rx";
import { WebsocketService } from "./websocket.service";

//const CHAT_URL = "wss://echo.websocket.org";
const CHAT_URL = "ws://localhost:8080";

export interface Message {
  typeMessage: string;
  message: string;
}

export interface SendConfigWSS {
  lang: string;
  passportScanner: string;
};

@Injectable()
export class ChatService {
  public messages: Subject<Message>;
  public configWSS: Subject<SendConfigWSS>;

  constructor(wsService: WebsocketService) {

    this.messages = <Subject<Message>>wsService.connect(CHAT_URL).map(
      (response: MessageEvent): Message => {
        let data = JSON.parse(response.data);

        return {
          typeMessage: data.typeMessage,
          message: data.message
        };
      }
    );

    this.configWSS = <Subject<SendConfigWSS>>wsService.connect(CHAT_URL).map(
      (response: MessageEvent): SendConfigWSS => {
        let data = JSON.parse(response.data);

        return {
          lang: data.lang,
          passportScanner: data.passportScanner
        };
      }
    );
  }
}
