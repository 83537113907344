<div class="row border-bottom py-2 justify-content-between font-small">
  <div class="col-auto">
    <button class="btn btn-sm btn-blue mr-2"
            data-toggle="modal"
            data-target=".statement-card"
            (click)="createStatement(modalStatement)"
            i18n="laf|Main - control panel@@laf.main.controlPanel.button.baggageStatement">
      Baggage statement
    </button>
    <button class="btn btn-sm btn-blue"
            i18n="laf|Main - control panel@@laf.main.controlPanel.button.statementOnFind">Statement on the find</button>
  </div>
  <div class="col-auto d-flex">
    <button class="btn btn-ico btn-light-gray btn-xs mr-2"><span class="material-icons">filter_none</span></button>
    <button class="btn btn-ico btn-light-gray btn-xs mr-2"><span class="material-icons">flag</span></button>
    <div class="input-group mr-2">
      <div class="input-group-prepend">
        <span class="input-group-text font-small"
              i18n="laf|Main - control panel@@laf.main.controlPanel.displayPeriod">Displayed period</span>
      </div>
      <input type="text"
             disabled=""
             class="form-control form-control-sm"
             value="Current (-24h)"
             i18n-value="laf|Main - control panel@@laf.main.controlPanel.current24">
    </div>
  </div>
  <div class="col-4 pl-0">
    <div class="d-flex w-100">
      <div class="filter-block-wrap mr-2 w-100" (click)="filter.show = !filter.show">
        <div class="input-group-prepend">
          <span class="input-group-text border-0 rounded-0 py-1"
                i18n="laf|Main - control panel@@laf.main.controlPanel.find">Find</span>
          <div class="filter-elements d-flex pl-2 text-nowrap flex-wrap">
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.start && filter.params.finish">
              <i class="material-icons mx-1" (click)="clearFilterParametr('date', $event)">close</i>
              <span i18n="laf|Filter@@laf.main.filter.date">Date</span>&nbsp;{{filter.params.start | date:
              'dd.MM.yyyy'
              }}&nbsp;-&nbsp;{{filter.params.finish | date: 'dd.MM.yyyy'}}
            </div>
            <div class="filter-element mr-2 my-1" *ngIf="filter.params.status!=null">
              <i class="material-icons ml-2" (click)="clearFilterParametr('status', $event)">close</i>
              <span i18n="laf|Filter@@laf.main.filter.flight">Flight</span>&nbsp;{{filter.params.status}}
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center text-dark-gray">
          <i class="material-icons mx-1">arrow_drop_down</i>
        </div>
      </div>
      <!-- Filter -->
      <div class="filter-block border p-2 bg-white font-small" *ngIf="filter.show">
        <div class="container">
          <div class="row">
            <div class="col-3 px-1">
              <label for="filter-flight"
                     class="mr-2 mb-0"
                     i18n="laf|Filter@@laf.main.filter.filters">
                Filters
              </label>
            </div>
            <div class="col-8 px-1">
              <select id="filter-name"
                      class="form-control form-control-sm mr-2 py-0"
                      name="filter-name"
                      [value]="">
                <option [value]="-1">
                </option>
              </select>
            </div>
            <div class="col-1 px-1">
              <button class="btn btn-h-25 btn-ico btn-light-gray">
                <i class="material-icons">delete</i>
              </button>
            </div>
          </div>
          <hr />
          <div class="row pb-2">
            <div class="col-3 px-1">
              <label for="filter-aftn-number"
                     class="mr-2 mb-0"
                     i18n="laf|Filter@@laf.main.filter.field.statementNumber">
                Statement number
              </label>
            </div>
            <div class="col-9 px-1" *ngIf="1; else filterStatementLoad">
              <ng-select class="custom-sm p-0"
                          placeholder="WorldTracer number or other"
                          i18n-placeholder="laf|Filter@@laf.main.filter.field.statementNumber.placeholder">
                <ng-template ng-tag-tmp let-search="searchTerm">
                  <b i18n="laf|Filter@@laf.main.filter.field.statementNumberAdd">Add</b>:
                </ng-template>
              </ng-select>
            </div>
            <ng-template #filterStatementLoad>
              <div class="col-9 px-1">
                <div class="d-flex align-items-center pl-2 bg-light-gray rounded">
                  <div class="spinner-border spinner-border-sm float-left"
                       role="status"
                       aria-hidden="true"></div>
                  <div class="float-left pl-2"
                       i18n="laf|Filter@@laf.main.filter.field.statementNumberMessage">
                    Parsing an statement string...
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
          <div class="row pb-2">
            <div class="col-3 px-1">
              <label for="filter-flight"
                     class="mr-2 mb-0"
                     i18n="laf|Filter@@laf.main.filter.field.baggageTag">
                Baggage tag
              </label>
            </div>
            <div class="col-9 px-1" *ngIf="1; else filterBaggageTagLoad">
                <ng-select #filterBaggageTagInputCatch
                           class="custom-sm p-0"
                           [multiple]="true"
                           [isOpen]= "false"
                           placeholder="Baggage tag numbers separated by a space"
                           i18n-placeholder="laf|Filter@@laf.main.filter.field.baggageTag.placeholder">
                  <ng-template ng-tag-tmp let-search="searchTerm">
                    <b i18n="laf|Filter@@laf.main.filter.field.baggageTagAdd">Add:</b>&nbsp;{{search}}
                  </ng-template>
                </ng-select>
            </div>
            <ng-template #filterBaggageTagLoad>
              <div class="col-9 px-1">
                <div class="d-flex align-items-center pl-2 bg-light-gray rounded">
                  <div class="spinner-border spinner-border-sm float-left"
                       role="status"
                       aria-hidden="true"></div>
                  <div class="float-left pl-2"
                       i18n="laf|Filter@@laf.main.filter.field.baggageTagMessage">
                    Parsing an baggage tag numbers string...
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
          <div class="row pb-2">
            <div class="col-3 px-1">
              <label for="filter-status-eq"
                     class="mr-2 mb-0"
                     i18n="laf|Filter@@laf.main.filter.field.status">
                Status
              </label>
            </div>
            <div class="col-3 px-1">
              <select id="filter-status-eq"
                      class="form-control form-control-sm mr-2 py-0"
                      name="filter-status-eq">
                <option value=""
                        i18n="laf|Filter@@laf.main.filter.field.statusEqual">
                  Equal
                </option>
                <option value="!"
                        i18n="laf|Filter@@laf.main.filter.field.statusNotEqual">
                  Not equal
                </option>
              </select>
            </div>
            <div class="col-6 px-1">
              <ng-select class="custom-sm p-0"
                         [virtualScroll]="true"
                         [multiple]="true"
                         [hideSelected]="true">
                <ng-template ng-option-tmp let-item="item">
                  -
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="row pb-2">
            <div class="col-3 px-1">
              <label for="filter-reason-eq"
                     class="mr-2 mb-0"
                     i18n="laf|Filter@@laf.main.filter.field.reason">
                Reason 
              </label>
            </div>
            <div class="col-3 px-1">
              <select id="filter-reason-eq"
                      class="form-control form-control-sm mr-2 py-0"
                      name="filter-reason-eq">
                <option value=""
                        i18n="laf|Filter@@laf.main.filter.field.reasonEqual">
                  Equal
                </option>
                <option value="!"
                        i18n="laf|Filter@@laf.main.filter.field.reasonNotEqual">
                  Not equal
                </option>
              </select>
            </div>
            <div class="col-6 px-1">
              <ng-select class="custom-sm p-0"
                         [virtualScroll]="true"
                         [multiple]="true"
                         [hideSelected]="true">
                <ng-template ng-option-tmp let-item="item">
                  -
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="row pb-2">
            <div class="col-3 px-1">
              <label for="filter-passenger-name"
                     class="mr-2 mb-0"
                     i18n="laf|Filter@@laf.main.filter.field.passengerName">
                Passenger name
              </label>
            </div>
            <div class="col-9 px-1">
              <input id="filter-passenger-name"
                     class="form-control form-control-sm"
                     name="filter-passenger-name"/>
            </div>
          </div>
          <div class="row pb-2">
            <div class="col-3 px-1">
              <label for="filter-pnr"
                     class="mr-2 mb-0">
                PNR
              </label>
            </div>
            <div class="col-9 px-1">
              <input id="filter-pnr"
                     class="form-control form-control-sm"
                     name="filter-pnr"/>
            </div>
          </div>
          <div class="row pb-2">
            <div class="col-3 px-1">
              <label for="filter-recipient"
                     class="mr-2 mb-0"
                     i18n="laf|Filter@@laf.main.filter.field.route">
                Route
              </label>
            </div>
            <div class="col-3 px-1">
              <select id="filter-route-eq"
                      class="form-control form-control-sm mr-2 py-0"
                      name="filter-route-eq">
                <option value=""
                        i18n="laf|Filter@@laf.main.filter.field.routeContain">
                  Contain
                </option>
                <option value="!"
                        i18n="laf|Filter@@laf.main.filter.field.routeNotContain">
                  Not contain
                </option>
              </select>
            </div>

            <div class="col-6 px-1" *ngIf="1; else filterRouteLoad">
              <ng-select class="custom-sm p-0"
                         [multiple]="true"
                         [isOpen]="false"
                         placeholder="Enter airport separated by a space"
                         i18n-placeholder="laf|Filter@@laf.main.filter.field.route.placeholder">
                <ng-template ng-tag-tmp let-search="searchTerm">
                  <b i18n="laf|Filter@@laf.main.filter.field.routeAdd">Add:</b>&nbsp;
                </ng-template>
              </ng-select>
            </div>
            <ng-template #filterRouteLoad>
              <div class="col-6 px-1">
                <div class="d-flex align-items-center pl-2 bg-light-gray rounded">
                  <div class="spinner-border spinner-border-sm float-left"
                      role="status"
                      aria-hidden="true"></div>
                  <div class="float-left pl-2"
                      i18n="laf|Filter@@laf.main.filter.field.routeMessage">
                    Parsing an route string...
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
          <div class="row pb-2">
            <div class="col-3 px-1">
              <label for="filter-flight"
                     class="mr-2 mb-0"
                     i18n="laf|Filter@@laf.main.filter.field.flightNumber">
                Flight number
              </label>
            </div>
            <div class="col-9 px-1" *ngIf="1; else filterFlightNumberLoad">
                <ng-select #filterFlightNumberInputCatch
                           class="custom-sm p-0"
                           [multiple]="true"
                           [isOpen]= "false"
                           placeholder="Enter flight numbers separated by a space"
                           i18n-placeholder="laf|Filter@@laf.main.filter.field.flightNumber.placeholder">
                  <ng-template ng-tag-tmp let-search="searchTerm">
                    <b i18n="laf|Filter@@laf.main.filter.field.flightNumberAdd">Add:</b>&nbsp;{{search}}
                  </ng-template>
                </ng-select>
            </div>
            <ng-template #filterFlightNumberLoad>
              <div class="col-9 px-1">
                <div class="d-flex align-items-center pl-2 bg-light-gray rounded">
                  <div class="spinner-border spinner-border-sm float-left"
                       role="status"
                       aria-hidden="true"></div>
                  <div class="float-left pl-2"
                       i18n="laf|Filter@@laf.main.filter.field.flightNumberMessage">
                    Parsing an flight numbers string...
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
          <div class="row pb-2">
            <div class="col-3 px-1">
              <label class="mr-2 mb-0"
                     i18n="laf|Filter@@laf.main.filter.field.baggageDropOffPoint">Drop-off point
              </label>
            </div>
            <div class="col-3 px-1">
              <select class="form-control form-control-sm py-0">
                <option value=""
                        i18n="laf|Filter@@laf.main.filter.field.baggageDropOffPointContain">
                  Contain
                </option>
                <option value="!"
                        i18n="laf|Filter@@laf.main.filter.field.baggageDropOffPointNotContain">
                  Not contain
                </option>
              </select>
            </div>
            <div class="col-6 px-1">
              <input class="form-control form-control-sm"/>
            </div>
          </div>
          <div class="row pb-2">
            <div class="col-3 px-1">
              <label class="mr-2 mb-0"
                     i18n="laf|Filter@@laf.main.filter.field.baggageType">Type
              </label>
            </div>
            <div class="col-3 px-1">
              <select class="form-control form-control-sm py-0">
                <option value=""
                        i18n="laf|Filter@@laf.main.filter.field.baggageTypeContain">
                  Contain
                </option>
                <option value="!"
                        i18n="laf|Filter@@laf.main.filter.field.baggageTypeNotContain">
                  Not contain
                </option>
              </select>
            </div>
            <div class="col-6 px-1">
              <input class="form-control form-control-sm"/>
            </div>
          </div>
          <div class="row pb-2">
            <div class="col-3 px-1">
              <label class="mr-2 mb-0"
                     i18n="laf|Filter@@laf.main.filter.field.baggageColor">Color
              </label>
            </div>
            <div class="col-3 px-1">
              <select class="form-control form-control-sm py-0">
                <option value=""
                        i18n="laf|Filter@@laf.main.filter.field.baggageColorContain">
                  Contain
                </option>
                <option value="!"
                        i18n="laf|Filter@@laf.main.filter.field.baggageColorNotContain">
                  Not contain
                </option>
              </select>
            </div>
            <div class="col-6 px-1">
              <input class="form-control form-control-sm"/>
            </div>
          </div>
          <div class="row pb-2">
            <div class="col-3 px-1">
              <label class="mr-2 mb-0"
                     i18n="laf|Filter@@laf.main.filter.field.baggageMaterial">Material
              </label>
            </div>
            <div class="col-3 px-1">
              <select class="form-control form-control-sm py-0">
                <option value=""
                        i18n="laf|Filter@@laf.main.filter.field.baggageMaterialContain">
                  Contain
                </option>
                <option value="!"
                        i18n="laf|Filter@@laf.main.filter.field.baggageMaterialNotContain">
                  Not contain
                </option>
              </select>
            </div>
            <div class="col-6 px-1">
              <input class="form-control form-control-sm"/>
            </div>
          </div>
          <div class="row pb-2">
            <div class="col-3 px-1">
              <label class="mr-2 mb-0"
                     i18n="laf|Filter@@laf.main.filter.field.baggageExternalElements">External elements
              </label>
            </div>
            <div class="col-3 px-1">
              <select class="form-control form-control-sm py-0">
                <option value=""
                        i18n="laf|Filter@@laf.main.filter.field.baggageExternalElementsContain">
                  Contain
                </option>
                <option value="!"
                        i18n="laf|Filter@@laf.main.filter.field.baggageExternalElementsNotContain">
                  Not contain
                </option>
              </select>
            </div>
            <div class="col-6 px-1">
              <input class="form-control form-control-sm"/>
            </div>
          </div>
          <hr />
          <div class="row pb-2">
            <div class="col-3 px-1">
              <label for="filter-date-from"
                      class="mr-2 mb-0"
                      i18n="laf|Filter@@laf.main.filter.field.dateFrom">
                Date from
              </label>
            </div>
            <div class="col-3 px-1">
              <input type="time"
                      class="form-control mr-1"
                      #start_time>
            </div>
            <div class="col-6 px-1">
              <input type="date"
                     class="form-control"
                     name="filterParamsStartDate"
                     #start_date>
            </div>
          </div>
          <div class="row pb-2">
            <div class="col-3 px-1">
              <label for="filter-date-from"
                      class="mr-2 mb-0"
                      i18n="laf|Filter@@laf.main.filter.field.dateTo">
                Date to
              </label>
            </div>
            <div class="col-3 px-1">
              <input type="time"
                      class="form-control mr-1"
                      #finish_time>
            </div>
            <div class="col-6 px-1">
              <input type="date"
                      class="form-control"
                      #finish_date>
            </div>
          </div>
        </div>
        <hr />
        <button class="btn btn-gray-blue-dark btn-sm float-right ml-2"
                (click)="filter.show = !filter.show"
                i18n="laf|Filter@@laf.main.filter.button.close">
          Close
        </button>
        <button class="btn btn-blue btn-sm float-right ml-2"
                (click)="filterApp()"
                i18n="laf|Filter@@laf.main.filter.button.fing">
          Find
        </button>
        <button class="btn btn-blue btn-sm float-right"
                (click)="clearFilter()"
                i18n="laf|Filter@@laf.main.filter.button.clearAll">
          Clear all
        </button>
      </div>
      <button type="button"
              class="btn btn-xs btn-ico btn-light-gray"
              ngbTooltip="Filter on/off"
              i18n-ngbTooltip="laf|Filter@@laf.main.filter.button.filterOnOff"
              [ngClass]="{'btn-active' : filter.apply}"
              (click)="filterSwitch()">
        <i class="material-icons">filter_alt</i>
      </button>
    </div>
  </div>
</div>

<div class="row content">
  <div class="bg-light-gray border-right folders-block h-100 pb-5 col-xl-2 col-sm-3 col-12 px-0">
    <div class="d-flex align-items-center pointer p-2 header-group" (click)="display.activeWindow = 'tracingLuggage'"
      [ngClass]="{'bg-gray': display.activeWindow === 'tracingLuggage'}">
      <i class="material-icons arrow-menu" data-toggle="collapse"
        data-target="#tracingLuggageMenu">keyboard_arrow_down</i>
      <i class="material-icons mr-2 text-gray-blue-dark">work</i>
      <span i18n="laf|Left panel@@laf.main.leftPanel.baggageTracing">Baggage tracing</span>
      <span class="badge badge-dark badge-pill ml-auto">4</span>
    </div>
    <div class="collapse multi-collapse show" id="tracingLuggageMenu">
      <div class="mp-0 left-menu">
        <div class="pointer pl-2 ty-1 mt-1 mb-1">
          <div class="pt-2 pr-2 pb-2 pl-5 m-0">
            <p class="m-0 d-flex align-items-center">
              <span i18n="laf|Left panel@@laf.main.leftPanel.baggageTracing.lost">Lost</span>
              <span class="badge badge-dark badge-pill ml-auto">4</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="collapse multi-collapse show">
      <div class="mp-0 left-menu">
        <div class="pointer pl-2 ty-1 mt-1 mb-1">
          <div class="pt-2 pr-2 pb-2 pl-5 m-0">
            <p class="m-0 d-flex align-items-center">
              <span i18n="laf|Left panel@@laf.main.leftPanel.baggageTracing.found">Found</span>
              <span class="badge badge-dark badge-pill ml-auto">1</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="collapse multi-collapse show">
      <div class="mp-0 left-menu">
        <div class="pointer pl-2 ty-1 mt-1 mb-1">
          <div class="pt-2 pr-2 pb-2 pl-5 m-0">
            <p class="m-0 d-flex align-items-center">
              <span i18n="laf|Left panel@@laf.main.leftPanel.baggageTracing.damaged">Damaged</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="collapse multi-collapse show">
      <div class="mp-0 left-menu">
        <div class="pointer pl-2 ty-1 mt-1 mb-1">
          <div class="pt-2 pr-2 pb-2 pl-5 m-0">
            <p class="m-0 d-flex align-items-center">
              <span i18n="laf|Left panel@@laf.main.leftPanel.baggageTracing.deadline">Deadline</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center pointer p-2 header-group" (click)="display.activeWindow = 'lostThings'"
      [ngClass]="{'bg-gray': display.activeWindow === 'lostThings'}">
      <i class="material-icons arrow-menu opacity-0">keyboard_arrow_down</i>
      <i class="material-icons mr-2 text-gray-blue-dark">local_grocery_store</i>
      <span i18n="laf|Left panel@@laf.main.leftPanel.lostItems">Lost items</span>
      <span class="badge badge-dark badge-pill ml-auto">7</span>
    </div>

  </div>

  <div class="col-xl-10 p-0 col-sm-9 col-12 h-100 bg-white" *ngIf="display.activeWindow === 'tracingLuggage'">
    <div class="bg-light-gray border-bottom py-1 px-3 font-small">
      <div class="row justify-content-between">
        <div class="col-auto">
          <p class="m-0"><span i18n="laf|Baggage@@laf.baggage.table.status.new">New</span>: <span class="font-weight-bold mr-2">4</span>&nbsp;<span i18n="laf|Baggage@@laf.baggage.table.status.inProgress">In progress</span>: <span
              class="font-weight-bold">27</span></p>
        </div>
        <div class="col-auto d-flex align-items-center">
          <span class="text-nowrap line-height-1 mr-2" i18n="laf|Baggage@@laf.baggage.table.status.statement">Statement status:</span>&nbsp;
          <div role="group" aria-label="" class="btn-group btn-group-sm w-100 mr-3">
            <button type="button"
                    class="btn btn-h-25 btn-light-gray"
                    i18n="laf|Baggage@@laf.baggage.table.status.button.new"> New </button>
            <button type="button"
                    class="btn btn-h-25 btn-light-gray"
                    i18n="laf|Baggage@@laf.baggage.table.status.button.inProgress"> In progress </button>
            <button type="button"
                    class="btn btn-h-25 btn-light-gray"
                    i18n="laf|Baggage@@laf.baggage.table.status.button.found"> Found </button>
            <button type="button"
                    class="btn btn-h-25 btn-light-gray"
                    i18n="laf|Baggage@@laf.baggage.table.status.button.delivery"> Delivery </button>
            <button type="button"
                    class="btn btn-h-25 btn-light-gray"
                    i18n="laf|Baggage@@laf.baggage.table.status.button.storage"> Storage </button>
            <button type="button"
                    class="btn btn-h-25 btn-light-gray"
                    i18n="laf|Baggage@@laf.baggage.table.status.button.issued"> Issued </button>
          </div>
          <div class="d-flex flex-nowrap mr-2 align-items-center per-page">
            <div class="text-nowrap line-height-1" i18n="laf|Baggage@@laf.baggage.table.status.perPage">Per page</div>
            <select id="perPage" class="form-control form-control-sm ml-2 per-page" name="perPage"
              [ngModel]="pagination.perPage" (change)="changePerPage(+$event.target.value)">
              <option *ngFor="let count of pagination.countsPerPage" [value]="count">
                {{count}}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <table class="table-striped table-border w-100 table-sticky-header font-small">
      <thead class="bg-gray text-center">
        <tr>
          <th></th>
          <th i18n="laf|Baggage@@laf.baggage.table.column.airport">Airport</th>
          <th i18n="laf|Baggage@@laf.baggage.table.column.flight">Flight</th>
          <th i18n="laf|Baggage@@laf.baggage.table.column.flightDate">Flight date</th>
          <th i18n="laf|Baggage@@laf.baggage.table.column.statement">Statement</th>
          <th i18n="laf|Baggage@@laf.baggage.table.column.statementDate">Date of the statement</th>
          <th i18n="laf|Baggage@@laf.baggage.table.column.reason">Reason</th>
          <th i18n="laf|Baggage@@laf.baggage.table.column.passenger">Passenger</th>
          <th i18n="laf|Baggage@@laf.baggage.table.column.baggage">Baggage tag</th>
          <th i18n="laf|Baggage@@laf.baggage.table.column.status">Status</th>
          <th i18n="laf|Baggage@@laf.baggage.table.column.search">Search</th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center" *ngFor="let statementItem of statements"
          (click)="loadStatement(statementItem.statementId)"
          (dblclick)="openStatement(statementItem.statementId, modalStatement)"
          [ngClass]="{'bg-gray': statementItem.statementId === statement.id }">
          <td></td>
          <td></td>
          <td>{{statementItem.arrival.flight}}</td>
          <td>{{statementItem.arrival.dt | date: 'dd.MM.yyyy'}}</td>
          <td>{{statementItem.statementName}}</td>
          <td>{{statementItem.dtInsert | date: 'dd.MM.yyyy'}}</td>
          <td>{{statementItem.baggage.reason}}</td>
          <td>{{statementItem.passenger}}</td>
          <td>{{statementItem.baggage.tag}}</td>
          <td>{{statementItem.baggage.status}}</td>
          <td>{{statementItem.baggage.wt}}</td>
        </tr>
      </tbody>
    </table>
    <div class="bottom-panel bg-light-gray border-top py-2 px-3" *ngIf="statement.id">
      <div class="row">
        <div class="col pr-0">
          <div class="d-flex justify-content-between align-items-center bottom-panel-header mb-1">
            <span class="font-weight-bold" i18n="laf|Baggage@@laf.baggage.statementInfo.statement">Statement</span>
            <div class="btn-group">
              <div class="dropdown">
                <button class="btn btn-xs dropdown-toggle btn-white" type="button" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  <i class="material-icons">assistant_photo</i>
                  <span i18n="laf|Baggage@@laf.baggage.statementInfo.statement.button.mark">Mark</span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownFlagsDetail">
                  <button *ngFor="let mark of marks" class="dropdown-item">
                    <i class="material-icons" [ngStyle]="{'color': mark }">assistant_photo</i>
                    <span>{{mark}}</span>
                  </button>
                  <div class="dropdown-divider"></div>
                  <button class="dropdown-item font-small">
                    <i class="material-icons" style="color: #5c5c5c;">assistant_photo</i>
                    <span i18n="laf|Baggage@@laf.baggage.statementInfo.statement.button.clear">Clear</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <table class="w-100 info-table font-small">
            <tr>
              <th i18n="laf|Baggage@@laf.baggage.statementInfo.table.number">Number</th>
              <td>{{statement.name}}</td>
            </tr>
            <tr i18n="laf|Baggage@@laf.baggage.statementInfo.table.flight">
              <th>Flight</th>
              <td>{{statement.route.arrival.flight}} <span>от</span> {{statement.route.arrival.dt | date: 'dd.MM.yyyy'}}
              </td>
            </tr>
            <tr>
              <th i18n="laf|Baggage@@laf.baggage.statementInfo.table.route">Route</th>
              <td>{{statement.fullRoute}}</td>
            </tr>
            <tr>
              <th i18n="laf|Baggage@@laf.baggage.statementInfo.table.reason">Reason</th>
              <td>Не прибыл багаж</td>
            </tr>
            <tr>
              <th i18n="laf|Baggage@@laf.baggage.statementInfo.table.note">Note</th>
              <td height="40">{{statement.note}}</td>
            </tr>
          </table>
        </div>
        <div class="col-5 pr-0">
          <div class="d-flex justify-content-between align-items-center bottom-panel-header mb-1">
            <span class="font-weight-bold" i18n="laf|Baggage@@laf.baggage.passengerInfo">Passenger</span>
          </div>
          <table class="w-100 info-table font-small">
            <tr>
              <th i18n="laf|Baggage@@laf.baggage.passengerInfo.table.name">Name</th>
              <td>{{statement.passenger.passenger}}</td>
            </tr>
            <tr>
              <th i18n="laf|Baggage@@laf.baggage.passengerInfo.table.phone">Phone</th>
              <td>{{statement.passenger.phone1}} {{statement.passenger.phone2}}</td>
            </tr>
            <tr>
              <th i18n="laf|Baggage@@laf.baggage.passengerInfo.table.email">Email</th>
              <td>{{statement.passenger.email}}</td>
            </tr>
            <tr>
              <th i18n="laf|Baggage@@laf.baggage.passengerInfo.table.address">Address</th>
              <td height="60">{{statement.passenger.address}}</td>
            </tr>
          </table>
        </div>
        <div class="col">
          <div class="d-flex justify-content-between align-items-center bottom-panel-header mb-1">
            <span class="font-weight-bold" i18n="laf|Baggage@@laf.baggage.baggageInfo">Baggage</span>
          </div>
          <table class="w-100 info-table font-small">
            <tr>
              <th i18n="laf|Baggage@@laf.baggage.baggageInfo.table.status">Status</th>
              <td>Новая заявка</td>
            </tr>
            <tr>
              <th i18n="laf|Baggage@@laf.baggage.baggageInfo.table.notification">Notification</th>
              <td>SMS, Email от 25.01.2021</td>
            </tr>
            <tr>
              <th i18n="laf|Baggage@@laf.baggage.baggageInfo.table.storage">Storage</th>
              <td>ВВЛ</td>
            </tr>
            <tr>
              <th i18n="laf|Baggage@@laf.baggage.baggageInfo.table.delivery">Delivery</th>
              <td height="60">Свердловская область, г. Екатеринбург, ул.Мамина-Сибиряка, дом 192, кв. 376</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-10 p-0 col-sm-9 col-12 h-100 bg-white" *ngIf="display.activeWindow === 'lostThings'">
    <div class="bg-light-gray border-bottom py-1 px-3 font-small">
      <div class="row justify-content-between">
        <div class="col-auto">
          <p class="m-0"><span i18n="laf|Lost item@@laf.lostItem.table.status.new">New:</span> <span class="font-weight-bold mr-2">2</span><span i18n="laf|Lost item@@laf.lostItem.table.status.inProgress"> In progress:</span>&nbsp;<span
              class="font-weight-bold">20</span></p>
        </div>
        <div class="col-auto">
          <div class="d-flex flex-nowrap mr-2 align-items-center per-page">
            <div class="text-nowrap line-height-1" i18n="laf|Lost item@@laf.lostItem.table.status.perPage">Per page</div>
            <select id="perPage" class="form-control form-control-sm ml-2 per-page" name="perPage"
              [ngModel]="pagination.perPage" (change)="changePerPage(+$event.target.value)">
              <option *ngFor="let count of pagination.countsPerPage" [value]="count">
                {{count}}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <table class="table-striped table-border w-100 table-sticky-header font-small">
      <thead class="bg-gray text-center">
        <tr>
          <th></th>
          <th i18n="laf|Lost item@@laf.lostItem.table.column.airport">Airport</th>
          <th i18n="laf|Lost item@@laf.lostItem.table.column.statement">Statement</th>
          <th i18n="laf|Lost item@@laf.lostItem.table.column.statementDate">Date of the statement</th>
          <th i18n="laf|Lost item@@laf.lostItem.table.column.flight">Flight</th>
          <th i18n="laf|Lost item@@laf.lostItem.table.column.flightDate">Flight date</th>
          <th i18n="laf|Lost item@@laf.lostItem.table.column.reason">Reason</th>
          <th i18n="laf|Lost item@@laf.lostItem.table.column.name">Name</th>
          <th i18n="laf|Lost item@@laf.lostItem.table.column.status">Status</th>
          <th i18n="laf|Lost item@@laf.lostItem.table.column.issued">Issued</th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center">
          <td width="35px"></td>
          <td>SVO</td>
          <td>S7 8792</td>
          <td>25.01.2021</td>
          <td>SVO-0121-1</td>
          <td>25.01.21</td>
          <td>Найденная вещь</td>
          <td>Телефон</td>
          <td>Новая заявка</td>
          <td></td>
        </tr>
        <tr class="text-center">
          <td width="35px"></td>
          <td>SVO</td>
          <td>S7 8792</td>
          <td>25.01.2021</td>
          <td>SVO-0121-1</td>
          <td>25.01.21</td>
          <td>Найденная вещь</td>
          <td>Телефон</td>
          <td>Новая заявка</td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <div class="bottom-panel bg-light-gray border-top py-2 px-3">
      <div class="row">
        <div class="col pr-0">
          <div class="d-flex justify-content-between align-items-center bottom-panel-header mb-1">
            <span class="font-weight-bold" i18n="laf|Lost item@@laf.lostItem.statementInfo">Statement</span>
            <div class="btn-group">
              <div class="dropdown">
                <button class="btn btn-xs dropdown-toggle btn-white" type="button" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  <i class="material-icons">assistant_photo</i>
                  <span i18n="laf|Lost item@@laf.lostItem.statementInfo.button.mark">Mark</span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownFlagsDetail">
                  <button *ngFor="let mark of marks" class="dropdown-item">
                    <i class="material-icons" [ngStyle]="{'color': mark }">assistant_photo</i>
                    <span>{{mark}}</span>
                  </button>
                  <div class="dropdown-divider"></div>
                  <button class="dropdown-item font-small">
                    <i class="material-icons" style="color: #5c5c5c;">assistant_photo</i>
                    <span  i18n="laf|Lost item@@laf.lostItem.statementInfo.button.clear">Clear</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <table class="w-100 info-table font-small">
            <tr>
              <th i18n="laf|Lost item@@laf.lostItem.statementInfo.number">Number</th>
              <td>SVO-1221-1 от 25.01.2021</td>
            </tr>
            <tr>
              <th i18n="laf|Lost item@@laf.lostItem.flight">Flight</th>
              <td>S7 8792 от 25.01.2021</td>
            </tr>
            <tr>
              <th i18n="laf|Lost item@@laf.lostItem.route">Route</th>
              <td>TJX-FRA-DME-SVO</td>
            </tr>
            <tr>
              <th i18n="laf|Lost item@@laf.lostItem.reason">Reason</th>
              <td>Найденная вещь</td>
            </tr>
            <tr>
              <th i18n="laf|Lost item@@laf.lostItem.note">Note</th>
              <td height="40"></td>
            </tr>
          </table>
        </div>
        <div class="col-5 pr-0">
          <div class="d-flex justify-content-between align-items-center bottom-panel-header mb-1">
            <span class="font-weight-bold" i18n="laf|Lost item@@laf.lostItem.passengerInfo">Passenger</span>
          </div>
          <table class="w-100 info-table font-small">
            <tr>
              <th i18n="laf|Lost item@@laf.lostItem.passengerInfo.name">Name</th>
              <td></td>
            </tr>
            <tr>
              <th i18n="laf|Lost item@@laf.lostItem.passengerInfo.phone">Phone</th>
              <td></td>
            </tr>
            <tr>
              <th i18n="laf|Lost item@@laf.lostItem.passengerInfo.email">Email</th>
              <td></td>
            </tr>
            <tr>
              <th i18n="laf|Lost item@@laf.lostItem.passengerInfo.address">Address</th>
              <td height="60"></td>
            </tr>
          </table>
        </div>
        <div class="col">
          <div class="d-flex justify-content-between align-items-center bottom-panel-header mb-1">
            <span class="font-weight-bold" i18n="laf|Lost item@@laf.lostItem.baggageInfo">Baggage</span>
          </div>
          <table class="w-100 info-table font-small">
            <tr>
              <th i18n="laf|Lost item@@laf.lostItem.status">Status</th>
              <td>Новая заявка</td>
            </tr>
            <tr>
              <th i18n="laf|Lost item@@laf.lostItem.baggageInfo.notification">Notification</th>
              <td>SMS, Email от 25.01.2021</td>
            </tr>
            <tr>
              <th i18n="laf|Lost item@@laf.lostItem.storage">Storage</th>
              <td>ВВЛ</td>
            </tr>
            <tr>
              <th i18n="laf|Lost item@@laf.lostItem.delivery">Delivery</th>
              <td height="60"></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<ng-template #modalStatement let-modal>
  <form #flightForm="ngForm">
    <div class="modal-content bg-light-gray">
      <div class="loading" *ngIf="display.loading">
        <div class="cssload-spin-box"></div>
      </div>
      <div class="modal-header">
        <h5 class="modal-title">
          <span i18n="laf|Baggage modal@@laf.modal.baggage">Baggage statement</span>
        </h5>
        <button type="button" class="close mp-0" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-panel bg-white p-2">
        <button class="btn btn-sm btn-light-gray mr-2 btn-ico">
          <span class="material-icons mr-2">receipt</span>
          <span i18n="laf|Baggage modal@@laf.modal.baggage.button.generateStatement">Generate statement</span>
        </button>
        <button class="btn btn-sm btn-light-gray mr-2 btn-ico">
          <span class="material-icons mr-2">find_in_page</span>
          <span i18n="laf|Baggage modal@@laf.modal.baggage.button.wantedStatement">Wanted statement</span>
        </button>
        <button class="btn btn-sm btn-light-gray mr-2 btn-ico">
          <span class="material-icons mr-2">local_shipping</span>
          <span i18n="laf|Baggage modal@@laf.modal.baggage.button.deliveryStatement">Delivery Statement</span>
        </button>
        <button class="btn btn-sm btn-light-gray mr-2 btn-ico">
          <span class="material-icons mr-2">notifications_active</span>
          <span i18n="laf|Baggage modal@@laf.modal.baggage.button.historyAndNotification">History and notifications</span>
        </button>
        <button class="btn btn-sm btn-light-gray btn-ico">
          <span class="material-icons mr-2">email</span>
          <span i18n="laf|Baggage modal@@laf.modal.baggage.button.telegrams">Telegrams</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-6 pr-0">
            <div class="card h-100">
              <div class="card-body p-3 bg-white rounded">
                <div class="d-flex align-items-center justify-content-between mb-2">
                  <p class="m-0 h6 card__header--important" i18n="laf|Baggage modal@@laf.modal.baggage.statement">Statement</p>
                  <button class="btn btn-ico btn-light-gray btn-xs">
                    <span class="material-icons">filter_none</span>
                  </button>
                </div>
                <div class="row mb-3">
                  <div class="col-6 pr-0 mb-2">
                    <input type="text" disabled class="form-control custom-sm font-weight-bold"
                      value="SVO-S7-1605221">
                  </div>
                  <div class="col-6 mb-2">
                    <div class="input-group">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.date">Date</span>
                      </div>
                      <input type="text" class="form-control custom-sm"
                            value="{{statement.route.arrival.dt | date: 'dd.MM.yyyy HH:mm'}}"
                            disabled />
                    </div>
                  </div>
                  <div class="col-12 mb-2">
                    <div class="input-group">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.note">Note</span>
                      </div>
                      <textarea class="form-control form-control-sm" rows="2"
                              [(ngModel)]="statement.note" name="statementNote">{{statement.note}}</textarea>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group-prepend">
                      <div class="custom-control custom-switch d-flex align-items-center ml-2"
                        style="min-width: fit-content;">
                        <input type="checkbox" class="custom-control-input" id="notifyPassenger" name="notifyPassenger"
                          [checked]="statement.notifications.enabled" (click)="checkNotifications()">
                        <label class="custom-control-label" for="notifyPassenger" i18n="laf|Baggage modal@@laf.modal.baggage.statement.notifyPassenger">
                          Notify passenger
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.method">Method</span>
                      </div>
                      <ng-select class="w-100 custom-sm"
                                  multiple="true"
                                  bindValue="id"
                                  bindLabel="name"
                                  [(ngModel)]="statement.notifications.typesId"
                                  name="statementWay"
                                  [items]="adminData.notifications"></ng-select>
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <p class="mb-2 h6 border-top py-2 font-weight-bold" i18n="laf|Baggage modal@@laf.modal.baggage.statement.route">Route</p>
                  <div class="accordion" id="accordionArrivalFlight">
                    <div class="card">
                      <div class="card-header p-0" id="headingOne">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                            type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                            aria-controls="collapseOne">
                            <span class="text-light-blue" i18n="laf|Baggage modal@@laf.modal.baggage.statement.route.arrivalFlight">Arrival flight</span>
                          </button>
                        </h2>
                      </div>
                      <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                        data-parent="#accordionArrivalFlight">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-6 mb-2">
                              <div class="input-group">
                                <div class="input-group-prepend custom-sm">
                                  <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.route.arrivalFlight.flight">Flight</span>
                                </div>
                                <input type="text" class="form-control custom-sm"
                                      [(ngModel)]="statement.route.arrival.flight"
                                      name="statementFlight" />
                              </div>
                            </div>
                            <div class="col-6 mb-2">
                              <div class="input-group">
                                <div class="input-group-prepend custom-sm">
                                  <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.route.arrivalFlight.date">Date</span>
                                </div>
                                <input type="date" class="form-control"
                                        [ngModel]="statement.route.arrival.dt | date: 'yyyy-MM-dd'"
                                        (input)="statement.route.arrival.dt = parseDate($event.target.value, statementTime.value)"
                                        name="statementDate"
                                        #statementDate="ngModel" required />
                                <input type="time" class="form-control" style="max-width: 100px"
                                        [ngModel]="statement.route.arrival.dt | date: 'HH:mm'"
                                        (input)="statement.route.arrival.dt = parseDate(statementDate.value, $event.target.value)"
                                        name="statementTime"
                                        #statementTime="ngModel" required />
                              </div>
                            </div>
                            <div class="col-6 mb-2">
                              <div class="input-group flex-nowrap">
                                <div class="input-group-prepend custom-sm">
                                  <span class="input-group-text font-small py-0"
                                        i18n="laf|Baggage modal@@laf.modal.baggage.statement.route.arrivalFlight.arrival">Arrival</span>
                                </div>
                                <ng-select class="w-100 custom-sm"
                                          [items]="references.airports"
                                          bindLabel="iata"
                                          bindValue="iata"
                                          [(ngModel)]="statement.route.arrival.arrival"
                                          #filterParamsAirport="ngModel"
                                          name="statementArrival"
                                          [virtualScroll]="true"
                                          [loading]="selectLoadAnimation.airports"
                                          (scroll)="onScrollNgSelect($event, 'airports')"
                                          (scrollToEnd)="onScrollToEndNgSelect('airports')"
                                          [searchFn]="customSelectSearch"
                                          appendTo="body">
                                  <ng-template ng-option-tmp let-item="item">
                                    <div class="font-small">
                                      <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                                    </div>
                                  </ng-template>
                                </ng-select>
                              </div>
                            </div>
                            <div class="col-6 mb-2">
                              <div class="input-group flex-nowrap">
                                <div class="input-group-prepend custom-sm">
                                  <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.route.arrivalFlight.departure">Departure</span>
                                </div>
                                <ng-select class="w-100 custom-sm"
                                          [items]="references.airports"
                                          bindLabel="iata"
                                          bindValue="iata"
                                          [(ngModel)]="statement.route.arrival.departure"
                                          #filterParamsAirport="ngModel"
                                          name="statementDeparture"
                                          [virtualScroll]="true"
                                          [loading]="selectLoadAnimation.airports"
                                          (scroll)="onScrollNgSelect($event, 'airports')"
                                          (scrollToEnd)="onScrollToEndNgSelect('airports')"
                                          [searchFn]="customSelectSearch"
                                          appendTo="body">
                                  <ng-template ng-option-tmp let-item="item">
                                    <div class="font-small">
                                      <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                                    </div>
                                  </ng-template>
                                </ng-select>
                              </div>
                            </div>
                            <div class="col-6 mb-2">
                              <div class="input-group">
                                <div class="input-group-prepend custom-sm">
                                  <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.route.arrivalFlight.ticketNumber">Ticket number</span>
                                </div>
                                <input type="text" class="form-control custom-sm" name="statementTicket" [(ngModel)]="statement.route.arrival.ticket" />
                              </div>
                            </div>
                            <div class="col-6 mb-2">
                              <div class="input-group">
                                <div class="input-group-prepend custom-sm">
                                  <span class="input-group-text font-small py-0">PNR</span>
                                </div>
                                <input type="text" class="form-control custom-sm" name="statementPnr" [(ngModel)]="statement.route.arrival.pnr" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header p-0" id="headingTwo">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                            type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                            aria-controls="collapseTwo">
                            <span class="text-light-blue" i18n="laf|Baggage modal@@laf.modal.baggage.statement.routeFull">Full route by ticket</span>
                          </button>
                        </h2>
                      </div>
                      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                        data-parent="#accordionArrivalFlight">
                        <div class="card-body">
                          <div class="input-group flex-nowrap mb-2">
                            <div class="input-group-prepend custom-sm">
                              <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.routeFull.route">Route</span>
                            </div>
                            <ng-select class="w-100 custom-sm p-0"
                                      [items]="references.airports"
                                      bindLabel="iata"
                                      bindValue="id"
                                      [multiple]="true"
                                      [(ngModel)]="statement.route.full.routeId"
                                      #statementAirport="ngModel"
                                      name="statementAirport"
                                      [virtualScroll]="true"
                                      [loading]="selectLoadAnimation.airports"
                                      (scroll)="onScrollNgSelect($event, 'airports')"
                                      (scrollToEnd)="onScrollToEndNgSelect('airports')"
                                      [searchFn]="customSelectSearch"
                                      [addTag]="selectRouteFullItinerary()"
                                      appendTo="body">
                              <ng-template ng-option-tmp let-item="item">
                                <div class="font-small">
                                  <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                                </div>
                              </ng-template>
                            </ng-select>
                          </div>
                          <div class="row mb-2" *ngFor="let flightItem of statement.route.full.flights; let i = index">
                            <div class="col-4">
                              <div class="input-group flex-nowrap">
                                <div class="input-group-prepend custom-sm">
                                  <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.routeFull.airline">Airline</span>
                                </div>
                                <ng-select class="w-100 custom-sm p-0"
                                          [items]="references.airlines"
                                          bindLabel="iata"
                                          bindValue="id"
                                          [multiple]="true"
                                          [(ngModel)]="flightItem.airlineId"
                                          name="statementAirline_{{i}}"
                                          [virtualScroll]="true"
                                          [loading]="selectLoadAnimation.airlines"
                                          (scroll)="onScrollNgSelect($event, 'airlines')"
                                          (scrollToEnd)="onScrollToEndNgSelect('airlines')"
                                          [searchFn]="customSelectSearch"
                                          appendTo="body">
                                  <ng-template ng-option-tmp let-item="item">
                                    <div class="font-small">
                                      <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                                    </div>
                                  </ng-template>
                                </ng-select>
                                <!-- <input type="number" class="form-control custom-sm" value="AT" /> -->
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="input-group">
                                <div class="input-group-prepend custom-sm">
                                  <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.routeFull.flight">Flight</span>
                                </div>
                                <input type="number" class="form-control custom-sm" name="flightItemFlight_{{i}}" [(ngModel)]="flightItem.flight" />
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="input-group">
                                <div class="input-group-prepend custom-sm">
                                  <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.routeFull.date">Date</span>
                                </div>
                                <input type="date" class="form-control"
                                        [ngModel]="flightItem.dt | date: 'yyyy-MM-dd'"
                                        (input)="flightItem.dt = parseDate($event.target.value)"
                                        name="flightItemDate_{{i}}"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p class="mb-2 h6 border-top py-2 font-weight-bold" i18n="laf|Baggage modal@@laf.modal.baggage.statement.passenger">Passenger</p>
                  <div class="row">
                    <div class="col-12 mb-2">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.passenger.name">Full name</span>
                        </div>
                        <input type="text" class="form-control custom-sm"
                              [(ngModel)]="statement.passenger.passenger"
                              name="statementPassengerName" />
                      </div>
                    </div>
                    <div class="col-6 mb-2">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.passenger.phone1">Phone 1</span>
                        </div>
                        <input type="text" class="form-control custom-sm"
                              [(ngModel)]="statement.passenger.phone1"
                              name="statementPassengerPhone1" />
                      </div>
                    </div>
                    <div class="col-6 mb-2">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.passenger.phone2">Phone 2</span>
                        </div>
                        <input type="text" class="form-control custom-sm"
                              [(ngModel)]="statement.passenger.phone2"
                              name="statementPassengerPhone2" />
                      </div>
                    </div>
                    <div class="col-12 mb-2">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.passenger.email">Email</span>
                        </div>
                        <input type="email" class="form-control custom-sm"
                              [(ngModel)]="statement.passenger.email"
                              name="statementPassengerEmail" />
                      </div>
                    </div>
                    <div class="col-12 ">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.passenger.addressRegistation">Address of the registration</span>
                        </div>
                        <textarea class="form-control form-control-sm" rows="2"
                                  [(ngModel)]="statement.passenger.address"
                                  name="statementPassengerAddress">{{statement.passenger.address}}</textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="card h-100">
              <div class="card-body p-3 bg-white rounded"
                  *ngFor="let baggageItem of statement.baggage; let i = index"
                  [ngClass]="{'d-none': display.baggageBlock !== i}">
                <div class="d-flex align-items-center justify-content-between mb-2">
                  <p class="m-0 h6 card__header--important" i18n="laf|Baggage modal@@laf.modal.baggage.statement.baggage">Baggage</p>
                  <div>
                    <button class="btn btn-ico btn-light-gray btn-xs mr-2">
                      <span class="material-icons">print</span>
                    </button>
                    <button *ngIf="baggageItem.commercialAct"
                            class="btn btn-ico btn-light-gray btn-xs mr-2"
                            (click)="openCommercialAct(baggageItem.commercialAct, modalCommercialAct)">
                      <span class="material-icons">description</span>
                      <span>1</span>
                    </button>
                    <button *ngIf="!baggageItem.commercialAct"
                            class="btn btn-ico btn-light-gray btn-xs mr-2"
                            (click)="createCommercialAct(baggageItem.id, modalCommercialAct)">
                      <span class="material-icons">description</span>
                    </button>
                    <button class="btn btn-ico btn-light-gray btn-xs mr-2">
                      <span class="material-icons">flag</span>
                    </button>
                    <button class="btn btn-ico btn-light-gray btn-xs">
                      <span class="material-icons">attach_file</span>
                    </button>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-6 mb-2">
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.baggage.reason">Reason</span>
                      </div>
                      <ng-select class="w-100 custom-sm p-0"
                                [(ngModel)]="baggageItem.reasonId"
                                name="baggageItemReason{{i}}"
                                bindLabel="name"
                                bindValue="id"
                                [items]="reasons">
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-6 mb-2">
                    <div class="input-group">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.baggage.number">Number</span>
                      </div>
                      <input type="text" class="form-control custom-sm" [(ngModel)]="baggageItem.tag" name="baggageItemTag{{i}}" />
                    </div>
                  </div>
                  <div class="col-6 mb-2">
                    <div class="input-group">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.baggage.tagnumber">Tag number</span>
                      </div>
                      <input type="text" class="form-control custom-sm" [(ngModel)]="baggageItem.tag" name="baggageItemTag{{i}}" />
                    </div>
                  </div>
                  <div class="col-6 mb-2">
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.baggage.status">Status</span>
                      </div>
                      <ng-select class="w-100 custom-sm p-0"
                                [(ngModel)]="baggageItem.statusId"
                                name="baggageItemStatus{{i}}"
                                bindLabel="name"
                                bindValue="id"
                                [loading]="selectLoadAnimation.baggage_statuses"
                                [items]="references.baggage_statuses">
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.baggage.weight">Weight on the tag, kg.</span>
                      </div>
                      <input type="number" class="form-control custom-sm"
                            name="baggageItemExpected{{i}}"
                            [(ngModel)]="baggageItem.weight.expected" />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.baggage.weightActual">Actual weight, kg.</span>
                      </div>
                      <input type="number" class="form-control custom-sm"
                            name="baggageItemActual{{i}}"
                            [(ngModel)]="baggageItem.weight.actual"  />
                    </div>
                  </div>
                </div>
                <p class="mb-2 h6 border-top py-2 font-weight-bold" i18n="laf|Baggage modal@@laf.modal.baggage.statement.baggage.specification">Baggage specifications</p>
                <div class="row mb-3">
                  <div class="col-12 mb-2">
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.baggage.specification.type">Baggage type</span>
                      </div>
                      <ng-select class="w-100 custom-sm p-0"
                                [(ngModel)]="baggageItem.characteristics.typeId"
                                name="baggageItemType{{i}}"
                                bindLabel="name"
                                bindValue="id"
                                [loading]="selectLoadAnimation.baggage_types"
                                [items]="references.baggage_types">
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-6 mb-2">
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.baggage.specification.material">Material</span>
                      </div>
                      <ng-select class="w-100 custom-sm p-0"
                                [(ngModel)]="baggageItem.characteristics.materialId"
                                name="baggageItemMaterial{{i}}"
                                bindLabel="name"
                                bindValue="id"
                                [loading]="selectLoadAnimation.baggage_materials"
                                [items]="references.baggage_materials">
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-6 mb-2">
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.baggage.specification.color">Color</span>
                      </div>
                      <ng-select class="w-100 custom-sm p-0"
                                [(ngModel)]="baggageItem.characteristics.colorId"
                                name="baggageItemColor{{i}}"
                                bindLabel="name"
                                bindValue="id"
                                [loading]="selectLoadAnimation.baggage_colors"
                                [items]="references.baggage_colors">
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-12 mb-2">
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.baggage.specification.externalElements">External elements</span>
                      </div>
                      <ng-select class="w-100 custom-sm p-0"
                                [(ngModel)]="baggageItem.characteristics.elements"
                                name="baggageItemElements{{i}}"
                                [multiple]="true"
                                bindLabel="name"
                                bindValue="id"
                                [loading]="selectLoadAnimation.baggage_elements"
                                [items]="references.baggage_elements">
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="input-group">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.baggage.specification.description">Description</span>
                      </div>
                      <textarea class="form-control form-control-sm" rows="2"
                                [(ngModel)]="baggageItem.characteristics.description"
                                name="baggageItemDescription{{i}}">{{baggageItem.characteristics.description}}</textarea>
                    </div>
                  </div>
                </div>
                <nav>
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <a class="nav-item nav-link active" id="nav-wt-tab{{i}}" data-toggle="tab" href="#nav-wt{{i}}"
                      role="tab" aria-selected="true" i18n="laf|Baggage modal@@laf.modal.baggage.statement.tab.worldTracer">
                      World tracer
                    </a>
                    <a class="nav-item nav-link" id="nav-forwarding-tab{{i}}" data-toggle="tab" href="#nav-forwarding{{i}}"
                      role="tab" aria-selected="false" i18n="laf|Baggage modal@@laf.modal.baggage.statement.tab.forwarding">
                      Forwarding
                    </a>
                    <a class="nav-item nav-link" id="nav-delivery-tab{{i}}" data-toggle="tab" href="#nav-delivery{{i}}"
                      role="tab" aria-selected="false" i18n="laf|Baggage modal@@laf.modal.baggage.statement.tab.delivery">
                      Delivery
                    </a>
                    <a class="nav-item nav-link" id="nav-storage-tab{{i}}" data-toggle="tab" href="#nav-storage{{i}}"
                      role="tab" aria-selected="false" i18n="laf|Baggage modal@@laf.modal.baggage.statement.tab.storage">
                      Storage
                    </a>
                    <a class="nav-item nav-link" id="nav-photo-tab{{i}}" data-toggle="tab" href="#nav-photo{{i}}"
                      role="tab" aria-selected="false" i18n="laf|Baggage modal@@laf.modal.baggage.statement.tab.photo">
                      Photo
                    </a>
                  </div>
                </nav>
                <div class="tab-content pt-2" id="nav-tabContent">
                  <div class="tab-pane show active" id="nav-wt{{i}}" role="tabpanel">
                    <div class="input-group">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.tab.worldTracer.number">Number</span>
                      </div>
                      <input type="text" class="form-control custom-sm"
                            [(ngModel)]="baggageItem.worldTracer.statement"
                            name="baggageWTstatement{{i}}" />
                    </div>
                  </div>
                  <div class="tab-pane fade" id="nav-delivery{{i}}" role="tabpanel">
                    <div class="custom-control custom-checkbox mb-2">
                      <input type="checkbox" class="custom-control-input"
                            id="deliveryRequired{{i}}" name="baggageDeliveryEnabled{{i}}"
                            [(ngModel)]="baggageItem.delivery.enabled">
                      <label class="custom-control-label" for="deliveryRequired{{i}}" i18n="laf|Baggage modal@@laf.modal.baggage.statement.tab.delivery.required">Delivery required</label>
                    </div>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.tab.delivery.address">Delivery address</span>
                      </div>
                      <textarea class="form-control form-control-sm" rows="2"
                                [(ngModel)]="baggageItem.delivery.address"
                                name="baggageDeliveryAddress{{i}}">{{baggageItem.delivery.address}}</textarea>
                    </div>
                    <div class="input-group">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.tab.delivery.note">Note</span>
                      </div>
                      <textarea class="form-control form-control-sm" rows="2"
                                [(ngModel)]="baggageItem.delivery.note"
                                name="baggageDeliveryNote{{i}}">{{baggageItem.delivery.note}}</textarea>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="nav-forwarding{{i}}" role="tabpanel">
                    <div class="row">
                      <div class="col-6">
                        <p class="mb-2 h6 font-weight-bold" i18n="laf|Baggage modal@@laf.modal.baggage.statement.tab.forwarding.arrival">Arrival</p>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.tab.forwarding.arrival.flight">Flight</span>
                          </div>
                          <input type="text" class="form-control custom-sm"
                                [(ngModel)]="baggageItem.forwarding.arrival.flight"
                                name="baggageArrivalFlight{{i}}" />
                        </div>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.tab.forwarding.arrival.date">Date</span>
                          </div>
                          <input type="date" class="form-control"
                                  [ngModel]="baggageItem.forwarding.arrival.dt | date: 'yyyy-MM-dd'"
                                  (input)="baggageItem.forwarding.arrival.dt = parseDate($event.target.value, baggageItemArrivalTime.value)"
                                  name="baggageItemArrivalDate{{i}}"
                                  #baggageItemArrivalDate="ngModel" required />
                          <input type="time" class="form-control" style="max-width: 100px"
                                  [ngModel]="baggageItem.forwarding.arrival.dt | date: 'HH:mm'"
                                  (input)="baggageItem.forwarding.arrival.dt = parseDate(baggageItemArrivalTime.value, $event.target.value)"
                                  name="baggageItemArrivalTime{{i}}"
                                  #baggageItemArrivalTime="ngModel" required />
                        </div>
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.tab.forwarding.arrival.status">Status</span>
                          </div>
                          <ng-select class="w-100custom-sm p-0"
                                [items]="references.baggage_statuses"
                                bindLabel="name"
                                bindValue="id"
                                [(ngModel)]="baggageItem.forwarding.arrival.statusId"
                                name="baggageArrivalStatus{{i}}">
                          </ng-select>
                        </div>
                      </div>
                      <div class="col-6">
                        <p class="mb-2 h6 font-weight-bold" i18n="laf|Baggage modal@@laf.modal.baggage.statement.tab.forwarding.departure">Departure</p>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.tab.forwarding.departure.flight">Flight</span>
                          </div>
                          <input type="text" class="form-control custom-sm"
                                [(ngModel)]="baggageItem.forwarding.departure.flight"
                                name="baggageDepartureFlight{{i}}" />
                        </div>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.tab.forwarding.date">Date</span>
                          </div>
                          <input type="date" class="form-control"
                                  [ngModel]="baggageItem.forwarding.departure.dt | date: 'yyyy-MM-dd'"
                                  (input)="baggageItem.forwarding.departure.dt = parseDate($event.target.value, baggageItemDepartureTime.value)"
                                  name="baggageItemDepartureDate{{i}}"
                                  #baggageItemDepartureDate="ngModel" required />
                          <input type="time" class="form-control" style="max-width: 100px"
                                  [ngModel]="baggageItem.forwarding.departure.dt | date: 'HH:mm'"
                                  (input)="baggageItem.forwarding.departure.dt = parseDate(baggageItemDepartureTime.value, $event.target.value)"
                                  name="baggageItemDepartureTime{{i}}"
                                  #baggageItemDepartureTime="ngModel" required />
                        </div>
                        <div class="input-group flex-nowrap mb-2">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.tab.forwarding.status">Status</span>
                          </div>
                          <ng-select class="w-100 custom-sm p-0"
                                [items]="references.baggage_statuses"
                                bindLabel="name"
                                bindValue="id"
                                [(ngModel)]="baggageItem.forwarding.departure.statusId"
                                name="baggageDepartureStatus{{i}}">
                          </ng-select>
                        </div>
                        <div class="input-group">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.tab.forwarding.departure.tagNumber">Tag number</span>
                          </div>
                          <input type="text" class="form-control custom-sm"
                                [(ngModel)]="baggageItem.forwarding.departure.tag"
                                name="baggageDepartureTag{{i}}" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="nav-storage{{i}}" role="tabpanel">
                    <div class="input-group flex-nowrap mb-2">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.tab.storage.name">Storage location name</span>
                      </div>
                      <ng-select class="w-100 custom-sm p-0"
                                [items]="adminData.storages"
                                bindLabel="name"
                                bindValue="id"
                                [(ngModel)]="baggageItem.storage.id"
                                name="baggageStorage{{i}}">
                      </ng-select>
                    </div>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.tab.storage.location">Location</span>
                      </div>
                      <input type="text" class="form-control custom-sm" disabled
                        value="Терминал В, 2 этаж, комната 211" />
                    </div>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.tab.storage.schedule">Schedule</span>
                      </div>
                      <input type="text" class="form-control custom-sm" disabled
                        value="Пн-Сб: 8.00-20.00, Вс: 10.00-20.00" />
                    </div>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.tab.storage.contact">Contacts</span>
                      </div>
                      <input type="text" class="form-control custom-sm" disabled value="8 (800) 555-35-35" />
                    </div>
                  </div>
                  <div class="tab-pane fade" id="nav-photo{{i}}" role="tabpanel">
                    <p class="m-0" i18n="laf|Baggage modal@@laf.modal.baggage.statement.tab.photo.actionNote">Click on photo to view or edit</p>
                    <div class="row">
                      <div class="col-4">
                        <div class="photo-item">
                          <span class="material-icons">add</span>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="photo-item">
                          <span class="material-icons">add</span>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="photo-item">
                          <span class="material-icons">add</span>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="photo-item">
                          <span class="material-icons">add</span>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="photo-item">
                          <span class="material-icons">add</span>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="photo-item">
                          <span class="material-icons">add</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer bg-white mt-3 pt-3 d-flex justify-content-between align-items-center">
                <div>
                  <button class="btn btn-ico btn-light-gray btn-xs mr-2"
                          (click)="removeItem(statement.baggage, display.baggageBlock)">
                    <span class="material-icons">delete</span>
                  </button>
                  <button class="btn btn-ico btn-light-gray btn-xs"
                        (click)="addItem(statement.baggage, classes.baggage); display.baggageBlock = statement.baggage.length-1">
                    <span class="material-icons">add</span>
                  </button>
                </div>
                <div>
                  <div role="group" class="btn-group btn-group-sm">
                    <button type="button" class="btn btn-h-25 btn-light-gray"
                            *ngFor="let baggageItem of statement.baggage; let i = index"
                            [ngClass]="{'btn-green': display.baggageBlock === i}"
                            (click)="display.baggageBlock = i"> {{ i+1 }} </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Кнопки карточки рейса -->
      <div class="modal-footer justify-content-between align-items-center">
        <div>
          <button type="button" class="btn btn-sm btn-blue" *ngIf="!statement.id" (click)="addStatement()">
            <span i18n="laf|Baggage modal@@laf.modal.baggage.statement.button.append">Append</span>
          </button>
          <button type="button" class="btn btn-sm btn-blue" *ngIf="statement.id" (click)="saveStatement()">
            <span i18n="laf|Baggage modal@@laf.modal.baggage.statement.button.save">Save</span>
          </button>
          <button type="button" class="btn btn-sm btn-gray-blue-dark ml-2" data-dismiss="modal">
            <span i18n="laf|Baggage modal@@laf.modal.baggage.statement.button.close">Close</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</ng-template>


<!-- Modal -->
<ng-template #modalCommercialAct let-modal>
  <form #flightForm="ngForm">
    <div class="modal-content bg-light-gray">
      <div class="loading" *ngIf="display.loading">
        <div class="cssload-spin-box"></div>
      </div>
      <div class="modal-header">
        <h5 class="modal-title">
          <span i18n="laf|Baggage modal@@laf.modal.commercialAct">Commercial act</span>
        </h5>
        <button type="button" class="close mp-0" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-panel bg-white p-2">
        <button class="btn btn-sm btn-light-gray mr-2 btn-ico">
          <span class="material-icons mr-2">print</span>
          <span i18n="laf|Baggage modal@@laf.modal.commercialAct.button.print">Print</span>
        </button>
        <button class="btn btn-sm btn-light-gray mr-2 btn-ico">
          <span class="material-icons mr-2">print</span>
          <span i18n="laf|Baggage modal@@laf.modal.commercialAct.printPir">Print PIR</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-6 pr-0">
            <div class="card h-100">
              <div class="card-body p-3 bg-white rounded">
                <div class="d-flex align-items-center justify-content-between mb-2">
                  <p class="m-0 h6 card__header--important" i18n="laf|Baggage modal@@laf.modal.commercialAct.act">Commercial act</p>
                </div>
                <div class="row mb-3">
                  <div class="col-6 pr-0 mb-2">
                    <div class="input-group">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.number">Number</span>
                      </div>
                      <input type="text" disabled class="form-control form-control-sm"
                            [(ngModel)]="commercialAct.name"
                            name="commercialActName">
                    </div>
                  </div>
                  <div class="col-6 mb-2">
                    <div class="input-group">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.date">Date</span>
                      </div>
                      <input type="text" class="form-control custom-sm"
                            value="{{commercialAct.dtInsert | date: 'dd.MM.yyyy HH:mm'}}"
                            name="commercialActDate"/>
                    </div>
                  </div>
                  <div class="col-6 pr-0 mb-2">
                    <div class="input-group">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.airport">Airport</span>
                      </div>
                      <input type="text" disabled class="form-control form-control-sm"
                            [(ngModel)]="commercialAct.airportId"
                            name="commercialActAirport">
                    </div>
                  </div>
                  <div class="col-6 mb-2">
                    <div class="input-group">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.airline">Airline</span>
                      </div>
                      <input type="text" disabled class="form-control form-control-sm"
                            [(ngModel)]="commercialAct.airlineId"
                            name="commercialActAirline">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.reason">Reason</span>
                      </div>
                      <ng-select class="w-100 custom-sm p-0"
                                  bindValue="id"
                                  bindLabel="name"
                                  [(ngModel)]="commercialAct.reasonId"
                                  name="commercialActtWay"
                                  [items]="reasons"></ng-select>
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <p class="mb-2 h6 border-top py-2 font-weight-bold" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.passenger">Passenger</p>
                  <div class="row">
                    <div class="col-12 mb-2">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.passenger.name">Full name</span>
                        </div>
                        <input type="text" class="form-control custom-sm"
                              [(ngModel)]="commercialAct.passenger.passenger"
                              name="commercialActPassengerName" />
                      </div>
                    </div>
                    <div class="col-6 mb-2">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.passenger.phone1">Phone 1</span>
                        </div>
                        <input type="text" class="form-control custom-sm"
                              [(ngModel)]="commercialAct.passenger.phone1"
                              name="commercialActPassengerPhone1" />
                      </div>
                    </div>
                    <div class="col-6 mb-2">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.passenger.phone2">Phone 2</span>
                        </div>
                        <input type="text" class="form-control custom-sm"
                              [(ngModel)]="commercialAct.passenger.phone2"
                              name="commercialActPassengerPhone2" />
                      </div>
                    </div>
                    <div class="col-6 mb-2">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.passenger.passport">Passport</span>
                        </div>
                        <input type="text" class="form-control custom-sm"
                              [(ngModel)]="commercialAct.passenger.passport"
                              name="commercialActPassengerPassport" />
                      </div>
                    </div>
                    <div class="col-6 ">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.birthday">Birthday</span>
                        </div>
                        <input type="date" class="form-control"
                                [ngModel]="commercialAct.passenger.dateOfBirth | date: 'yyyy-MM-dd'"
                                (input)="commercialAct.passenger.dateOfBirth = parseDate($event.target.value)"
                                name="commercialActPassengerDateOfBirth"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <p class="mb-2 h6 border-top py-2 font-weight-bold" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.arrival">Arrival flight</p>
                  <div class="row">
                    <div class="col-6 mb-2">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.arrival.flight">Flight</span>
                        </div>
                        <input type="text" class="form-control custom-sm"
                              [(ngModel)]="commercialAct.route.arrival.flight"
                              name="commercialActFlight" />
                      </div>
                    </div>
                    <div class="col-6 mb-2">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.arrival.date">Date</span>
                        </div>
                        <input type="date" class="form-control"
                                [ngModel]="commercialAct.route.arrival.dt | date: 'yyyy-MM-dd'"
                                (input)="commercialAct.route.arrival.dt = parseDate($event.target.value, commercialActTime.value)"
                                name="commercialActDate"
                                #commercialActDate="ngModel" required />
                        <input type="time" class="form-control" style="max-width: 100px"
                                [ngModel]="commercialAct.route.arrival.dt | date: 'HH:mm'"
                                (input)="commercialAct.route.arrival.dt = parseDate(commercialActDate.value, $event.target.value)"
                                name="commercialActTime"
                                #commercialActTime="ngModel" required />
                      </div>
                    </div>
                    <div class="col-6 mb-2">
                      <div class="input-group flex-nowrap">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.arrival.from">Arrival</span>
                        </div>
                        <ng-select class="w-100 custom-sm p-0"
                                  [items]="references.airports"
                                  bindLabel="iata"
                                  bindValue="iata"
                                  [(ngModel)]="commercialAct.route.arrival.arrival"
                                  #filterParamsAirport="ngModel"
                                  name="commercialActArrival"
                                  [virtualScroll]="true"
                                  [loading]="selectLoadAnimation.airports"
                                  (scroll)="onScrollNgSelect($event, 'airports')"
                                  (scrollToEnd)="onScrollToEndNgSelect('airports')"
                                  [searchFn]="customSelectSearch"
                                  appendTo="body">
                          <ng-template ng-option-tmp let-item="item">
                            <div class="font-small">
                              <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                            </div>
                          </ng-template>
                        </ng-select>
                      </div>
                    </div>
                    <div class="col-6 mb-2">
                      <div class="input-group flex-nowrap">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.arrival.to">Departure</span>
                        </div>
                        <ng-select class="w-100 custom-sm p-0"
                                  [items]="references.airports"
                                  bindLabel="iata"
                                  bindValue="iata"
                                  [(ngModel)]="commercialAct.route.arrival.departure"
                                  #filterParamsAirport="ngModel"
                                  name="commercialActDeparture"
                                  [virtualScroll]="true"
                                  [loading]="selectLoadAnimation.airports"
                                  (scroll)="onScrollNgSelect($event, 'airports')"
                                  (scrollToEnd)="onScrollToEndNgSelect('airports')"
                                  [searchFn]="customSelectSearch"
                                  appendTo="body">
                          <ng-template ng-option-tmp let-item="item">
                            <div class="font-small">
                              <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                            </div>
                          </ng-template>
                        </ng-select>
                      </div>
                    </div>
                    <div class="col-6 mb-2">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.arrival.ticketNumber">Ticket number</span>
                        </div>
                        <input type="text" class="form-control custom-sm" name="commercialActTicket" [(ngModel)]="commercialAct.route.arrival.ticket" />
                      </div>
                    </div>
                    <div class="col-6 mb-2">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0">PNR</span>
                        </div>
                        <input type="text" class="form-control custom-sm" name="commercialActPnr" [(ngModel)]="commercialAct.route.arrival.pnr" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <p class="mb-2 h6 border-top py-2 font-weight-bold" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.routeFull">Full route by ticket</p>
                  <div class="input-group flex-nowrap mb-2">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.route">Route</span>
                    </div>
                    <ng-select class="w-100 custom-sm p-0"
                              [items]="references.airports"
                              bindLabel="iata"
                              bindValue="id"
                              [multiple]="true"
                              [(ngModel)]="commercialAct.route.full.routeId"
                              #commercialActAirport="ngModel"
                              name="commercialActAirport"
                              [virtualScroll]="true"
                              [loading]="selectLoadAnimation.airports"
                              (scroll)="onScrollNgSelect($event, 'airports')"
                              (scrollToEnd)="onScrollToEndNgSelect('airports')"
                              [searchFn]="customSelectSearch"
                              appendTo="body">
                      <ng-template ng-option-tmp let-item="item">
                        <div class="font-small">
                          <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                  <div class="row mb-2" *ngFor="let flightItem of commercialAct.route.full.flights; let i = index">
                    <div class="col-4">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.arrival.routeFull.airline">Airline</span>
                        </div>
                        <ng-select class="form-control custom-sm p-0"
                                  [items]="references.airlines"
                                  bindLabel="iata"
                                  bindValue="id"
                                  [multiple]="true"
                                  [(ngModel)]="flightItem.airlineId"
                                  name="commercialActAirline_{{i}}"
                                  [virtualScroll]="true"
                                  [loading]="selectLoadAnimation.airlines"
                                  (scroll)="onScrollNgSelect($event, 'airlines')"
                                  (scrollToEnd)="onScrollToEndNgSelect('airlines')"
                                  [searchFn]="customSelectSearch"
                                  appendTo="body">
                          <ng-template ng-option-tmp let-item="item">
                            <div class="font-small">
                              <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                            </div>
                          </ng-template>
                        </ng-select>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.arrival.routeFull.flight">Flight</span>
                        </div>
                        <input type="number" class="form-control custom-sm"
                              name="commercialActRouteFlight_{{i}}"
                              [(ngModel)]="flightItem.flight" />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.arrival.routeFull.date">Date</span>
                        </div>
                        <input type="date" class="form-control"
                                [ngModel]="flightItem.dt | date: 'yyyy-MM-dd'"
                                (input)="flightItem.dt = parseDate($event.target.value)"
                                name="commercialActRouteDate_{{i}}"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <div class="d-flex border-top py-2 align-items-center justify-content-between">
                    <p class="m-0 h6 font-weight-bold" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.commissionMembers">Сommission members</p>
                    <button class="btn btn-ico btn-light-gray btn-xs mr-2" (click)="addPersonComission()">
                      <span class="material-icons">add</span>
                    </button>
                  </div>
                  <div class="input-group mt-2" *ngFor="let person of commercialAct.comission; let i = index">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.commissionMembers.positionAndName">Position and name</span>
                    </div>
                    <input type="text" class="form-control custom-sm" [(ngModel)]="person.position" name="personPosition{{i}}" />
                    <input type="text" class="form-control custom-sm" [(ngModel)]="person.fio" name="personFio{{i}}" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="card h-100">
              <div class="card-body p-3 bg-white rounded">
                <div class="d-flex align-items-center justify-content-between mb-2">
                  <p class="m-0 h6 card__header--important" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.baggage">Baggage</p>
                </div>
                <div class="mb-3">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.baggage.tagNumber">Tag number</span>
                    </div>
                    <input type="text" class="form-control custom-sm" [(ngModel)]="commercialAct.baggage.tag" name="baggageTag" />
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.baggage.declaredPrice">Declared price</span>
                    </div>
                    <input type="number" class="form-control custom-sm" [(ngModel)]="commercialAct.baggage.declaredValue" name="declaredValue" />
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.baggage.checkForPaidBaggage">Check for paid baggage</span>
                    </div>
                    <input type="text" class="form-control custom-sm" [(ngModel)]="commercialAct.baggage.receipt" name="baggageReceipt" />
                  </div>
                  <div class="input-group">
                    <div class="input-group-prepend custom-sm">
                      <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.baggage.description">Description</span>
                    </div>
                    <textarea class="form-control form-control-sm" rows="2"
                              [(ngModel)]="commercialAct.baggage.defectFromBaggageStatement"
                              name="defectFromBaggageStatement">{{commercialAct.baggage.defectFromBaggageStatement}}</textarea>
                  </div>
                </div>
                <div class="mb-3">
                  <p class="mb-2 h6 border-top py-2 font-weight-bold" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.baggage.infoFromTag">Information from the baggage tag</p>
                  <div class="row">
                    <div class="col-6 mb-2">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.baggage.infoFromTag.numberPieces">Number of pieces</span>
                        </div>
                        <input type="text" class="form-control custom-sm"
                              [(ngModel)]="commercialAct.baggage.infoFromTag.pieces"
                              name="commercialActInfoFromTagPiecest" />
                      </div>
                    </div>
                    <div class="col-6 mb-2">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.baggage.infoFromTag.weight">Weight</span>
                        </div>
                        <input type="text" class="form-control custom-sm"
                              [(ngModel)]="commercialAct.baggage.infoFromTag.weight"
                              name="commercialActInfoFromTagWeight" />
                      </div>
                    </div>
                    <div class="col-6 mb-2">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.baggage.infoFromTag.arrival">Arrival</span>
                        </div>
                        <ng-select class="form-control custom-sm p-0"
                                  [items]="references.airports"
                                  bindLabel="iata"
                                  bindValue="iata"
                                  [(ngModel)]="commercialAct.baggage.infoFromTag.arrival"
                                  #commercialActInfoFromTagArrival="ngModel"
                                  name="commercialActInfoFromTagArrival"
                                  [virtualScroll]="true"
                                  [loading]="selectLoadAnimation.airports"
                                  (scroll)="onScrollNgSelect($event, 'airports')"
                                  (scrollToEnd)="onScrollToEndNgSelect('airports')"
                                  [searchFn]="customSelectSearch"
                                  appendTo="body">
                          <ng-template ng-option-tmp let-item="item">
                            <div class="font-small">
                              <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                            </div>
                          </ng-template>
                        </ng-select>
                      </div>
                    </div>
                    <div class="col-6 mb-2">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.baggage.infoFromTag.departure">Departure</span>
                        </div>
                        <ng-select class="form-control custom-sm p-0"
                                  [items]="references.airports"
                                  bindLabel="iata"
                                  bindValue="iata"
                                  [(ngModel)]="commercialAct.baggage.infoFromTag.departure"
                                  #commercialActInfoFromTagDeparture="ngModel"
                                  name="commercialActInfoFromTagDeparture"
                                  [virtualScroll]="true"
                                  [loading]="selectLoadAnimation.airports"
                                  (scroll)="onScrollNgSelect($event, 'airports')"
                                  (scrollToEnd)="onScrollToEndNgSelect('airports')"
                                  [searchFn]="customSelectSearch"
                                  appendTo="body">
                          <ng-template ng-option-tmp let-item="item">
                            <div class="font-small">
                              <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                            </div>
                          </ng-template>
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <p class="mb-2 h6 border-top py-2 font-weight-bold" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.baggage.actualData">Actual data</p>
                  <div class="row">
                    <div class="col-6 mb-2">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.baggage.actualData.numberOfPieces">Number of pieces</span>
                        </div>
                        <input type="text" class="form-control custom-sm"
                              [(ngModel)]="commercialAct.baggage.pieces"
                              name="commercialActBaggagePiecest" />
                      </div>
                    </div>
                    <div class="col-6 mb-3">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.baggage.actualData.weight">Weight</span>
                        </div>
                        <input type="text" class="form-control custom-sm"
                              [(ngModel)]="commercialAct.baggage.weight"
                              name="commercialActBaggageWeight" />
                      </div>
                    </div>
                    <div class="col-12 mb-1">
                      <div class="custom-control custom-checkbox mb-2">
                        <input type="checkbox" class="custom-control-input"
                              id="isPacked" name="isPacked"
                              [(ngModel)]="commercialAct.baggage.isPacked">
                        <label class="custom-control-label" for="isPacked" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.baggage.actualData.isPacked">Luggage is packed</label>
                      </div>
                    </div>
                    <div class="col-12 mb-2">
                      <div class="custom-control custom-checkbox mb-2">
                        <input type="checkbox" class="custom-control-input"
                              id="isOpened" name="isOpened"
                              [(ngModel)]="commercialAct.baggage.isOpened">
                        <label class="custom-control-label" for="isOpened" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.baggage.actualData.isOpened">When writing the act, the luggage was opened</label>
                      </div>
                    </div>
                    <div class="col-12 mb-3">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.baggage.actualData.faultDescription">Fault description</span>
                        </div>
                        <textarea class="form-control form-control-sm" rows="2"
                                  [(ngModel)]="commercialAct.baggage.defect"
                                  name="baggageDefect">{{commercialAct.baggage.defect}}</textarea>
                      </div>
                    </div>
                    <div class="col-12 mb-2">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.baggage.additionalInfo">Additional information</span>
                        </div>
                        <textarea class="form-control form-control-sm" rows="2"
                                  [(ngModel)]="commercialAct.baggage.additionalInfo"
                                  name="additionalInfo">{{commercialAct.baggage.additionalInfo}}</textarea>
                      </div>
                    </div>
                    <div class="col-12 mb-2">
                      <div class="input-group">
                        <div class="input-group-prepend custom-sm">
                          <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.baggage.attachedToAct">Attached to the act</span>
                        </div>
                        <textarea class="form-control form-control-sm" rows="2"
                                  [(ngModel)]="commercialAct.baggage.note"
                                  name="baggageNote">{{commercialAct.baggage.note}}</textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="custom-control custom-checkbox mb-2">
                      <input type="checkbox" class="custom-control-input"
                            id="isSeal" name="isPacked"
                            [(ngModel)]="commercialAct.baggage.seal.enabled">
                      <label class="custom-control-label" for="isSeal" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.baggage.isSealed">Luggage sealed</label>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0" i18n="laf|Baggage modal@@laf.modal.commercialAct.act.baggage.actualData.sealNumber">Seal number</span>
                      </div>
                      <input type="text" class="form-control custom-sm"
                            [(ngModel)]="commercialAct.baggage.seal.name"
                            name="sealName"
                            [disabled]="!commercialAct.baggage.seal.enabled" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Кнопки карточки рейса -->
      <div class="modal-footer justify-content-between align-items-center">
        <div>
          <button type="button" class="btn btn-sm btn-blue" *ngIf="!commercialAct.id" (click)="addCommercialAct()">
            <span i18n="laf|Baggage modal@@laf.modal.commercialAct.button.append">Append</span>
          </button>
          <button type="button" class="btn btn-sm btn-blue" *ngIf="commercialAct.id" (click)="saveCommercialAct()">
            <span i18n="laf|Baggage modal@@laf.modal.commercialAct.button.save">Save</span>
          </button>
          <button type="button" class="btn btn-sm btn-gray-blue-dark ml-2" data-dismiss="modal">
            <span i18n="laf|Baggage modal@@laf.modal.commercialAct.button.close">Close</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</ng-template>
