import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { GlobalSettings } from '../settings/global-settings';


@Injectable({
  providedIn: 'root'
})
export class MaterialAccountingRestApiService {

  // ***** Запросы к БЭКу

  constructor(private http: HttpClient,
              private globalSettings: GlobalSettings) {
    globalSettings.loadDefaultConfig();
  }

  /**
   * Функция формирования заголовка сообщения
   * @param requestId Идентификатор запроса
   * @returns Возвращает сформированный заголовок для отправки
   */
  setDefaultHttpHeader(requestId?): Object {
    // Формирование заголовков для отслеживания запросов
    // X-Correlation-ID идентификатор пользовательской сессии
    // X-Request-ID идентификатор события / запроса
    let httpOptions = {};
    httpOptions['headers'] = { 'Content-Type' : 'application/json',
                               'X-Correlation-ID' : this.globalSettings.userSessionUUID,
                               'X-Request-ID' : (requestId === undefined) ? this.globalSettings.randomUuid : requestId };
    return httpOptions;
  }

  // Пользовательская фукнция, в данном случае запрос справочников
  getReference(name, xRequestId?): Promise<any[]> {
    const httpOptions = this.setDefaultHttpHeader(xRequestId);

    // #### Здесть указать адрес расположения API точки для получения данных
    return this.http.get<any[]>(this.globalSettings.apiMaterialAccountingURL +
                                '/references/' + name, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      ).toPromise();
  }

  /**
   * Функция стандартной обработки ошибок
   * @param error Ошибка отловленная в приложении
   * @returns Возращает описание ошибки в человеочитаемом виде
   */
  handleError(error) {
    let errorMessage = '';
    let errorDetail: any = null;
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorDetail = error.error;
      errorDetail.status = error.status;
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    if (errorDetail) {
      return throwError(errorDetail);
    } else {
      return throwError(errorMessage);
    }
  }
}
