import { Component, OnInit, Injectable } from '@angular/core';
import { UserNotification, GlobalSettings } from '../settings/global-settings';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.less']
})

@Injectable({
  providedIn: 'root'
})


export class NotificationsComponent implements OnInit {

  userNotifications: Array<UserNotification>;

  constructor(
    public globalSettings: GlobalSettings) {
    }

  async ngOnInit() {
    // Ожидание авторизации пользователя, для получения доступа к группам прав
    await this.globalSettings.loadComplete();
    this.getNotifications();
  }

  getNotifications() {
    this.userNotifications = this.globalSettings.getNotifications();
  }

  showNotification(title: string, message: string) {
    this.globalSettings.showNotification(title, message);
    this.getNotifications();
  }

}
