import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GlobalI18n } from 'src/app/settings/global-i18n';
import { ModuleName } from 'src/app/settings/global-settings';
import { Flight, Terminal } from '../../administration-data/flights-reference';
import { FlightsRestApiService } from '../../administration-data/flights-rest-api.service';

@Component({
  selector: 'app-am-flights',
  templateUrl: './am-flights.component.html',
  styleUrls: ['./am-flights.component.less']
})
export class AmFlightsComponent implements OnInit {

  activeTab = 'tab-stands';
  activeState = 'action-append';

  stands: Array<Flight> = [];
  stand: Flight = new Flight();
  terminals: Array<Terminal> = [];
  terminal: Terminal = new Terminal();

  @ViewChild('standForm') standForm: NgForm;
  @ViewChild('terminalForm') terminalForm: NgForm;
  @ViewChild('closebutton') closebutton: any;

  // Текущее действие пользователя
  userAction = '';
  modalType = '';
  // Переменная содержащая описание ошибки возникающей при работе модуля
  errorMessage = '';
  // Тип ошибки: error, warning, info
  errorType = '';
  // Ответ пользователя
  userAnswer = null;

  loading = false;

  constructor(
    public restApi: FlightsRestApiService,
    public globalI18n: GlobalI18n,
  ) { }

  ngOnInit(): void {
    this.loadStands();
  }

  // Stands
  loadStands() {
    this.stands.length = 0;
    return this.restApi.getStands().subscribe((data: Flight[]) => {
      for (const item of data) {
        const group = new Flight();
        Object.assign(group, item);
        this.stands = [...this.stands, group];
      }
    });
  }

  loadStand(id) {
    return this.restApi.getStand(id).subscribe((data: Flight) => {
      this.stand = new Flight();
      Object.assign(this.stand, data);
      this.activeState = 'action-update';
    });
  }

  createStand() {
    this.stand = new Flight();
    this.activeState = 'action-append';
  }

  addStand() {
    this.loading = true;
    return this.restApi.addStand(this.stand).subscribe(() => {
      this.loading = false;
      this.closebutton.nativeElement.click();
      this.loadStands();
    });
  }

  updateStand(id){
    this.loading = true;
    return this.restApi.updateStand(id, this.stand).subscribe(() => {
      this.loading = false;
      this.closebutton.nativeElement.click();
      this.loadStands();
    });
  }

  async deleteStand(id) {
    this.userAction = 'delete-ahm';
    this.errorMessage = this.globalI18n.getMessage(ModuleName.Schedule, 'deleteItem');
    this.errorType = 'warning';
    this.modalType = 'setAnswer'
    await this.waitAnswer().then(async res => {
      if (res) {
        this.loading = true;
        this.stand.dtRangeFinish = new Date();
        return this.restApi.updateStand(id, this.stand).subscribe(() => {
          this.loading = false;
          this.closebutton.nativeElement.click();
          this.loadStands();
        });
      }
    });
  }

  // Terminals
  loadTerminals() {
    this.terminals.length = 0;
    return this.restApi.getTerminals().subscribe((data: Terminal[]) => {
      for (const item of data) {
        const terminal = new Terminal();
        Object.assign(terminal, item);
        this.terminals = [...this.terminals, terminal];
      }
    });
  }

  loadTerminal(id) {
    return this.restApi.getTerminal(id).subscribe((data: Terminal) => {
      this.terminal = new Terminal();
      Object.assign(this.terminal, data);
      this.activeState = 'action-update';
    });
  }

  createTerminal() {
    this.terminal = new Terminal();
    this.activeState = 'action-append';
  }

  addTerminal() {
    this.loading = true;
    return this.restApi.addTerminal(this.terminal).subscribe(() => {
      this.loading = false;
      this.closebutton.nativeElement.click();
      this.loadTerminals();
    });
  }

  updateTerminal(id){
    this.loading = true;
    return this.restApi.updateTerminal(id, this.terminal).subscribe(() => {
      this.loading = false;
      this.closebutton.nativeElement.click();
      this.loadTerminals();
    });
  }

  async deleteTerminal(id) {
    this.userAction = 'delete-ahm';
    this.errorMessage = this.globalI18n.getMessage(ModuleName.Schedule, 'deleteItem');
    this.errorType = 'warning';
    this.modalType = 'setAnswer'
    await this.waitAnswer().then(async res => {
      if (res) {
        this.loading = true;
        this.terminal.dtRangeFinish = new Date();
        return this.restApi.updateTerminal(id, this.terminal).subscribe(() => {
          this.loading = false;
          this.closebutton.nativeElement.click();
          this.loadStands();
        });
      }
    });
  }

  async waitAnswer() {
    let res = await this.checkAnswer();
    return res;
  }

  checkAnswer() {
    return new Promise(resolve => {
      let interval = setInterval(() => {
        if (this.userAnswer !== null) {
          const answer = this.userAnswer;
          this.clearErrorMess();
          clearInterval(interval);
          resolve(answer);
        }
      }, 300)
   });
  }

  clearErrorMess() {
    this.errorMessage = '';
    this.errorType = '';
    this.modalType = '';
    this.userAnswer = null;
  }

  parseDate(dateString: string): Date {
    if (dateString !== '') {
      return new Date(dateString);
    }
    return null;
  }

  changeTab(event: { target: { id: string; }; }) {
    this.activeTab = event.target.id;
    this.activeState = 'action-append';
    switch (this.activeTab) {
      case 'tab-stands': {
        this.loadStands();
        break;
      }
      case 'tab-terminals': {
        this.loadTerminals();
        break;
      }
    }
  }

  showTab(item: string): boolean {
    if (item === this.activeTab) {
      return true;
    } else {
      return false;
    }
  }
}
