import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FileSaverService } from 'ngx-filesaver';
import { GlobalSettings, ModuleName } from '../settings/global-settings';
import {
  Ahm,
  AhmData,
  CabinArea,
  CalculationAhm,
  Flight,
  Route,
  HoldsAndCompartmentsCalc,
  FilterParams,
  DowChangesCrew,
  UldTypes,
  Document,
  NotocDangerous,
  NotocOther,
  CalculationManual,
  DeltaDowDoi
} from '../weight-balance-data/weight-balance';
import { WeightBalanceModuleRestApiService } from '../weight-balance-data/weight-ballance-rest-api-service';
import { GlobalI18n } from '../settings/global-i18n';
import {
  ReferanceAirport,
  ReferanceAirline,
  ReferanceAircraft,
  ReferanceTail,
  ReferanceMovement
} from '../referance-module-data/referance';
import { HttpResponse } from '@angular/common/http';
import { drawGravity, createCanvas } from '../core/gravity'
import { Md5 } from 'ts-md5';
import { NGXLogger } from 'ngx-logger';
import { ECSEvent, EventProvider } from 'src/app/settings/logger-monitor';

@Component({
  selector: 'app-weight-balance',
  templateUrl: './weight-balance.component.html',
  styleUrls: ['./weight-balance.component.less']
})
export class WeightBalanceComponent implements OnInit {

  @ViewChild('closebutton') closebutton: any;
  @ViewChild('flightForm') flightForm: any;

  private md5 = new Md5();

  // Текущее окно
  activeWindow = 'flight-list';
  // Текущая вкладка
  activeTab = 'standard-units';
  flightCardActiveTab = 'flight-card-tab-route';
  // Отображение данных и весах и центровке в виде таблицы или графика
  chartIsShow = false;

  // Флаг загрузки
  loading = false;
  // Тип загрузки
  loadingType = '';

  // Классы для добавления элементов по кнопке add
  calculationCompartments = HoldsAndCompartmentsCalc;
  NotocDangerous = NotocDangerous;
  NotocOther = NotocOther;

  units = {
    weight: [{
      id: 1,
      englishName: 'Killogram',
      localName: 'Килограммы'
    }],
    length: [{
      id: 1,
      englishName: 'Meters',
      localName: 'Метры'
    }],
    volume: [{
      id: 1,
      englishName: 'Cubic metre',
      localName: 'Кубические метры'
    }],
    liquidVolume: [{
      id: 1,
      englishName: 'Liter',
      localName: 'Литры'
    }]
  };

  // typeOfCargo = [
  //   {id: 1, name: 'Baggage'},
  //   {id: 2, name: 'Cargo'},
  //   {id: 3, name: 'Mail'},
  //   {id: 4, name: 'FKT'},
  //   {id: 6, name: 'EIC'},
  //   {id: 4, name: 'CSU'},
  //   {id: 5, name: 'Ballast'},
  // ];

  typeOfCargo = ['Baggage', 'Cargo', 'Mail', 'FKT', 'EIC', 'CSU', 'EXP', 'SPB', 'Ballast'];

  documentTypes = [
    {
      id: 1,
      type: 'Loading instruction',
      url: '/loading_instruction',
    }, {
      id: 2,
      type: 'Loadsheet',
      url: '/loadsheet',
    }, {
      id: 3,
      type: 'Notoc',
      url: '/notoc',
    },
  ];

  documentTypesManual = [
    {
      id: 1,
      type: 'Loadsheet',
      url: '/loadsheet',
    }
  ];

  // documentUrl = '/load_instruction_report';
  documentText = '';

  // Флаг, который блокирует кнопку печати, если точки вышли
  printDisabled = false;

  // Флаг - расчет по АХМ (true) или вручную (false)
  calculationByAhm = false;

  // Багажники
  locations = [];
  // Точки маршрута после домашнего
  destination = [];
  // Типы контейнеров
  uldTypes = [];
  // Типы компоновок экипажа
  crewComposition: Array<DowChangesCrew> = [];
  calculationCrewComposition: DowChangesCrew = new DowChangesCrew({cockpit: 0, cabin: 0, extra: 0, dow: 0, doi: 0});

  // Дельта
  deltaDowDoi = new DeltaDowDoi();
  enterFactDowDoiAutomatically = true;

  // Признак существующей корректной центровки для ВС
  calculationCorrect = false;

  flights: Flight[];
  flightsHash: string | Int32Array;
  flight: Flight = new Flight(this.globalSettings.homeAirport);
  calculation: CalculationAhm = new CalculationAhm();
  calculationManual: CalculationManual = new CalculationManual();
  ahms: Ahm[];
  ahmData: AhmData = new AhmData();
  chartData = [];

  references = {
    aircraft_types: [] as ReferanceAircraft[],
    airlines: [] as ReferanceAirline[],
    airports: [] as ReferanceAirport[],
    seasons: [],
    tails: [] as ReferanceTail[],
    movement_types: [] as ReferanceMovement[],
    // dow_items: []
  }

  impCodes = {
    dangerous: [],
    other: [],
  }

  selectLoadAnimation = {
    airlines: true,
    aircraft_types: true,
    airports: true,
    tails: true,
    movement_types: true,
    dangerous: true,
    other: true,
  }

  buffer = {
    tails: [],
    airlines: [],
    aircraft_types: [],
    airports: [],
  }

  // Размер отображаемых данных в выпадающем списке
  bufferSize = {
    tails: 50,
    airlines: 50,
    aircraft_types: 50,
    airports: 50,
  }

  numberOfItemsFromEndBeforeFetchingMore = 10;

  error = {
    errorMessage: '',
    errorType: '',
  }

  modalType = '';
  userAnswer = null;
  usesAction = '';

  filterParams: FilterParams = new FilterParams();
  filterApply = false;
  showFilter = false;
  searchFlight = '';
  messages: Array<string> = [];

  documentEditions: Array<Document> = [];
  currentDocumentEditions = null;
  currentDocumentView = null;

  // для setInterval обновления даты AHM
  ahmLastupdateInterval;
  loadFlightsInterval;

  //@ViewChild('gravityCentre', {static: false}) gravityCentre: ElementRef;
  @ViewChild('gravityCentreBlock', {static: false}) gravityCentreBlock: ElementRef;
  @ViewChild('gravityCentreBlockBig', {static: false}) gravityCentreBlockBig: ElementRef;
  @ViewChild("print") print!: ElementRef;

  public context: CanvasRenderingContext2D;
  public contextBig: CanvasRenderingContext2D;

  constructor(
    public restApi: WeightBalanceModuleRestApiService,
    private fileSaverService: FileSaverService,
    public globalSettings: GlobalSettings,
    public globalI18n: GlobalI18n,
    private logger: NGXLogger
  ) {
    globalSettings.loadDefaultConfig();
  }

  get today() {
    return new Date();
  }

  ngOnInit(): void {
    this.loadWindow();
    this.loadReferences();
    this.messages['current'] = this.globalI18n.getMessage(ModuleName.Schedule, 'current');
  }

  // Flights
  async loadFlights() {
    let xRequestId = this.globalSettings.randomUuid;
    this.logger.info('loadMessages',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.loadFlights',
                                  'call',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    clearInterval(this.ahmLastupdateInterval);
    await this.restApi.getFlights(this.filterApply ? this.filterParams : null, xRequestId).then(data => {
      this.md5.start();
      const newDataHash = data.length > 0 ? this.md5.appendStr(JSON.stringify(data)).end() : '';
      // Проверка хеша текущих и новых данных
      if (this.flightsHash !== newDataHash) {
        this.flights = [];
        data.forEach(el => {
          let flight = new Flight(this.globalSettings.homeAirport);
          Object.assign(flight, el);
          this.flights.push(flight);
        });
        // Обновление хеша сохраненных данных
        this.flightsHash = newDataHash;
        this.loading = false;
      }
    }, err => {
      this.displayError(err);
    });
  }

  get flightList() {
    if (this.flights && this.flights.length > 0) {
      return this.flights
      .filter(item => {
        if (this.searchFlight !== '') {
          return ((item.airlineIata && item.airlineIata.toLowerCase().indexOf(this.searchFlight.toLowerCase()) !== -1) ||
                  (item.flightNumber && item.flightNumber.toLowerCase().indexOf(this.searchFlight.toLowerCase()) !== -1));
        } else {
          return item;
        }
      });
    } else return [];
  }

  selectFirstTab() {
    document.querySelectorAll('.nav-tabs li a').forEach(el => el.classList.remove('active'));
    document.querySelector('.nav-tabs li:first-child a').classList.add('active');
    document.querySelectorAll('.tab-content > div').forEach(el => el.classList.remove('show', 'active'));
    document.querySelector('.tab-content > div:first-child').classList.add('show', 'active');
  }

  createFlight() {
    this.logger.info('createFlight',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.createFlight',
                                  'click',
                                  '',
                                  EventProvider.UI,
                                  '',
                                  ''));
    this.flight = new Flight(this.globalSettings.homeAirport);
    this.flightForm.form.markAsUntouched();
    this.flightForm.reset();
    this.calculationByAhm = false;
    this.selectFirstTab();
    this.flight.route[0].airportId = this.globalSettings.homeAirport;
    this.flight.route[0].airportIata = this.airportsSortIata.find(el => el.id === this.globalSettings.homeAirport).iata;
  }

  async selectFlight(id) {
    let xRequestId = this.globalSettings.randomUuid;
    this.logger.info('selectFlight',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.selectFlight',
                                  'call',
                                  id,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    // if (this.flight.id != id) {
      this.flightForm.form.markAsUntouched();
      this.flightForm.reset();
      this.selectFirstTab();
      await this.restApi.getFlight(id, xRequestId).then(flight => {
        this.flight = new Flight(this.globalSettings.homeAirport);
        Object.assign(this.flight, flight);
        this.findAircraftTypeByTail(this.flight.isAhmCalc);
        if (this.flight.route) {
           this.flight.route = [];
           this.destination = [];
           let flag = false;
           flight.route.forEach(el => {
             let route = new Route();
             Object.assign(route, el);
             route.fixData();
             if (flag) this.destination.push(route)
             this.flight.route.push(route);
             if (route.airportId === this.globalSettings.homeAirport) flag = true
         });
        }

      }, err => {
        this.displayError(err);
      });
    // }
  }

  addFlight() {
    let xRequestId = this.globalSettings.randomUuid;
    this.logger.info('openCalculation',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.openCalculation',
                                  'click',
                                  '',
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    this.loading = true;
    this.flightForm.form.markAllAsTouched();
    if (this.flightForm.valid) {
      let airports = this.flight.route.map(el => el.airportId);
      if (!airports.includes(this.globalSettings.homeAirport)) {
        this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'noBaseAirport');
        this.error.errorType = 'error';
        return;
      }
      return this.restApi.addFlight(this.flight, xRequestId).subscribe(async (data: HttpResponse<any>) => {
        // Получение данных из заголовков ответа
        // идентификатор рейса будет в нем как /flights/110008765
        const location = data.headers.get('Location');
        // Регулярное выражение, в 2 группе будет идентификатор рейса
        let getFlightIdFromHeader = new RegExp("(\/flights\/)([0-9]*)", "g");
        let match = getFlightIdFromHeader.exec(location);
        this.flight.id = +match[2];
        // Закрытие окна редактирования карточки рейса
        this.closebutton.nativeElement.click();
        await this.loadFlights().then(async (data) => {
          // Открытие расчета для вновь созданного рейса
          // await this.openCalculation(match[2]);
          this.loading = false;
        });
      }, err => {
        this.displayError(err);
        this.loading = false;
      });
    } else {
      this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'missingRequiredFields');
      this.error.errorType = 'error';
      this.loading = false;
      return;
    }
  }

  editFlight() {
    this.logger.info('editFlight',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.editFlight',
                                  'click',
                                  '',
                                  EventProvider.UI,
                                  '',
                                  ''));
  }

  async openCalculation(flightId) {
    let xRequestId = this.globalSettings.randomUuid;
    this.logger.info('openCalculation',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.openCalculation',
                                  'click',
                                  flightId,
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    this.documentText = '';
    this.calculationCorrect = false;
    await this.selectFlight(flightId).then(async () => {
      if (this.flight.isAhmCalc) {
        this.loadImpCodes();
        await this.editCalculationAhm(flightId);
      } else {
        await this.editCalculationManual(flightId);
      }
    });
    document.addEventListener('keypress', this.autoCalculate);
  }

  autoCalculate = this.autoCalculateFunc.bind(this);
  autoCalculateFunc(event) {
    if (event.key === 'Enter' && event.target.nodeName.toLowerCase() !== 'textarea') {
      this.calculate();
    }
  }

  saveFlight() {
    let xRequestId = this.globalSettings.randomUuid;
    this.logger.info('openCalculation',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.openCalculation',
                                  'click',
                                  '',
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    this.flightForm.form.updateValueAndValidity();
    this.flightForm.form.markAllAsTouched();
    if (this.flightForm.valid) {
      let airports = this.flight.route.map(el => el.airportId);
      if (!airports.includes(this.globalSettings.homeAirport)) {
        this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'noBaseAirport');
        this.error.errorType = 'error';
        return;
      }
      this.loading = true;
      return this.restApi.updateFlight(this.flight, xRequestId).then((data: {}) => {
        this.closebutton.nativeElement.click();
        this.loadFlights();
        this.loading = false;
        if (this.flight.route) {
           this.destination = [];
           let flag = false;
           this.flight.route.forEach(el => {
             let route = new Route();
             Object.assign(route, el);
             if (flag) this.destination.push(route);
             if (route.airportId === this.globalSettings.homeAirport) flag = true;
         });
        }
        this.calculation.passengers.weights.handLuggage = this.flight.loading.handLuggage;
        this.calculationManual.passengers.weights.handLuggage = this.flight.loading.handLuggage;
      }, err => {
        this.displayError(err);
        this.loading = false;
      });
    } else {
      this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'missingRequiredFields');
      this.error.errorType = 'error';
      this.loading = false;
      return;
    }
  }

  async editCalculationAhm(id, changeTab?: boolean) {
    let xRequestId = this.globalSettings.randomUuid;
    this.logger.info('editCalculationAhm',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.editCalculationAhm',
                                  'call',
                                  id,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    this.activeWindow = 'calculation';
    clearInterval(this.loadFlightsInterval);
    // По умолчанию вкладка всегда переключается на 1 в форме
    // если передано значение, то остаемся на текущий вкладке
    if (changeTab == null) {
      this.activeTab = 'flight-tab-info';
    }
    this.calculation = new CalculationAhm();
    this.calculationCrewComposition = new DowChangesCrew({cockpit: 0, cabin: 0, extra: 0, dow: 0, doi: 0});
    this.deltaDowDoi = new DeltaDowDoi();

    await this.loadAhmData();

    this.ahmLastupdateInterval = setInterval(() => {
      if (this.flight?.id) {
        this.getAhmLastupdate(this.flight.tailId);
      }
    }, 30000);

    let dataWell = this.ahmData.centreOfGravityChart[0];

    this.chartData = [];
    await this.restApi.getCalculation(id, xRequestId).then( async (data) => {
      this.usesAction = 'editCalculationAhm';
      this.calculation = JSON.parse(JSON.stringify(data));
      this.calculation.passengers.cabinArea = [];
      data.passengers.cabinArea.forEach(el => {
        el.index = this.ahmData.configurations
                  .find(conf => conf.name === this.calculation.configuration).sections
                  .find(section => section.name === el.name).index;
        this.calculation.passengers.cabinArea.push(Object.assign(new CabinArea(), el));
      });
      await this.restApi.getChart(this.calculation, xRequestId).then(charts => {
        this.chartData = charts;
      });
      for (const crew of this.crewComposition) {
        if (crew.cabin === this.calculation.crew.schema.cabin &&
            crew.cockpit === this.calculation.crew.schema.cockpit &&
            crew.extra === this.calculation.crew.schema.extra) {
          this.calculationCrewComposition = new DowChangesCrew(crew);
          this.deltaDowDoi.standard.dow = crew.dow;
          this.deltaDowDoi.standard.doi = crew.doi;
          break;
        } else if (crew.cabin === this.calculation.crew.schema.cabin &&
                   crew.cockpit === this.calculation.crew.schema.cockpit &&
                   !crew.extra) {
          this.calculationCrewComposition = new DowChangesCrew(crew);
          this.deltaDowDoi.standard.dow = crew.dow;
          this.deltaDowDoi.standard.doi = crew.doi;
          break;
        }
      }
      if (this.calculation.dow == this.deltaDowDoi.calculated.dow &&
          this.calculation.doi == this.deltaDowDoi.calculated.doi) {
        this.enterFactDowDoiAutomatically = true;
      } else {
        this.enterFactDowDoiAutomatically = false;
      }
      dataWell = this.calculation.centreOfGravityChart;
    },
    error => {
      this.usesAction = 'addCalculation';
      this.enterFactDowDoiAutomatically = true;
      this.setDefaultCalculation();
    });

    this.documentEditions = [];
    this.currentDocumentEditions = null;
    this.getDocumentEditions();

    if (this.gravityCentreBlock) {
      let width = this.gravityCentreBlock.nativeElement.clientWidth;
      this.context = createCanvas(width, width, this.gravityCentreBlock.nativeElement);
      this.printDisabled = drawGravity(width, width, dataWell, this.chartData, this.ahmData, this.context);
    }

    if (this.gravityCentreBlockBig) {
      let height = document.documentElement.clientHeight - 100;
      this.contextBig = createCanvas(height, height, this.gravityCentreBlockBig.nativeElement);
      this.printDisabled = drawGravity(height, height, dataWell, this.chartData, this.ahmData, this.contextBig);
    }
    this.getDeltaDowDoi(xRequestId);

    this.checkCalculationCorrect();
  }

  async editCalculationManual(id) {
    let xRequestId = this.globalSettings.randomUuid;
    this.logger.info('editCalculationManual',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.editCalculationManual',
                                  'call',
                                  id,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    this.activeWindow = 'calculation-manual';
    clearInterval(this.loadFlightsInterval);
    this.calculationManual= new CalculationManual();
    this.calculationManual.flightId = this.flight.id;

    await this.restApi.getCalculationManual(id, xRequestId).then( async (data) => {
      this.usesAction = 'editCalculationManual';
      this.calculationManual = JSON.parse(JSON.stringify(data));
      this.calculationManual.passengers.cabinArea = [];
      data.passengers.cabinArea.forEach(el => {
        el.index = this.ahmData.configurations
                  .find(conf => conf.name === this.calculation.configuration).sections
                  .find(section => section.name === el.name).index;
        this.calculationManual.passengers.cabinArea.push(Object.assign(new CabinArea(), el));
      });
      for (const crew of this.crewComposition) {
        if (crew.cabin === this.calculationManual.crew.schema.cabin &&
            crew.cockpit === this.calculationManual.crew.schema.cockpit &&
            crew.extra === this.calculationManual.crew.schema.extra) {
          this.calculationCrewComposition = new DowChangesCrew(crew);
          this.deltaDowDoi.standard.dow = crew.dow;
          this.deltaDowDoi.standard.doi = crew.doi;
          break;
        } else if (crew.cabin === this.calculationManual.crew.schema.cabin &&
                   crew.cockpit === this.calculationManual.crew.schema.cockpit &&
                   !crew.extra) {
          this.calculationCrewComposition = new DowChangesCrew(crew);
          this.deltaDowDoi.standard.dow = crew.dow;
          this.deltaDowDoi.standard.doi = crew.doi;
          break;
        }
      }
    },
    error => {
      this.usesAction = 'addCalculationManual';
      this.calculationManual.passengers.weights.handLuggageIncluded = true;
      this.calculationManual.passengers.weights.handLuggage = this.flight.loading.handLuggage || null;
    });

    this.documentEditions = [];
    this.currentDocumentEditions = null;
    this.getDocumentEditions();

    //this.checkCalculationCorrect();
  }

  async loadAhmData() {
    let xRequestId = this.globalSettings.randomUuid;
    this.logger.info('loadAhmData',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.loadAhmData',
                                  'call',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    await this.restApi.getAhmData(this.flight.tailId, this.flight.id, xRequestId).then(data => {
      this.ahmData = new AhmData();
      Object.assign(this.ahmData, data);
      this.locations = [];
      this.ahmData.holdsAndCompartments.fwd.forEach(el => {
        if (el.index !== 0 && el.maxWeight !==0) this.locations.push(el);
        if (el.bays && el.bays.length > 0) {
          el.bays.forEach(bay => {
            this.locations.push(bay);
          });
        }
      });
      this.ahmData.holdsAndCompartments.aft.forEach(el => {
        if (el.index !== 0 && el.maxWeight !==0) this.locations.push(el);
        if (el.bays && el.bays.length > 0) {
          el.bays.forEach(bay => {
            this.locations.push(bay);
          });
        }
      });
      this.uldTypes = [];
      if (this.ahmData.uldTypes && this.ahmData.uldTypes.length > 0) {
        this.ahmData.uldTypes.forEach(el => {
          this.uldTypes.push(el);
        });
      }
      let bulkUld = new UldTypes;
      bulkUld.name = 'BULK';
      bulkUld.weight = 0;
      bulkUld.maxWeight = 0;
      bulkUld.maxVolume = 0;
      this.uldTypes.push(bulkUld);

      this.crewComposition = [];
      if (this.ahmData.dowChanges.crew && this.ahmData.dowChanges.crew.length > 0) {
        this.ahmData.dowChanges.crew.forEach( el => {
          this.crewComposition.push(new DowChangesCrew(el));
        })
      }
    }, err => {
      this.displayError(err);
    });
  }

  async reloadAhmData() {
    let xRequestId = this.globalSettings.randomUuid;
    this.logger.info('reloadAhmData',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.reloadAhmData',
                                  'click',
                                  '',
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'reloadAhmData');
    this.error.errorType = 'warning';
    this.modalType = 'setAnswer';
    await this.waitAnswer().then(async res => {
      if (res) {
        let id = this.calculation.id;
        let edno = this.calculation.edno;
        await this.loadAhmData();
        this.calculation = new CalculationAhm();
        this.calculation.id = id;
        this.calculation.edno = edno;
        this.calculationCrewComposition = new DowChangesCrew({cockpit: 0, cabin: 0, extra: 0, dow: 0, doi: 0});
        this.deltaDowDoi = new DeltaDowDoi();
        this.setDefaultCalculation();
        this.calculation.ahmLastupdate = this.ahmData.lastupdate;

        let dataWell = this.ahmData.centreOfGravityChart[0];

        this.chartData = [];

        let width = this.gravityCentreBlock.nativeElement.clientWidth;
        //this.context = createCanvas(width, width, this.gravityCentreBlock.nativeElement);
        this.printDisabled = drawGravity(width, width, dataWell, this.chartData, this.ahmData, this.context);

        let height = document.documentElement.clientHeight - 100;
        //this.contextBig = createCanvas(height, height, this.gravityCentreBlockBig.nativeElement);
        this.printDisabled = drawGravity(height, height, dataWell, this.chartData, this.ahmData, this.contextBig);
        this.checkCalculationCorrect();
      }
    });
  }

  getAhmLastupdate(id) {
    let xRequestId = this.globalSettings.randomUuid;
    this.logger.info('getAhmLastupdate',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.getAhmLastupdate',
                                  'call',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    this.restApi.getAhmData(id, this.flight.id, xRequestId).then(data => {
      this.ahmData.lastupdate = data.lastupdate;
    }, err => {
      this.displayError(err);
    });
  }

  setDefaultCalculation() {
    this.logger.info('setDefaultCalculation',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.setDefaultCalculation',
                                  'call',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  ''));
    this.calculation.flightId = this.flight.id;
    this.calculation.tailsAhmDataId = this.ahmData.id;
    this.calculation.mtow = this.ahmData.takeOffWeight;
    this.calculation.mlw = this.ahmData.landingWeight;
    this.calculation.ahmLastupdate = this.ahmData.lastupdate;
    this.calculation.fuel.taxi = this.ahmData.taxiFuel;
    this.calculation.fuel.onBoard = null;
    this.calculation.fuel.trip = null;
    this.calculation.fuel.ballast = null;
    this.calculation.mzfw = this.ahmData.zeroFuelWeight;
    // Установка плотности топлива по умолчанию, если задано в AHM
    for (const fuel of this.ahmData.effectOfFuel) {
      if (fuel.default) {
        this.calculation.fuelDensity = fuel.density;
        break;
      }
    }
    this.calculation.crew.cockpit.weight = this.ahmData.standartWeights.crew.cockpit.weight;
    this.calculation.crew.cockpit.handLuggage = this.ahmData.standartWeights.crew.cockpit.handLuggage;
    this.calculation.crew.cabin.weight = this.ahmData.standartWeights.crew.cabin.weight;
    this.calculation.crew.cabin.handLuggage = this.ahmData.standartWeights.crew.cabin.handLuggage;
    // Поиск стандартного экипажа
    for (const crew of this.ahmData.dowChanges.crew) {
      if (crew.standard) {
        this.calculationCrewComposition = new DowChangesCrew(crew);
        this.deltaDowDoi.standard.dow = crew.dow;
        this.deltaDowDoi.standard.doi = crew.doi;
        this.calculation.crew.schema.cabin = crew.cabin;
        this.calculation.crew.schema.cockpit = crew.cockpit;
        this.calculation.crew.schema.extra = crew.extra;
        this.calculation.crew.cabin.amount = crew.cabin;
        this.calculation.crew.cockpit.amount = crew.cockpit;
        // this.calculation.dow = crew.dow;
        // this.calculation.doi = crew.doi;
        this.calculation.dow = this.deltaDowDoi.calculated.dow;
        this.calculation.doi = this.deltaDowDoi.calculated.doi;
        break;
      }
    }
    // Если стандартного экипажа нету, берет первый
    if (this.calculation.dow === null && this.ahmData.dowChanges.crew.length > 0) {
      this.calculationCrewComposition = new DowChangesCrew(this.ahmData.dowChanges.crew[0]);
      this.deltaDowDoi.standard.dow = this.ahmData.dowChanges.crew[0].dow;
      this.deltaDowDoi.standard.doi = this.ahmData.dowChanges.crew[0].doi;
      this.calculation.crew.schema.cabin = this.ahmData.dowChanges.crew[0].cabin;
      this.calculation.crew.schema.cockpit = this.ahmData.dowChanges.crew[0].cockpit;
      this.calculation.crew.schema.extra = this.ahmData.dowChanges.crew[0].extra;
      this.calculation.crew.cabin.amount = this.ahmData.dowChanges.crew[0].cabin;
      this.calculation.crew.cockpit.amount = this.ahmData.dowChanges.crew[0].cockpit;
      // this.calculation.dow = this.ahmData.dowChanges.crew[0].dow;
      // this.calculation.doi = this.ahmData.dowChanges.crew[0].doi;
      this.calculation.dow = this.deltaDowDoi.calculated.dow;
      this.calculation.doi = this.deltaDowDoi.calculated.doi;
    }

    if (this.ahmData.configurations && this.ahmData.configurations.length === 1) {
      this.changeVersionAhm(this.ahmData.configurations[0]);
    }

    let season = this.getSeason(this.flight.homeRoute.dtDepartureScheduled);
    console.log('season', season);


    this.calculation.passengers.weights = {...this.ahmData.standartWeights.passengers[season]}
    this.calculation.passengers.weights.handLuggageIncluded = this.ahmData.standartWeights.passengers.handLuggageIncluded;
    this.calculation.passengers.weights.handLuggage = this.flight.loading.handLuggage || null;

    // Добавление записей в таблицу распределения груза при первом расчете
    // функционал отключен, так как центровщики сначала вводят пассажиров и
    // топливо, далее делают предварительный расчет и после понимания текущей
    // схемы загрузки начинают распределять вес в переднюю или заднюю часть ВС.
    // Сейчас приходится сначала удалять пункту по умолчанию и потом их добавлять.
    // for (let i = 0; i < this.flight.route.length; i++) {
    //   let route = this.flight.route[i];
    //   if (this.flight.isDeparture(route.airportId)) {
    //     if (route.loading.baggageWeight > 0) {
    //       this.addLoadHoldsAndCompartments({destination: route.airportIata, type: 'Baggage', weight: route.loading.baggageWeight});
    //     }
    //     if (route.loading.cargo > 0) {
    //       this.addLoadHoldsAndCompartments({destination: route.airportIata, type: 'Cargo', weight: route.loading.cargo});
    //     }
    //     if (route.loading.mail > 0) {
    //       this.addLoadHoldsAndCompartments({destination: route.airportIata, type: 'Mail', weight: route.loading.mail});
    //     }
    //   }
    // }

    let pantry = this.ahmData.dowChanges.pantry.find(el => el.standard);
    if (pantry) {
      this.changeDowChanges(pantry, 'pantry')
    }

    let water = this.ahmData.dowChanges.potableWater.find(el => el.standard);
    if (water) {
      this.changeDowChanges(water, 'potableWater')
    }

  }

  /**
   * Функция установки компановки
   * @param {string} event вариант компановки
   */
  changeVersionAhm(event) {
    this.logger.info('changeVersionAhm',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.changeVersionAhm',
                                  'change',
                                  event,
                                  EventProvider.UI,
                                  '',
                                  ''));
    if (event) {
      this.calculation.configuration = event.name;
      this.calculation.passengers.cabinArea = [];
      event.sections.forEach(section => {
        let area = new CabinArea(section);
        this.calculation.passengers.cabinArea.push(area);
      });
    } else return;
  }

  changeDowChanges(event, name) {
    if (event) {
      this.deltaDowDoi[name].standardDow = this.ahmData.dowChanges[name].find(el => el.standard).weight || 0;
      this.deltaDowDoi[name].standardDoi = this.ahmData.dowChanges[name].find(el => el.standard).index || 0;
      if (event.standard) {
        this.calculation.dowChanges[name].code = event.code;
        this.deltaDowDoi[name].dow = 0;
        this.deltaDowDoi[name].doi = 0;
      } else {
        this.calculation.dowChanges[name].code = event.code;
        this.deltaDowDoi[name].dow = +(event.weight.toFixed(3));
        this.deltaDowDoi[name].doi = +(event.index.toFixed(3));
      }
    } else {
      this.calculation.dowChanges[name].code = '';
      this.deltaDowDoi[name].dow = 0;
      this.deltaDowDoi[name].doi = 0;
      this.deltaDowDoi[name].standardDow = 0;
      this.deltaDowDoi[name].standardDoi = 0;
    }

    if (this.enterFactDowDoiAutomatically) {
      this.calculation.dow = this.deltaDowDoi.calculated.dow;
      this.calculation.doi = this.deltaDowDoi.calculated.doi;
    }
    // this.calculation.dow = this.deltaDowDoi.calculated.dow;
    // this.calculation.doi = this.deltaDowDoi.calculated.doi;
  }

  async getDeltaDowDoi(xRequestId) {
    if (this.calculation.fuel.ballast > 0) {
      await this.restApi.getDeltaBallast(this.calculation, xRequestId).then(ballast => {
        this.deltaDowDoi.ballast = {dow: ballast.deltaDow, doi: ballast.deltaDoi};
      }, err => {
        this.displayError(err);
      });
    } else {
      this.deltaDowDoi.ballast = {dow: 0, doi: 0};
    }
    let pantry = this.ahmData.dowChanges.pantry.find(el => el.code === this.calculation.dowChanges.pantry.code);
    this.changeDowChanges(pantry, 'pantry');
    let potableWater = this.ahmData.dowChanges.potableWater.find(el => el.code === this.calculation.dowChanges.potableWater.code);
    this.changeDowChanges(potableWater, 'potableWater');
  }

  async calculate() {
    let xRequestId = this.globalSettings.randomUuid;
    this.logger.info('calculate',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.calculate',
                                  'call',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    this.getDeltaDowDoi(xRequestId);
    return new Promise((resolve) => {
      this.documentText = '';
      this.calculation.lastupdate = new Date();
      this.restApi.calculate(this.calculation, xRequestId).subscribe(async data => {
        this.calculation = JSON.parse(JSON.stringify(data));
        this.calculation.lastupdate = new Date();
        //Object.assign(this.calculation, data);
        this.calculation.passengers.cabinArea = [];
        data.passengers.cabinArea.forEach(el => {
          el.index = this.ahmData.configurations
                    .find(conf => conf.name === this.calculation.configuration).sections
                    .find(section => section.name === el.name).index;
          this.calculation.passengers.cabinArea.push(Object.assign(new CabinArea(), el))
        });
        await this.restApi.getChart(this.calculation, xRequestId).then(charts => {
          this.chartData = charts;
          let width = this.gravityCentreBlock.nativeElement.clientWidth;
          this.printDisabled = drawGravity(width, width, this.calculation.centreOfGravityChart, this.chartData, this.ahmData, this.context);
          let height = document.documentElement.clientHeight - 100;
          this.printDisabled = drawGravity(height, height, this.calculation.centreOfGravityChart, this.chartData, this.ahmData, this.contextBig);
          this.checkCalculationCorrect();
        }, err => {
          this.displayError(err);
        });

        resolve(this.calculation);
      }, err => {
        this.displayError(err);
      });
    });
  }

  async validationCalculate(calc, makeDocuments?: boolean) {
    this.logger.info('validationCalculate',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.validationCalculate',
                                  'call',
                                  [calc, makeDocuments],
                                  EventProvider.APPLICATION,
                                  '',
                                  ''));
    this.error.errorMessage = '';

    // Check version
    if (!calc.configuration) {
      this.error.errorType = 'error';
      this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'enterVersionAircraft');
      return false;
    }

    // Check fuel
    if (!calc.fuel.onBoard || !calc.fuel.taxi || !calc.fuel.trip || !calc.fuelDensity) {
      this.error.errorType = 'error';
      this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'enterFuelData');
      return false;
    }

    if (calc.fuel.ballast > 0 && (calc.fuel.onBoard - calc.fuel.taxi - calc.fuel.trip) < calc.fuel.ballast) {
      this.error.errorType = 'error';
      this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'fuelWeightDiscrepancy');
      return false;
    }

    // Check composition and DOW/DOI
    if (!calc.dow || !calc.doi || (!calc.crew.schema.cabin && !calc.crew.schema.cockpit)) {
      this.error.errorType = 'error';
      this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'enterCrewComposition');
      return false;
    }

    // Check weight
    if (!calc.mtow || !calc.mlw || !calc.mzfw) {
      this.error.errorType = 'error';
      this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'enterWeightData');
      return false;
    }

    // check compartments

    let errCompartment = false;
    this.calculation.holdsAndCompartments.forEach(compartment => {
      if (compartment.name === '') {
        errCompartment = true;
      }
    });

    if (errCompartment) {
      this.error.errorType = 'error';
      this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'noLuggageCompartmentsSelected');
      return false;
    }

    this.ahmData.holdsAndCompartments.aft.forEach(compartment => {
      if (compartment.maxWeight > 0 && this.getLoadCompartment(compartment.name) > compartment.maxWeight) {
        errCompartment = true;
      }
      compartment.bays.forEach(bay => {
        if (this.getLoadWeight(bay.name) > bay.maxWeight) {
          errCompartment = true;
        }
      })
    });

    this.ahmData.holdsAndCompartments.fwd.forEach(compartment => {
      if (compartment.maxWeight > 0 && this.getLoadCompartment(compartment.name) > compartment.maxWeight) {
        errCompartment = true;
      }
      compartment.bays.forEach(bay => {
        if (this.getLoadWeight(bay.name) > bay.maxWeight) {
          errCompartment = true;
        }
      })
    });

    if (errCompartment) {
      this.error.errorType = 'error';
      this.error.errorMessage += this.globalI18n.getMessage(ModuleName.WeightBalance, 'compartmentsOverloaded');
      return false;
    }

    let errLimitCompartment = false;
    this.ahmData.holdsAndCompartments.limits.forEach(compartment => {
      let limit = compartment.compartments.reduce((acc, el) => acc += this.getLoadCompartment(el), 0);
      if (limit > compartment.maxWeight) {
        this.error.errorMessage +=
                    this.globalI18n.getMessage(ModuleName.WeightBalance, 'summaryWeightCompartment')
                    + ' ' + compartment.compartments
                    + ' ' + this.globalI18n.getMessage(ModuleName.WeightBalance, 'cannotExceedValue')
                    + ' ' + compartment.maxWeight + '\n';
        errLimitCompartment = true;
      }
    });

    if (errLimitCompartment) {
      this.error.errorType = 'error';
      return false;
    }

    // Check Passengers
    let errPassengers = false;
    this.calculation.passengers.cabinArea.forEach((el) => {
      if (el.maxPassengers < el.passengers.adult) {
        errPassengers = true;
      }
    });

    if (errPassengers) {
      this.error.errorType = 'error';
      this.error.errorMessage += this.globalI18n.getMessage(ModuleName.WeightBalance, 'maxValueCabinSectionExceeded');
      return false;
    }

    // Проверка на превышение весов только в момент создания документов для
    // экипажа
    if (makeDocuments) {
      if (calc.tow > calc.mtow) {
        this.error.errorType = 'error';
        this.error.errorMessage += this.globalI18n.getMessage(ModuleName.WeightBalance, 'maxTow');
        return false;
      }

      if (calc.lw > calc.mlw) {
        this.error.errorType = 'error';
        this.error.errorMessage += this.globalI18n.getMessage(ModuleName.WeightBalance, 'maxLw');
        return false;
      }
    }

    // Check payload and seating
    let warning = false;

    if (this.allLoadWeight() != this.flight.loading.luggage + this.flight.loading.cargo + this.flight.loading.mail) {
      this.error.errorMessage += this.globalI18n.getMessage(ModuleName.WeightBalance, 'warningPayload') + '\n';
      warning = true;
    }
    if (this.placesDeclared() + this.calculation.crew.additional.inCabin != this.getTotalPassenger()) {
      this.error.errorMessage += this.globalI18n.getMessage(ModuleName.WeightBalance, 'warningZoneSeating') + '\n';
      warning = true;
    }

    let validate = true;

    if (warning) {
      this.error.errorMessage += this.globalI18n.getMessage(ModuleName.WeightBalance, 'continue');
      this.error.errorType = 'warning';
      this.modalType = 'setAnswer'
      await this.waitAnswer().then(async res => {
        if (res) {
          validate = true;
        } else {
          validate = false;
        }
      });
    }

    return validate;
  }

  async validationManualCalculate(calc, makeDocuments?: boolean) {
    this.logger.info('validationManualCalculate',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.validationManualCalculate',
                                  'call',
                                  [calc, makeDocuments],
                                  EventProvider.APPLICATION,
                                  '',
                                  ''));
    this.error.errorMessage = '';

    let warning = false;

    // Check Passengers
    // if (calc.passengers.weights.adult + calc.passengers.weights.female + calc.passengers.weights.child > calc.ll) {
    //   this.error.errorMessage += this.globalI18n.getMessage(ModuleName.WeightBalance, 'zoneSeatindError') + '\n';
    //   warning = true;
    // }

    // Check Passengers
    if (!calc.seats.max) {
      if (this.flight.loading.adult + this.flight.loading.child > calc.seats.current) {
        this.error.errorMessage += this.globalI18n.getMessage(ModuleName.WeightBalance, 'zoneSeatindError') + '\n';
        warning = true;
      }
    } else {
      if (this.flight.loading.adult + this.flight.loading.child > calc.seats.max) {
        this.error.errorMessage += this.globalI18n.getMessage(ModuleName.WeightBalance, 'zoneSeatindError') + '\n';
        warning = true;
      }
      if (calc.seats.current > calc.seats.max) {
        this.error.errorMessage += this.globalI18n.getMessage(ModuleName.WeightBalance, 'totalPassengersMoreMax') + '\n';
        warning = true;
      }
    }
    let validate = true;

    if (warning) {
      this.error.errorMessage += this.globalI18n.getMessage(ModuleName.WeightBalance, 'continue');
      this.error.errorType = 'warning';
      this.modalType = 'setAnswer'
      await this.waitAnswer().then(async res => {
        if (res) {
          validate = true;
        } else {
          validate = false;
        }
      });
    }

    return validate;
  }

  async addCalculate() {
    let xRequestId = this.globalSettings.randomUuid;
    this.logger.info('addCalculate',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.addCalculate',
                                  'click',
                                  '',
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    let res = await this.validationCalculate(this.calculation);
    if (res) {
      await this.calculate().then(async (data) => {
        await this.restApi.addCalculate(this.calculation, xRequestId).then(async (data) => {
          // Получение данных из заголовков ответа
          const location = data.headers.get('Location');
          // Регулярное выражение, в 2 группе будет идентификатор расчета
          let getIdFromHeader = new RegExp("(\/calculations\/)([0-9]*)", "g");
          this.usesAction = 'editCalculationAhm';
          this.calculation.id = +getIdFromHeader.exec(location)[2];
          await this.editCalculationAhm(this.flight.id, false);
        }, err => {
          this.displayError(err);
        });
      });
    }
  }

  async addCalculateManual() {
    let xRequestId = this.globalSettings.randomUuid;
    this.logger.info('addCalculateManual',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.addCalculateManual',
                                  'click',
                                  '',
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    let res = await this.validationManualCalculate(this.calculationManual);
    if (res) {
      await this.restApi.updateFlight(this.flight, xRequestId);
      this.restApi.addCalculateManual(this.calculationManual, xRequestId).subscribe(async (data) => {
        // Получение данных из заголовков ответа
        const location = data.headers.get('Location');
        // Регулярное выражение, в 2 группе будет идентификатор расчета
        let getIdFromHeader = new RegExp("(\/manual_loadsheet\/)([0-9]*)", "g");
        let id = getIdFromHeader.exec(location)[2]
        this.calculationManual.id = +id;
        this.usesAction = 'editCalculationManual';
        this.editCalculationManual(this.flight.id);
      }, err => {
        this.displayError(err);
      });
    }
  }

  async saveCalculate(id, res?) {
    let xRequestId = this.globalSettings.randomUuid;
    this.logger.info('saveCalculate',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.saveCalculate',
                                  'click',
                                  '',
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    res = res || await this.validationCalculate(this.calculation);
    if (res) {
      await this.calculate().then(async (data) => {
        await this.restApi.updateCalculate(id, this.calculation, xRequestId).then(data => {
        }, err => {
          this.displayError(err);
        });
      });
    }
  }

  async saveCalculateManual(id) {
    let xRequestId = this.globalSettings.randomUuid;
    this.logger.info('saveCalculateManual',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.saveCalculateManual',
                                  'click',
                                  id,
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    let res = await this.validationManualCalculate(this.calculationManual);
    if (res) {
      await this.restApi.updateFlight(this.flight, xRequestId);
      this.restApi.updateCalculateManual(id, this.calculationManual, xRequestId).then(data => {
      }, err => {
        this.displayError(err);
      });
    }
  }

  changeHandBaggage(type: string) {
    this.logger.info('changeHandBaggage',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.changeHandBaggage',
                                  'click',
                                  !this.calculation.passengers.weights.handLuggageIncluded,
                                  EventProvider.UI,
                                  '',
                                  ''));
    if (type === 'ahm') {
      let state = !this.calculation.passengers.weights.handLuggageIncluded;
      this.calculation.passengers.weights.handLuggageIncluded = state;
      if (state) this.calculation.passengers.weights.handLuggage = this.flight.loading.handLuggage;
    } else if (type === 'manual') {
      let state = !this.calculationManual.passengers.weights.handLuggageIncluded;
      this.calculationManual.passengers.weights.handLuggageIncluded = state;
      if (state) this.calculationManual.passengers.weights.handLuggage = this.flight.loading.handLuggage;
    }
  }

  changeFktIncluded() {
    this.logger.info('changeFktIncluded',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.changeFktIncluded',
                                  'click',
                                  !this.calculation.fktIncludedInDowDoi,
                                  EventProvider.UI,
                                  '',
                                  ''));
    this.calculation.fktIncludedInDowDoi = !this.calculation.fktIncludedInDowDoi;
  }

  changeNotocNil() {
    this.logger.info('changeNotocNil',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.changeNotocNil',
                                  'click',
                                  !this.calculation.notoc.releasedByAnother,
                                  EventProvider.UI,
                                  '',
                                  ''));
    this.calculation.notoc.releasedByAnother = !this.calculation.notoc.releasedByAnother;
  }

  async printDocument(url) {
    let xRequestId = this.globalSettings.randomUuid;
    this.logger.info('printDocument',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.printDocument',
                                  'click',
                                  url,
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    await this.restApi.loadDocument(this.flight.id, this.currentDocumentEditions, url, xRequestId).then(data => {
      this.documentText = data.text;
      // Пауза перед печатью, что бы ангуляр обновил элемент из которого берется текс для печати
      setTimeout(() => {
        this.print.nativeElement.click();
      }, 250);
    }, err => {
      this.displayError(err);
    });
  }

  async loadDocument(url: string){
    let xRequestId = this.globalSettings.randomUuid;
    this.logger.info('loadDocument',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.loadDocument',
                                  'click',
                                  url,
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    await this.restApi.loadDocument(this.flight.id, this.currentDocumentEditions, url, xRequestId).then(data => {
      this.documentText = data.text;
    }, err => {
      this.displayError(err);
    });
  }

  async createDocuments() {
    let xRequestId = this.globalSettings.randomUuid;
    this.logger.info('createDocuments',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.createDocuments',
                                  'click',
                                  '',
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    if (this.activeWindow == 'calculation') {
      let res = await this.validationCalculate(this.calculation, true);
      if (res) {
        this.loadingType = 'createDocuments';
        // Перед формированием документов добавление, если расчетов не было
        if (this.usesAction == 'addCalculation') {
          await this.addCalculate();
        } else {
          await this.saveCalculate(this.calculation.id, res);
        }
        return this.restApi.createDocuments(this.flight.id, xRequestId).subscribe(data => {
          // После удочного создания новой редакции документов
          // нужно обновить порядковый номер документов в расчете
          this.calculation.edno++;
          this.saveCalculate(this.calculation.id, res);
          this.getDocumentEditions();
          this.loadingType = '';
        }, err => {
          this.displayError(err);
          this.loadingType = '';
        });
      }
    } else {
      this.loadingType = 'createDocuments';
      if (this.usesAction == 'addCalculationManual') {
        await this.addCalculateManual();
      } else {
        await this.saveCalculateManual(this.calculationManual.id);
      }
      return this.restApi.createDocuments(this.flight.id, xRequestId).subscribe(data => {
        // После удочного создания новой редакции документов
        // нужно обновить порядковый номер документов в расчете
        this.calculationManual.edno++;
        this.saveCalculateManual(this.calculationManual.id);
        this.getDocumentEditions();
        this.loadingType = '';
      }, err => {
        this.displayError(err);
        this.loadingType = '';
      });
    }
  }

  saveDocument(url: string) {
    let xRequestId = this.globalSettings.randomUuid;
    this.logger.info('saveDocument',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.saveDocument',
                                  'click',
                                  url,
                                  EventProvider.UI,
                                  '',
                                  xRequestId));
    return this.restApi.saveDocument(this.flight.id, this.currentDocumentEditions, url, xRequestId).subscribe(data => {
      const blob = new Blob([data], { type: 'application' });
      this.fileSaverService.save(blob, this.flight.airlineIata +
                                 this.flight.flightNumber + '_' +
                                 this.flight.homeRoute.dtDepartureScheduled + '_'
                                 + url + '.pdf');
    }, err => {
      this.displayError(err);
    });
  }

  async getDocumentEditions() {
    let xRequestId = this.globalSettings.randomUuid;
    this.logger.info('getDocumentEditions',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.getDocumentEditions',
                                  'call',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    this.currentDocumentView = 0;
    this.documentText = null;
    return await this.restApi.getDocumentEditions(this.flight.id, xRequestId).then(data => {
      this.documentEditions = data;
      // Установка последней редакции активной в списке документов
      if (this.documentEditions.length > 0) {
        this.currentDocumentEditions = this.documentEditions[this.documentEditions.length - 1].edno;
      }
    }, err => {
      this.displayError(err);
    });
  }

  async loadReferences() {
    let xRequestId = this.globalSettings.randomUuid;
    this.logger.info('loadReferences',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.loadReferences',
                                  'call',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    for (const key in this.references) {
      if (Object.prototype.hasOwnProperty.call(this.references, key)) {
        this.selectLoadAnimation[key] = true;
        if (key == 'aircraft_types') {
          await this.restApi.getReference(key, xRequestId).then( aircrafts => {
            aircrafts.forEach(el => {
              let aicraft = new ReferanceAircraft();
              Object.assign(aicraft, el);
              this.references[key] = [...this.references[key], aicraft];
            });
          }, err => {
            this.displayError(err);
          });
        } else {
          await this.restApi.getReference(key, xRequestId).then(data => {
            this.references[key] = data;
          }, err => {
            this.displayError(err);
          });
        }
        this.selectLoadAnimation[key] = false;
      }
    }
  }

  async loadImpCodes() {
    let xRequestId = this.globalSettings.randomUuid;
    this.logger.info('loadImpCodes',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.loadImpCodes',
                                  'call',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    for (const key in this.impCodes) {
      if (Object.prototype.hasOwnProperty.call(this.impCodes, key)) {
        this.selectLoadAnimation[key] = true;
        await this.restApi.getReference('imp_codes/' + key, xRequestId).then(data => {
          this.impCodes[key] = data;
        }, err => {
          this.displayError(err);
        });
        this.selectLoadAnimation[key] = false;
      }
    }
  }

  getClDvByIata(iata) {
    return this.impCodes.dangerous.filter(el => el.iata === iata)[0]?.class || null;
  }

  changeImpCode(i) {
    this.logger.info('changeImpCode',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.changeImpCode',
                                  'click',
                                  i,
                                  EventProvider.UI,
                                  '',
                                  ''));
    this.calculation.notoc.dangerous[i].clDv = null;
    let impDrill = this.getClDvByIata(this.calculation.notoc.dangerous[i].impDrill)
    if (impDrill && impDrill.length === 1) {
      this.calculation.notoc.dangerous[i].clDv = impDrill[0]
    }
  }

  onScrollToEndNgSelect(name) {
    this.logger.info('onScrollToEndNgSelect',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.onScrollToEndNgSelect',
                                  'call',
                                  name,
                                  EventProvider.APPLICATION,
                                  '',
                                  ''));
    this.fetchMore(name);
  }

  onScrollNgSelect({ end }, name) {
    this.logger.info('onScrollNgSelect',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.onScrollNgSelect',
                                  'call',
                                  name,
                                  EventProvider.APPLICATION,
                                  '',
                                  ''));
    if (this.selectLoadAnimation[name] || this.references[name].length <= this.buffer[name].length) {
      return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.buffer[name].length) {
      this.fetchMore(name);
    }
  }

  private fetchMore(name) {
    const len = this.buffer[name].length;
    const more = this.references[name].slice(len, this.bufferSize[name] + len);
    this.selectLoadAnimation[name] = true;
    this.buffer[name] = this.buffer[name].concat(more);
    this.selectLoadAnimation[name] = false;
  }

  /**
   * Функция обработки значения из редактируемой таблицы
   * @param {string} value Строка, введеная пользователем
   */
  toNumber(value) {
    let num = parseFloat(value.replace(/<\/?[^>]+(>|$)/g, "").replace(/^(-)|[^0-9.,]+/ig, '$1').replace(/^-+/g, '-').replace(',', '.'));
    return num || null;
  }

  /**
   * Функция поиска в выпадающим списке по нескольким параметрам
   * @param {string} term Строка для поиска введеня пользователем
   * @param {ReferanceAirline} item Элемент для поиска
   */
  customSelectSearch(term: string, item) {
    term = term.toLowerCase();
    return term.length < 4 ?
            (item.iata && item.iata.toLowerCase().indexOf(term) > -1) ||
            (item.code && item.code[1] && item.code[1].toLowerCase().indexOf(term) > -1)
          : term.length < 5 ?
            (item.icao && item.icao.toLowerCase().indexOf(term) > -1)
          :
            (item.name && item.name[0] && item.name[0].toLowerCase().indexOf(term) > -1) ||
            (item.name && item.name[1] && item.name[1].toLowerCase().indexOf(term) > -1)
    term = term.toLowerCase();
    if (term.length < 4) {
      return item.iata.toLowerCase().indexOf(term) > -1; // ||item.icao.toLowerCase().indexOf(term) > -1
    } else {
      return item.name[0].toLowerCase().indexOf(term) > -1 ||
             item.name[1].toLowerCase().indexOf(term) > -1;
    }
  }

  getById(array, id: number) {
    if (!array || array.length === 0) return null;
    let res = array.filter(el => el.id === id)[0];
    if (res) return res;
    else return null;
  }

  getProp(object, id, prop) {
    if(object && object.length > 0 && id) {
      let res = this.getById(object, id);
      if (res && res[prop])
        return res[prop];
      else
        return null;
    } else return null;
  }

  setProperty(prop, value) {
    if (prop = value) return true;
  }

  addItem(array, item) {
    array.push(item);
  }

  loadWindow(target = this.activeWindow) {
    this.logger.info('loadWindow',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.loadWindow',
                                  'click',
                                  target,
                                  EventProvider.UI,
                                  'Load flights list',
                                  ''));
    switch (target) {
      case 'flight-list': {
        this.activeWindow = target;
        document.removeEventListener('keypress', this.autoCalculate);
        this.loadFlights();
        this.loadFlightsInterval = setInterval(() => {
          this.loadFlights();
        }, 60000);
        break;
      }
    }
  }

  changeTab(event: { target: { id: string; }; }) {
    this.logger.info('changeTab',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.changeTab',
                                  'click',
                                  event.target.id,
                                  EventProvider.UI,
                                  '',
                                  ''));
    this.activeTab = event.target.id;
  }

  showTab(item: string): boolean {
    return item === this.activeTab;
  }

  showFlightCardTab(item: string): boolean {
    this.logger.info('showFlightCardTab',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.showFlightCardTab',
                                  'call',
                                  '',
                                  EventProvider.APPLICATION,
                                  '',
                                  ''));
    return item === this.flightCardActiveTab;
  }

  parseDate(dateString: string, time?): Date {
    if (time && dateString) {
      return new Date(dateString + 'T' + time);
    } else if (!time && dateString) {
      return new Date(dateString);
    }
    return null;
  }

  parseTime(date, dateString: string): Date {
    if (date.value && date.value + 'T' + dateString) {
      return new Date(date.value + 'T' + dateString);
    }
    return null;
  }

  addRoutePoint() {
    this.logger.info('addRoutePoint',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.addRoutePoint',
                                  'click',
                                  '',
                                  EventProvider.UI,
                                  '',
                                  ''));
    const num = this.flight.route.length;
    this.flight.route[num] = new Route();
    this.flight.route[num].order = num;
  }

  getLoadWeight(section) {
    return this.calculation.holdsAndCompartments
      .filter(el => el.name === section)
      .reduce((acc, el) => { return acc += el.weight + el.uldWeight }, 0);
  }

  /**
   * Функция получения веса загрузки по типу и точке маршрута
   * @param {string} type тип загрузки
   * @param {string} name точка маршрута
   */
  getLoadWeightByType(type, name = null) {
    if (name) {
      return this.calculation.holdsAndCompartments
        .filter(el => el?.destination === name && el.type[0]?.toLocaleUpperCase() === type.toLocaleUpperCase())
        .reduce((acc, el) => { return acc += el.weight}, 0);
    } else {
      return this.calculation.holdsAndCompartments
        .filter(el => el.type[0]?.toLocaleUpperCase() === type.toLocaleUpperCase())
        .reduce((acc, el) => { return acc += el.weight}, 0);
    }
  }

  /**
   * Функция получения веса загрузки по типу и багажнику
   * @param {string} type тип загрузки
   * @param {string} section багажник
   */
  getLoadCompartment(section, type = null) {
    if (type) {
      return this.calculation.holdsAndCompartments
        .filter(
          (el) =>
            el.name[0] === section &&
            el.type[0] &&
            el.type[0].toLocaleUpperCase() === type.toLocaleUpperCase()
        )
        .reduce((acc, el) => {
          return (acc += el.weight + el.uldWeight);
        }, 0);
    } else {
      return this.calculation.holdsAndCompartments
        .filter(el => el.name[0] === section)
        .reduce((acc, el) => { return acc += el.weight + el.uldWeight }, 0);
    }
  }

  findOverloadSection(compartment) {
    let names = [];
    let weight = 0;
    if (compartment.bays.length > 0) {
      this.calculation.holdsAndCompartments
          .filter(el => el.name[0] === compartment.name)
          .forEach(el => {
            let section = compartment.bays.find(bay => bay.name === el.name);
            if (section && this.getLoadWeight(el.name) > section.maxWeight && !names.includes(el.name)) {
              names.push(el.name);
              weight += this.getLoadWeight(el.name) - section.maxWeight;
            }
          });
    }
    if (this.getLoadWeight(compartment.name) > compartment.maxWeight) {
      names.push(compartment.name);
      weight += this.getLoadWeight(compartment.name) - compartment.maxWeight;
    }
    return {names: names.join(', '), weight};
  }

  allLoadWeightWithUld() {
    return this.calculation.holdsAndCompartments.reduce((acc, el) => {
      // if (el.type !== 'FKT' && el.type !== 'EIC' && el.type !== 'CSU' && el.type !== 'Ballast') acc += el.weight + el.uldWeight;
      if (el.type === 'Baggage' || el.type === 'Cargo' || el.type === 'Mail') {
        acc += el.weight + el.uldWeight;
      }
      return acc;
    }, 0);
  }

  allLoadWeight() {
    return this.calculation.holdsAndCompartments.reduce((acc, el) => {
      // if (el.type !== 'FKT' && el.type !== 'EIC' && el.type !== 'CSU' && el.type !== 'Ballast') acc += el.weight;
      if (el.type === 'Baggage' || el.type === 'Cargo' || el.type === 'Mail') {
        acc += el.weight;
      }
      return acc;
    }, 0);
  }

  addItemByClass(array, item, params?) {
    this.logger.info('addItemByClass',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.addItemByClass',
                                  'click',
                                  [array, item],
                                  EventProvider.UI,
                                  'Add load',
                                  ''));
    array.push(new item(params));
  }

  /**
   * Функция добавления строки загрузки
   * @param {string} destination направление
   * @param {string} type тип загрузки
   */
  addLoadHoldsAndCompartments(params?: Object) {
    this.logger.info('addLoad',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.addLoad',
                                  'click',
                                  [],
                                  EventProvider.UI,
                                  'Add load',
                                  ''));
    let item;
    if (params) {
      item = new HoldsAndCompartmentsCalc(params);
    } else {
      item = new HoldsAndCompartmentsCalc(this.destination && this.destination.length === 1 ? {destination: this.destination[0].displayName} : null);
    }
    this.calculation.holdsAndCompartments.push(item);
  }

  getSeason(date) {
    date  = new Date(date);
    const lastMarch = new Date('03-31-' + date.getFullYear());
    let day = lastMarch.getDay();

    let startSummer = lastMarch;
    if (day !== 0) {
      startSummer.setDate(startSummer.getDate() - day);
    }

    const lastOctober = new Date('10-31-' + date.getFullYear());
    day = lastOctober.getDay();
    let startWinter = lastOctober;
    if (day !== 0) {
      startWinter.setDate(startWinter.getDate() - day);
    }

    return date > startSummer && date < startWinter ? 'summer' : 'winter';

  }

  async deleteRoute(index) {
    this.logger.info('deleteRoute',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.deleteRoute',
                                  'click',
                                  '',
                                  EventProvider.UI,
                                  '',
                                  ''));
    if (this.flight.route.length < 3) {
      this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'warningRoute');
      this.error.errorType = 'error';
    } else {
      this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'deleteItem');
      this.error.errorType = 'warning';
      this.modalType = 'setAnswer';
      await this.waitAnswer().then(async res => {
        if (res) {
          this.flight.route.splice(index, 1);
        }
      });
    }

    this.flight.route[0].dtArrivalScheduled = null;
    this.flight.route[0].dtArrivalEstimated = null;
    this.flight.route[0].dtArrivalFact = null;

    this.flight.route[this.flight.route.length - 1].dtDepartureScheduled = null;
    this.flight.route[this.flight.route.length - 1].dtDepartureEstimated = null;
    this.flight.route[this.flight.route.length - 1].dtDepartureFact = null;
  }

  async deleteElement(array, index) {
    this.logger.info('deleteElement',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.deleteElement',
                                  'click',
                                  [array, index],
                                  EventProvider.UI,
                                  '',
                                  ''));
    this.error.errorMessage = this.globalI18n.getMessage(ModuleName.WeightBalance, 'deleteItem');
    this.error.errorType = 'warning';
    this.modalType = 'setAnswer';
    await this.waitAnswer().then(async res => {
      if (res) {
        array.splice(index, 1);
      }
    });
  }

  async waitAnswer() {
    let res = await this.checkAnswer();
    return res;
  }

  checkAnswer() {
    return new Promise(resolve => {
      let interval = setInterval(() => {
        if (this.userAnswer !== null) {
          const answer = this.userAnswer;
          this.clearErrorMess();
          clearInterval(interval);
          resolve(answer);
        }
      }, 500);
   });
  }

  clearErrorMess() {
    this.logger.info('clearErrorMess',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.clearErrorMess',
                                  'click',
                                  '',
                                  EventProvider.UI,
                                  '',
                                  ''));
    this.error.errorMessage = '';
    this.error.errorType = '';
    this.modalType = '';
    this.userAnswer = null;
  }

  changeWeightAndBalanceView(showItem) {
    this.logger.info('changeWeightAndBalanceView',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.changeWeightAndBalanceView',
                                  'click',
                                  showItem,
                                  EventProvider.UI,
                                  '',
                                  ''));
    if (showItem === 'table') {
      this.chartIsShow = false;
    } else {
      this.chartIsShow = true;
    }

  }

  clearFilter() {
    this.logger.info('clearFilter',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.clearFilter',
                                  'click',
                                  name,
                                  EventProvider.UI,
                                  '',
                                  ''));
    this.filterParams = new FilterParams();
    this.filterApply = false;
    this.filterFlight();
  }

  filterApp() {
    this.logger.info('filterApp',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.filterApp',
                                  'click',
                                  '',
                                  EventProvider.UI,
                                  '',
                                  ''));
    this.filterApply = true;
    this.filterFlight();
  }

  filterSwitch() {
    this.logger.info('filterSwitch',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.filterSwitch',
                                  'click',
                                  '',
                                  EventProvider.UI,
                                  '',
                                  ''));
    this.filterApply = !this.filterApply;
    this.filterFlight();
  }

  filterFlight() {
    this.showFilter = false;
    this.loadFlights();
  }

  clearFilterParametr(field: string, event) {
    this.logger.info('clearFilterParametr',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.clearFilterParametr',
                                  'click',
                                  field,
                                  EventProvider.UI,
                                  '',
                                  ''));
    event.stopPropagation();
    if (field === 'date') {
      delete this.filterParams['start'];
      delete this.filterParams['finish'];
    } else {
      delete this.filterParams[field];
    }
    if (this.quickSearchCheck()) {
      this.filterApply = false;
    }
    this.filterFlight();
  }

  quickSearchCheck() {
    const newFilter = new FilterParams();
    return JSON.stringify(this.filterParams) === JSON.stringify(newFilter);
  }

  async findAircraftTypeByTail(isAhmCalc = null) {
    let xRequestId = this.globalSettings.randomUuid;
    this.logger.info('findAircraftTypeByTail',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.findAircraftTypeByTail',
                                  'call',
                                  isAhmCalc,
                                  EventProvider.APPLICATION,
                                  '',
                                  xRequestId));
    if (this.flight.tailId) {
      await this.restApi.getAhmData(this.flight.tailId, this.flight.id, xRequestId).then(data => {
        this.calculationByAhm = true;
        this.flight.isAhmCalc = isAhmCalc ?? true;
      }, err => {
        this.calculationByAhm = false;
        this.flight.isAhmCalc = isAhmCalc ?? false;;
      });
      for (const tail of this.references.tails) {
        if (tail.id === this.flight.tailId) {
          this.flight.aircraftTypeId = tail.aircraftTypeId;
          this.flight.aircraftTypeIata = tail.aircraftTypeIata;
          break;
        }
      }
    } else {
      this.calculationByAhm = false;
      this.flight.isAhmCalc = false;;
    }
  }

  onClearAircraft() {
    this.flight.tailId = null;
    this.flight.aircraftTypeId = null;
    this.findAircraftTypeByTail();
  }

  changeAhmCalc(value) {
    this.logger.info('changeAhmCalc',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.changeAhmCalc',
                                  'click',
                                  value,
                                  EventProvider.UI,
                                  '',
                                  ''));
    this.flight.isAhmCalc = value;
  }

  updateDowDoiAfterCrewChange($event) {
    this.logger.info('updateDowDoiAfterCrewChange',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.updateDowDoiAfterCrewChange',
                                  'change',
                                  $event,
                                  EventProvider.UI,
                                  '',
                                  ''));
    if ($event) {
      this.calculation.crew.schema.cabin = $event.cabin;
      this.calculation.crew.schema.cockpit = $event.cockpit;
      this.calculation.crew.schema.extra = $event.extra;
      this.calculationCrewComposition = <DowChangesCrew>$event;
      this.deltaDowDoi.standard.dow = $event.dow;
      this.deltaDowDoi.standard.doi = $event.doi;
      // TODO Проверить как отрабаотывает при изменении воды
      if (this.enterFactDowDoiAutomatically) {
        this.calculation.dow = this.deltaDowDoi.calculated.dow;
        this.calculation.doi = this.deltaDowDoi.calculated.doi;
      }
    }
  }

  setUldWeight($event, index) {
    this.logger.info('updateDowDoiAfterCrewChange',
                     new ECSEvent(ModuleName.WeightBalance,
                                  'func.updateDowDoiAfterCrewChange',
                                  'change',
                                  [$event.weight, index],
                                  EventProvider.UI,
                                  '',
                                  ''));
    this.calculation.holdsAndCompartments[index].uldWeight = $event.weight;
  }

  getTotalPassenger() {
    return this.calculation.passengers.cabinArea.reduce((acc, element) => acc += element.passengers.adult, 0);
  }

  placesDeclared() {
    return this.flight.loading.business + this.flight.loading.economy;
  }

  getDocumentValue(edno, field): string | Date {
    for (let edition of this.documentEditions) {
      if (edition.edno === edno) {
        if (field == 'preparedBy') {
          return edition.preparedBy;
        } else if (field == 'preparedAt') {
          return edition.preparedAt.toLocaleString();
        }
      }
    }
    return '';
  }

  displayError(err) {
    if (err.type) {
      this.error.errorType = err.type;
      if (typeof err.message == 'object') {
        this.logger.error('displayError', new ECSEvent(ModuleName.WeightBalance,
                                                      'main.displayError',
                                                      'call',
                                                      err,
                                                      EventProvider.APPLICATION,
                                                      'Error: ' + err.message + '\ndetail:' + err.detail));
        if (this.globalSettings.language == 'en') {
          this.error.errorMessage = err.message[0];
        } else {
          this.error.errorMessage = err.message[1];
        }
      } else {
        this.error.errorMessage = err.message;
      }
    } else {
      this.error.errorMessage = err;
    }
  }

  verifiedMessage: Array<any> = []; // для валидации сообщения
  /**
   * Разбивает сообщение на строки
   *
   * @param {text} текст сообщения
   * @param {textarea} элемент поля ввода
   */
   splitIntoLines(text: string, textarea?) {
    let coursorPosition;
    if (textarea) {
      coursorPosition = textarea.selectionStart;
    }
    let contentArray = [];
    if (text && text != '') {
      contentArray = text.toUpperCase().split(/\n|\r\n/);
    }
    const maxLength = 30;
    const newContent = [];
    this.verifiedMessage = [];
    contentArray.forEach(element => {

      if (element.length > maxLength) {
        if (element[maxLength] != ' ') {
          let line = element.slice(0, maxLength);
          let lineEndIndex = line.lastIndexOf(' ') + 1;
          if (lineEndIndex == 0) {
            newContent.push(element);
            this.verifiedMessage.push(Array(true, element, this.globalI18n.getMessage(ModuleName.ComMan, 'textLineLong')));
          } else {
            while (element.length > maxLength) {
              const lineSlice = line.slice(0, lineEndIndex - 1);
              newContent.push(lineSlice);
              this.verifiedMessage.push(Array(false, lineSlice));
              element = element.slice(lineEndIndex);
              line = element.slice(0, maxLength);
              lineEndIndex = line.lastIndexOf(' ') + 1;
            }
            newContent.push(element);
            this.verifiedMessage.push(Array(false, element));
          }
        } else {
          newContent.push(element.slice(0, maxLength));
          this.verifiedMessage.push(Array(false, element.slice(0, maxLength)));
          newContent.push(element.slice(maxLength));
          this.verifiedMessage.push(Array(false, element.slice(maxLength)));
        }
      } else {
        newContent.push(element);
        this.verifiedMessage.push(Array(false, element));
      }
    });
    if (textarea) {
      textarea.value = newContent.join('\r\n');
      textarea.setSelectionRange(coursorPosition, coursorPosition);
    }
    return newContent.join('\r\n');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let dataWell = this.ahmData.centreOfGravityChart[0];

    if (this.gravityCentreBlock) {
      let width = this.gravityCentreBlock.nativeElement.clientWidth;
      this.context = createCanvas(width, width, this.gravityCentreBlock.nativeElement);
      this.printDisabled = drawGravity(width, width, dataWell, this.chartData, this.ahmData, this.context);
    }

    if (this.gravityCentreBlockBig) {
      let height = document.documentElement.clientHeight - 100;
      this.contextBig = createCanvas(height, height, this.gravityCentreBlockBig.nativeElement);
      this.printDisabled = drawGravity(height, height, dataWell, this.chartData, this.ahmData, this.contextBig);
    }
    this.checkCalculationCorrect();
    event.target.innerWidth;
  }

  /**
   * Функция поиска в выпадающим списке по нескольким параметрам
   * @param {string} term Строка для поиска введеная пользователем
   * @param {ReferanceTail} item Элемент для поиска
   */
  customSelectSearchTail(term: string, item: ReferanceTail) {
    // Преобразуется все в верхний регистр
    // Из строк убираются все символы форматирования бортового номера
    // Производится поиск на вхождение по упрощенным строкам
    return item.tail.toUpperCase().replace(/[-=_+\\\/\s]/g, '').indexOf(term.toUpperCase().replace(/[-=_+\\\/\s]/g, '')) !== -1;
  }

  // Функция сортировки массива Авиакомпаний по коду IATA для удобного
  // просмотра и поиска по выпадающему списку
  get airlinesSortIata(): ReferanceAirline[] {
    return this.references.airlines.sort((a, b) => a.iata.localeCompare(b.iata));
  }

  // Функция сортировки массива Авиакомпаний по коду ICAO для удобного
  // просмотра и поиска по выпадающему списку
  get airlinesSortIcao(): ReferanceAirline[] {
    return this.references.airlines.sort((a, b) => a.icao.localeCompare(b.icao));
  }

  get airportsSortIata(): ReferanceAirport[] {
    return this.references.airports.sort((a, b) => a.iata.localeCompare(b.iata));
  }

  /**
   * Функция поиска в выпадающим списке по нескольким параметрам
   * @param {string} term Строка для поиска введеня пользователем
   * @param {ReferanceAirport} item Элемент для поиска
   */
  customSelectSearchAirline(term: string, item) {
    term = term.toLowerCase();
    return term.length < 3 ?
            (item.iata && item.iata.toLowerCase().indexOf(term) > -1) ||
            (item.code && item.code[1] && item.code[1].toLowerCase().indexOf(term) > -1)
          : term.length < 4 ?
            (item.icao && item.icao.toLowerCase().indexOf(term) > -1)
          :
            (item.name && item.name[0] && item.name[0].toLowerCase().indexOf(term) > -1) ||
            (item.name && item.name[1] && item.name[1].toLowerCase().indexOf(term) > -1);
  }

  checkCalculationCorrect() {
    if (this.chartData && this.chartData.length > 0 && !this.printDisabled) {
      this.calculationCorrect = true;
    } else {
      this.calculationCorrect = false;
    }
  }

  unallocatedSeats(): number {
    return this.placesDeclared() + this.calculation.crew.additional.inCabin - this.getTotalPassenger();
  }

  /**
   * Функция получения зоны рассадки
   * @param {Array} cabinArea массив с секциями
   * @param {string} zone зона (AFT или FWD)
   */
  getZoneSeating(cabinArea, zone: string) {
    let result: Array<CabinArea> = [];
    switch (zone) {
      case 'FWD':
        result = cabinArea.filter(el => el.index < 0);
        break;
      case 'AFT':
        result = cabinArea.filter(el => el.index > 0);
        break;
    }
    return result;
  }

  /* TODO: реализовать отображение цифр с пробелами */
  formatNumber(number) {
    return new Intl.NumberFormat('ru-RU').format(number);
  }

  strToNumber(str) {
    return +str.replaceAll(' ', '');
  }

  changeEnterFactDowDoiAutomatically() {
    this.enterFactDowDoiAutomatically = !this.enterFactDowDoiAutomatically;
    if (this.enterFactDowDoiAutomatically) {
      this.calculation.dow = this.deltaDowDoi.calculated.dow;
      this.calculation.doi = this.deltaDowDoi.calculated.doi;
    }
  }
  /* END TODO */

  changeAirport(route, item) {
    route.airportIata = item.iata;
    route.airportIcao = item.icao;
    route.airportCode = item.code;
  }
}
